import React, {useEffect, useState} from 'react';
import SearchBox, {SearchBoxSuffix} from "src/components/SearchBox";
import Filter from "src/icons/fluency-regular/Filter";
import  {InnerColumnWrapper} from "src/layouts/TopbarLayout";
import {useHistory, useRouteMatch} from "react-router-dom";
import Box from "src/components/Box";
import {useRestriction} from 'src/hooks';
import {UserPermissionsEnum} from 'src/common/permissions';
import {useDosagesQuery} from "src/redux/API/dosageAPI";
import {layouts, Pagination,Table,Button} from "@med-io/neural-ui";
const {TopBarLayout} = layouts;

function DosageForms() {
    const {path} = useRouteMatch();
    const history = useHistory();
    const {isPermitted} = useRestriction()
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const limit = 30;

    const {data, isFetching} = useDosagesQuery({limit, page, search})

    const columns = [
        {
            title: "Наименование",
            dataIndex: "name",
            render: (cellData: any) => cellData.ru
        }
    ]


    const onSearch = (search: string) => setSearch(search)

    return (
        <TopBarLayout
            title="Лекрственные формы"
            topBarActions={
                isPermitted(UserPermissionsEnum["dosage_form.create"]) ?
                    <Button onClick={() => history.push('/catalog/dosageForms/new')} variant='primary' size='md'>Добавить лек. форму</Button> : <></>
            }
            bottomBar={
                <Pagination total={data?.count || 0} defaultPageSize={limit}
                            onChange={setPage} current={page}/>
            }
        >
            <InnerColumnWrapper>
                <SearchBox delayedSearch onSearch={onSearch} defaultValue={search}
                           suffix={<><SearchBoxSuffix onClick={() => console.log("Filter click")}><Filter
                               size={20}/></SearchBoxSuffix></>}/>
                <Box display='block'>
                    <Table
                        columns={columns}
                        data={data?.items || []}
                        onRow={(row) => ({
                            onClick: () => history.push({
                                pathname: `/catalog/dosageForms/${row.id}`,
                                state: {dosageForm: row}
                            })
                        })}
                        loading={isFetching}
                        size='md'
                        rowKey='id'
                        scroll = {{y: '65vh'}}
                    />
                </Box>
            </InnerColumnWrapper>
        </TopBarLayout>
    )

}

export default DosageForms;
