import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { UserPermissionsEnum } from "src/common/permissions";
import Product from "src/screens/catalog/products/Product";
import Products from "src/screens/catalog/products/Products";
import Symptoms from "src/screens/catalog/symptoms";
import NewSymptom from "src/screens/catalog/symptoms/NewSymptom";
import Manufacturers from "src/screens/catalog/manufacturers/Manufacturers";
import Manufacturer from "src/screens/catalog/manufacturers/Manufacturer";
import Categories from "src/screens/catalog/categories/Categories";
import Category from "src/screens/catalog/categories/Category";
import INNs from "src/screens/catalog/inn/INNs";
import INN from "src/screens/catalog/inn/INN";
import ATCs from "src/screens/catalog/atc/ATCs";
import ATC from "src/screens/catalog/atc/ATC";
import DosageForms from "src/screens/catalog/dosageForms/DosageForms";
import DosageForm from "src/screens/catalog/dosageForms/DosageForm";
import ReleaseForms from "src/screens/catalog/relaseForms/ReleaseForms";
import ReleaseForm from "src/screens/catalog/relaseForms/ReleaseForm";
import ATCsTree from "src/screens/catalog/atc/ATCsTree";
import CategoriesTree from "src/screens/catalog/categories/CategoriesTree";
import RestrictedRoute from "src/routes/RestrictedRoute";
import { ProductVariantRoutes } from "./product";
import ProductVariants from "src/screens/catalog/productVariants/ProductVariants";
import { ProductVariantImport } from "src/screens/catalog/import/ProductVariantImport";

export const CatalogRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
        <Route exact path={path + "/"}>
            <Redirect to='/' />
        </Route>
      <RestrictedRoute permission={UserPermissionsEnum["product.read"]} exact path={path + "/products"}
                       component={Products} />
      <RestrictedRoute permission={UserPermissionsEnum["product.read"]} exact path={path + "/products/:id"}
                       component={Product} />
      <RestrictedRoute permission={UserPermissionsEnum["product.read"]}
                       path={path + "/products/:id/variants/:product_variant_id"}
                       component={ProductVariantRoutes} />
      <RestrictedRoute permission={UserPermissionsEnum["product_variants.read"]} exact path={path + "/productVariants"}
                       component={ProductVariants} />
      <RestrictedRoute permission={UserPermissionsEnum["symptom.read"]} exact path="/catalog/symptoms"
                       component={Symptoms} />
      <RestrictedRoute permission={UserPermissionsEnum["symptom.read"]} exact path="/catalog/symptoms/:id"
                       component={NewSymptom} />
      <RestrictedRoute permission={UserPermissionsEnum["manufacturer.read"]} exact path="/catalog/manufacturers"
                       component={Manufacturers} />
      <RestrictedRoute permission={UserPermissionsEnum["manufacturer.read"]} exact path="/catalog/manufacturers/:id"
                       component={Manufacturer} />
      <RestrictedRoute permission={UserPermissionsEnum["inn.read"]} exact path="/catalog/inns" component={INNs} />
      <RestrictedRoute permission={UserPermissionsEnum["inn.read"]} exact path="/catalog/inns/:id" component={INN} />
      <RestrictedRoute permission={UserPermissionsEnum["atc.read"]} exact path="/catalog/atcs" component={ATCs} />
      <RestrictedRoute permission={UserPermissionsEnum["atc.read"]} exact path="/catalog/atcs/tree"
                       component={ATCsTree} />
      <RestrictedRoute permission={UserPermissionsEnum["atc.read"]} exact path="/catalog/atcs/:id" component={ATC} />
      <RestrictedRoute permission={UserPermissionsEnum["category.read"]} exact path="/catalog/categories"
                       component={Categories} />
      <RestrictedRoute permission={UserPermissionsEnum["category.read"]} exact path="/catalog/categories/tree"
                       component={CategoriesTree} />
      <RestrictedRoute permission={UserPermissionsEnum["category.read"]} exact path="/catalog/categories/:id"
                       component={Category} />
      <RestrictedRoute permission={UserPermissionsEnum["release_form.read"]} exact path="/catalog/releaseForms"
                       component={ReleaseForms} />
      <RestrictedRoute permission={UserPermissionsEnum["release_form.read"]} exact path="/catalog/releaseForms/:id"
                       component={ReleaseForm} />
      <RestrictedRoute permission={UserPermissionsEnum["dosage_form.read"]} exact path="/catalog/dosageForms"
                       component={DosageForms} />
      <RestrictedRoute permission={UserPermissionsEnum["dosage_form.read"]} exact path="/catalog/dosageForms/:id"
                       component={DosageForm} />
      <RestrictedRoute permission={UserPermissionsEnum["product.create"]} exact path={path + "/import"}
                       component={ProductVariantImport} />
    </Switch>
  );
};
