import React, { memo, useState } from 'react'
import styled from 'styled-components'
import {Check} from "@med-io/neural-ui";
import CheckBox from "src/components/CheckBox";
interface Props {
  isHorizontal?: boolean
  width?: string
  height?: string
}

const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
`

const ListItem = styled.div<Props>`
  display: flex;
  padding: 5px;
  gap: 20px;
  margin-bottom: 5px;
  width: 100%;
  align-items: center;
  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;

  &:last-child {
    margin-bottom: ${(p) => (p.isHorizontal ? '5px' : '0')};
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: ${(p) => p.theme.borderRadius.default}px;
  }
`

const LabelStyled = styled.label`
  color: #9c9c9c;
  font-weight: 400;
  font-size: 14px;
`

const ListWrapper = styled.div<Props>`
  display: flex;
  flex-direction: ${(p) => (p.isHorizontal ? 'row' : 'column')};
  justify-content: ${(p) => (p.isHorizontal ? 'space-between' : 'normal')};
  align-items: ${(p) => (p.isHorizontal ? 'center' : 'normal')};
`

export type checkBoxListOnSelectProps = {
  selectedKeys: Array<string | number>
  key: { status: boolean; value: string | number }
}

interface ComponentProps {
  data: Array<{ value: string | number; label: string }>
  label?: string
  onSelect?: (result: checkBoxListOnSelectProps) => void
  selectedKeys?: Array<number | string>
  selectedKey?: number | string
  valueKey?: string
  labelKey?: string
  isRadio?: boolean
  isHorizontal?: boolean
}

const CheckBoxList: React.FC<ComponentProps> = memo((props) => {
  const handleSelect = (
      status: boolean,
      value: string | number,
      index: number,
      indexInSelected: number,
  ) => {
    if (props.onSelect) {
      if (props.selectedKeys) {
        let selectedKeys: Array<string | number> = [...props.selectedKeys]
        if (status) {
          selectedKeys.push(value)
        } else {
          selectedKeys.splice(indexInSelected, 1)
        }
        props.onSelect({ selectedKeys, key: { status, value } })
      } else if (props.selectedKey) {
        props.onSelect({ selectedKeys: [value], key: { status, value } })
      }
    } else {
      console.error('CheckBoxList: Missing onSelect prop')
    }
  }

  return (
      <>
        <Wrapper isHorizontal={props.isHorizontal}>
          {props.label && <LabelStyled>{props.label}</LabelStyled>}
          <ListWrapper isHorizontal={props.isHorizontal}>
            {props.data.map((item: any, index: number) => {
              const selectedIndex = props.selectedKeys ? props.selectedKeys.indexOf(item.value) : -1
              let isChecked = props.isRadio ? props.selectedKey === item.value : selectedIndex > -1

              return (
                  <ListItem
                      key={index}
                      isHorizontal={props.isHorizontal}
                      onClick={() => {
                        handleSelect(!isChecked, item.value, index, selectedIndex)
                      }}
                  >
                    <Check type="checkbox" onChange={()=>{}} key={item.value} size="md" checked={isChecked} checkStyle="check" variant="square"/>
                    <label htmlFor={item.label} key={item.label}>
                      {item.label}
                    </label>
                  </ListItem>
              )
            })}
          </ListWrapper>
        </Wrapper>
      </>
  )
})

export default CheckBoxList
