import React, { PureComponent } from "react";
import styled, { withTheme } from "styled-components";
import { color, fontSize, space } from "styled-system";
import noop from "lodash/noop";
import get from "lodash/get";

import { darken, lighten, rgba } from "src/styles/helpers";
import fontFamily from "src/styles/styled/fontFamily";
import { focusedShadowStyle } from "src/components/Box/Tabbable";

import Spinner from "src/components/Spinner";
import { withProps } from "src/typed";
import { Link } from "react-router-dom";
import { Theme } from "src/styles/theme";

type Style = any;

const styledWithProps = {
  div: withProps<Props>()(styled.div),
  button: withProps<Props>()(styled.button)
};

const buttonStyles: { [_: string]: Style } = {
  default: {
    default: (p: { isFocused: any; disabled: any; theme: { colors: { palette: { text: { shade20: any; }; }; }; }; }) => `
      box-shadow: ${p.isFocused ? focusedShadowStyle : ""};
      ${p.disabled ? `color: ${p.theme.colors.palette.text.shade20}` : ""}
    `,
    active: (p: { theme: { colors: { palette: { divider: string; }; }; }; }) => `
      background: ${rgba(p.theme.colors.palette.divider, 0.2)};
    `,
    hover: (p: { theme: { colors: { palette: { divider: string; }; }; }; }) => `
      background: ${rgba(p.theme.colors.palette.divider, 0.1)};
    `,
  },
  primary: {
    default: (p: { disabled: any; theme: { colors: { palette: { action: { disabled: any; }; primary: { contrastText: any; main: string; }; text: { shade20: any; }; }; }; }; inverted: any; isFocused: any; }) => `
      background: ${
      p.disabled
        ? `${lighten(p.theme.colors.palette.primary.main, 0.3)} !important`
        : p.inverted
          ? p.theme.colors.palette.primary.contrastText
          : p.theme.colors.palette.primary.main
    };
      color: ${
      p.disabled
        ? p.theme.colors.palette.text.shade20
        : p.inverted
          ? p.theme.colors.palette.primary.main
          : p.theme.colors.palette.primary.contrastText
    };
      box-shadow: ${
      p.isFocused
        ? `
          0 0 0 1px ${darken(p.theme.colors.palette.primary.main, 0.3)} inset,
          0 0 0 1px ${rgba(p.theme.colors.palette.primary.main, 0.5)},
          0 0 0 3px ${rgba(p.theme.colors.palette.primary.main, 0.3)};`
        : ""
    }
    `,
    hover: (p: { inverted: any; theme: { colors: { palette: { primary: { contrastText: string; main: string; }; }; }; }; }) => `
       background: ${
      p.inverted
        ? darken(p.theme.colors.palette.primary.contrastText, 0.1)
        : lighten(p.theme.colors.palette.primary.main, 0.2)
    };
     `,
    active: (p: { inverted: any; theme: { colors: { palette: { primary: { contrastText: string; main: string; }; }; }; }; }) => `
       background: ${
      p.inverted
        ? darken(p.theme.colors.palette.primary.contrastText, 0.1)
        : darken(p.theme.colors.palette.primary.main, 0.1)
    };
     `,
  },
  danger: {
    default: (p: { disabled: any; theme: { colors: { palette: { action: { disabled: any; }; text: { shade20: any; }; primary: { contrastText: any; }; }; alertRed: string; }; }; isFocused: any; }) => `
      background: ${
      p.disabled
        ? `${p.theme.colors.palette.action.disabled} !important`
        : p.theme.colors.alertRed
    };
      color: ${
      p.disabled
        ? p.theme.colors.palette.text.shade20
        : p.theme.colors.palette.primary.contrastText
    };
      box-shadow: ${
      p.isFocused
        ? `
          0 0 0 1px ${darken(p.theme.colors.alertRed, 0.3)} inset,
          0 0 0 1px ${rgba(p.theme.colors.alertRed, 0.5)},
          0 0 0 3px ${rgba(p.theme.colors.alertRed, 0.3)};
        `
        : ""
    }
    `,
    hover: (p: { theme: { colors: { alertRed: string; }; }; }) => `
      background: ${lighten(p.theme.colors.alertRed, 0.1)};
     `,
    active: (p: { theme: { colors: { alertRed: string; }; }; }) => `
      background: ${darken(p.theme.colors.alertRed, 0.1)};
     `,
  },
  secondary: {
    default: (p: { disabled: any; theme: { colors: { palette: { action: { disabled: any; hover: any; }; text: { shade20: any; }; primary: { main: string; }; }; }; }; isFocused: any; }) => `
      background: ${
      p.disabled
        ? `${p.theme.colors.palette.action.disabled} !important`
        : "#EFEFF0"
    };
      color: ${
      p.disabled
        ? `${p.theme.colors.palette.text.shade20} !important`
        : "#404145"
    };
      box-shadow: ${
      p.isFocused
        ? `
          0 0 0 1px ${darken(p.theme.colors.palette.primary.main, 0.3)} inset,
          0 0 0 1px ${rgba(p.theme.colors.palette.primary.main, 0.5)},
          0 0 0 3px ${rgba(p.theme.colors.palette.primary.main, 0.3)};`
        : ""
    }
    `,
    hover: (p: { theme: { colors: { palette: { action: { hover: string; }; }; }; }; }) => `
       background: ${lighten("#EFEFF0", 0.01)};
     `,
    active: (p: { theme: { colors: { palette: { action: { hover: string; }; }; }; }; }) => `
       background: ${darken(p.theme.colors.palette.action.hover, 0.1)};
     `,
  },
  lighterDanger: {
    default: (p: { disabled: any; theme: { colors: { palette: { action: { disabled: any; }; }; alertRed: any; }; }; }) => `
      color: ${
      p.disabled
        ? `${p.theme.colors.palette.action.disabled} !important`
        : p.theme.colors.alertRed
    };
    `,
    hover: (p: { theme: { colors: { alertRed: any; }; }; }) => `
      color: ${p.theme.colors.alertRed};
     `,
    active: (p: { theme: { colors: { alertRed: any; }; }; }) => `
      color: ${p.theme.colors.alertRed};
     `,
  },
  outline: {
    default: (p: { outlineColor: any; theme: { colors: { palette: { primary: { main: any; }; }; }; }; isFocused: any; }) => {
      const c = p.outlineColor
        ? get(p.theme.colors, p.outlineColor) || p.outlineColor
        : p.theme.colors.palette.primary.main;

      return `
        background: transparent;
        border: 1px solid ${c};
        color: ${c};
        box-shadow: ${
        p.isFocused
          ? `
            0 0 0 3px ${rgba(c, 0.3)};`
          : ""
      }
      `;
    },
    hover: (p: { outlineColor: any; theme: { colors: { palette: { primary: { main: any; }; }; }; }; }) => {
      const c = p.outlineColor
        ? get(p.theme.colors, p.outlineColor) || p.outlineColor
        : p.theme.colors.palette.primary.main;
      return `
        background: ${rgba(c, 0.1)};
      `;
    },
    active: (p: { outlineColor: any; theme: { colors: { palette: { primary: { main: string; }; }; }; }; }) => {
      const c = p.outlineColor
        ? get(p.theme.colors, p.outlineColor) || p.outlineColor
        : p.theme.colors.palette.primary.main;
      return `
        background: ${rgba(c, 0.15)};
        color: ${darken(
        p.outlineColor
          ? get(p.theme.colors, p.outlineColor) || p.outlineColor
          : p.theme.colors.palette.primary.main,
        0.1,
      )};
        border-color: ${darken(
        p.outlineColor
          ? get(p.theme.colors, p.outlineColor) || p.outlineColor
          : p.theme.colors.palette.primary.main,
        0.1,
      )};
      `;
    },
  },
  outlineGrey: {
    default: (p: { theme: { colors: { palette: { text: { shade60: any; }; }; }; }; isFocused: any; }) => `
      background: transparent;
      border: 1px solid ${p.theme.colors.palette.text.shade60};
      color: ${p.theme.colors.palette.text.shade60};
      box-shadow: ${p.isFocused ? focusedShadowStyle : ""}
    `,
    active: (p: { theme: { colors: { palette: { text: { shade60: string; }; }; }; }; }) => `
      color: ${darken(p.theme.colors.palette.text.shade60, 0.1)};
      border-color: ${darken(p.theme.colors.palette.text.shade60, 0.1)};
    `,
  },
  icon: {
    default: () => `
      font-size: ${fontSize}px;
      padding-left: 10px;
      padding-right: 10px;
    `,
  },
  isLoading: {
    default: () => `
      // padding-left: 40px;
      padding-right: 40px;
      pointer-events: none;
      opacity: 0.7;
    `,
  },
};

function getStyles(props: any, state: any) {
  let output = "";
  let hasModifier = false;
  for (const s in buttonStyles) {
    if (buttonStyles.hasOwnProperty(s) && props[s] === true) {
      const style = buttonStyles[s][state];
      if (style) {
        hasModifier = true;
        output += style(props);
      }
    }
  }
  if (!hasModifier) {
    const defaultStyle = buttonStyles.default[state];
    if (defaultStyle) {
      output += defaultStyle(props) || "";
    }
  }

  return output;
}

const LoadingWrapper: any = withProps<Props>()(styledWithProps.div)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChildrenWrapper = withProps<Props>()(styledWithProps.div)`
  opacity: ${p => (p.isLoading ? 0 : 1)};
  flex-shrink: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
`;

export const Base: any = withProps<Props>()(styledWithProps.button).attrs((p: { fontSize: any; small: any; color: any; theme: { colors: { palette: { text: { shade60: any; }; }; }; }; }) => ({
  fontSize: p.fontSize || (!p.small ? 4 : 3),
  px: !p.small ? 4 : 3,
  py: !p.small ? 2 : 0,
  color: p.color || p.theme.colors.palette.text.shade60,
  bg: "transparent",
}))`
  ${space};
  ${color};
  ${fontSize};
  ${fontFamily};
  font-weight: 500;
  border: none;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: row;
  align-items: center;
  border-radius: ${p => p.theme.borderRadius.default}px;
  cursor: ${(p: any) => (p.disabled ? "not-allowed" : "pointer")};
  height: ${(p: any) => (p.small ? 34 : 40)}px;
  pointer-events: ${(p: any) => (p.disabled ? "none" : "")};
  outline: none;
  text-decoration: none;
  width: ${(p: any) => (p.fullWidth ? '100%' : 'unset')};

  ${(p: any) => getStyles(p, "default")};

  &:hover {
    ${(p: any) => getStyles(p, "hover")};
  }

  &:active {
    ${(p: any) => getStyles(p, "active")};
  }

  &:focus {
    ${(p: any) => getStyles(p, "focus")};
  }
`;

export type Props = {
  children?: any,
  icon?: boolean,
  primary?: boolean,
  type?: string,
  inverted?: boolean, // only used with primary for now
  secondary?: boolean,
  danger?: boolean,
  lighterDanger?: boolean,
  disabled?: boolean,
  outline?: boolean,
  outlineGrey?: boolean,
  onClick?: Function,
  small?: boolean,
  isLoading?: boolean,
  event?: string,
  eventProperties?: Object,
  mr?: number,
  ml?: number,
  mx?: number,
  my?: number,
  mt?: number,
  mb?: number,
  fullWidth?: boolean,
  to?: string,
  theme: Theme
};

class Button extends PureComponent<Props,
  {
    isFocused: boolean,
  }> {
  static defaultProps = {
    onClick: noop,
    primary: false,
    small: false,
    danger: false,
    inverted: false,
  };

  state = {
    isFocused: false,
  };

  handleFocus = () => {
    if (process.env.REACT_APP_isGlobalTabEnabled) {
      this.setState({ isFocused: true });
    }
  };

  handleBlur = () => {
    this.setState({ isFocused: false });
  };

  render() {
    const { isFocused } = this.state;
    const { disabled, to } = this.props;
    const { onClick, children, isLoading, event, eventProperties, theme, ...rest } = this.props;
    const isClickDisabled = disabled || isLoading;
    const onClickHandler = (e: any) => {
      if (onClick) {
        if (event) {
          // track(event, eventProperties);
        }
        onClick(e);
      }
    };
    return (
      <Base
        {...rest}
        onClick={isClickDisabled ? undefined : onClickHandler}
        isFocused={isFocused}
        disabled={isClickDisabled}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        as={to && Link}
        to={to}
      >
        {isLoading ? (
          <LoadingWrapper>
            <Spinner size={28} color={(this.props.primary || this.props.danger) ? theme.colors.palette.primary.contrastText : theme.colors.palette.primary.main} />
          </LoadingWrapper>
        ) : null}
        <ChildrenWrapper isLoading={isLoading}>{children}</ChildrenWrapper>
      </Base>
    );
  }
}

export default withTheme(Button);
