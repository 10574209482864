
import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../common";

import {
    CreateManufacturerRequest,
    CreateManufacturerResponse,
    DeleteManufacturerRequest,
    DeleteManufacturerResponse,
    ManufacturerByIdRequest,
    ManufacturerByIdResponse,
    ManufacturersRequest,
    ManufacturersResponse,
    UpdateManufacturerRequest,
    UpdateManufacturerResponse
} from "src/redux/API/manufacturerAPI/manufacturerAPI.types";

export const manufacturerAPI = createApi({
    reducerPath: 'manufacturerAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        manufacturers: builder.query<ManufacturersResponse, ManufacturersRequest>({
            query: ({
                        limit,
                        page,
                        search,
                        order_by
                    }) => `/v1/manufacturer?limit=${limit}&page=${page}&search=${search}&order_by=${order_by}`
        }),
        createManufacturer: builder.mutation<CreateManufacturerResponse, CreateManufacturerRequest>({
            query: (payload) => ({
                url: `/v1/manufacturer`,
                method: 'POST',
                body: payload
            })
        }),
        manufacturerById: builder.query<ManufacturerByIdResponse, ManufacturerByIdRequest>({
            query: ({id}) => `/v1/manufacturer/${id}`
        }),
        updateManufacturer: builder.mutation<UpdateManufacturerResponse, UpdateManufacturerRequest>({
            query: ({id, ...payload}) => ({
                url: `/v1/manufacturer/${id}`,
                method: 'PUT',
                body: payload
            })
        }),
        deleteManufacturer: builder.mutation<DeleteManufacturerResponse, DeleteManufacturerRequest>({
            query: ({id}) => ({
                url: `/v1/manufacturer/${id}`,
                method: 'DELETE'
            })
        })
    }),
})

export const {
    useManufacturersQuery,
    useCreateManufacturerMutation,
    useManufacturerByIdQuery,
    useUpdateManufacturerMutation,
    useDeleteManufacturerMutation
} = manufacturerAPI