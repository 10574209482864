import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRestriction } from "src/hooks";
import { UserPermissionsEnum } from "src/common/permissions";
import { Box } from "src/components/Box/Box";
import SearchBox from "src/components/SearchBox";
import { Product, ProductsRequestFilters, useProductsQuery } from "src/redux/API/productAPI";
import { Table, TableColumns,layouts } from "@med-io/neural-ui";
import {SectionWithHeader} from "src/components/SectionWithHeader";
const {TopBarLayout} = layouts


const columns: TableColumns<Product> = [
    {
        title: "Наименование",
        dataIndex: "name",
        render: (cell) => cell.ru
    },
    {
        title: "Страна",
        dataIndex: "country",
        render: (cell) => cell.name
    }
]

type Props = {
    id: string;
    title: string;
    filter_by: keyof Omit<ProductsRequestFilters, 'search'>;
}

export const ProductsList = ({ id, title, filter_by }: Props) => {
    const history = useHistory()
    const { isPermitted } = useRestriction()
    const [filters, setFilters] = useState<{
        limit: number;
        page: number;
        search: string;
    }>({
        limit: 10,
        page: 1,
        search: ""
    })

    const { data, isFetching, isError, refetch } = useProductsQuery({
        ...filters,
        [filter_by]: id
    }, {
        skip: !isPermitted(UserPermissionsEnum["product.read"])
    })

    const onSearch = async (search: string) => {
        const value = search.trim()
        setFilters(prev => ({
            ...prev,
            search: value,
            page: 1
        }))
    }

    const onPageChange = useCallback((page: number) => {
        setFilters(prev => ({
            ...prev,
            page
        }))
    }, [])

    const onRowClick = useCallback((row) => history.push(`/catalog/products/${row.id}`), [])

    if (!isPermitted(UserPermissionsEnum["product.read"])) return null;

    return (
        <SectionWithHeader title={title}>
            <Box display="block" height="100%" overflowY="hidden">
                           <Box>
                               <SearchBox onSearch={onSearch} delayedSearch />
                           </Box>
                               <Table<Product>
                               columns={columns}
                               data={data?.items || []}
                               onRow={(row) => ({ onClick: () => onRowClick(row) })}
                               rowKey="id"
                               size="md"
                               scroll={{ y: '70vh' }}
                               loading={isFetching}
                           />
                   </Box>
        </SectionWithHeader>
    )
}
