import httpService from 'src/apis/httpService'
import { login } from './types'

export default {
    login(body: login) {
        return httpService.post('/v1/auth', body)
    },
    logout() {
        return httpService.delete('/v1/auth/logout')
    },
    isAuthenticated() {
        return httpService.get('/v1/auth/is-authenticated')
    }
}
