import React from 'react'
import styled from 'styled-components'
import TopBar from 'src/components/Menus/TopBar'
import TopMenu from 'src/components/Menus/TopMenu'
import { AutoSizer } from 'react-virtualized'

export const InnerColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  & > *:last-child {
    flex: 1;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: auto;
  min-height: 0;
  border-radius: ${p => p.theme.borderRadius.default}px;
  overflow: hidden;
`

const ContentWrapper: any = styled.div`
  background-color: ${(p) => p.theme.colors.palette.background.paper};
  width: 100%;
  padding: 30px;
  flex: auto;
  overflow: auto;
  min-height: 0;
  min-width: 0;
  flex-direction: column;

  &.noPd {
    padding: 0;
  }
`

const Footer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  background-color: #fff;
  border-top: solid 1px;
  border-color: ${(p) => p.theme.colors.palette.divider};
`

type Props = {
  title: string
  children?: JSX.Element | JSX.Element[]
  menuItems?: JSX.Element | JSX.Element[]
  onBack?: () => void
  noBack?: boolean
  prefix?: JSX.Element
  noPrefixPd?: boolean
  noChildrenPd?: boolean
  footer?: JSX.Element
  noPrefixSuffix?: boolean
}

function TopbarLayout({
                        title,
                        children,
                        menuItems,
                        onBack,
                        prefix,
                        noBack,
                        noPrefixPd,
                        noChildrenPd,
                        footer,
                        noPrefixSuffix
                      }: Props) {
  return (
    <Wrapper>
      <TopBar
        noBack={noBack}
        title={title}
        onBack={onBack}
        prefix={prefix}
        noPrefixPd={noPrefixPd}
        noPrefixSuffix={noPrefixSuffix}
      >
        {menuItems && <TopMenu>{menuItems}</TopMenu>}
      </TopBar>
      <ContentWrapper className={noChildrenPd ? 'noPd' : ''}>
        <AutoSizer>
          {({ height, width }) => {
            return <div style={{ height, width }}>{children}</div>
          }}
        </AutoSizer>
      </ContentWrapper>
      {footer && <Footer>{footer}</Footer>}
    </Wrapper>
  )
}

export default TopbarLayout
