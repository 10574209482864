import httpService from 'src/apis/httpService'

export default {
  getPermissions(params: any = {}) {
    return httpService.get('/v1/permission', { params })
  },
  getOnePermission(id: number) {
    return httpService.get('/v1/permission/' + id)
  },
  createPermission(body: any) {
    return httpService.post('/v1/permission', body)
  },
  updatePermission(body: any, id: string) {
    return httpService.put('/v1/permission/' + id, body)
  },
  deletePermission(id: number) {
    return httpService.delete('/v1/permission/' + id)
  },
}