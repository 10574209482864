import React, { useCallback, useMemo, useState } from "react";
import Button from "src/components/Button";
import styled, { keyframes } from "styled-components";
import { rgba } from "src/styles/helpers";
import { withProps } from "src/typed";
import { useDispatch, useSelector } from "react-redux";
import Dismiss from "src/icons/fluency-regular/Dismiss";
import {
  DefaultModalHeader,
  ModalBody,
  ModalDimmer,
  ModalFooter,
  ModalMainWrapper,
  ModalWrapper
} from "src/components/Modal";
import { zoomIn as animationType } from 'react-animations';

const animation = keyframes`${animationType}`;

const ModalWrapperStyled: any = styled(ModalWrapper)`
  animation: 0.2s ${animation};
`;

// FIXME: Use one modal component. Import existing one. Fix dimmer duplicating conflict

function Modal({
                 visible,
                 children,
                 onClose
               }: { visible?: boolean, children?: JSX.Element | JSX.Element[], onClose?: () => void }) {
  const dispatch = useDispatch()
  const isOpen = visible
  //
  // const handleClose = useCallback(() => {
  //   onClose && onClose() || dispatch(closeModal())
  // }, [closeModal]);

  if (!isOpen) return null

  return (
    <ModalMainWrapper>
      {isOpen && <ModalDimmer onClick={onClose} />}
      <ModalWrapperStyled>
        {children}
      </ModalWrapperStyled>
    </ModalMainWrapper>
  );
}

type Props = {
  children: JSX.Element,
  title?: string,
  okText?: string,
  cancelText?: string,
  description?: string | JSX.Element,
  visible?: boolean,
  onConfirm: () => void,
  onCancel?: () => void,
  okButton?: JSX.Element,
  cancelButton?: JSX.Element
}

function Confirm({
                   children,
                   title,
                   okText,
                   cancelText,
                   description,
                   visible,
                   onConfirm,
                   onCancel,
                   okButton,
                   cancelButton,
                 }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const handleConfirm = useCallback(() => {
    onConfirm();
    setIsOpen(false);
  }, [onCancel]);

  const handleCancel = useCallback(() => {
    onCancel && onCancel();
    setIsOpen(false);
  }, [onCancel]);

  const okBtn = useMemo(() => {
    if (okButton) {
      return React.cloneElement(okButton, { onClick: () => handleConfirm() });
    } else {
      return <Button ml={2} onClick={() => handleConfirm()} primary>{okText || "Да"}</Button>;
    }
  }, [okButton]);

  const cancelBtn = useMemo(() => {
    if (cancelButton) {
      return React.cloneElement(cancelButton, { onClick: () => handleCancel() });
    } else {
      return <Button onClick={() => handleCancel()} secondary>{cancelText || "Нет"}</Button>;
    }
  }, [cancelButton]);

  return (
    <>
      <Modal visible={isOpen || visible} onClose={() => handleCancel()}>
        <DefaultModalHeader title={title || ""} onClose={() => handleCancel()} />
        <ModalBody>{description}</ModalBody>
        <ModalFooter stretched>
          {cancelBtn}
          {okBtn}
        </ModalFooter>
      </Modal>
      {React.cloneElement(children, { onClick: () => setIsOpen(true) })}
    </>
  );
}

export default Confirm;