import React, { useState } from 'react';
import styled from 'styled-components';
import Avatar from "react-avatar";
import { AvatarPickerProps } from "src/components/AvatarPicker/types";

const Wrapper = styled.div`
  position: relative;
  width: min-content;
  cursor: pointer;
`

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`

const AvatarStyled = styled(Avatar)`
  & {
    border: ${p => `solid 1px ${p.theme.colors.palette.divider}`};
    border-radius: ${p => p.round ? 'unset' : p.theme.borderRadius.default}px;
  }

  & > * {
    border: none;
  }
`

export const AvatarPicker = (props: AvatarPickerProps) => {
  const { onFilePicked, fileTypes, src, size, round } = props;
  const [url, setUrl] = useState("")
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      const file = e.target.files[0] as File & { url: string }
      const fileUrl = (window.URL || window.webkitURL).createObjectURL(file)
      file['url'] = fileUrl
      setUrl(fileUrl)
      onFilePicked(file);
    }
  }

  const accept = fileTypes?.join(", ").trim() || ''

  return (
    <Wrapper>
      <AvatarStyled color='unset' round={round} src={url.length ? url : src} size={size} />
      <Input type='file' onChange={onChange} accept={accept} />
    </Wrapper>
  );
};
