import React from "react";

const ArrowLeft = ({ size = 30, color = '#000' }: { size?: number, color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.4159 24.7376C13.7907 25.0948 14.3842 25.0803 14.7414 24.7054C15.0984 24.3305 15.084 23.737 14.7091 23.3799L6.89484 15.9366H25.3125C25.8302 15.9366 26.25 15.5169 26.25 14.9991C26.25 14.4814 25.8302 14.0616 25.3125 14.0616H6.89471L14.7091 6.61829C15.084 6.26117 15.0984 5.66776 14.7414 5.29285C14.3842 4.91795 13.7907 4.90351 13.4159 5.26062L4.14224 14.094C3.93108 14.2951 3.80614 14.5516 3.76741 14.8184C3.75599 14.8769 3.75 14.9374 3.75 14.9991C3.75 15.0611 3.75601 15.1216 3.76747 15.1803C3.80627 15.4469 3.9312 15.7033 4.14224 15.9043L13.4159 24.7376Z" />
  </svg>
);

export default ArrowLeft;
