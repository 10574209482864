import React from "react";

const Navigation = ({ size = 30, color = '#000' }: { size?: number, color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.44053 22.4999H26.5655C27.0833 22.4999 27.503 22.9196 27.503 23.4374C27.503 23.912 27.1504 24.3042 26.6928 24.3664L26.5655 24.3749H3.44053C2.92277 24.3749 2.50303 23.9551 2.50303 23.4374C2.50303 22.9627 2.85573 22.5705 3.31332 22.5085L3.44053 22.4999H26.5655H3.44053ZM3.44053 14.3786H26.5655C27.0833 14.3786 27.503 14.7984 27.503 15.3161C27.503 15.7907 27.1504 16.183 26.6928 16.2451L26.5655 16.2536H3.44053C2.92277 16.2536 2.50303 15.8339 2.50303 15.3161C2.50303 14.8415 2.85573 14.4492 3.31332 14.3872L3.44053 14.3786H26.5655H3.44053ZM3.43945 6.25391H26.5645C27.0823 6.25391 27.502 6.67364 27.502 7.19141C27.502 7.66603 27.1493 8.05827 26.6916 8.12036L26.5645 8.12891H3.43945C2.92169 8.12891 2.50195 7.70918 2.50195 7.19141C2.50195 6.71679 2.85465 6.32454 3.31224 6.26247L3.43945 6.25391H26.5645H3.43945Z" />
  </svg>

);

export default Navigation;
