import httpService from 'src/apis/httpService'
import {
    createCategory,
    getCategories,
    updateCategory,
    updateCategoryImage
} from './types'

export default {
    getCategories(params: getCategories) {
        return httpService.get('/v1/category', {params})
    },
    getOneCategory(id: string) {
        return httpService.get(`/v1/category/${id}`)
    },
    createCategory(body: createCategory) {
        return httpService.post('/v1/category', body)
    },
    updateCategory(body: updateCategory, id: string) {
        return httpService.put(`/v1/category/${id}`, body)
    },
    updateCategoryImage(id: string, body: updateCategoryImage) {
        return httpService.put(`/v1/category/${id}/image`, body)
    },
    deleteCategory(id: string) {
        return httpService.delete(`/v1/category/${id}`)
    },
}