import React from 'react';
import styled from 'styled-components';

import img from './svgs/ggg.jpg';
import { IconSize } from './types';

const Img = styled.img``;

export const Example: React.FC<IconSize> = ({ size }) => {
  return <Img src={img} width={size} />;
};
