import React from 'react';
import Intro from "src/screens/login/Intro";
import LoginForm from "src/screens/login/LoginForm";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

const IntroWrapper = styled.div`
  flex: 1;
  width: 100%;
`

const LoginWrapper = styled.div`
  width: 45%;
  padding: 281px 120px;
`

const LoginLayout = ({children}: {children: JSX.Element}) => {
    return (
        <Wrapper>
            <IntroWrapper>
                <Intro />
            </IntroWrapper>
            <LoginWrapper>
                {children}
            </LoginWrapper>
        </Wrapper>
    )
}

export default LoginLayout;