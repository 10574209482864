import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../common";
import {
    CreateInnRequest, CreateInnResponse,
    InnByIdRequest,
    InnByIdResponse,
    InnRequest,
    InnResponse, UpdateInnByIdNumberRequest, UpdateInnByIdNumberResponse, UpdateInnByIdRequest, UpdateInnByIdResponse
} from "src/redux/API/innAPI/innAPI.types";

export const innAPI = createApi({
    reducerPath: 'innAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        inn: builder.query<InnResponse, InnRequest>({
            query: ({
                        limit,
                        page,
                        search,
                        order_by
                    }) => `/v1/inn?search=${search}&order_by=${order_by}&limit=${limit}&page=${page}`
        }),
        createInn: builder.mutation<CreateInnResponse, CreateInnRequest>({
            query: (payload) => ({
                url: `/v1/inn`,
                method: 'POST',
                body: payload
            })
        }),
        innById: builder.query<InnByIdResponse, InnByIdRequest>({
            query: ({id}) => `/v1/inn/${id}`
        }),
        updateInnByIdNumber: builder.mutation<UpdateInnByIdNumberResponse, UpdateInnByIdNumberRequest>({
            query: ({id, ...payload}) => ({
                url: `/v1/inn/${id}`,
                method: 'PUT',
                body: payload
            })
        }),
        updateInnById: builder.mutation<UpdateInnByIdResponse, UpdateInnByIdRequest>({
            query: ({id, ...payload}) => ({
                url: `/v1/inn/${id}`,
                method: 'PUT',
                body: payload
            })
        }),
    }),
})

export const {
    useInnByIdQuery,
    useInnQuery,
    useCreateInnMutation,
    useUpdateInnByIdMutation,
    useUpdateInnByIdNumberMutation
} = innAPI