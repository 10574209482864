import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from 'src/apis'
import { ManufacturerItem, ManufacturerStore } from './types'
import Fuse from "fuse.js"

export const initialState: ManufacturerStore = {
    items: [],
    filteredItems: [],
    filters: {
        page: 1,
        limit: 10000,
        search: ''
    },
    currentCount: 0,
    count: 0,
    loading: false,
    error: null,
    newCompanyForm: {},
    isSubmiting: false
}

export const getManufacturers = createAsyncThunk(
    'GET_MANUFACTURERS',
    async (payload: any = {}, thunkAPI: any) => {
        const {
            manufacturers
        } = thunkAPI.getState()
        if (payload.search && payload.search !== manufacturers.filters.search) {
            payload.page = 1
        }
        const filters = { ...manufacturers.filters, ...payload }
        const { data } = await Apis.manufacturers.getManufacturers({ ...filters })
        return { data, filters };
    },
)

export const getOneManufacturer = createAsyncThunk(
    'GET_MANUFACTURER',
    async (payload: any = {}, thunkAPI: any) => {
        const {
            manufacturers: { id },
        } = thunkAPI.getState();
        const { data } = await Apis.manufacturers.getOneManufacturer(id)
        return { data }
    },
)

export const updateManufacturer = createAsyncThunk(
    'UPDATE_MANUFACTURER',
    async (payload: any = {}) => {
        // const { data } = await Apis.manufacturers.updateManufacturer(payload);
        // return { data }
    },
)

export const createManufacturer = createAsyncThunk(
    'CREATE_MANUFACTURER',
    async (payload: any = {}) => {
        const { data } = await Apis.manufacturers.createManufacturer(payload);
        return { data }
    },
)

const ManufacturerSlice = createSlice({
    name: 'MANUFACTURERS',
    initialState,
    reducers: {
        SET_NEW_MANUFACTURERS_FORM: (state: ManufacturerStore, action) => {
            state.newCompanyForm = action.payload
        },
        SET_FILTERS: (state: ManufacturerStore, action) => {
            state.filters = { ...state.filters, ...action.payload }
        },
        SEARCH_MANUFACTURERS: (state: ManufacturerStore, { payload }: { payload: string }) => {
            const searchValue = payload.trim()
            if (searchValue.length) {
                const options = {
                    includeScore: true,
                    keys: ['name']
                }
                const fuse = new Fuse(state.items, options)
                const result = fuse.search(searchValue).map(itm => itm.item)
                state.filteredItems = result
                state.count = result.length
            } else {
                state.filteredItems = state.items
                state.count = state.items.length
            }
        },
        CREATE_MANUFACTURER: (state: ManufacturerStore, { payload }: { payload: ManufacturerItem }) => {
            state.count += 1
            state.items.unshift(payload)
            state.filteredItems = state.items
        },
        DELETE_MANUFACTURER: (state: ManufacturerStore, { payload }: { payload: string}) => {
            state.count -= 1
            state.items = state.items.filter(itm => itm.id !== payload)
            state.filteredItems = state.items
        },
        UPDATE_MANUFACTURER: (state: ManufacturerStore, { payload }: { payload: ManufacturerItem }) => {
            for (let i = 0; i < state.items.length; i++) {
                if (state.items[i].id === payload.id) {
                    state.items[i] = payload;
                    break;
                }
            }
            state.filteredItems = state.items
        }
    },
    extraReducers: (builder) => {
        //getBranches
        builder.addCase(getManufacturers.pending, (state, action) => {
            state.error = null
            state.loading = true
        })
        builder.addCase(getManufacturers.fulfilled, (state, action) => {
            const {
                filters,
                data: { items, count },
            } = action.payload
            state.items = items
            state.filteredItems = items
            state.filters = filters
            state.count = count
            state.loading = false
            state.currentCount = state.items.length
        })
        builder.addCase(getManufacturers.rejected, (state, action) => {
            state.loading = false
            state.error = action.error
        })
        //get one branch
        builder.addCase(getOneManufacturer.pending, (state, action) => {

        })
        builder.addCase(getOneManufacturer.fulfilled, (state, action) => {

        })
        builder.addCase(getOneManufacturer.rejected, (state, action) => {

        })
        //create branch
        builder.addCase(createManufacturer.pending, (state, action) => {
            state.isSubmiting = true;
            state.error = null;
        })
        builder.addCase(createManufacturer.fulfilled, (state, action) => {
            state.isSubmiting = false;
        })
        builder.addCase(createManufacturer.rejected, (state, action) => {
            state.isSubmiting = false;
            state.error = action.error;
        })
        builder.addCase(updateManufacturer.pending, (state, action) => {

        })
        builder.addCase(updateManufacturer.fulfilled, (state, action) => {

        })
        builder.addCase(updateManufacturer.rejected, (state, action) => {

        })
    },
})

export const { SEARCH_MANUFACTURERS, CREATE_MANUFACTURER, DELETE_MANUFACTURER, UPDATE_MANUFACTURER } = ManufacturerSlice.actions
export default ManufacturerSlice.reducer;
