import httpService from 'src/apis/httpService'
import { createDosageForm, getDosageForms, updateDosageForm } from './types'

export default {
    getDosageForms(params: getDosageForms) {
        return httpService.get('/v1/form/dosage', {params})
    },
    getDosageForm(id: string) {
        return httpService.get(`/v1/form/dosage/${id}`)
    },
    createDosageForm(body: createDosageForm) {
        return httpService.post('/v1/form/dosage', body)
    },
    updateDosageForm(body: updateDosageForm, id: string) {
        return httpService.put(`/v1/form/dosage/${id}`, body)
    },
    deleteDosageForm(id: string) {
        return httpService.delete(`/v1/form/dosage/${id}`)
    },
}