import React from 'react';
import DefaultRoutes from "src/routes";
import Drawer from "src/components/Drawer";
import Modal from "src/components/Modal";
import { ToastContainer } from "react-toastify";

function Default() {
  return (
    <>
      <ToastContainer autoClose={5000} hideProgressBar closeButton={true} position="bottom-right" />
      <Drawer />
      <Modal key={1} />
      <DefaultRoutes />
    </>
  );
}

export default Default;
