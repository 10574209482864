import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { Field, Form, Formik, FormikProps } from "formik";
import { HorizontalBox } from "src/components/HorizontalBox";
import Input from "src/components/Input";
import * as Yup from "yup";
import Apis from "src/apis";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useRestriction } from "src/hooks";
import { UserPermissionsEnum } from "src/common/permissions";
import Confirm from "src/components/Confirm";
import Text from "src/components/Text";
import { DELETE_INN } from 'src/redux/features/inn';
import { ProductVariantsList } from '../products/ProductVariant/ProductVariantsList';
import {
  useCreateInnMutation,
  useInnByIdQuery,
  useUpdateInnByIdNumberMutation
} from "src/redux/API/innAPI";
import Spinner from "src/components/Spinner";
import {layouts, Button, Box, FormikInput, Typography} from "@med-io/neural-ui";
import {SectionWithHeader} from "src/components/SectionWithHeader";
const {TopBarLayout} = layouts

const FormBox: any = styled.div`
  height: 100%;
  border: 1px solid #E7E7E7;
  border-radius: ${p => p.theme.borderRadius.default}px;
`;

const ProductFlex = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 24px;
  height: 100%;
`


const Schema = Yup.object({
  name: Yup.object().shape({
    ru: Yup.string().required('Обязательное поле'),
    uz: Yup.string().required('Обязательное поле'),
    en: Yup.string(),
  }),
  code: Yup.string().optional().test(
      'len',
      'Минимум 6 символов',
      (val: string | undefined) => !val || val.length === 6
  )
})

// FIXME: code can be 7 char length

const INN = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  const formRef = useRef<FormikProps<any>>(null)
  const { state: { inn: editedData = {}, historyBack } = {} } = useLocation<any>() || null
  const { params: { id } } = useRouteMatch<any>()
  const isEditing = id !== 'new';
  const [updateInn, {isLoading: isUpdating}] = useUpdateInnByIdNumberMutation()
  const [createInn, {isLoading: isCreating}] = useCreateInnMutation()
  const {data: dataById, isFetching, isError, refetch} = useInnByIdQuery({id},{
    skip: !isEditing
  })


  const onSubmit = async (values: any, actions: any) => {
    if (isEditing) {
      updateInn({id, ...{code: values.code.trim().length ? values.code : null, name: values.name}})
      toast.success('МНН обновлен')
      history.replace({ pathname: `/catalog/inns/${id}`, state: { inn: values, historyBack: false } })
    } else {
      const {data}:any = await createInn({code: values.code.trim().length ? values.code : null, name: values.name,company_id: null})
      toast.success('МНН создан')
      if (data) {
        history.replace({ pathname: `/catalog/inns/${data.id}`, state: { inn: values, historyBack: false } })
      }
    }
  }

  const handleDelete = async () => {
    if (formRef.current) {
      formRef.current.setSubmitting(true)
      await Apis.inn.deleteINN(id)
      dispatch(DELETE_INN(id))
      history.goBack()
      formRef.current.setSubmitting(false)
      toast.success('Удалено')
    }
  }

  const { isPermitted } = useRestriction()

  const isAllowed = isEditing && isPermitted(UserPermissionsEnum["inn.update"]) || !isEditing && isPermitted(UserPermissionsEnum["inn.create"])

  if (isFetching) {
    return <Spinner size={15}/>
  }

  const initialValues = {
    name: isEditing ? dataById?.name : '',
    code: isEditing ? dataById?.code : ''
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Schema}
      innerRef={formRef}
      enableReinitialize
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <TopBarLayout
          title={isEditing ? values.name.ru : 'Новый МНН'}
          disableBodyOverflow
          isContentLoading={isFetching}
          isError={isError}
          onRetry={refetch}
          onBack={() => historyBack ? history.goBack() : history.push('/catalog/inns')}
          topBarActions={
            <>
              {isEditing && isPermitted(UserPermissionsEnum["inn.delete"]) && <Confirm title='Удаление МНН'
                okButton={<Button ml={1.5} type='button' destructive variant='primary' size='md'>Удалить</Button>}
                description={
                  <Box display='block' w='300px'>
                    <Typography>Вы действительно
                      хотите
                      удалить
                      этот МНН?</Typography>
                  </Box>
                }
                onConfirm={handleDelete}>
                <Button mr={1.5} type='button' variant='tertiaryGray' size='md'>Удалить</Button>
              </Confirm>}
              {
                isAllowed && <Button onClick={() => handleSubmit()} variant='primary' size='md' loading={isSubmitting}>Сохранить</Button>
              }
            </>
          }
        >
          <Box display='flex' gap={1.5} h='85vh'>
            <Box w='30%'>
              <SectionWithHeader title='Данные МНН'>
                <FormikInput name='name.ru' label='Название (Ru)' placeholder='Введите название' size='sm' fullWidth/>
                <FormikInput name='name.uz' label='Название (Uz)' placeholder='Введите название' size='sm' fullWidth/>
                <FormikInput name='name.en' label='Название (En)' placeholder='Введите название' size='sm' fullWidth/>
                <FormikInput name='code' label='Код' placeholder='Введите код' size='sm' fullWidth/>
              </SectionWithHeader>
            </Box>
            <Box w='67.5%'>
              {isEditing && <ProductVariantsList id={id} title='Варианты продукта' filter_by='inn_ids' />}
            </Box>
          </Box>
        </TopBarLayout>
      )}
    </Formik>
  )
}

export default INN;
