import React from "react"
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom"
import { TopMenuItem } from "src/components/Menus/TopMenu"
import TopbarLayout from "src/layouts/TopbarLayout"
import TopMenuLayout from "src/layouts/TopMenuLayout"
import Basic from "src/screens/mobile/collections/basic"
import BasicCollection from "src/screens/mobile/collections/basic/basicCollection"
import Colored from "src/screens/mobile/collections/colored"
import ColoredCollection from "src/screens/mobile/collections/colored/coloredCollection"
import Image from "src/screens/mobile/collections/image"
import ImageCollection from "src/screens/mobile/collections/image/imageCollection"

const CollectionsRoutes = () => {

    const history = useHistory()
    return (
        <>
            <Switch>
                <Route
                    exact
                    path={`/mobile/collections/colored/:id`}
                >
                    <ColoredCollection />
                </Route>
                <Route
                    exact
                    path={`/mobile/collections/image/:id`}
                >
                    <ImageCollection />
                </Route>
                <Route
                    exact
                    path={`/mobile/collections/basic/:id`}
                >
                    <BasicCollection />
                </Route>
            </Switch>
            <TopMenuLayout
                noChildrenPd
                menuItems={[
                    <TopMenuItem key='Цветные карточки' exact title='Цветные карточки' to={`/mobile/collections`} />,
                    <TopMenuItem key='С Изображением' exact title='С изображением' to={`/mobile/collections/image`} />,
                    <TopMenuItem key='Раскрытые' exact title='Раскрытые' to={`/mobile/collections/basic`} />,
                    <TopMenuItem key='Грид' exact title='Грид' to={`/mobile/collections/coloredGrid`} />,
                ]}
                title="Подборки"
            >
                <Switch>
                    <Route
                        exact
                        path={`/mobile/collections`}
                    >
                        <Colored />
                    </Route>
                    <Route
                        exact
                        path={`/mobile/collections/image`}
                    >
                        <Image />
                    </Route>
                    <Route
                        exact
                        path={`/mobile/collections/basic`}
                    >
                        <Basic />
                    </Route>
                </Switch>
            </TopMenuLayout>
        </>
    )
}

export default CollectionsRoutes