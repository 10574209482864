import React from 'react';
import Box from "src/components/Box";
import Spinner from "src/components/Spinner";

const Loading = () => {
  return (
    <Box display='flex' alignItems='center' justifyContent='center' height='100%' width='100%'>
      <Spinner size={80} />
    </Box>
  );
};

export default Loading;