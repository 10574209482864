import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../common";
import {
    CreateDiseaseSymptomRequest,
    CreateDiseaseSymptomResponse, DeleteDiseaseSymptomRequest, DeleteDiseaseSymptomResponse,
    DiseasesSymptomsRequest,
    DiseasesSymptomsResponse,
    DiseaseSymptomByIdRequest,
    DiseaseSymptomByIdResponse,
    UpdateDiseaseSymptomRequest,
    UpdateDiseaseSymptomResponse
} from "src/redux/API/diseasesSymptomsAPI/diseasesSymptomsAPI.types";

export const diseasesSymptomsAPI = createApi({
    reducerPath: 'diseasesSymptomsAP',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        diseasesSymptoms: builder.query<DiseasesSymptomsResponse, DiseasesSymptomsRequest>({
            query: ({search, limit, page}) => `/api/v1/diseases/symptoms?search=${search}&limit=${limit}&page=${page}`
        }),
        createDiseaseSymptom: builder.mutation<CreateDiseaseSymptomResponse, CreateDiseaseSymptomRequest>({
            query: (payload) => ({
                url: `/v1/diseases/symptoms`,
                method: 'POST',
                body: payload
            })
        }),
        updateDiseaseSymptom: builder.mutation<UpdateDiseaseSymptomResponse, UpdateDiseaseSymptomRequest>({
            query: ({id, ...body}) => ({
                url: `/v1/diseases/symptoms/${id}`,
                method: 'PUT',
                body: body
            })
        }),
        diseaseSymptomById: builder.query<DiseaseSymptomByIdResponse, DiseaseSymptomByIdRequest>({
            query: ({id}) => `/v1/diseases/symptoms/${id}`
        }),
        deleteDiseaseSymptom: builder.query<DeleteDiseaseSymptomResponse, DeleteDiseaseSymptomRequest>({
            query: ({id}) => ({
                url: `/v1/diseases/symptoms/${id}`,
                method: 'DELETE'
            })
        })
    }),

})

export const {
    useDiseasesSymptomsQuery,
    useCreateDiseaseSymptomMutation,
    useUpdateDiseaseSymptomMutation,
    useDiseaseSymptomByIdQuery,
    useDeleteDiseaseSymptomQuery
} = diseasesSymptomsAPI