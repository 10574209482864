import axios from 'axios'
import { errorHandler } from "src/helpers";

function trimRecursively(value: any) {
  if (value instanceof FormData) return value;
  if (Array.isArray(value)) {
    return value.reduce((all, item) => {
      all.push(trimRecursively(item));
      return all;
    }, []);
  } else if (typeof value === 'object' && value !== null) {
    return Object.keys(value).reduce((all: any, key: any) => {
      all[key] = trimRecursively(value[key]);
      return all;
    }, {});
  } else if (typeof value === 'string') {
    return value.trim();
  } else {
    return value;
  }
}

axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    config.withCredentials = true
    config.baseURL = process.env.REACT_APP_API_BASE_URL

    // triming string datas
    config.data = trimRecursively(config.data)
    return config
  },
  (error) => {
    errorHandler(error);
    throw error
  },
)

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  // Do something with response error
  errorHandler(error);
  throw error
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
}
