import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { HorizontalBox } from "src/components/HorizontalBox";
import { Field, Form, Formik } from "formik";
import Input, { Label } from "src/components/Input";
import * as Yup from "yup";
import Switch from "src/components/Switch";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { FormikHelpers } from "formik/dist/types";
import SubSectionHeader from "src/components/SubSectionHeader";
import { useRestriction } from "src/hooks";
import { UserPermissionsEnum } from "src/common/permissions";
import {useCreateCompanyMutation, useUpdateCompanyMutation} from "src/redux/API/companyAPI";
import {layouts, Button, Box, FormikInput} from "@med-io/neural-ui";
import {SectionWithHeader} from "src/components/SectionWithHeader";
const {TopBarLayout} = layouts


type CompanyType = {
  id:                 string;
  name:               string;
  logo_url:           null | string;
  phone_number:       string;
  email:              string;
  address:            string;
  tin:                string;
  batch_barcode:      boolean;
  main_branch:        null | string;
  company_super_user: CompanySuperUser;
  is_distributor:boolean
}

interface CompanySuperUser {
  id:           string;
  email:        string;
  name:         string;
  surname:      string;
  patronymic:   string;
  passport:     string;
  phone_number: string;
  avatar:       string | null;
  sex:          number;
}



const CompanyFlex = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 24px;
  height: 100%;
`

const StyledBox = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
  
  @media screen and (max-width: 1440px) {
    grid-template-columns: 1fr 1fr;
  }
  
  @media screen and (max-width: 1260px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`

const LeftForm = styled.div`
  padding-top: 1rem;
`

const FormStyled = styled(Form)`
  display: grid;
  height: 100%;
  grid-template-columns: 300px 1fr;
  gap: 24px;
  width: 100%;
`

const FormBox: any = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #E7E7E7;
  border-radius: ${p => p.theme.borderRadius.default}px;
`;

const RightFromBox = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #E7E7E7;
  border-radius: ${p => p.theme.borderRadius.default}px;
`

const NewSchema = Yup.object().shape({
  company: Yup.object().shape({
    "name": Yup.string().max(20).required('Обязательное поле!'),
    "phone_number": Yup.string().max(12).required('Обязательное поле!'),
    "email": Yup.string().email(),
    "address": Yup.string().max(50).required('Обязательное поле!').required(),
    "tin": Yup.string().nullable().max(30),
    "batch_barcode": Yup.boolean().required('Обязательное поле!'),
    "is_distributor": Yup.boolean().required('Обязательное поле!')
  }),
  company_user: Yup.object().shape({
    "name": Yup.string().max(20).required('Обязательное поле!'),
    "surname": Yup.string().max(20).required('Обязательное поле!'),
    "patronymic": Yup.string().max(20).required('Обязательное поле!'),
    "password": Yup.string().min(8, '').max(16).required('Обязательное поле!'),
    "confirm_password": Yup.string().max(16).required('Обязательное поле!'),
    "email": Yup.string().email().required('Обязательное поле!'),
    "phone_number": Yup.string().nullable().max(12)
  }),
})

const EditSchema = Yup.object().shape({
  company: Yup.object().shape({
    "name": Yup.string().max(20).required('Обязательное поле!'),
    "phone_number": Yup.string().max(12).required('Обязательное поле!'),
    "email": Yup.string().email(),
    "address": Yup.string().max(50).required('Обязательное поле!').required(),
    "tin": Yup.string().max(30).nullable(true)
  })
})

const Company = () => {
  const history = useHistory()
  const { state: { company: editedData = {}, historyBack } = {} } = useLocation<any>() || null
  const { params: { id } } = useRouteMatch<any>()
  const isEditing = id !== 'new'
  const [updateCompany,{isLoading:isUpdating}] = useUpdateCompanyMutation()
  const [createCompany,{isLoading: isCreating}] = useCreateCompanyMutation()
  const [state, setState] = useState<any>({
    company: {
      name: "",
      phone_number: "",
      email: "",
      address: "",
      tin: "",
      batch_barcode: false,
      is_distributor:false
    },
    company_user: {
      name: "",
      surname: "",
      patronymic: "",
      password: "",
      confirm_password: "",
      phone_number: "",
      email: ""
    }
  })

  useEffect(() => {
    console.log(history.location.state)
    if (isEditing) {
      setState({ ...state, company: editedData })
    }
  }, [])

  const onSubmit = async (values: any, actions: FormikHelpers<any>) => {
    values.company.tin = values.company.tin || null
    values.company_user.phone_number = values.company_user.phone_number || null
    actions.setSubmitting(true)
    if (isEditing) {
      updateCompany({id,...values.company})
      toast.success('Компания обновлена')
    } else {
      const {data}: any = await createCompany(values)
      toast.success('Компания создана')
      if (data) {
        history.replace(data.id, { company: data  })
      }
    }
  }

  const { isPermitted } = useRestriction()

  const isAllowed = isEditing && isPermitted(UserPermissionsEnum["company.update"]) || !isEditing && isPermitted(UserPermissionsEnum["company.create"])

  return (
    <Formik
      initialValues={state}
      validationSchema={isEditing ? EditSchema : NewSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
        return (
          <TopBarLayout
            title={isEditing ? values.company.name : 'Новая компания'}
            disableBodyOverflow
            onBack={() => historyBack ? history.goBack() : history.push('/company')}
            topBarActions={isAllowed ? <Button loading={isSubmitting} onClick={() => handleSubmit()} variant='primary' size='md'>Сохранить</Button> : <></>}
          >
            <Box display='flex' h='85vh' gap={1.5}>
              <Box w='30%'>
                <SectionWithHeader title='Данные компании'>
                  <FormikInput name='company.name' label='Название компании' placeholder='Введите название' size='sm' fullWidth/>
                  <FormikInput name='company.phone_number' label='Номер телефона' placeholder='Введите номер телефона' size='sm' fullWidth/>
                  <FormikInput name='company.email' label='Электронная почта' placeholder='Введите адрес эл. почты' size='sm' fullWidth/>
                  <FormikInput name='company.address' label='Адрес' placeholder='Дом, Улица, Адрес' size='sm' fullWidth/>
                  <FormikInput name='company.tin' label='ИНН'  placeholder='ИНН' size='sm' fullWidth/>
                  {!isEditing && <Field name='company.batch_barcode'>
                      {({ field }: any) => (
                          <Box justifyContent="space-between">
                            <Label>Учет склада по баркоду</Label>
                            <Switch checked={field.value}
                                    onToggleChange={(value) => setFieldValue(field.name, value)} />
                          </Box>
                      )
                      }
                    </Field>
                  }
                  <Field name="company.is_distributor">
                    {
                      ({ field }: any) => (
                          <Box justifyContent="space-between">
                            <Label>Дистрибьютор</Label>
                            <Switch checked={field.value} onToggleChange={(value) =>setFieldValue(field.name, value)} />
                          </Box>
                      )
                    }
                  </Field>
                </SectionWithHeader>
              </Box>
              <Box w='67.5%'>
                {!isEditing &&
                    <SectionWithHeader title='Данные директора'>
                      <Box display='grid' style={{ gridTemplateColumns: "2fr 1fr", gap: "24px" }} p="24px">
                        <LeftForm>
                          <SubSectionHeader>Личные данные</SubSectionHeader>
                          <StyledBox>
                            <FormikInput  name='company_user.name' label='Имя' placeholder='Введите имя'  size='sm' fullWidth/>
                            <FormikInput   name='company_user.surname' label='Фамилия' placeholder='Введите фамилию'  size='sm' fullWidth/>
                            <FormikInput  name='company_user.patronymic' label='Отчество' placeholder='Введите отчество'  size='sm' fullWidth/>
                            <FormikInput   name='company_user.phone_number' label='Номер телефона'  placeholder='Введите номер телефона'  size='sm' fullWidth/>
                          </StyledBox>
                        </LeftForm>
                        <LeftForm>
                          <SubSectionHeader>Данные для входа в систему</SubSectionHeader>
                          <Box display="block">
                            <FormikInput name='company_user.email'  label='Электронная почта' placeholder='Введите адрес эл. почты' size='sm' fullWidth/>
                            <FormikInput name='company_user.password'  label='Пароль' placeholder='Придумайте пароль' size='sm' fullWidth/>
                            <FormikInput name='company_user.confirm_password' label='Повторите пароль' placeholder='Подтвердите пароль' size='sm' fullWidth/>
                          </Box>
                        </LeftForm>
                      </Box>

                    </SectionWithHeader>
                }
              </Box>
            </Box>
          </TopBarLayout>
        )
      }}
    </Formik>
  )
}

export default Company;
