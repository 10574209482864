import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../common";
import {
    CompanyUserByIdRequest,
    CompanyUserByIdResponse,
    CompanyUsersRequest,
    CompanyUsersResponse
} from "src/redux/API/companyUsersAPI/companyUsersAPI.types";


export const companyUsersAPI = createApi({
    reducerPath: 'companyUsersAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        companyUsers: builder.query<CompanyUsersResponse, CompanyUsersRequest>({
            query: ({
                        limit = 10,
                        page = 1,
                        search = '',
                        company_id
                    }) => `/api/v1/company/user?limit=${limit}&page=${page}&search=${search}&company_id=${company_id}`
        }),
        companyUserById: builder.query<CompanyUserByIdResponse, CompanyUserByIdRequest>({
            query: ({id}) => `/v1/company/user/${id}`
        })
    }),
});

export const {useCompanyUsersQuery, useCompanyUserByIdQuery} = companyUsersAPI;
