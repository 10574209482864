import React from 'react';
import PaginationComponent from 'rc-pagination';
import styled, { useTheme } from 'styled-components';

import { ArrowLeft, ArrowRight } from '../../icons';
import { Margins } from '../../types';

import { PaginationProps } from './types';

const PaginationWrapper = styled.div<Margins>`
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: ${(p) => `${p.mt}rem ${p.mr}rem ${p.mb}rem ${p.ml}rem`};
  
  & .pagination-item {
    //transition: 300ms all ease-in-out;
  }

  & .pagination {
    display: inline-flex;
    padding: 0;
    margin: 0;
    align-items: center;
    list-style: none;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    overflow: hidden;
  }

  & .pagination-prev,
  & .pagination-next,
  & .pagination-item,
  & .pagination-jump-prev,
  & .pagination-jump-next {
    cursor: pointer;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;
    min-height: 40px;
  }

  & .pagination-jump-prev,
  & .pagination-jump-next,
  & .pagination-prev,
  & .pagination-item {
    border-right: 1px solid #D0D5DD;
  }

  & .pagination-jump-prev,
  & .pagination-jump-next {
  }
  
  & .pagination-item:hover,
  & .pagination-prev:hover,
  & .pagination-next:hover,
  & .pagination-jump-prev:hover,
  & .pagination-jump-next:hover {
    background-color: #E8F1FF;
    color: #1571D4;
  }
  & .pagination-item-active, & .pagination-item-active:hover {
    transition: 200ms all ease-in-out;
    background-color: #1571D4;
    color: #fff;
  }
}
  & .pagination-disabled {
    cursor: not-allowed;
    &:hover {
      background: #fff;
    }
`;

export const Pagination: React.FC<PaginationProps> = (props) => {
  const {
    defaultPageSize,
    total,
    className,
    page,
    ref,
    mb = 0,
    ml = 0,
    mr = 0,
    mt = 0,
    onChange,
  } = props;
  const theme = useTheme();
  return (
    <PaginationWrapper ref={ref} mb={mb} ml={ml} mr={mr} mt={mt}>
      <PaginationComponent
        className={className}
        total={total}
        current={page}
        defaultPageSize={defaultPageSize}
        prefixCls="pagination"
        nextIcon={
          <ArrowRight size="1.25rem" color="#344054" />
        }
        prevIcon={
          <ArrowLeft size="1.25rem" color="#344054" />
        }
        jumpNextIcon="..."
        jumpPrevIcon="..."
        onChange={onChange}
        showTitle={false}
      />
    </PaginationWrapper>
  );
};
