import React from "react"
const Empty = ({ size = 200 }: { size?: number }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 200 201" fill="none">
            <path d="M72.7078 173.703C83.3197 180.099 90.8902 177.444 93.7885 172.315C99.3934 162.396 118.029 142.112 147.562 135.257C186.676 126.177 178.508 102.068 154.463 89.4167C130.418 76.7655 112.439 59.2602 115.16 40.2263C117.881 21.1924 90.9197 14.6308 78.9297 51.2456C71.1815 74.9075 57.2962 81.2466 48.4418 82.7704C40.0967 84.2065 30.9682 86.0084 26.5167 93.2116C21.1151 101.952 23 110.793 45.6153 116.856C77.6634 125.449 48.9219 159.366 72.7078 173.703Z" fill="#DDEDFF" />
            <path d="M45.136 132.231C45.5851 131.974 50.6539 129.828 75.8703 141.584C75.7968 141.684 75.7656 141.731 75.7656 141.731C75.7656 141.731 49.5507 150.56 44.4144 139.684C42.8806 136.446 43.3201 134.216 45.0922 132.256L45.136 132.231Z" fill="#2F89EC" />
            <path d="M129.89 136.681C124.412 141.286 115.82 144.629 107.53 140.184C108.84 139.347 110.193 138.485 111.573 137.593C119.899 132.244 127.232 135.823 129.89 136.681Z" fill="#2F89EC" />
            <path d="M139.703 80.0351C145.3 126.644 131.999 135.102 130.518 136.387C130.089 136.759 129.915 136.732 129.267 136.565L129.256 136.572C126.513 135.844 119.899 132.243 111.573 137.592C110.193 138.484 108.839 139.346 107.53 140.183C99.0368 145.569 92.1784 149.365 87.2373 147.162C83.0462 145.024 79.2673 143.174 75.87 141.584C50.6536 129.829 45.5848 131.974 45.1357 132.232C45.6568 131.948 50.4371 129.298 56.8737 124.638C56.8956 124.626 56.9175 124.613 56.9222 124.596C65.5256 118.361 77.0473 108.535 85.3291 95.896C90.967 87.3012 96.4826 65.5124 107.124 61.2372C128.996 53.0782 138.795 70.5547 139.703 80.0351Z" fill="#459DFF" />
            <path d="M115.52 79.217C116.199 77.4243 116.133 75.7375 115.373 75.4495C114.612 75.1615 113.445 76.3813 112.766 78.174C112.087 79.9667 112.154 81.6534 112.914 81.9414C113.675 82.2294 114.842 81.0097 115.52 79.217Z" fill="white" />
            <path d="M129.446 81.5044C129.927 79.6488 129.679 77.9791 128.891 77.7749C128.104 77.5708 127.076 78.9096 126.595 80.7652C126.114 82.6208 126.362 84.2905 127.149 84.4946C127.936 84.6987 128.964 83.3599 129.446 81.5044Z" fill="white" />
            <path d="M120.969 88.3186C120.855 88.3185 120.744 88.2794 120.656 88.2077C120.567 88.1361 120.505 88.0363 120.481 87.9248C120.392 87.5212 119.995 86.327 119.367 86.1495C118.738 85.972 117.504 86.6611 117.005 87.0226C116.898 87.1004 116.764 87.1323 116.633 87.1114C116.502 87.0904 116.385 87.0183 116.307 86.9109C116.229 86.8035 116.197 86.6696 116.218 86.5386C116.239 86.4077 116.311 86.2904 116.419 86.2126C116.615 86.0702 118.377 84.8311 119.638 85.187C120.954 85.5582 121.41 87.4927 121.458 87.7121C121.474 87.7851 121.473 87.8608 121.456 87.9336C121.439 88.0063 121.406 88.0743 121.359 88.1325C121.312 88.1907 121.252 88.2376 121.185 88.2698C121.118 88.302 121.044 88.3187 120.969 88.3186H120.969Z" fill="white" />
        </svg>
    )
}

export default Empty