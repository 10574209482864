import styled from "styled-components";
import { color, fontSize, fontWeight, letterSpacing, lineHeight, space, system, textAlign, } from "styled-system";
import type { ThemedComponent } from "src/styles/StyleProvider";
import fontFamily from "src/styles/styled/fontFamily";

const uppercase = system({
  uppercase: {
    // @ts-ignore
    prop: "uppercase",
    property: "textTransform",
    transform: value => (value ? "uppercase" : "none"),
  },
});

const textTransform = system({
  textTransform: true,
});

type Props = {
  fontFamily?: string,
  fontSize?: number | string,
  textAlign?: string,
  color?: string,
  fontWeight?: string,
  mt?: number | string,
  mb?: number | string,
  singleLine?: boolean,
  ellipsis?: boolean,
  align?: "DEPRECATED: USE textAlign INSTEAD",
  lineHeight?: string,
  capitalize?: boolean,
  display?: 'block' | 'flex' | 'inline-block' | 'inline',
  width?: string,
  textOverflow?: 'clip' | 'ellipsis' | 'string' | 'initial' | 'inherit',
  whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-wrap' | 'pre-line' | 'break-spaces',
  flex?: number | string,
  overflow?: 'visible' | 'hidden' | 'scroll' | 'auto',
}

const Text: ThemedComponent<Props> = styled.span`
  ${uppercase};
  ${lineHeight};
  ${fontFamily};
  ${fontSize};
  ${textAlign};
  ${color};
  ${fontWeight};
  ${space};
  ${letterSpacing};
  ${textTransform};
  display: ${(p: Props) => p.display || 'unset'};
  width: ${(p: Props) => p.width || 'unset'};
  text-overflow: ${(p: Props) => p.ellipsis ? 'ellipsis' : p.textOverflow || 'unset'};
  white-space: ${(p: Props) => p.singleLine ? 'nowrap' : p.whiteSpace || 'unset'};
  flex: ${(p: Props) => p.flex || 'unset'};
  overflow: ${(p: Props) => p.ellipsis || p.singleLine ? 'hidden' : p.overflow || 'unset'};
`;

export default Text;
