import React, { useEffect, useState } from 'react';
import SearchBox, { SearchBoxSuffix } from "src/components/SearchBox";
import Filter from "src/icons/fluency-regular/Filter";
import  { InnerColumnWrapper } from "src/layouts/TopbarLayout";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useRestriction } from "src/hooks";
import { UserPermissionsEnum } from "src/common/permissions";
import Box from "src/components/Box";
import styled from "styled-components";
import Apis from "../../../apis"
import { Pagination } from "src/stories/components/Pagination";
import Text from "src/components/Text";
import { layouts, Table, Button, Typography } from "@med-io/neural-ui";
import {useProductsQuery} from "src/redux/API/productAPI";
const {TopBarLayout} = layouts

const FooterWrapper = styled.div`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const Wrapper = styled.div`
  height: 100%;
`

export type FooterProps = {
    page: number;
    limit: number;
    onChange: (page: number) => void
    count: number
}

const Footer: React.FC<FooterProps> = ({page, limit, onChange, count}) => {
    const [state, setState] = useState({
        productVariants: 0,
        products: 0,
    })
    useEffect(() => {
        getStats()
    }, [])

    const getStats = async () => {
        const {data} = await Apis.home.getHome();
        setState({productVariants: data.product_variants, products: data.product})
    }

    return (
        <FooterWrapper>
            <Box style={{gap: "10px"}}>
                <Typography size='sm'>Продукты: {state.products}</Typography>
                <Typography size='sm'>Варианты: {state.productVariants}</Typography>
            </Box>
            <Pagination
                page={page}
                total={count}
                defaultPageSize={limit}
                onChange={(page) => onChange(page)}
            />
        </FooterWrapper>
    )
}

function Products() {
    const limit = 30;
    const [page,setPage] = useState(1)
    const [search,setSearch] = useState('')
    const {data,isFetching, isError, refetch} = useProductsQuery({limit,page,search})
    const {path} = useRouteMatch();
    const history = useHistory();

    const columns = [
        {
            title: "Наименование",
            dataIndex: "name",
            render: (cellData:any) => cellData.ru
        },
        {
            title: "Страна",
            dataIndex: "country",
            render: (cellData:any) => cellData.name
        },
        {
            title: "Производитель",
            dataIndex: "manufacturer",
            render: (cellData:any) => cellData.name
        }
    ]


    const onSearch = (search: string) => setSearch(search)


    const {isPermitted} = useRestriction()

    return (
        <TopBarLayout
            title="Продукты"
            isContentLoading={isFetching}
            isError={isError}
            onRetry={refetch}
            topBarActions={isPermitted(UserPermissionsEnum["product.create"]) ?
                <Button onClick={() => history.push('/catalog/products/new')} variant='primary' size='md'>Добавить продукт</Button> : <></>}

            bottomBar={
                <Footer
                    page={page}
                    limit={limit}
                    onChange={(page: number) => setPage(page)}
                    count={data?.count || 1}
                />
            }
        >
            <Wrapper>
                <InnerColumnWrapper>
                    <SearchBox delayedSearch onSearch={onSearch} defaultValue={search}
                               suffix={<><SearchBoxSuffix onClick={() => console.log("Filter click")}><Filter
                                   size={20}/></SearchBoxSuffix></>}/>
                    <Box display='block'>
                        <Table
                            columns={columns}
                            data={data?.items || []}
                            onRow={(row) => ({
                                onClick: () => history.push({
                                    pathname: `/catalog/products/${row.id}`,
                                    state: {
                                        product: row,
                                        from: history.location.pathname
                                    }
                                })
                            })}
                            loading={isFetching}
                            rowKey='id'
                            size='md'
                            scroll = {{y: '65vh'}}
                        />
                    </Box>
                </InnerColumnWrapper>
            </Wrapper>
        </TopBarLayout>
    )
}

export default Products;
