import React, { useEffect, useState } from 'react';
import {layouts, Pagination,Table,Button} from "@med-io/neural-ui";
import { useHistory, useRouteMatch } from "react-router-dom";
import SearchBox, { SearchBoxSuffix } from "src/components/SearchBox";
import Filter from "src/icons/fluency-regular/Filter";
import styled from "styled-components";
import Box from "src/components/Box";
import { useRestriction } from 'src/hooks';
import { UserPermissionsEnum } from 'src/common/permissions';
import {useManufacturersQuery} from "src/redux/API/manufacturerAPI";
const {TopBarLayout} = layouts
export const InnerColumnWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  & > *:last-child {
    flex: 1;
  }
`;

const Manufacturers = () => {
  const { isPermitted } = useRestriction()
  const { path } = useRouteMatch();
  const history = useHistory();
  const [page,setPage] = useState(1)
  const [search,setSearch] = useState('')
  const limit = 30;
  const {data, isFetching, isError, refetch} = useManufacturersQuery({limit,page,search})

  const columns = [
    {
      title: "Наименование",
      dataIndex: "name"
    },
  ]


  const onSearch = (search: string) => setSearch(search)

  return (
    <TopBarLayout
      title="Производители"
      isContentLoading={isFetching}
      isError={isError}
      onRetry={refetch}
      topBarActions={
        isPermitted(UserPermissionsEnum["manufacturer.create"]) ? <Button onClick={() => history.push('/catalog/manufacturers/new')} variant='primary' size='md'>Добавить производителя</Button> : <></>
      }
      bottomBar={
       <Pagination total={data?.count || 0} defaultPageSize={limit} onChange={setPage} current={page}/>
      }
    >
      <InnerColumnWrapper>
        <SearchBox delayedSearch onSearch={onSearch} defaultValue={search}
          suffix={<><SearchBoxSuffix onClick={() => console.log("Filter click")}><Filter
            size={20} /></SearchBoxSuffix></>} />
        <Box display='block'>
          <Table
            columns={columns}
            data={data?.items || []}
            onRow={(row) => ({
                onClick: () => history.push({
                    pathname: `/catalog/manufacturers/${row.id}`,
                    state: {manufacturer: row}
                })
            })}
            loading={isFetching}
            rowKey="id"
            size="md"
            scroll={{y: '65vh'}}
             />
        </Box>
      </InnerColumnWrapper>
    </TopBarLayout>
  )
}

export default Manufacturers;
