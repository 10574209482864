import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from 'src/apis'
import { CountriesStore } from './types'


export const initialState: CountriesStore = {
  items: [],
  filters: {
    page: 1,
    limit: 300,
    search: '',
    code: undefined
  },
  currentCount: 0,
  count: 0,
  loading: false,
  error: null,
  newCompanyForm: {},
  isSubmiting: false
}

export const getCountries = createAsyncThunk(
  'GET_COUNTRIES',
  async (payload: any = {}, thunkAPI: any) => {
    const {
      countries
    } = thunkAPI.getState()
    const filters = { ...countries.filters, ...payload }
    const { data } = await Apis.countries.getCountries({ ...filters })
    data.items = data.items.sort((a: any, b: any) => {
      if (a.name.ru < b.name.ru) {
        return -1;
      }
      if (a.name.ru > b.name.ru) {
        return 1;
      }
      return 0;
    })
    return { data, filters };
  },
)

export const getOneCountry = createAsyncThunk(
  'GET_COUNTRY',
  async (payload: any = {}, thunkAPI: any) => {
    const {
      countries: { id },
    } = thunkAPI.getState();
    const { data } = await Apis.countries.getOneCountry(id)
    return { data }
  },
)

export const updateCountry = createAsyncThunk(
  'UPDATE_COUNTRY',
  async (payload: any = {}) => {
    const { data } = await Apis.countries.updateCountry(payload);
    return { data }
  },
)

export const createCountry = createAsyncThunk(
  'CREATE_COUNTRY',
  async (payload: any = {}) => {
    const { data } = await Apis.countries.createCountry(payload);
    return { data }
  },
)

const CountrySlice = createSlice({
  name: 'COUNTRIES',
  initialState,
  reducers: {
    SET_NEW_COUNTRIES_FORM: (state: CountriesStore, action) => {
      state.newCompanyForm = action.payload
    },
    SET_FILTERS: (state: CountriesStore, action) => {
      state.filters = { ...state.filters, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    //getBranches
    builder.addCase(getCountries.pending, (state, action) => {
      state.error = null
      state.loading = true
    })
    builder.addCase(getCountries.fulfilled, (state, action) => {
      const {
        filters,
        data: { items, count },
      } = action.payload
      state.filters = filters
      state.items = [...state.items, ...items]
      state.count = count
      state.loading = false
      state.currentCount += items.length
    })
    builder.addCase(getCountries.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
    })
    //get one branch
    builder.addCase(getOneCountry.pending, (state, action) => {

    })
    builder.addCase(getOneCountry.fulfilled, (state, action) => {

    })
    builder.addCase(getOneCountry.rejected, (state, action) => {

    })
    //create branch
    builder.addCase(createCountry.pending, (state, action) => {
      state.isSubmiting = true;
      state.error = null;
    })
    builder.addCase(createCountry.fulfilled, (state, action) => {
      state.isSubmiting = false;
    })
    builder.addCase(createCountry.rejected, (state, action) => {
      state.isSubmiting = false;
      state.error = action.error;
    })
    builder.addCase(updateCountry.pending, (state, action) => {

    })
    builder.addCase(updateCountry.fulfilled, (state, action) => {

    })
    builder.addCase(updateCountry.rejected, (state, action) => {

    })
  },
})

export default CountrySlice.reducer;
