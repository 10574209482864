import * as Yup from "yup";

export const initialState = {
  unit_of_measure: {
    uz: "",
    ru: "",
    en: ""
  },
  composition: {
    uz: "",
    ru: "",
    en: ""
  },
  description: {
    uz: "",
    ru: "",
    en: ""
  },
  pharmacodynamics: {
    uz: "",
    ru: "",
    en: ""
  },
  pharmacokinetics: {
    uz: "",
    ru: "",
    en: ""
  },
  indications_for_use: {
    uz: "",
    ru: "",
    en: ""
  },
  contraindications: {
    uz: "",
    ru: "",
    en: ""
  },
  carefully: {
    uz: "",
    ru: "",
    en: ""
  },
  pregnancy_and_breastfeeding: {
    uz: "",
    ru: "",
    en: ""
  },
  methods_of_use_and_doses: {
    uz: "",
    ru: "",
    en: ""
  },
  side_effects: {
    uz: "",
    ru: "",
    en: ""
  },
  overdose: {
    uz: "",
    ru: "",
    en: ""
  },
  special_instructions: {
    uz: "",
    ru: "",
    en: ""
  },
  influence_on_driving: {
    uz: "",
    ru: "",
    en: ""
  },
  storage_conditions: {
    uz: "",
    ru: "",
    en: ""
  },
  interactions: {
    uz: "",
    ru: "",
    en: ""
  },
}

export type ProductVariantInstruction = typeof initialState

const Schema = Yup.object().shape({
  composition: Yup.object({
    "uz": Yup.string().min(2, "Too short!"),
    "ru": Yup.string().min(2, "Too short!"),
    "en": Yup.string().min(2, "Too short!"),
  }),
  description: Yup.object({
    "uz": Yup.string().min(2, "Too short!"),
    "ru": Yup.string().min(2, "Too short!"),
    "en": Yup.string().min(2, "Too short!"),
  }),
  pharmacodynamics: Yup.object({
    "uz": Yup.string().min(2, "Too short!"),
    "ru": Yup.string().min(2, "Too short!"),
    "en": Yup.string().min(2, "Too short!"),
  }),
  pharmacokinetics: Yup.object({
    "uz": Yup.string().min(2, "Too short!"),
    "ru": Yup.string().min(2, "Too short!"),
    "en": Yup.string().min(2, "Too short!"),
  }),
  indications_for_use: Yup.object({
    "uz": Yup.string().min(2, "Too short!"),
    "ru": Yup.string().min(2, "Too short!"),
    "en": Yup.string().min(2, "Too short!"),
  }),
  contraindications: Yup.object({
    "uz": Yup.string().min(2, "Too short!"),
    "ru": Yup.string().min(2, "Too short!"),
    "en": Yup.string().min(2, "Too short!"),
  }),
  carefully: Yup.object({
    "uz": Yup.string().min(2, "Too short!"),
    "ru": Yup.string().min(2, "Too short!"),
    "en": Yup.string().min(2, "Too short!"),
  }),
  pregnancy_and_breastfeeding: Yup.object({
    "uz": Yup.string().min(2, "Too short!"),
    "ru": Yup.string().min(2, "Too short!"),
    "en": Yup.string().min(2, "Too short!"),
  }),
  methods_of_use_and_doses: Yup.object({
    "uz": Yup.string().min(2, "Too short!"),
    "ru": Yup.string().min(2, "Too short!"),
    "en": Yup.string().min(2, "Too short!"),
  }),
  side_effects: Yup.object({
    "uz": Yup.string().min(2, "Too short!"),
    "ru": Yup.string().min(2, "Too short!"),
    "en": Yup.string().min(2, "Too short!"),
  }),
  overdose: Yup.object({
    "uz": Yup.string().min(2, "Too short!"),
    "ru": Yup.string().min(2, "Too short!"),
    "en": Yup.string().min(2, "Too short!"),
  }),
  special_instructions: Yup.object({
    "uz": Yup.string().min(2, "Too short!"),
    "ru": Yup.string().min(2, "Too short!"),
    "en": Yup.string().min(2, "Too short!"),
  }),
  influence_on_driving: Yup.object({
    "uz": Yup.string().min(2, "Too short!"),
    "ru": Yup.string().min(2, "Too short!"),
    "en": Yup.string().min(2, "Too short!"),
  }),
  storage_conditions: Yup.object({
    "uz": Yup.string().min(2, "Too short!"),
    "ru": Yup.string().min(2, "Too short!"),
    "en": Yup.string().min(2, "Too short!"),
  }),
  interactions: Yup.object({
    "uz": Yup.string().min(2, "Too short!"),
    "ru": Yup.string().min(2, "Too short!"),
    "en": Yup.string().min(2, "Too short!"),
  }),
})

export default Schema
