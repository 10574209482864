import React, { Component, useCallback, useRef } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'src/styles/helpers'
import { useDispatch, useSelector } from 'react-redux'
import Dismiss from 'src/icons/fluency-regular/Dismiss'
import { JSONObject, withProps } from 'src/typed'
import { CLOSE_MODAL } from 'src/redux/features/UI'
import { RootState } from "src/redux/store";

export type ModalComponentProps = {
  title: string
  payload: JSONObject
}

const WrapperStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalMainWrapper: any = styled.div`
  ${WrapperStyle}
`

const DimmerStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 102;
  background-color: ${() => rgba('#000', 0.6)};
`

export const ModalDimmer = styled.div`
  ${DimmerStyle}
`

const ModalWrapperStyle = css`
  background-color: ${(p) => p.theme.colors.palette.background.paper};
  border-radius: ${(p) => p.theme.borderRadius.default}px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 100%;
  max-width: 100%;
  min-height: 200px;
  min-width: 400px;
  z-index: 103;
`

export const ModalWrapper = styled.div`
  ${ModalWrapperStyle}
`

const ModalHeaderStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  height: 50px;
  box-sizing: content-box;
  border-bottom: solid 1px;
  border-color: ${(p) => p.theme.colors.palette.divider};
`

export const ModalHeader = styled.div`
  ${ModalHeaderStyle}
`

const ModalTitleStyle = css`
  font-weight: 500;
  font-size: 18px;
  padding: 0 20px;
`

const ModalTitle = styled.div`
  ${ModalTitleStyle}
`

const ModalCloseBtnStyle = css`
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${(p) => p.theme.colors.palette.text.shade5};
  }
`

const ModalCloseBtnStyled = styled.div`
  ${ModalCloseBtnStyle}
`

const ModalBodyStyle = css`
  height: 100%;
  padding: 20px;
  font-weight: 400;
  overflow-y: auto;
  flex: 1;
`

export const ModalBody = styled.div`
  ${ModalBodyStyle}
`

const ModalFooterStyle: any = css`
  position: sticky;
  bottom: 0;
  padding: 10px 20px;
  //border-top: solid 1px;
  border-color: ${(p) => p.theme.colors.palette.divider};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const ModalFooter: any = withProps<{
  stretched?: boolean
}>()(styled.div)`
  ${ModalFooterStyle}
    & > * {
    flex: ${(p) => (p.stretched ? 1 : 'unset')};
  }
`

export function ModalCloseBtn(props: any) {
  const dispatch = useDispatch()

  const handleClose = useCallback(() => {
    (props.onClose && props.onClose()) || dispatch(CLOSE_MODAL())
  }, [CLOSE_MODAL])

  return (
    <ModalCloseBtnStyled {...props} onClick={handleClose}>
      <Dismiss size={24} />
    </ModalCloseBtnStyled>
  )
}

export const DefaultModalHeader = ({ title, onClose }: { title: string; onClose?: () => void }) => (
  <ModalHeader>
    <ModalTitle>{title}</ModalTitle>
    <ModalCloseBtn onClose={() => onClose && onClose()} />
  </ModalHeader>
)

type ModalProps = {
  isOpen?: boolean
  children?: JSX.Element
}

function Modal(props?: ModalProps) {
  const dispatch = useDispatch()
  const { key, payload, title } = useSelector((state: RootState) => state.UI.modal)
  const isLocalModal = Object.keys(props ? props : {}).length
  const isOpen = isLocalModal ? props?.isOpen : !!key.length
  const ref = useRef(null)

  const handleClose = useCallback(() => {
    dispatch(CLOSE_MODAL())
  }, [CLOSE_MODAL])
  

  if (!isOpen) return null

  const onKeydown = (e: KeyboardEvent) => {
    const clickedKey = e.key.toLowerCase()
  
    
    if (key.length) {
      switch (clickedKey) {
        case 'escape':
          dispatch(CLOSE_MODAL())
          break
      }
    }
  }
  

  const getContent = () => {

    switch (key) {
      default:
        return {
          content: props?.children || <div></div>
        }
    }
  }

  const { content }: any = getContent()

  
  return (
    <ModalMainWrapper tabIndex='0' onKeyDown={onKeydown} ref={ref} key='central_modal'>
      {isOpen && <ModalDimmer onClick={handleClose} />}
      <ModalWrapper>{content}</ModalWrapper>
    </ModalMainWrapper>
  )
}

export default Modal
