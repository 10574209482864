import React, {useRef, useState} from 'react';
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import styled from "styled-components";
import {ErrorMessage, Field, Form, Formik, FormikProps} from "formik";
import {HorizontalBox} from "src/components/HorizontalBox";
import Input, {ErrorStyled} from "src/components/Input";
import * as Yup from "yup";
import {ATCSelect} from "src/common/selects";
import {toast} from "react-toastify";
import {useRestriction} from "src/hooks";
import {UserPermissionsEnum} from "src/common/permissions";
import Confirm from "src/components/Confirm";
import Text from "src/components/Text";
import {ProductVariantsList} from '../products/ProductVariant/ProductVariantsList';
import {
    useATCByIdQuery,
    useCreateATCMutation,
    useDeleteATCMutation,
    useUpdateATCMutation
} from "src/redux/API/atcClassAPI";
import Spinner from "src/components/Spinner";
import {layouts, Button, Box, FormikInput, Typography} from "@med-io/neural-ui";
import {SectionWithHeader} from "src/components/SectionWithHeader";
const {TopBarLayout} = layouts;

const FormBox: any = styled.div`
  height: 100%;
  border: 1px solid #E7E7E7;
  border-radius: ${p => p.theme.borderRadius.default}px;
`;


const ProductFlex = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 24px;
  height: 100%;
`

const Schema = Yup.object({
    name: Yup.object().shape({
        ru: Yup.string().required('Обязательное поле'),
        uz: Yup.string().required('Обязательное поле'),
        en: Yup.string().nullable(true),
    }),
    code: Yup.string().required('Обязательное поле').matches(
        /^(^[A-Za-z0-9]+$)$/gi,
        'Код может состоять только из латинских букв и цифр'
    ),
    parent_id: Yup.number().nullable(true).transform((_, val) => val === Number(val) ? val : null)
})

const ATC = () => {
    const history = useHistory();
    const formRef = useRef<FormikProps<any>>(null)
    const {state: {atc: editedData = {}, historyBack} = {}} = useLocation<any>() || null
    const {params: {id}} = useRouteMatch<any>()
    const isEditing = id !== 'new';
    const [updateATC, {isLoading: isUpdating}] = useUpdateATCMutation()
    const [createATC, {isLoading: isCreating}] = useCreateATCMutation()
    const [deleteATC, {isLoading}] = useDeleteATCMutation()
    const {data: dataById, isFetching, refetch, isError} = useATCByIdQuery({id}, {
        skip: !isEditing
    })
    const [state, setState] = useState({
        name: {
            uz: '',
            ru: '',
            en: ''
        },
        public_name: {
            uz: '',
            ru: '',
            en: ''
        },
        code: '',
        parent_id: null
    })


    const onSubmit = async (values: any, actions: any) => {
        actions.setSubmitting(true)
        if (isEditing) {
            updateATC({id, ...{...values, public_name: values.name}})
            toast.success('АТХ обновлен')
            history.replace({pathname: `/catalog/atcs/${id}`, state: {atc: values, historyBack: false}})
        } else {
            const {data}:any = await createATC({...values, public_name: values.name})
            toast.success('АТХ создан')
            if (data) {
                history.replace({pathname: `/catalog/atcs/${data.id}`, state: {atc: values, historyBack: false}})
            }
        }
    }

    const handleDelete = async () => {
        if (formRef.current) {
            formRef.current.setSubmitting(true)
            deleteATC({id})
            history.goBack()
            formRef.current.setSubmitting(false)
            toast.success('Удалено')
        }
    }

    const {isPermitted} = useRestriction()

    const isAllowed = isEditing && isPermitted(UserPermissionsEnum["atc.update"]) || !isEditing && isPermitted(UserPermissionsEnum["atc.create"])

    if (isFetching) {
        return <Spinner size={15}/>
    }

    const initialValues = {
        name: {
            uz: isEditing ? dataById?.name.uz : '',
            ru: isEditing ? dataById?.name.ru : '',
            en: isEditing ? dataById?.name.en : null
        },
        public_name: {
            uz: isEditing ? dataById?.public_name.uz : '',
            ru: isEditing ? dataById?.public_name.uz : '',
            en: isEditing ? dataById?.public_name.uz : ''
        },
        code: isEditing ? dataById?.code : '',
        parent_id: null
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={Schema}
            enableReinitialize
            innerRef={formRef}
        >
            {({values, handleSubmit, setFieldValue,isSubmitting}) => {
                return (
                    <TopBarLayout
                        title={isEditing ? values.name.ru : 'Новый ATХ'}
                        isError={isError}
                        onRetry={refetch}
                        onBack={() => historyBack ? history.goBack() : history.push('/catalog/atcs')}
                        topBarActions={
                            <>
                                {isEditing && isPermitted(UserPermissionsEnum["atc.delete"]) &&
                                    <Confirm title='Удаление ATХ'
                                             okButton={
                                                 <Button ml={1.5}
                                                         type='button'
                                                         destructive
                                                         variant='primary'
                                                         size='md'
                                                 >Удалить
                                                 </Button>
                                             }
                                             description={
                                                 <Box display='block' w='300px'>
                                                     <Typography>Вы действительно хотите удалить этот АТХ?</Typography>
                                                 </Box>
                                             }
                                             onConfirm={handleDelete}
                                    >
                                        <Button mr={1.5} type='button' variant='tertiaryGray' size='md'>Удалить</Button>
                                    </Confirm>
                                }
                                {
                                    isAllowed && <Button onClick={() => handleSubmit()} variant='primary' size='md' loading={isSubmitting}>Сохранить</Button>
                                }
                            </>
                        }
                    >
                        <Box display='flex' h='85vh' gap={1.5}>
                            <Box w='30%'>
                                <SectionWithHeader title='Данные ATХ'>
                                    <FormikInput name='name.ru' label='Название (Ru)' placeholder='Введите название' size='sm' fullWidth/>
                                    <FormikInput name='name.uz' label='Название (Uz)' placeholder='Введите название' size='sm' fullWidth/>
                                    <FormikInput name='name.en' label='Название (En)' placeholder='Введите название' size='sm' fullWidth/>
                                    <FormikInput name='code' label='Код'  size='sm' placeholder='Введите код' fullWidth/>
                                    <Field
                                        name='parent_id'
                                        placeholder='Выберите АТХ'>
                                        {({field}: any) => (
                                            <ATCSelect label='Родительский АТХ класс'
                                                       placeholder='Выберите АТХ'
                                                       excludedIds={[id]}
                                                       value={field.value}
                                                       virtualized
                                                       name='atc_id'
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name='parent_id' component={ErrorStyled}/>
                                </SectionWithHeader>
                            </Box>
                            <Box w='67.5%'>
                                {isEditing && <ProductVariantsList id={id} filter_by='atc_ids' title='Варианты продукта'/>}
                            </Box>
                        </Box>
                    </TopBarLayout>
                )
            }}
        </Formik>
    )
}

export default ATC;
