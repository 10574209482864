import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {baseQuery} from "src/redux/API/common";
import {
    CreateProductVariantRequest,
    CreateProductVariantResponse,
    DeleteProductVariantRequest,
    DeleteProductVariantResponse, ImportRequest, ImportResponse,
    ProductVariantByIdRequest,
    ProductVariantByIdResponse,
    ProductVariantsRequest,
    ProductVariantsResponse,
    UpdateProductVariantImagesResponse,
    UpdateProductVariantInstructionsRequest,
    UpdateProductVariantInstructionsResponse,
    UpdateProductVariantRequest,
    UpdateProductVariantResponse
} from "src/redux/API/productVariantsAPI/productVariantsAPI.types";

export const productVariantsAPI = createApi({
    reducerPath: 'productVariantsAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        productVariants: builder.query<ProductVariantsResponse, ProductVariantsRequest>({
            query: ({
                        limit,
                        page,
                        search,
                    }) => `/v1/product/variants?limit=${limit}&page=${page}&search=${search}`
        }),
        createProductVariant: builder.mutation<CreateProductVariantResponse, CreateProductVariantRequest>({
            query: (payload) => ({
                url: `/v1/product/variants`,
                method: 'POST',
                body: payload
            })
        }),
        productVariantById: builder.query<ProductVariantByIdResponse, ProductVariantByIdRequest>({
            query: ({id}) => `/v1/product/variants/${id}`
        }),
        updateProductVariant: builder.mutation<UpdateProductVariantResponse, UpdateProductVariantRequest>({
            query: ({id, ...payload}) => ({
                url: `/v1/product/variants/${id}`,
                method: 'PUT',
                body: payload
            })
        }),
        deleteProductVariant: builder.mutation<DeleteProductVariantResponse, DeleteProductVariantRequest>({
            query: ({id}) => ({
                url: `/v1/product/variants/${id}`,
                method: 'DELETE'
            })
        }),
        updateProductVariantImages: builder.mutation<UpdateProductVariantImagesResponse, any>({
            query: ({id, imageFile}) => {
                let bodyFormData = new FormData();
                bodyFormData.append('file', imageFile);
                return {
                    url: `/v1/product/variants/${id}/images`,
                    method: 'PUT',
                    body: {bodyFormData}
                }
            }
        }),
        updateProductVariantInstructions: builder.mutation<UpdateProductVariantInstructionsResponse, UpdateProductVariantInstructionsRequest>({
            query: ({id, ...body}) => ({
                url: `/v1/product/variants/${id}/instructions`,
                method: 'PUT',
                body: body
            })
        }),
        import: builder.mutation<ImportResponse, ImportRequest>({
            query: (body) => ({
                url: `http://localhost:3001/v1/product/import`,
                method: 'POST',
                body: body
            })
        })

    }),
})

export const {
    useProductVariantsQuery,
    useProductVariantByIdQuery,
    useCreateProductVariantMutation,
    useUpdateProductVariantInstructionsMutation,
    useUpdateProductVariantImagesMutation,
    useUpdateProductVariantMutation,
    useDeleteProductVariantMutation,
    useImportMutation
} = productVariantsAPI
