import React from 'react';
import {useDispatch} from 'react-redux';
import Button from "src/components/Button";
import ArrowClockwise from "src/icons/fluency-regular/ArrowClockwise";
import {atcAPI} from "src/redux/API/atcClassAPI";

const UpdateATCsBtn = () => {
    const dispatch = useDispatch();
    const onClick = () => dispatch(atcAPI.util.invalidateTags(['ATCs']))
    return <Button onClick={onClick} icon><ArrowClockwise/></Button>;
};

export default UpdateATCsBtn;