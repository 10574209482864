import httpService from 'src/apis/httpService'
import {getSymptoms, updateSymptom, createSymptom} from './types'

export default {
    getSymptoms(params: getSymptoms) {
        return httpService.get('/v1/diseases/symptoms', {params})
    },
    getOneSymptom(id: string) {
        return httpService.get(`/v1/diseases/symptoms/${id}`)
    },
    createSymptom(body: createSymptom) {
        return httpService.post('/v1/diseases/symptoms', body)
    },
    updateSymptom(body: updateSymptom, id: string) {
        return httpService.put(`/v1/diseases/symptoms/${id}`, body)
    },
    deleteSymptom(id: string) {
        return httpService.delete(`/v1/diseases/symptoms/${id}`)
    },
}