import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {ErrorMessage, Field, Form, Formik, FormikProps} from "formik";
import * as Yup from "yup";
import {HorizontalBox} from "src/components/HorizontalBox";
import Input, {ErrorStyled} from "src/components/Input";
import Apis from "src/apis";
import {toast} from "react-toastify";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useRestriction} from "src/hooks";
import {UserPermissionsEnum} from "src/common/permissions";
import Confirm from "src/components/Confirm";
import Text from "src/components/Text";
import {ManufacturerItem} from 'src/redux/features/manufacturers/types';
import {ProductsList} from '../products/ProductsList';
import {
    useCreateManufacturerMutation,
    useDeleteManufacturerMutation, useManufacturerByIdQuery,
    useUpdateManufacturerMutation
} from "src/redux/API/manufacturerAPI";
import Spinner from "src/components/Spinner";
import {layouts, Button, Box, FormikInput, Typography} from "@med-io/neural-ui";
import {SectionWithHeader} from "src/components/SectionWithHeader";
const {TopBarLayout} = layouts

const Flex = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 24px;
  height: 100%;
`


const FormStyled = styled(Form)`
  width: 100%;
  height: 100%;
  border: 1px solid #E7E7E7;
  border-radius: ${p => p.theme.borderRadius.default}px;
`

const Schema = Yup.object({
    name: Yup.string().required('Обязательное поле')
})

const Manufacturer = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const formRef = useRef<FormikProps<any>>(null)
    const {state: {manufacturer: editedData = {}, historyBack} = {}} = useLocation<any>() || null
    const {params: {id}} = useRouteMatch<any>()
    const isEditing = id !== 'new'
    const [updateManufacturer, {isLoading: isUpdating}] = useUpdateManufacturerMutation()
    const [createManufacturer, {isLoading: isCreating}] = useCreateManufacturerMutation()
    const [deleteManufacturer, {isLoading}] = useDeleteManufacturerMutation()
    const {data: dataById, isFetching, isError, refetch} = useManufacturerByIdQuery({id}, {
        skip: !isEditing
    })

    const onSubmit = async (values: any, actions: any) => {
        actions.setSubmitting(true)
        if (isEditing) {
            updateManufacturer({id, ...values})
            history.replace({
                pathname: `/catalog/manufacturers/${id}`,
                state: {manufacturer: values, historyBack: false}
            })
            toast.success('Производитель обновлен')
        } else {
            const {data}:any = await createManufacturer(values)
            toast.success('Производитель создан')
            if (data) {
                history.replace({
                    pathname: `/catalog/manufacturers/${data.id}`,
                    state: {manufacturer: values, historyBack: false}
                })
            }
        }
    }

    const handleDelete = async () => {
        if (formRef.current) {
            formRef.current.setSubmitting(true)
            deleteManufacturer({id})
            history.goBack()
            formRef.current.setSubmitting(false)
            toast.success('Удалено')
        }
    }

    const {isPermitted} = useRestriction()

    const isAllowed = isEditing && isPermitted(UserPermissionsEnum["manufacturer.update"]) || !isEditing && isPermitted(UserPermissionsEnum["manufacturer.create"])

    if (isFetching) {
        return <Spinner size={15}/>
    }

    const initialValues = {
        name: isEditing ? dataById?.name : ''
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Schema}
            onSubmit={onSubmit}
            innerRef={formRef}
            enableReinitialize
        >
            {({handleSubmit, values, isSubmitting}) => (
                <TopBarLayout
                    title={isEditing ? values.name : 'Новый производитель'}
                    disableBodyOverflow
                    isContentLoading={isFetching}
                    isError={isError}
                    onRetry={refetch}
                    onBack={() => historyBack ? history.goBack() : history.push('/catalog/manufacturers')}
                    topBarActions={
                        <>
                            {isEditing && isPermitted(UserPermissionsEnum["manufacturer.delete"]) &&
                                <Confirm title='Удаление производителя'
                                         okButton={<Button ml={1.5} type='button' destructive variant='primary' size='md'>Удалить</Button>}
                                         description={
                                             <Box display='block' w='300px'>
                                                 <Typography>Вы
                                                     действительно
                                                     хотите
                                                     удалить
                                                     этого производителя?</Typography>
                                             </Box>
                                         }
                                         onConfirm={handleDelete}>
                                    <Button mr={1.5} type='button' variant='tertiaryGray' size='md'>Удалить</Button>
                                </Confirm>}
                            {
                                isAllowed && <Button onClick={() => handleSubmit()} variant='primary' size='md' loading={isSubmitting}>Сохранить</Button>
                            }

                        </>
                    }
                >
                    <Box display='flex' h='85vh' gap={1.5}>
                        <Box w='30%'>
                            <SectionWithHeader title='Название'>
                                <FormikInput name='name' label='Название' placeholder='Введите название' size='sm' fullWidth/>
                            </SectionWithHeader>
                        </Box>
                        <Box w='67.5%'>
                            {isEditing && <ProductsList id={id} title="Продукты производителя" filter_by="manufacturer_ids" />}
                        </Box>
                    </Box>
                </TopBarLayout>
            )}
        </Formik>
    )
}

export default Manufacturer;
