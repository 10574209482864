import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { history } from "src/App";

const Listeners = () => {
  const { user: { isAuthenticated } } = useSelector((state: RootState) => state)

  useEffect(() => {
    if (!isAuthenticated) {
      history.push('/login')
    }
  }, [isAuthenticated])

  return null;
}

export default Listeners;