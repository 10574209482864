import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from 'src/apis'
import { RootState } from "src/redux/store";
import { ReleaseFormsItem, ReleaseFormsStore } from './types';
import Fuse from 'fuse.js';

export const initialState: ReleaseFormsStore = {
  items: [],
  filteredItems: [],
  filters: {
    page: 1,
    limit: 10000,
    search: '',
  },
  currentCount: 0,
  count: 0,
  loading: false,
  error: null,
  newCompanyForm: {},
  isSubmiting: false
}

export const getReleaseForms = createAsyncThunk(
  'GET_RELEASE_FORMS',
  async (payload: any = {}, thunkAPI: any) => {
    const {
      releaseForms
    }: RootState = thunkAPI.getState()
    if (payload.search && payload.search !== releaseForms.filters.search) {
      payload.page = 1
    }
    const filters = { ...releaseForms.filters, ...payload }
    const { data } = await Apis.releaseForms.getReleaseForms({ ...filters })
    return { data, filters };
  },
)

export const getOneReleaseForm = createAsyncThunk(
  'GET_RELEASE_FORM',
  async (payload: any = {}, thunkAPI: any) => {
    const {
      releaseForms: { id },
    } = thunkAPI.getState();
    const { data } = await Apis.releaseForms.getOneReleaseForm(id)
    return { data }
  },
)

export const updateReleaseForm = createAsyncThunk(
  'UPDATE_RELEASE_FORM',
  async (payload: any = {}) => {
    // const { data } = await Apis.inn.updateINN(payload);
    // return { data }
  },
)

export const createReleaseForm = createAsyncThunk(
  'CREATE_RELEASE_FORM',
  async (payload: any = {}) => {
    const { data } = await Apis.inn.createINN(payload);
    return { data }
  },
)

export const deleteReleaseForm = createAsyncThunk(
  'DELETE_RELEASE_FORM',
  async (payload: any = {}, thunkAPI: any) => {
    const {
      inn: { id },
    } = thunkAPI.getState();
    const { data } = await Apis.diseases.deleteDisease(id)
    return { data }
  },
)

const ReleaseFormSlice = createSlice({
  name: 'inn',
  initialState,
  reducers: {
    SET_NEW_DISEASE_FORM: (state: ReleaseFormsStore, action) => {
      state.newCompanyForm = action.payload
    },
    SET_FILTERS: (state: ReleaseFormsStore, action) => {
      state.filters = { ...state.filters, ...action.payload }
    },
    SEARCH_RELEASEFORMS: (state: ReleaseFormsStore, { payload }: { payload: string }) => {
      const searchValue = payload.trim()
      if(searchValue.length) {
        const options = {
          includeScore: true,
          keys: ['name.ru', 'name.uz', 'name.en']
        }
        const fuse = new Fuse(state.items, options)
        const result = fuse.search(searchValue).map(itm => itm.item)
        state.filteredItems = result
        state.count = result.length
      } else {
        state.filteredItems = state.items
        state.count = state.items.length
      }
    },
    CREATE_RELEASE_FORM: (state: ReleaseFormsStore, {payload}: {payload: ReleaseFormsItem}) => {
      state.count += 1
      state.items.unshift(payload)
      state.filteredItems = state.items
    },
    DELETE_RELEASE_FORM: (state: ReleaseFormsStore, {payload}: {payload: string}) => {
      state.count -= 1
      state.items = state.items.filter(itm => itm.id !== payload)
      state.filteredItems = state.items
    },
    UPDATE_RELEASE_FORM: (state: ReleaseFormsStore, {payload}: {payload: ReleaseFormsItem}) => {
      for(let i = 0; i < state.items.length; i++) {
        if(state.items[i].id === payload.id){
          state.items[i] = payload;
          break;
        }
      }
      state.filteredItems = state.items
    }
  },
  extraReducers: (builder) => {
    //getBranches
    builder.addCase(getReleaseForms.pending, (state, action) => {
      state.error = null
      state.loading = true
    })
    builder.addCase(getReleaseForms.fulfilled, (state, action) => {
      const {
        filters,
        data: { items, count },
      } = action.payload
      state.items = items
      state.filteredItems = items
      state.filters = filters
      state.count = count
      state.loading = false
      state.currentCount = state.items.length
    })
    builder.addCase(getReleaseForms.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
    })
    //get one branch
    builder.addCase(getOneReleaseForm.pending, (state, action) => {

    })
    builder.addCase(getOneReleaseForm.fulfilled, (state, action) => {

    })
    builder.addCase(getOneReleaseForm.rejected, (state, action) => {

    })
    //create branch
    builder.addCase(createReleaseForm.pending, (state, action) => {
      state.isSubmiting = true;
      state.error = null;
    })
    builder.addCase(createReleaseForm.fulfilled, (state, action) => {
      state.isSubmiting = false;
    })
    builder.addCase(createReleaseForm.rejected, (state, action) => {
      state.isSubmiting = false;
      state.error = action.error;
    })
    builder.addCase(updateReleaseForm.pending, (state, action) => {

    })
    builder.addCase(updateReleaseForm.fulfilled, (state, action) => {

    })
    builder.addCase(updateReleaseForm.rejected, (state, action) => {

    })
    builder.addCase(deleteReleaseForm.pending, (state, action) => {

    })
    builder.addCase(deleteReleaseForm.fulfilled, (state, action) => {

    })
    builder.addCase(deleteReleaseForm.rejected, (state, action) => {

    })
  },
})

export const { SEARCH_RELEASEFORMS, CREATE_RELEASE_FORM, UPDATE_RELEASE_FORM, DELETE_RELEASE_FORM } = ReleaseFormSlice.actions
export default ReleaseFormSlice.reducer;
