import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../common";
import {StatsCountResponse} from "src/redux/API/statsAPI/statsAPI.types";


export const statsAPI = createApi({
    reducerPath: 'statsAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        statsCount: builder.query<StatsCountResponse,void>({
            query: () => `/v1/stats/count`
        })
    }),
});

export const {useStatsCountQuery} =statsAPI