import React, { memo, useCallback, useEffect, useState } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import styled, { useTheme, keyframes } from 'styled-components'
import { darken, lighten, rgba } from 'src/styles/helpers'
import { color, fontFamily, fontSize, space } from 'styled-system'
import ArrowLeft from 'src/icons/fluency-regular/ArrowLeft'
import Navigation from 'src/icons/fluency-regular/Navigation'
import ChevronDown from "src/icons/fluency-regular/ChevronDown";
import Box from "src/components/Box";
import Logo from 'src/icons/medio_logo.svg'
import Icon from 'src/icons/medio_icon.svg'
import Text from "src/components/Text";
import { bounceIn, flip } from 'react-animations';
import { Typography } from "@med-io/neural-ui";

const bounceInAnimation = keyframes`${bounceIn}`;
const flipAnimation = keyframes`${flip}`;

const AnimatedBlock: any = styled.div`
  animation: 0.7s ${bounceInAnimation};

  .expanded {
    animation: 0.7s ${bounceInAnimation} !important;
  }
`;

const MenuWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  border-right: solid 1px;
  border-color: ${(p) => p.theme.colors.palette.divider};
  transition: ease-in 0.2s;
  background: #fff;

  &.expanded {
    width: 280px;
  }
`

const NavLinkStyled: any = styled.div`
  display: block;
  padding: 0 10px;
  text-decoration: none;
  ${space};
  ${fontFamily};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: ease-in 0.2s;
  color: ${(p: any) => p.theme.colors.palette.text.shade100};

  svg {
    transition: ease-in 0.3s;
    fill: ${(p: any) => p.theme.colors.palette.text.shade100} !important;
    padding: 1px;
  }

  &:hover {
    .inner-wrapper {
      background-color: ${(p) => p.theme.colors.palette.text.shade5};
    }
  }

  .inner-wrapper {
    background-color: ${(p) => p.theme.colors.palette.background.paper};
    border-radius: ${(p) => p.theme.borderRadius.default}px;
    padding: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    transition: ease-in 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .chevron {
      display: flex;
      align-items: center;
      transition: all 0.5s;
    }

    .up {
      transform: rotate(180deg);
    }
  }

  .child {
    display: flex;
    align-items: center;
    padding: 10px 0 10px 35px;
    text-decoration: none;
    border-radius: ${(p) => p.theme.borderRadius.default}px;
    color: ${() => darken('#fff', 0.6)};

    :hover {
      background-color: ${(p) => p.theme.colors.palette.text.shade5};
    }

    &.active {
      background: ${(p) => p.theme.colors.palette.text.shade5};
      color: ${(p) => p.theme.colors.palette.primary.main};
    }

    //padding: 0 5px;
  }

  &.active .inner-wrapper {
    background-color: ${(p) => lighten(p.theme.colors.palette.primary.main, 0.65)};
    color: ${(p) => p.theme.colors.palette.primary.main};

    svg {
      //fill: ${(p) => p.theme.colors.palette.primary.main} !important;
    }
  }
`

const MenuItemStyled = styled.div`
  display: block;
  padding: 0 10px;
  text-decoration: none;
  ${space};
  ${fontFamily};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  width: fit-content;
  color: ${(p) => p.theme.colors.palette.primary.contrastText};

  .inner-wrapper {
    background-color: ${(p) => p.theme.colors.palette.background.paper};
    border-radius: ${(p) => p.theme.borderRadius.default}px;
    padding: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;

    :hover {
      background-color: ${(p) => p.theme.colors.palette.text.shade5};
    }
  }

  &.active .inner-wrapper {
    background-color: ${(p) => p.theme.colors.palette.primary.main};
    color: ${(p) => p.theme.colors.palette.primary.contrastText};

    svg {
      fill: ${(p) => p.theme.colors.palette.primary.contrastText};
    }
  }
`

const MenuItemIconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled.div`
  display: none;
  justify-content: flex-start;
  justify-self: flex-start;

  &.center {
    justify-content: center;
  }

  &.expanded {
    display: flex;
    padding-left: 15px;
  }
`

const HeaderWrapper = styled.div`
  display: block;
  height: 60px;

  .inner-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const HeaderTitle = styled.div`
  padding: 10px;
  font-weight: 500;
  align-self: center;
  justify-self: center;
  font-size: 18px;
`

const HeaderPrefixSuffix = styled.div`
  padding: 10px 20px;
  height: 100%;
  display: flex;
  align-items: center;

  &.hover:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.colors.palette.text.shade5};
  }
`

const EmptyIconSpace = styled.div`
  height: 24px;
  width: 24px;
`

const FlexSuffix = styled.div`
  display: flex;
  height: 100%;
`

const SuffixIconStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 36px;
`

const SuffixStyled = styled.div`
  display: flex;
  width: 36px;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-left: 1px solid #e7e7e7;
`

const Header = ({
                  title,
                  backLink,
                  expanded,
                }: {
  title: string
  backLink?: string
  expanded: boolean
}) => {
  const history = useHistory()

  const onBack = useCallback(
    () => (backLink ? history.push(backLink) : history.goBack()),
    [backLink],
  )

  return (
    <HeaderWrapper>
      <div className='inner-wrapper'>
        <HeaderPrefixSuffix className='hover' onClick={() => onBack()}>
          <ArrowLeft size={24} />
        </HeaderPrefixSuffix>
        {expanded && (
          <>
            <HeaderTitle>{title}</HeaderTitle>
            <HeaderPrefixSuffix>
              <EmptyIconSpace />
            </HeaderPrefixSuffix>
          </>
        )}
      </div>
    </HeaderWrapper>
  )
}

export const SideMenuItem = ({
                               title,
                               to,
                               icon,
                               onClick,
                               expanded = true,
                               alignTitle = 'left',
                               exact = false,
                               items = [],
                               expandMenu
                             }: {
  title?: string
  to?: string
  icon?: any
  onClick?: any
  expanded?: boolean
  alignTitle?: string
  exact?: boolean,
  items?: any[],
  expandMenu?: () => void
}) => {
  const theme: any = useTheme()
  const [itemsExpanded, setItemsExpanded] = useState(false)
  const { pathname } = useLocation()

  useEffect(() => {
    if (!expanded) {
      setItemsExpanded(false)
    }
  }, [expanded])

  const hasItems = items.length

  const contrastThemeColor = theme.colors.palette.primary.contrastText

  const handleClick = () => {
    expanded && setItemsExpanded(!itemsExpanded)
    if (!expanded && expandMenu && hasItems) {
      expandMenu()
      setItemsExpanded(true)
    }
  }

  if (to) {
    return (
      <NavLinkStyled exact={true} className={to !== '/' && pathname.includes(to) ? 'active' : ''}
                     as={!hasItems ? NavLink : 'div'} to={!hasItems ? to : '/catalog'}>
        <div onClick={handleClick} className='inner-wrapper'>
          <Box display='flex' alignItems='center' justifyContent='center'>
            <MenuItemIconWrapper>
              {icon && React.createElement(icon, { size: 22 })}
            </MenuItemIconWrapper>
            <Title className={`${alignTitle} ${expanded && 'expanded'}`}><Typography size='sm'>{title}</Typography></Title>
          </Box>
          {hasItems && expanded ?
            <div className={`chevron ${itemsExpanded ? 'up' : 'down'}`}><ChevronDown size={22} /></div> : null}
        </div>
        {itemsExpanded && items.map((item: any, index: number) => (
          <NavLink to={item.to} key={index} className='child'>
            <Title className={`${alignTitle} ${expanded && 'expanded'}`}><Typography size='sm'>{item.title}</Typography></Title>
          </NavLink>
        ))}
      </NavLinkStyled>
    )
  }
  return (
    <MenuItemStyled className={title ? 'full-width' : ''} onClick={onClick}>
      <div className='inner-wrapper'>
        <MenuItemIconWrapper>{icon && React.createElement(icon, { size: 24 })}</MenuItemIconWrapper>
        {expanded && <Title className={`${alignTitle}`}>{title}</Title>}
      </div>
    </MenuItemStyled>
  )
}

const MainHeader = ({ expanded }: { expanded: boolean }) => {
  const [counter, setCounter] = useState(0)
  return (
    <AnimatedBlock className={expanded ? 'expanded' : 'hidden'} onClick={() => setCounter(counter + 1)}
                   key={expanded.toString() + counter}>
      {expanded ? <Box height='70px' padding={15} display='flex' alignItems='center' justifyContent='center'>
          <img style={{ objectFit: 'fill', width: 'min-content' }} height='100%' src={Logo} />
        </Box> :
        <Box width='60px' height='70px' padding={15} display='flex' alignItems='center' justifyContent='center'>
          <img style={{ objectFit: 'contain', width: 'min-content' }} height='100%' src={Icon} />
        </Box>}
    </AnimatedBlock>
  )
}

function SideMenu({
                    children,
                    title = '',
                    backLink,
                    mainHeader,
                    footer
                  }: {
  children?: JSX.Element | JSX.Element[]
  title?: string
  backLink?: string,
  mainHeader?: boolean,
  footer?: JSX.Element
}) {
  const [expanded, setExpand] = useState(true)

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement<any>, {
        expanded: expanded,
        expandMenu: () => setExpand(true)
      })
    }
    return child
  })

  return (
    <MenuWrapper className={expanded ? 'expanded' : ''}>
      <Box height='100%' flexDirection='column' justifyContent='flex-start'>
        {/*<SideMenuItem title="Склад" alignTitle="center" icon={ArrowLeft} onClick={() => setState(!state)}/>*/}
        {mainHeader ? <MainHeader expanded={expanded} /> : title ?
          <Header title={title} expanded={expanded} backLink={backLink} /> : null}
        <SideMenuItem title={title} icon={Navigation} onClick={() => setExpand(!expanded)} />
        <Box overflowY="auto" flex={1} height='100%' display='block'>
          {childrenWithProps}
        </Box>
        {footer && <Box>{
          React.cloneElement(footer as React.ReactElement<any>, {
            expanded: expanded
          })}</Box>}
      </Box>
    </MenuWrapper>
  )
}

export default SideMenu
