import React from "react";

const ChevronDown = ({ size = 18, color = "#000" }: { size?: number, color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 18 19" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.16475 6.85225C3.38442 6.63258 3.74058 6.63258 3.96025 6.85225L9 11.892L14.0398 6.85225C14.2594 6.63258 14.6155 6.63258 14.8352 6.85225C15.0549 7.07192 15.0549 7.42808 14.8352 7.64775L9.39772 13.0852C9.17805 13.3049 8.82195 13.3049 8.60227 13.0852L3.16475 7.64775C2.94508 7.42808 2.94508 7.07192 3.16475 6.85225Z" />
  </svg>

);

export default ChevronDown;
