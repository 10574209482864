import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../common";
import {
    AdminsRequest,
    AdminsResponse,
    AdminsPermissionsResponse,
    CreateAdminResponse,
    CreateAdminRequest,
    AdminsByIdResponse,
    AdminsByIdRequest,
    UpdateAdminByIdResponse,
    UpdateAdminByIdRequest, UpdateAdminAvatarByIdResponse, DeleteAdminByIdResponse, DeleteAdminByIdRequest
} from "src/redux/API/adminAPI/adminAPI.types";


export const adminAPI = createApi({
    reducerPath: 'adminAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        admins: builder.query<AdminsResponse, AdminsRequest>({
            query: ({
                        limit = 10,
                        page = 1,
                        search = '',
                        order_by
                    }) => `/v1/admin?limit=${limit}&page=${page}&search=${search}&order_by=${order_by}`
        }),
        adminsPermissions: builder.query<AdminsPermissionsResponse, void>({
            query: () => `/v1/admin/permissions`
        }),
        createAdmin: builder.mutation<CreateAdminResponse, CreateAdminRequest>({
            query: (payload) => ({
                url: `/v1/admin`,
                method: 'POST',
                body: payload
            })
        }),
        adminsById: builder.query<AdminsByIdResponse, AdminsByIdRequest>({
            query: ({id}) => `/v1/admin/${id}`
        }),
        updateAdminById: builder.mutation<UpdateAdminByIdResponse, UpdateAdminByIdRequest>({
            query: ({id, ...payload}) => ({
                url: `/v1/admin/${id}`,
                method: 'PUT',
                body: payload
            })
        }),
        updateAdminAvatarById: builder.mutation<UpdateAdminAvatarByIdResponse, any>({
            query: ({id, ...imageFile}) => {
                let bodyFormData = new FormData();
                bodyFormData.append('file', imageFile);
                return {
                    url: `/v1/admin/${id}/avatar`,
                    method: 'PUT',
                    body: {bodyFormData}
                }
            }
        }),
        deleteAdminById: builder.mutation<DeleteAdminByIdResponse, DeleteAdminByIdRequest>({
            query: ({id}) => ({
                url: `/v1/admin/${id}`,
                method: 'DELETE'
            })
        })

    }),

})

export const {
    useAdminsQuery,
    useAdminsPermissionsQuery,
    useCreateAdminMutation,
    useAdminsByIdQuery,
    useUpdateAdminByIdMutation,
    useUpdateAdminAvatarByIdMutation,
    useDeleteAdminByIdMutation
} = adminAPI;