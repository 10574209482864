import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Button, layouts, Typography } from "@med-io/neural-ui";
import styled from "styled-components";
import Confirm from "src/components/Confirm";
import * as XLSX from "xlsx";
import {
  ProductVariantExcelRow,
  ProductVariantImportProductGroup,
  transformProductVariantExcelKeys,
  validateProductVariantExcel
} from "src/screens/catalog/import/types";
import { toast } from "react-toastify";
import Loading from "src/screens/Loading";
import { ProductVariantsDataSheet } from "src/screens/catalog/import/ProductVariantsDataSheet";
import { getManufacturers } from "src/redux/features/manufacturers";
import { useDispatch, useSelector } from "react-redux";
import { getATCs } from "src/redux/features/atc";
import { getINNs } from "src/redux/features/inn";
import { getReleaseForms } from "src/redux/features/releaseForms";
import { getDosageForms } from "src/redux/features/dosageForms";
import { RootState } from "src/redux/store";
import { getCountries } from "src/redux/features/countries";
import { transliterateRuToEn } from "src/common/string";
import { ImportProductsDataSheet } from "src/screens/catalog/import/ImportProductsDataSheet";
import product from "src/apis/product";

const { TopBarLayout } = layouts;

const DropzoneWrapper = styled.div`
  width: 100%;
  height: 300px;
  background-color: #F5F9FF;
  border: dashed 1px #1571D4;
  border-radius: 8px;
  cursor: pointer;

  .dropzone {
    padding: 1rem;
    height: 100%;
  }
`;

export const ProductVariantImport = () => {
  const [data, setData] = useState<ProductVariantExcelRow[]>([]);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<ProductVariantImportProductGroup[]>([]);
  const [step, setStep] = useState(0);
  const [grouping, setGrouping] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [initialData, setInitialData] = useState<any[]>([]);

  const dispatch = useDispatch();

  const {
    items: manufacturers,
    loading: manufacturersLoading
  } = useSelector((state: RootState) => state.manufacturers);
  const { items: atcs, loading: atcsLoading } = useSelector((state: RootState) => state.atc);
  const { items: inns, loading: innsLoading } = useSelector((state: RootState) => state.inn);
  const { items: releaseForms, loading: releaseFormsLoading } = useSelector((state: RootState) => state.releaseForms);
  const { items: dosageForms, loading: dosageFormsLoading } = useSelector((state: RootState) => state.dosageForms);
  const { items: countries, loading: countriesLoading } = useSelector((state: RootState) => state.countries);

  const areAllDataLoaded = React.useMemo(() => {
    return manufacturers.length && atcs.length && inns.length && releaseForms.length && dosageForms.length && countries.length;
  }, [manufacturers, atcs, inns, releaseForms, dosageForms, countries]);

  useEffect(() => {
    dispatch(getManufacturers({}));
    dispatch(getATCs({}));
    dispatch(getINNs({}));
    dispatch(getReleaseForms({}));
    dispatch(getDosageForms({}));
    dispatch(getCountries({}));
  }, []);

  const onDrop = (acceptedFiles: any) => {
    setLoading(true);
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const wb = XLSX.read(event.target!.result, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      const merges = ws['!merges'];

      if (merges) {
        merges.forEach((merge) => {
          const startRow = merge.s.r; // Start row index
          const endRow = merge.e.r; // End row index
          const colIndex = merge.s.c; // Column index

          // Access the merged cell value
          const cell = ws[XLSX.utils.encode_cell({ r: startRow, c: colIndex })];
          const mergedCellValue = cell ? cell.v : '';

          // "Unmerge" the cells and apply merged cell value to all rows
          for (let row = startRow; row <= endRow; row++) {
            const cellRef = XLSX.utils.encode_cell({ r: row, c: colIndex });
            ws[cellRef] = {
              ...cell,
              v: mergedCellValue,
            };
          }
        });
      }

      console.log(XLSX.utils.sheet_to_json<ProductVariantExcelRow>(ws))

      const data = XLSX.utils.sheet_to_json<ProductVariantExcelRow>(ws).map((row: any, index: number) => transformProductVariantExcelKeys(row, index, {
        manufacturers,
        atcs,
        inns,
        releaseForms,
        dosageForms,
        countries
      }));
      // Check if data has any row
      if (!data?.length) {
        setData([]);
      } else {
        validateProductVariantExcel(data).then((isValid) => {
          if (isValid) {
            setData(data);
          }
        });
      }
    };

    reader.readAsBinaryString(file);
    setLoading(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop, accept: {
      "file": [".xls", ".xlsx"]
    }
  });

  const handleClear = () => {
    setProducts([]);
    setData([]);
  };

  const handleChange = (fieldName: keyof ProductVariantExcelRow, value: string | number, index: number) => {
    setProducts([]);
    setData((prevData) => {
      const newData = [...prevData];
      // @ts-ignore
      newData[index][fieldName] = value;
      return newData;
    });
  };

  const validate = () => {
    //  Check if all select fields are selected
    const isValid = data.every((row) => {
      return row.manufacturer_id && row.atc_id && row.inn_id && row.release_form_id && row.dosage_form_id && row.country_id;
    });

    if (!isValid) {
      toast.error("Заполните все поля");
    }
    return isValid;
  };

  const groupProductVariants = () => {
    if (validate()) {
      setGrouping(true);
      const rows = [...data] as Array<ProductVariantExcelRow & { manufacturer_id: string, country_id: string }>;
      //    Group product variants to products by same product_name. There may be multiple variants with the same product_name.
      //    First need to create an object where keys are product_names and values are arrays of variants with the same product_name in order to check quickly access product. If there were array then it would be O(n) to find product by name
      const productGroups = {} as Record<string, ProductVariantImportProductGroup>;

      rows.forEach(({
                      product_name,
                      product_variant_name,
                      manufacturer_id,
                      manufacturer_name,
                      country_id,
                      country_name,
                      ...rest
                    }) => {
        const product_name_en = transliterateRuToEn(product_name);
        if (!productGroups[product_name_en]) {
          productGroups[product_name_en] = {
            name: { ru: product_name, en: product_name_en, uz: product_name_en },
            manufacturer_id: manufacturer_id,
            country_id: country_id,
            variants: []
          };
        }
        const variant_name_en = transliterateRuToEn(product_variant_name);

        productGroups[product_name_en].variants.push({
          name: { ru: product_variant_name, en: variant_name_en, uz: variant_name_en },
          ...rest
        });
      });

      //  Return array of productGroups
      setProducts(Object.values(productGroups));
      setStep(1);
      setGrouping(false);
    }
  };

  const backToVariants = () => {
    setStep(0);
  };

  const handleSubmit = async () => {
    if (!validate()) {
      toast.error("Заполните все поля");
      return;
    } else {
      try {
        setSubmitting(true);
        // console.log(products)
        const { data } = await product.importProducts(products)
        setSubmitting(false);
        toast.success(`Импортировано ${data.imported_products_count} продуктов и ${data.imported_variants_count} вариантов`);
      } catch (e) {

      } finally {
        setSubmitting(false);
      }
    }
  };

  const dataLength = data.length;

  return (
    <TopBarLayout
      title="Импорт продуктов и вариантов"
      disableBodyOverflow
      topBarActions={
        <>
          <Confirm onConfirm={handleClear} title="Внимание" description="Вы действительно хотите очистить импорт?">
            <Button size="sm" disabled={submitting || grouping || !areAllDataLoaded} variant="secondaryColor"
                    destructive>Очистить</Button>
          </Confirm>
          {step === 0 && dataLength ?
            <Button ml={1} size="sm" onClick={groupProductVariants} loading={grouping}
                    variant="secondaryColor">Группировать</Button>
            : step === 1 ? (
              <>
                <Button ml={1} size="sm" onClick={backToVariants} variant="secondaryGray">Назад в варианты</Button>
                <Button ml={1} size="sm" onClick={handleSubmit} loading={submitting}
                        variant="primary">Загрузить</Button>
              </>
            ) : null
          }
        </>
      }
    >
      {loading || !areAllDataLoaded ? (
        <Box>
          <Loading />
        </Box>
      ) : (
        dataLength && step === 0 ?
          <ProductVariantsDataSheet data={data} onChange={handleChange} />
          : products.length && step === 1 ? <ImportProductsDataSheet data={products} loading={submitting} /> :
            <DropzoneWrapper>
              <Box alignItems="center" justifyContent="center" {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <Typography size="sm" color="primary600">Перетащите файл сюда или нажмите чтобы выбрать</Typography>
              </Box>
            </DropzoneWrapper>
      )}
    </TopBarLayout>
  );
};
