import {createApi} from '@reduxjs/toolkit/query/react';

import {baseQuery} from '../common';
import {
    CitiesRequest,
    CitiesResponse, CityByIdRequest, CityByIdResponse,
    CreateCityRequest,
    CreateCityResponse, UpdateCityByIdRequest, UpdateCityByIdResponse
} from "src/redux/API/cityAPI/cityAPI.types";


export const cityAPI = createApi({
    reducerPath: 'cityAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        cities: builder.query<CitiesResponse, CitiesRequest>({
            query: ({
                        limit = 10,
                        page = 1,
                        search = '',
                        state_id,
                        order_by
                    }) => `/v1/area/city?limit=${limit}&page=${page}&search=${search}&state_id=${state_id}&order_by=${order_by}`
        }),
        createCity: builder.mutation<CreateCityResponse, CreateCityRequest>({
            query: (country) => ({
                url: `/v1/area/city`,
                method: 'POST',
                body: country
            })
        }),
        cityById: builder.query<CityByIdResponse, CityByIdRequest>({
            query: ({id}) => `/v1/area/city/${id}`
        }),
        updateCityById: builder.mutation<UpdateCityByIdResponse, UpdateCityByIdRequest>({
            query: ({id, ...payload}) => ({
                url: `/v1/area/city/${id}`,
                method: 'PUT',
                body: payload
            })
        })

    }),
});

export const {useCitiesQuery, useCreateCityMutation, useCityByIdQuery, useUpdateCityByIdMutation} = cityAPI