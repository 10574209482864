import { useEffect, useState } from "react";
import Apis from "src/apis";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { DELETE_MODERATOR, UPDATE_MODERATOR, getModerators } from "src/redux/features/moderators";
import * as Yup from "yup";
import { ModeratorsSelector } from "src/redux/features/moderators/selectors";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export type ModeratorItem = {
  id: string;
  name: string;
  surname: string;
  patronymic: string;
  email: string;
  created_at: Date;
  updated_at: Date | null;
  is_super: boolean;
  avatar: null;
  permissions: string[];
}


type Props = {
  id?: string
}

export const useModerators = (props: Props) => {
  const dispatch = useDispatch()
  const { id } = props
  const [loading, setLoading] = useState(true)
  const [count, setCount] = useState(0)
  const [error, setError] = useState<null | Error>(null)
  const history = useHistory()
  const [item, setItem] = useState<ModeratorItem>({
    avatar: null,
    created_at: new Date(),
    email: "",
    id: "",
    is_super: false,
    name: "",
    patronymic: "",
    permissions: [],
    surname: "",
    updated_at: null
  })
  const { items, filters: { page }, loading: loadingItems } = useSelector(ModeratorsSelector)

  const getItems = async () => {
    //  TODO: get moderators
  }

  const getOne = async (itemId = id) => {
    if (itemId) {
      setLoading(true)
      const { data } = await Apis.moderators.getOneModerator(itemId)
      if (data.is_super) {
        data.permissions = []
      }
      setItem(data)
      setLoading(false)
      return data
    }
  }

  const deleteItem = async (id: string, formRef: any) => {
    if (formRef.current) {
      formRef.current.setSubmitting(true)
      const { data } = await Apis.moderators.deleteModerator(id)
      formRef.current.setSubmitting(false)
      toast.success('Удалено')
      dispatch(DELETE_MODERATOR(id))
      history.goBack()
    }
  }

  useEffect(() => {
    if (!items.length) {
      dispatch(getModerators({}))
    }
    getOne()
  }, [])

  return {
    loading: id ? loading : loadingItems,
    items,
    reloadItems: getItems,
    reloadItem: getOne,
    error,
    count,
    getOne,
    item,
    deleteItem
  }
}
