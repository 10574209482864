import React, {useMemo} from 'react';
import {FormikSelect} from '@med-io/neural-ui';
import Fuse from 'fuse.js';
import {InnItem} from 'src/redux/features/inn/types';
import {INN, useInnQuery} from "src/redux/API/innAPI";

type INNSelectProps = {
    name: string;
    isClearable?: boolean;
    [x: string]: any
}
export const INNSelect = (props: INNSelectProps) => {
    const {data, isFetching} = useInnQuery({})

    const searchOptions: Fuse.IFuseOptions<InnItem> = {
        keys: [
            {name: "name.ru", weight: 1},
        ],
    };

    const items = useMemo(() => {
        if (data?.items) {
            return data.items
        } else {
            return []
        }
    }, [data])

    return (
        <FormikSelect<INN>
            options={items}
            loading={isFetching}
            getOptionLabel={(option) => option.name.ru}
            valueKey='id'
            virtualized
            placeholder='Выберите МНН'
            searchOptions={searchOptions}
            delayedSearch={150}
            size="sm"
            fullWidth
            label="МНН"
            searchable
            {...props}
        />
    );
};

export default INNSelect;
