import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../common";
import {
    LinkOneOsonProductRequest,
    LinkOneOsonProductResponse,
    OsonRequest,
    OsonResponse
} from "src/redux/API/platformsAPI/platformsAPI.types";

export const platformsAPI = createApi({
    reducerPath: 'platformsAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        oson: builder.query<OsonResponse, OsonRequest>({
            query: ({search, limit, page}) => `/v1/oson/?search=${search}&limit=${limit}&page=${page}`
        }),
        linkOneOsonProduct: builder.query<LinkOneOsonProductResponse, LinkOneOsonProductRequest>({
            query: ({
                        oson_id,
                        product_variant_id
                    }) => `/v1/oson/?oson_id=${oson_id}&product_variant_id=${product_variant_id}`
        })
    }),
})

export const {useOsonQuery, useLinkOneOsonProductQuery} = platformsAPI