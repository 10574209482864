import {createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from 'src/apis'
import { SymptomsStore } from './types'


export const initialState: SymptomsStore = {
    items: [],
    filters: {
        page: 1,
        limit: 100,
        search: '',
    },
    currentCount: 0,
    count: 0,
    loading: false,
    error: null,
    newCompanyForm: {},
    isSubmiting: false
}

export const getSymptoms = createAsyncThunk(
        'GET_SYMPTOMS',
    async (payload: any = {}, thunkAPI: any) => {
        const {
            symptoms
        } = thunkAPI.getState()
        const filters = {...symptoms.filters, ...payload}
        const {data} = await Apis.symptoms.getSymptoms({...filters})
        return {data, filters};
    },
)

export const getOneSymptom = createAsyncThunk(
    'GET_SYMPTOM',
    async (payload: any = {}, thunkAPI: any) => {
        const {
            symptoms: {id},
        } = thunkAPI.getState();
        const {data} = await Apis.symptoms.getOneSymptom(id)
        return {data}
    },
)


export const createSymptom = createAsyncThunk(
    'CREATE_SYMPTOM',
    async (payload: any = {}) => {
        const {data} = await Apis.symptoms.createSymptom(payload);
        return {data}
    },
)

export const deleteSymptom = createAsyncThunk(
    'DELETE_SYMPTOM',
    async (payload: any = {}, thunkAPI: any) => {
        const {
            symptoms: {id},
        } = thunkAPI.getState();
        const {data} = await Apis.symptoms.deleteSymptom(id)
        return {data}
    },
)

const SymptomSlice = createSlice({
    name: 'symptom',
    initialState,
    reducers: {
        SET_NEW_SYMPTOM_FORM: (state: SymptomsStore, action) => {
            state.newCompanyForm = action.payload
        },
        SET_FILTERS: (state: SymptomsStore, action) => {
            state.filters = {...state.filters, ...action.payload}
        },
    },
    extraReducers: (builder) => {
        //getBranches
        builder.addCase(getSymptoms.pending, (state, action) => {
            state.error = null
            state.loading = true
        })
        builder.addCase(getSymptoms.fulfilled, (state, action) => {
            const {
                filters,
                data: {items, count},
            } = action.payload
            state.filters = filters
            state.items = [...state.items, ...items]
            state.count = count
            state.loading = false
            state.currentCount += items.length
        })
        builder.addCase(getSymptoms.rejected, (state, action) => {
            state.loading = false
            state.error = action.error
        })
        //get one branch
        builder.addCase(getOneSymptom.pending, (state, action) => {

        })
        builder.addCase(getOneSymptom.fulfilled, (state, action) => {

        })
        builder.addCase(getOneSymptom.rejected, (state, action) => {

        })
        //create branch
        builder.addCase(createSymptom.pending, (state, action) => {
            state.isSubmiting = true;
            state.error = null;
        })
        builder.addCase(createSymptom.fulfilled, (state, action) => {
            state.isSubmiting = false;
        })
        builder.addCase(createSymptom.rejected, (state, action) => {
            state.isSubmiting = false;
            state.error = action.error;
        })
        // builder.addCase(updateSymptom.pending, (state, action) => {

        // })
        // builder.addCase(updateSymptom.fulfilled, (state, action) => {

        // })
        // builder.addCase(updateSymptom.rejected, (state, action) => {

        // })
        builder.addCase(deleteSymptom.pending, (state, action) => {

        })
        builder.addCase(deleteSymptom.fulfilled, (state, action) => {

        })
        builder.addCase(deleteSymptom.rejected, (state, action) => {

        })
    },
})

export default SymptomSlice.reducer;
