import React, { memo } from 'react';
import Text from "src/components/Text";
import { ProductVariant } from "src/screens/catalog/products/ProductVariant/ProductVariantInitial";
import {Typography,Box} from "@med-io/neural-ui";

const Item = ({ title, content }: { title: string, content: string }) => (
  <Box display='block' mb='20px'>
    <Typography component="div" type="text">{title}</Typography>
    <Typography weight="light">{content}</Typography>
  </Box>
)

const ProductVariantInfoBlock = (props: { data: ProductVariant }) => {
  const { data } = props

  return (
    <Box display='block'>
      <Item title='Название' content={data.name.ru} />
      <Item title='Лекарственная форма' content={data.dosage_form ? data.dosage_form.name.ru : '-'} />
      <Item title='Форма выпуска' content={data.release_form ? data.release_form.name.ru : '-'} />
      <Item title='Упаковка' content={data.package} />
      <Item title='МНН' content={data.inn ? data.inn.name : '-'} />
      <Item title='АТХ' content={data.atc ? `${data.atc.full_code} - ${data.atc.name}` : '-'} />
      <Item title='Категория' content={data.category ? data.category.name : "-"} />
      <Item title='Производитель' content={data.manufacturer.name} />
      <Item title='Баркоды' content={data.barcode.join(', ')} />
    </Box>
  );
};

export default ProductVariantInfoBlock;
