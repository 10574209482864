import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from 'src/apis'
import { InnItem, InnStore } from './types'
import Fuse from 'fuse.js'

export const initialState: InnStore = {
    items: [],
    filteredItems: [],
    filters: {
        page: 1,
        limit: 10000,
        search: '',
    },
    currentCount: 0,
    count: 0,
    loading: false,
    error: null,
    newCompanyForm: {},
    isSubmiting: false
}

export const getINNs = createAsyncThunk(
    'GET_INNS',
    async (payload: any = {}, thunkAPI: any) => {
        const {
            inn
        } = thunkAPI.getState()
        const filters = { ...inn.filters, ...payload }
        const { data } = await Apis.inn.getINNs({ ...filters })
        return { data, filters };
    },
)

export const getOneINN = createAsyncThunk(
    'GET_INN',
    async (payload: any = {}, thunkAPI: any) => {
        const {
            inn: { id },
        } = thunkAPI.getState();
        const { data } = await Apis.inn.getOneINN(id)
        return { data }
    },
)

export const updateINN = createAsyncThunk(
    'UPDATE_INN',
    async (payload: any = {}) => {
        // const {data} = await Apis.inn.updateINN(payload);
        // return {data}
    },
)

export const createINN = createAsyncThunk(
    'CREATE_INN',
    async (payload: any = {}) => {
        const { data } = await Apis.inn.createINN(payload);
        return { data }
    },
)

export const deleteINN = createAsyncThunk(
    'DELETE_INN',
    async (payload: any = {}, thunkAPI: any) => {
        const {
            inn: { id },
        } = thunkAPI.getState();
        const { data } = await Apis.diseases.deleteDisease(id)
        return { data }
    },
)

const INNSlice = createSlice({
    name: 'inn',
    initialState,
    reducers: {
        SET_NEW_DISEASE_FORM: (state: InnStore, action) => {
            state.newCompanyForm = action.payload
        },
        SET_FILTERS: (state: InnStore, action) => {
            state.filters = { ...state.filters, ...action.payload }
        },
        SEARCH_INN: (state: InnStore, { payload }: { payload: string }) => {
            const searchValue = payload.trim()
            if (searchValue.length) {
                const options: Fuse.IFuseOptions<InnItem> = {
                    includeScore: true,
                    keys: ['name.ru', 'name.uz', 'name.en', 'code'],

                }
                const fuse = new Fuse(state.items, options)
                const result = fuse.search(searchValue).map(itm => itm.item)
                state.filteredItems = result
                state.count = result.length
            } else {
                state.filteredItems = state.items
                state.count = state.items.length
            }
        },
        CREATE_INN: (state: InnStore, { payload }: { payload: InnItem }) => {
            state.count += 1
            state.items.unshift(payload)
            state.filteredItems = state.items
        },
        DELETE_INN: (state: InnStore, { payload }: { payload: string }) => {
            state.count -= 1
            state.items = state.items.filter(itm => itm.id !== payload)
            state.filteredItems = state.items
        },
        UPDATE_INN: (state: InnStore, { payload }: { payload: InnItem }) => {
            for (let i = 0; i < state.items.length; i++) {
                if (state.items[i].id === payload.id) {
                    state.items[i] = payload;
                    break;
                }
            }
            state.filteredItems = state.items
        }
    },
    extraReducers: (builder) => {
        //getBranches
        builder.addCase(getINNs.pending, (state, action) => {
            state.error = null
            state.loading = true
        })
        builder.addCase(getINNs.fulfilled, (state, action) => {
            const {
                filters,
                data: { items, count },
            } = action.payload
            state.items = items
            state.filteredItems = items
            state.filters = filters
            state.count = count
            state.loading = false
            state.currentCount = state.items.length
        })
        builder.addCase(getINNs.rejected, (state, action) => {
            state.loading = false
            state.error = action.error
        })
        //get one branch
        builder.addCase(getOneINN.pending, (state, action) => {

        })
        builder.addCase(getOneINN.fulfilled, (state, action) => {

        })
        builder.addCase(getOneINN.rejected, (state, action) => {

        })
        //create branch
        builder.addCase(createINN.pending, (state, action) => {
            state.isSubmiting = true;
            state.error = null;
        })
        builder.addCase(createINN.fulfilled, (state, action) => {
            state.isSubmiting = false;
        })
        builder.addCase(createINN.rejected, (state, action) => {
            state.isSubmiting = false;
            state.error = action.error;
        })
        builder.addCase(updateINN.pending, (state, action) => {

        })
        builder.addCase(updateINN.fulfilled, (state, action) => {

        })
        builder.addCase(updateINN.rejected, (state, action) => {

        })
        builder.addCase(deleteINN.pending, (state, action) => {

        })
        builder.addCase(deleteINN.fulfilled, (state, action) => {

        })
        builder.addCase(deleteINN.rejected, (state, action) => {

        })
    },
})

export const { SEARCH_INN, CREATE_INN, DELETE_INN, UPDATE_INN } = INNSlice.actions
export default INNSlice.reducer;
