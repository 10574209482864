import React, { memo, useState } from 'react';
import SubSectionHeader from "src/components/SubSectionHeader";
import CheckBoxList, { checkBoxListOnSelectProps } from "src/components/CheckBoxList";
import styled from "styled-components";
import PermissionsDisabledCover from "src/screens/moderator/PermissionsDisabledCover";
import { UserPermissions, UserPermissionsEnum } from "src/common/permissions";
import {SectionWithHeader} from "src/components/SectionWithHeader";
import {Box, Typography} from "@med-io/neural-ui";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #E7E7E7;
  border-radius: ${p => p.theme.borderRadius.default}px;
  position: relative;
`
const StyledBox = styled(Box)`
  display: block;
  overflow-y: auto;
  height: 100%;
  padding: 24px;
`

const Header = styled.div`
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #2C2C2C;
  margin-bottom: 24px;
`

type Props = {
  disabled?: boolean
  permissions: UserPermissionsEnum[]
  onChange: (permissions: UserPermissionsEnum[]) => void
}

// type onSelectProps = checkBoxListOnSelectProps extends { key: { value: UserPermissionsEnum, status: boolean }};

const Permissions = ({ disabled, permissions, onChange }: Props) => {

  const productRequired = [UserPermissionsEnum["manufacturer.read"], UserPermissionsEnum["category.read"], UserPermissionsEnum["atc.read"], UserPermissionsEnum["inn.read"], UserPermissionsEnum["dosage_form.read"], UserPermissionsEnum["release_form.read"]]

  const onSelect = ({ key }: any) => {
    let updated = permissions
    const entity = key.value.split('.')[0];
    if (key.status) {
      const entityReadPermission = entity + '.read' as UserPermissionsEnum
      if (!key.value.includes('read') && !updated.includes(entityReadPermission)) {
        updated = [...updated, entityReadPermission, key.value]
      } else {
        updated = [...updated, key.value]
      }
    } else {
      if (key.value.includes('read')) {
        updated = updated.filter((item: string) => !item.includes(entity))
      } else {
        const index = updated.indexOf(key.value);
        if (index > -1) {
          updated.splice(index, 1);
        }
      }
    }
    if (updated.includes(UserPermissionsEnum["product.read"])) {
      productRequired.forEach((item) => {
        if (!updated.includes(item)) {
          updated = [...updated, item]
        }
      })
    }
    onChange(updated)
  }

  return (
    <SectionWithHeader title='Разрешения'>
      {disabled ? <PermissionsDisabledCover /> : <></>}
      <StyledBox>
        <Box display='block' overflowY='auto'>
          <SubSectionHeader>Каталог</SubSectionHeader>
          <Box display='flex' justifyContent='space-between' pb={2}>
            <Box display='block' w='100%' flex={1}>
              <Box display='block' pb='10px' pl='5px'>
                <Typography>Продукты</Typography>
              </Box>
              <Box display='block' mr='24px'>
                <CheckBoxList
                    data={UserPermissions.product}
                    onSelect={onSelect}
                    selectedKeys={permissions}
                />
              </Box>
            </Box>

            <Box display='block' w='100%' flex={1}>
              <Box display='block' pb='10px' pl='5px'>
                <Typography>Категории</Typography>
              </Box>
              <Box display='block' mr='24px'>
                <CheckBoxList
                    data={UserPermissions.category}
                    onSelect={onSelect}
                    selectedKeys={permissions}
                />
              </Box>
            </Box>

            <Box display='block' w='100%' flex={1}>
              <Box display='block' pb='10px' pl='5px'>
                <Typography>АТХ</Typography>
              </Box>
              <Box display='block' mr='24px'>
                <CheckBoxList
                    data={UserPermissions.atc}
                    onSelect={onSelect}
                    selectedKeys={permissions}
                />
              </Box>
            </Box>
            <Box display='block' w='100%' flex={1}>
              <Box display='block' pb='10px' pl='5px'>
                <Typography>MHH</Typography>
              </Box>
              <Box display='block' mr='24px'>
                <CheckBoxList
                    data={UserPermissions.inn}
                    onSelect={onSelect}
                    selectedKeys={permissions}
                />
              </Box>
            </Box>

          </Box>

          <Box display='flex' justifyContent='space-between' pb={2}>
            <Box display='block' w='100%' flex={1}>
              <Box display='block' pb='10px' pl='5px'>
                <Typography>Производители</Typography>
              </Box>
              <Box display='block' mr='24px'>
                <CheckBoxList
                    data={UserPermissions.manufacturer}
                    onSelect={onSelect}
                    selectedKeys={permissions}
                />
              </Box>
            </Box>

            <Box display='block' w='100%' flex={1}>
              <Box display='block' pb='10px' pl='5px'>
                <Typography>Лекарственные формы</Typography>
              </Box>
              <Box display='block' mr='24px'>
                <CheckBoxList
                    data={UserPermissions.dosage_form}
                    onSelect={onSelect}
                    selectedKeys={permissions}
                />
              </Box>
            </Box>

            <Box display='block' w='100%' flex={1}>
              <Box display='block' pb='10px' pl='5px'>
                <Typography>Формы упаковки</Typography>
              </Box>
              <Box display='block' mr='24px'>
                <CheckBoxList
                    data={UserPermissions.release_form}
                    onSelect={onSelect}
                    selectedKeys={permissions}
                />
              </Box>
            </Box>
            <Box display='block' w='100%' flex={1}>
            </Box>
          </Box>

          <Box justifyContent='space-between'>
            <Box display='block' overflowY='auto' flex={1} w='100%'>
              <SubSectionHeader>Компании</SubSectionHeader>
              <Box display='flex' justifyContent='space-between' pb={2}>
                <Box display='block' w='100%' flex={1}>
                  <Box display='block' mr='24px'>
                    <CheckBoxList
                        data={UserPermissions.company}
                        onSelect={onSelect}
                        selectedKeys={permissions}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box display='block' overflowY='auto' mb={1}>
            <SubSectionHeader>Мобильное приложение</SubSectionHeader>
            <Box display='block' w='100%' flex={1}>
              <Box display='block' pb='10px' pl='5px'>
                <Typography>Подборки</Typography>
              </Box>
              <Box display='block' mr='24px'>
                <CheckBoxList
                    data={UserPermissions.collection}
                    onSelect={onSelect}
                    selectedKeys={permissions}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </StyledBox>
    </SectionWithHeader>
  );
};

export default Permissions;
