import styled from "styled-components";

export const HorizontalBox = styled.div`
  position: relative;
  min-height: 100px;
  flex-shrink: 1;

  &.flex {
    display: flex;

    & > * {
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: auto;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.align-bottom {
    align-items: flex-end;
  }

  &.barcode {
    svg {
      width: 100% !important;
    }
  }
`