import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../common";
import {
    CustomerByIdRequest,
    CustomerByIdResponse,
    CustomersRequest,
    CustomersResponse
} from "src/redux/API/customerAPI/customerAPI.types";


export const customerAPI = createApi({
    reducerPath: 'customerAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        customers: builder.query<CustomersResponse, CustomersRequest>({
            query: ({
                        limit = 10,
                        page = 1,
                        search = '',
                        order_by
                    }) => `/v1/customer/?searh=${search}&limit=${limit}&page=${page}&order_by=${order_by}`
        }),
        customerById: builder.query<CustomerByIdResponse, CustomerByIdRequest>({
            query: ({id}) => `/v1/customer/${id}`
        })
    }),
});

export const {useCustomersQuery, useCustomerByIdQuery} = customerAPI