import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Login from "src/screens/login";
import MainRoutes from "src/routes/MainRoutes";
import PrivateRoute from "src/routes/ProtectedRoute";
import Loading from "src/screens/Loading";
import NotFound from "src/screens/NotFound";
import { useUser } from "src/hooks/useUser";
import Listeners from "src/helpers/Listeners";

const DefaultRoutes = (): JSX.Element => {
  const { loading } = useUser({ initialize: true })

  if (loading) return <Loading />

  return (
    <Switch>
      <Route exact path='/login' component={Login} />
      <PrivateRoute path='/' component={MainRoutes} />
      <Route path='*' component={NotFound} />
      <Listeners />
    </Switch>
  )
};

export default DefaultRoutes;
