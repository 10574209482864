import {compose} from 'redux'
import {configureStore, ConfigureStoreOptions} from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import UI from './features/UI'
import companies from './features/companies'
import products from './features/products'
import user from './features/user'
import atc from './features/atc'
import countries from './features/countries'
import manufacturers from './features/manufacturers'
import categories from './features/categories'
import inn from './features/inn'
import dosageForms from './features/dosageForms'
import releaseForms from './features/releaseForms'
import moderators from './features/moderators'
import home from "./features/home"
import diseases from './features/diseases';
import symptoms from './features/symptoms';
import {adminAPI} from "src/redux/API/adminAPI";
import {authAPI} from "src/redux/API/authAPI";
import {bookingAPI} from "src/redux/API/bookingAPI";
import {companyAPI} from "src/redux/API/companyAPI";
import {atcAPI} from "src/redux/API/atcClassAPI";
import {categoryAPI} from "src/redux/API/categoryAPI";
import {cityAPI} from "src/redux/API/cityAPI";
import {collectionAPI} from "src/redux/API/collectionAPI";
import {companyBranchAPI} from "src/redux/API/companyBranchAPI";
import {companyUsersAPI} from "src/redux/API/companyUsersAPI";
import {countryAPI} from "src/redux/API/countryAPI";
import {customerAPI} from "src/redux/API/customerAPI";
import {diseasesAPI} from "src/redux/API/diseasesAPI";
import {diseasesSymptomsAPI} from "src/redux/API/diseasesSymptomsAPI";
import {districtAPI} from "src/redux/API/districtAPI";
import {dosageAPI} from "src/redux/API/dosageAPI";
import {innAPI} from "src/redux/API/innAPI";
import {manufacturerAPI} from "src/redux/API/manufacturerAPI";
import {platformsAPI} from "src/redux/API/platformsAPI";
import {productAPI} from "src/redux/API/productAPI";
import {productVariantsAPI} from "src/redux/API/productVariantsAPI";
import {releaseAPI} from "src/redux/API/releaseAPI";
import {stateAPI} from "src/redux/API/stateAPI";
import {statsAPI} from "src/redux/API/statsAPI";

const persistConfig = {
    key: 'user',
    storage,
}

const persistedReducer = persistReducer(persistConfig, user)

// @ts-ignore
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
    configureStore({
        reducer: {
            [adminAPI.reducerPath]: adminAPI.reducer,
            [atcAPI.reducerPath]: atcAPI.reducer,
            [authAPI.reducerPath]: authAPI.reducer,
            [bookingAPI.reducerPath]: bookingAPI.reducer,
            [categoryAPI.reducerPath]: categoryAPI.reducer,
            [cityAPI.reducerPath]: cityAPI.reducer,
            [collectionAPI.reducerPath]: collectionAPI.reducer,
            [companyAPI.reducerPath]: companyAPI.reducer,
            [companyBranchAPI.reducerPath]: companyBranchAPI.reducer,
            [companyUsersAPI.reducerPath]: companyUsersAPI.reducer,
            [countryAPI.reducerPath]: countryAPI.reducer,
            [customerAPI.reducerPath]: customerAPI.reducer,
            [diseasesAPI.reducerPath]: diseasesAPI.reducer,
            [diseasesSymptomsAPI.reducerPath]: diseasesSymptomsAPI.reducer,
            [districtAPI.reducerPath]: districtAPI.reducer,
            [dosageAPI.reducerPath]: dosageAPI.reducer,
            [innAPI.reducerPath]: innAPI.reducer,
            [manufacturerAPI.reducerPath]: manufacturerAPI.reducer,
            [platformsAPI.reducerPath]: platformsAPI.reducer,
            [productAPI.reducerPath]: productAPI.reducer,
            [productVariantsAPI.reducerPath]: productVariantsAPI.reducer,
            [releaseAPI.reducerPath]: releaseAPI.reducer,
            [stateAPI.reducerPath]: stateAPI.reducer,
            [statsAPI.reducerPath]: statsAPI.reducer,
            UI,
            products,
            companies,
            user: persistedReducer,
            atc,
            countries,
            manufacturers,
            categories,
            inn,
            dosageForms,
            releaseForms,
            moderators,
            home,
            diseases,
            symptoms
        },
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: false,
        }).concat([adminAPI.middleware, companyAPI.middleware,atcAPI.middleware, authAPI.middleware,bookingAPI.middleware,categoryAPI.middleware,cityAPI.middleware,collectionAPI.middleware,companyAPI.middleware,companyBranchAPI.middleware,companyUsersAPI.middleware,countryAPI.middleware,customerAPI.middleware,diseasesAPI.middleware,diseasesSymptomsAPI.middleware,districtAPI.middleware,dosageAPI.middleware,innAPI.middleware,manufacturerAPI.middleware,platformsAPI.middleware,productAPI.middleware,productVariantsAPI.middleware,releaseAPI.middleware,stateAPI.middleware,statsAPI.middleware]),
        devTools: process.env.NODE_ENV !== 'production',
        ...options,
    })

export const store = createStore()

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type RootState = ReturnType<typeof store.getState>
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
