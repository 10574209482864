import React, { useEffect, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { Field, FieldArray, Formik, FormikProps } from "formik";
import { Label } from "src/components/Input";
import * as Yup from "yup";
import {
  ATCSelect,
  CategorySelect,
  DosageFormSelect,
  INNSelect,
  ReleaseFormSelect,
  SexSelect
} from "src/common/selects";
import Switch from "src/components/Switch";
import Text from "src/components/Text";
import { toast } from "react-toastify";
import Loading from "src/screens/Loading";
import Confirm from "src/components/Confirm";
import { Box, Button, FormikInput, FormikNumberInput, IconButton, icons, layouts, Typography } from "@med-io/neural-ui";
import {
  useCreateProductVariantMutation,
  useDeleteProductVariantMutation,
  useProductVariantByIdQuery,
  useUpdateProductVariantMutation
} from "src/redux/API/productVariantsAPI";
import { SectionWithHeader } from "src/components/SectionWithHeader";

const { Plus, XClose } = icons;
const { TopBarLayout } = layouts;

const FormBox: any = styled.div`
  width: 30%;
  height: 100%;
  border: 1px solid #E7E7E7;
  border-radius: ${p => p.theme.borderRadius.default}px;
`;

const ProductFlex = styled.div`
  display: flex;
  height: 100%;

  & > *:last-child {
    flex: 1;
  }
`;

const Schema = Yup.object({
  atc_id: Yup.string().nullable().notRequired(),
  inn_id: Yup.string().nullable().notRequired(),
  dosage_form_id: Yup.string().nullable().notRequired(),
  release_form_id: Yup.string().nullable().notRequired(),
  category_id: Yup.string().required("Обязательное поле"),
  divisible: Yup.boolean().required("Обязательное поле"),
  pieces_per_package: Yup.number().required("Обязательное поле"),
  age_from: Yup.number().nullable(),
  age_to: Yup.number().nullable(),
  sex: Yup.number().required("Обязательное поле"),
  name: Yup.object().shape({
    ru: Yup.string().required("Обязательное поле"),
    uz: Yup.string().required("Обязательное поле"),
    en: Yup.string()
  }),
  package: Yup.string().required("Обязательное поле"),
  expiration_period: Yup.number().nullable(),
  on_prescription: Yup.boolean(),
  unit_of_measure: Yup.object({
    "ru": Yup.string().required("Обязательно").min(2, "Слишком коротко"),
    "en": Yup.string().min(2, "Слишком коротко"),
    "uz": Yup.string().min(2, "Слишком коротко")
  })
});

const ProductVariant = () => {
  const history = useHistory();
  const formRef = useRef<FormikProps<any>>(null);
  const { params: { product_variant_id: id, id: productId }, url } = useRouteMatch<any>();
  const isEditing = id !== "new";
  const [updateProduct, { isLoading: isUpdating, isSuccess: isUpdated }] = useUpdateProductVariantMutation();
  const [createProduct, { isLoading: isCreating, isSuccess: isCreated }] = useCreateProductVariantMutation();
  const [deleteProductVariant, { isLoading }] = useDeleteProductVariantMutation();
  const { data, isFetching, isError, refetch } = useProductVariantByIdQuery({ id }, {
      skip: !isEditing
    }
  );


  const initialState = {
    atc_id: data ? data.atc?.id : null,
    inn_id: data ? data.inn?.id : null,
    dosage_form_id: data ? data.dosage_form?.id : null,
    release_form_id: data ? data.release_form?.id : null,
    product_id: data ? data.product_id : "",
    category_id: data ? data.category.id : '',
    expiration_period: data ? data.expiration_period : undefined,
    package: data ? data.package : "",
    divisible: data ? data.divisible : true,
    on_prescription: false,
    sex: data ? data.sex : "",
    name: data ? data.name : "",
    age_from: null,
    age_to: null,
    barcode: data ? data.barcode : [],
    pieces_per_package: data ? data.pieces_per_package : undefined,
    unit_of_measure: data ? data.unit_of_measure : ""
  };
  const onSubmit = async (values: any) => {
    values.product_id = productId
    console.log(values)
    if (isEditing) {
      const res: any = await updateProduct({ id, ...values });
      if (!res.error) {
        toast.success("Данные варианта продукта обновлены");
        history.replace({ pathname: url, state: { productVariant: res.data } });
        history.push({ pathname: url + `/images`, state: { productVariant: res.data } });
      }
    } else {
      const res: any = await createProduct(values);
      if (!res.error) {
        toast.success("Вариант продукта создан");
        history.replace({
          pathname: `/catalog/products/${productId}/variants/${res.data.id}`,
          state: { productVariant: data }
        });
        history.push({ pathname: `/catalog/products/${res.data.product_id}/variants/${res.data.id}/images` });
      }
    }
  };


  const handleDelete = async () => {
    if (formRef.current) {
      formRef.current.setSubmitting(true);
      deleteProductVariant({ id });
      formRef.current.setSubmitting(false);
      toast.success("Удалено");
    }
  };

  if (isFetching) return <Loading />;


  return (
    <Formik
      initialValues={initialState}
      onSubmit={onSubmit}
      validationSchema={Schema}
      innerRef={formRef}
      enableReinitialize
    >
      {({ handleSubmit, setFieldValue, values, isSubmitting, errors }) => {
        return (
          <TopBarLayout
            title={isEditing ? data?.name.ru : "Новый вариант продукт"}
            disableBodyOverflow
            isContentLoading={isFetching}
            topBarActions={
              <>
                {isEditing && <Confirm title="Удаление варианта продукта"
                                       okButton={<Button ml={1.5} type="button" destructive
                                                         variant="primary"
                                                         size="md">Удалить</Button>}
                                       description={<Box display='block' w='300px'><Typography>Вы
                                         действительно
                                         хотите
                                         удалить
                                         этот вариант продукта?</Typography></Box>
                }
                                       onConfirm={handleDelete}>
                  <Button mr={1.5} type="button" variant="tertiaryGray" size="md">Удалить</Button>
                </Confirm>}
                <Button loading={isSubmitting} onClick={() => handleSubmit()}
                        variant="primary"
                        type="submit"
                        size="md">Далее</Button>
              </>}
            onBack={() => history.goBack()}
          >
            <Box display="flex" h="83vh">
              <Box w="70%">
                <SectionWithHeader title="Данные варианта">
                  <Box display="flex" gap={3}>
                    <Box w="40%">
                      <FormikInput name="name.ru" label="Название (Ru)"
                                   placeholder="Введите название" size="sm" fullWidth required />
                      <FormikInput name="name.uz" label="Название (Uz)"
                                   placeholder="Введите название" size="sm" fullWidth required />
                      <FormikInput name="name.en" label="Название (En)"
                                   placeholder="Введите название" size="sm" fullWidth />
                      <FormikInput name="package" label="Упаковка" placeholder="Например: 2*5"
                                   size="sm" fullWidth required />
                      <FormikNumberInput name="pieces_per_package" label="Кол-во штук в упаковке"
                                   placeholder="Например: 5"
                                   size="sm" type="number" fullWidth required min={0}/>
                      <FormikNumberInput name="expiration_period" label="Срок годности (в месяцах)"
                                   placeholder="Например: 1"
                                   size="sm" type="number" fullWidth min={0}/>
                      <Field name="divisible">
                        {({ field }: any) => (
                          <Box pb="1rem" w="100%" display="flex"
                               justifyContent="space-between">
                            <Label>Делимо</Label>
                            <Switch checked={field.value}
                                    onToggleChange={(value) => setFieldValue(field.name, value)} />
                          </Box>
                        )}
                      </Field>
                      <Field name="on_prescription">
                        {({ field }: any) => (
                          <Box w="100%" display="flex" justifyContent="space-between">
                            <Label>По рецепту</Label>
                            <Switch checked={field.value}
                                    onToggleChange={(value) => setFieldValue(field.name, value)} />
                          </Box>
                        )}
                      </Field>
                    </Box>
                    <Box w="50%">
                      <ATCSelect name="atc_id" isClearable />
                      <INNSelect name="inn_id" isClearable />
                      <ReleaseFormSelect name="release_form_id" isClearable />
                      <DosageFormSelect name="dosage_form_id" isClearable />
                      <SexSelect name="sex" isClearable />
                      <CategorySelect name="category_id" isClearable />
                      <Box display="flex" gap={1}>
                        <Box flex={1} w="48%">
                          <FormikNumberInput fullWidth name="age_from"
                                       label="Мин возраст (в месяцах)" type="number"
                                       placeholder=""
                                       size="sm" min={0} />
                        </Box>
                        <Box flex={1} w="48%">
                          <FormikNumberInput fullWidth name="age_to"
                                       label="Макс возраст (в месяцах)" type="number"
                                       placeholder=""
                                       size="sm" min={0} />
                        </Box>
                      </Box>
                      <Box w="100%">
                        <Typography component="div" mb={0.5}>Дозировка</Typography>
                        <Box display="flex" gap={1}>
                          <Box flex={1} w="31%">
                            <FormikInput fullWidth size="sm" name="unit_of_measure.ru"
                                         label="Ru" />
                          </Box>
                          <Box flex={1} w="31%">
                            <FormikInput fullWidth size="sm" name="unit_of_measure.uz"
                                         label="Uz" />
                          </Box>
                          <Box flex={1} w="31%">
                            <FormikInput fullWidth size="sm" name="unit_of_measure.en"
                                         label="En" />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </SectionWithHeader>
              </Box>
              <Box w="30%">
                <FieldArray
                  name="barcode"
                  render={arrayHelpers => (
                    <>
                      <SectionWithHeader title="Баркоды" actions={
                        <IconButton size="sm" variant="primary" icon={Plus} onClick={
                          () => arrayHelpers.push("")
                        } />
                      }>
                        {arrayHelpers.form.values.barcode.map((barcode: string, index: number) => (
                          <Box key={index} display="flex" gap={1} justifyContent="space-between">
                            <FormikInput size="sm" name={`barcode.${index}`} fullWidth />
                            <IconButton icon={XClose} size="sm" variant="secondaryColor"
                                        destructive onClick={
                              () => arrayHelpers.remove(index)
                            } />
                          </Box>
                        ))}
                      </SectionWithHeader>
                    </>
                  )}
                />
              </Box>
            </Box>
          </TopBarLayout>
        );
      }}
    </Formik>
  );
};

export default ProductVariant;
