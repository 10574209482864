import React from "react";
import Text from "src/components/Text";
import styled from "styled-components";
import { NewCardType } from "./types";

const NewCardWrapper = styled.div`
    aspect-ratio: 1;
    border: 1px solid #2F87E9;
    border-radius: ${p => p.theme.borderRadius.default}px;
    background: #fff;
    padding: 41px 16px 24px 16px;
    min-height: 145px;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

    cursor: pointer;
`
const NewCardContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 60%;
    min-height: 80px;
`

const NewCardPic = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EAF3FD;
`

export const NewCard = ({ style, onClick }: NewCardType) => {
    return (
        <NewCardWrapper onClick={onClick} style={{ ...style }}>
            <NewCardContent>
                <NewCardPic>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.98822 0.488373C9.78948 0.491478 9.6001 0.57334 9.46167 0.715979C9.32324 0.858618 9.24709 1.05037 9.24994 1.24912V9.24911H1.24994C1.15056 9.24771 1.05188 9.26607 0.959657 9.30313C0.867431 9.34019 0.78349 9.39521 0.712713 9.465C0.641936 9.53478 0.585734 9.61793 0.547373 9.70963C0.509012 9.80132 0.489258 9.89972 0.489258 9.99911C0.489258 10.0985 0.509012 10.1969 0.547373 10.2886C0.585734 10.3803 0.641936 10.4635 0.712713 10.5332C0.78349 10.603 0.867431 10.658 0.959657 10.6951C1.05188 10.7322 1.15056 10.7505 1.24994 10.7491H9.24994V18.7491C9.24853 18.8485 9.2669 18.9472 9.30396 19.0394C9.34102 19.1316 9.39604 19.2156 9.46582 19.2863C9.5356 19.3571 9.61876 19.4133 9.71045 19.4517C9.80214 19.49 9.90055 19.5098 9.99994 19.5098C10.0993 19.5098 10.1977 19.49 10.2894 19.4517C10.3811 19.4133 10.4643 19.3571 10.5341 19.2863C10.6038 19.2156 10.6589 19.1316 10.6959 19.0394C10.733 18.9472 10.7513 18.8485 10.7499 18.7491V10.7491H18.7499C18.8493 10.7505 18.948 10.7322 19.0402 10.6951C19.1324 10.658 19.2164 10.603 19.2872 10.5332C19.3579 10.4635 19.4141 10.3803 19.4525 10.2886C19.4909 10.1969 19.5106 10.0985 19.5106 9.99911C19.5106 9.89972 19.4909 9.80132 19.4525 9.70963C19.4141 9.61793 19.3579 9.53478 19.2872 9.465C19.2164 9.39521 19.1324 9.34019 19.0402 9.30313C18.948 9.26607 18.8493 9.24771 18.7499 9.24911H10.7499V1.24912C10.7514 1.14873 10.7327 1.04908 10.6949 0.956069C10.6571 0.863057 10.601 0.778576 10.53 0.707633C10.459 0.63669 10.3744 0.580728 10.2813 0.543065C10.1883 0.505402 10.0886 0.486804 9.98822 0.488373Z" fill="#2F87E9" />
                    </svg>
                </NewCardPic>
                <Text fontWeight="600" fontSize="14px" lineHeight="17px" color="#2F87E9">Новая карточка</Text>
            </NewCardContent>
        </NewCardWrapper>
    )
}