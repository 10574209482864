import httpService from 'src/apis/httpService'
import { createINN, getINNs, updateINN } from './types'

export default {
    getINNs(params: getINNs) {
        return httpService.get('/v1/inn', {params})
    },
    getOneINN(id: string) {
        return httpService.get(`/v1/inn/${id}`)
    },
    createINN(body: createINN) {
        return httpService.post('/v1/inn', body)
    },
    updateINN(body: updateINN, id: string) {
        return httpService.put(`/v1/inn/${id}`, body)
    },
    deleteINN(id: string) {
        return httpService.delete(`/v1/inn/${id}`)
    },
}