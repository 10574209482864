import {createApi} from '@reduxjs/toolkit/query/react';

import {baseQuery} from '../common';
import {
    CountriesRequest,
    CountriesResponse, CountryByIdRequest, CountryByIdResponse,
    CreateCountryRequest,
    CreateCountryResponse, UpdateCountryByIdRequest, UpdateCountryByIdResponse
} from "src/redux/API/countryAPI/countryAPI.types";

export const countryAPI = createApi({
    reducerPath: 'countryAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        countries: builder.query<CountriesResponse, CountriesRequest>({
            query: ({
                        limit = 10,
                        page = 1,
                        search = '',
                        code,
                        order_by
                    }) => `/v1/area/country?limit=${limit}&page=${page}&search=${search}&code=${code}&order_by=${order_by}`
        }),
        createCountry: builder.mutation<CreateCountryResponse, CreateCountryRequest>({
            query: (country) => ({
                url: `/v1/area/country`,
                method: 'POST',
                body: country
            })
        }),
        countryById: builder.query<CountryByIdResponse, CountryByIdRequest>({
            query: ({id}) => `/v1/area/country/${id}`
        }),
        updateCountryById: builder.mutation<UpdateCountryByIdResponse, UpdateCountryByIdRequest>({
            query: ({id, ...payload}) => ({
                url: `/v1/area/country/${id}`,
                method: 'PUT',
                body: payload
            })
        })

    }),
});

export const {
    useCountriesQuery,
    useCreateCountryMutation,
    useCountryByIdQuery,
    useUpdateCountryByIdMutation
} = countryAPI