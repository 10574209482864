import React, { memo } from "react";
import styled from "styled-components";
import { Box } from "src/components/Box/Box";
import { rgba } from "src/styles/helpers";

const Wrapper = styled(Box)`
  background: ${p => rgba('#fff', 0.8)};
  left: 0;
  top: 0;
  border-radius: ${p => p.theme.borderRadius.default}px;
`

const PermissionsDisabledCover = memo(() => {
  return (
    <Wrapper height='100%' width='100%' position='absolute'>

    </Wrapper>
  )
})

export default PermissionsDisabledCover
