import React from "react";
import styled from "styled-components";
import { BasicCardType } from "./types";


const BasicCardWrapper = styled.div`
    aspect-ratio: 1;
    padding: 24px 16px;
    border-radius: ${p => p.theme.borderRadius.default}px;
    background-color: #EAF3FD;
    min-height: 145px;
    display: flex;
    text-align: left;
    align-items: flex-end;
    margin-bottom: 24px;

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #2F87E9;
    cursor: pointer;
`

export const BasicCard = ({desc, onClick}: BasicCardType) => {
    return (
        <BasicCardWrapper onClick={onClick}>{desc}</BasicCardWrapper>
    )
}