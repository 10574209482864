import React from 'react';
import styled from "styled-components";
import Intro from "src/screens/login/Intro";
import LoginForm from "src/screens/login/LoginForm";
import LoginLayout from "src/layouts/LoginLayout";
import { history } from "src/App";


const Login = () => {
  return (
    <LoginLayout>
      <LoginForm />
    </LoginLayout>
  );
};

export default Login;