import React, { useState} from 'react';
import {useHistory, useRouteMatch} from "react-router-dom";
import SearchBox, {SearchBoxSuffix} from "src/components/SearchBox";
import Filter from "src/icons/fluency-regular/Filter";
import styled from "styled-components";
import {ColumnRenderProps} from "src/components/Table";
import Box from "src/components/Box";
import {useRestriction} from 'src/hooks';
import {UserPermissionsEnum} from 'src/common/permissions';
import { layouts, Table, Button, TableColumns } from "@med-io/neural-ui";
import { ATC, useATCsQuery } from "src/redux/API/atcClassAPI";

const {TopBarLayout} = layouts;

export const InnerColumnWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  & > *:last-child {
    flex: 1;
  }
`;

const ATCs = () => {
    const {isPermitted} = useRestriction()
    const [search, setSearch] = useState('')
    const {data, isFetching,isError,refetch} = useATCsQuery({search})
    const {path} = useRouteMatch();
    const history = useHistory();

    const columns: TableColumns<ATC> = [
        {
            title: "Наименование",
            dataIndex: "name",
            render: (cellData) => cellData.ru
        },
        {
            title: "Код",
            dataIndex: "full_code",
            width: 200,
            render: (cellData, rowData) => cellData || rowData.code
        }
    ]


    const onSearch = (search: string) => setSearch(search)
    return (
        <TopBarLayout
            title="Анатомо-Терапевтически-Химическая (АТХ) система классификации"
            isContentLoading={isFetching}
            isError={isError}
            onRetry={refetch}
            topBarActions={
                <>
                    <Button mr={1.5} onClick={() => history.push('/catalog/atcs/tree')} size='md' variant='tertiaryGray'>Древо</Button>
                    {
                        isPermitted(UserPermissionsEnum["atc.create"]) &&
                        <Button onClick={() => history.push('/catalog/atcs/new')} variant='primary' size='md'>Добавить ATХ</Button>
                    }
                </>
            }
        >
            <InnerColumnWrapper>
                <SearchBox delayedSearch onSearch={onSearch} defaultValue={search}
                           suffix={<><SearchBoxSuffix onClick={() => console.log("Filter click")}><Filter
                               size={20}/></SearchBoxSuffix></>}/>
                <Box display='block'>
                    <Table
                        columns={columns}
                        data={data?.items || []}
                        onRow={(row: any) => ({
                            onClick: () => history.push({
                                pathname: `/catalog/atcs/${row.id}`,
                                state: {atc: row}
                            })
                        })}
                        loading={isFetching}
                        scroll={{y: '75vh'}}
                        size='md'
                        rowKey='id'
                        virtualized
                    />
                </Box>
            </InnerColumnWrapper>
        </TopBarLayout>
    )
}

export default ATCs;
