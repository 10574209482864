import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from 'src/apis'
import { ATCItem, ATCStore } from './types'
import Fuse from 'fuse.js'

export const initialState: ATCStore = {
  items: [],
  filteredItems: [],
  filters: {
    lvl: 1,
    group: false,
    search: ''
  },
  groupedItems: [],
  currentCount: 0,
  count: 0,
  loading: false,
  error: null,
  newCompanyForm: {},
  isSubmiting: false
}

export const getATCs = createAsyncThunk(
  'GET_ATCs',
  async (payload: any = {}, thunkAPI: any) => {
    const {
      atc
    } = thunkAPI.getState()
    if (payload.search && payload.search !== atc.filters.search) {
      payload.page = 1
    }
    const filters = { ...atc.filters, ...payload }
    const { data } = await Apis.atc.getATCs({ ...filters, order_by: 'asc' })
    const { data: grouped } = await Apis.atc.getATCs({ ...filters, group: true })
    return { data, filters, grouped: grouped.items };
  },
)

export const updateATC = createAsyncThunk(
  'UPDATE_ATC',
  async (payload: any = {}) => {
    // const {data} = await Apis.atc.updateATC(payload);
    // return {data}
  },
)

export const createATC = createAsyncThunk(
  'CREATE_ATC',
  async (payload: any = {}) => {
    const { data } = await Apis.atc.createATC(payload);
    return { data }
  },
)

const ATCSlice = createSlice({
  name: 'ATC',
  initialState,
  reducers: {
    SET_NEW_ATC_FORM: (state: ATCStore, action) => {
      state.newCompanyForm = action.payload
    },
    SET_FILTERS: (state: ATCStore, action) => {
      state.filters = { ...state.filters, ...action.payload }
    },
    SEARCH_ATC: (state: ATCStore, { payload }: { payload: string }) => {
      const searchValue = payload.trim()
      if(searchValue.length) {
        const options = {
          includeScore: true,
          keys: ['name.ru', 'name.en', 'name.uz', 'full_code']
        }
        const fuse = new Fuse(state.items, options)
        const result = fuse.search(searchValue).map(itm => itm.item)
        state.filteredItems = result
        state.count = result.length
      } else {
        state.filteredItems = state.items
        state.count = state.items.length
      }
    },
    CREATE_ATC: (state: ATCStore, {payload}: {payload: ATCItem}) => {
      state.count += 1
      state.items.unshift(payload)
      state.filteredItems = state.items
    },
    DELETE_ATC: (state: ATCStore, {payload}: {payload: string}) => {
      state.count -= 1
      state.items = state.items.filter(itm => itm.id !== payload)
      state.filteredItems = state.items
    },
    UPDATE_ATC: (state: ATCStore, {payload}: {payload: ATCItem}) => {
      for(let i = 0; i < state.items.length; i++) {
        if(state.items[i].id === payload.id){
          state.items[i] = payload;
          break;
        }
      }
      state.filteredItems = state.items
    }
  },
  extraReducers: (builder) => {
    //getBranches
    builder.addCase(getATCs.pending, (state, action) => {
      state.error = null
      state.loading = true
    })
    builder.addCase(getATCs.fulfilled, (state, action) => {
      const {
        filters,
        data: { items, count },
        grouped
      } = action.payload
      state.items = items
      state.filteredItems = items
      state.groupedItems = grouped
      state.filters = filters
      state.count = count
      state.loading = false
      state.currentCount += state.items.length
    })
    builder.addCase(getATCs.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
    })
    //create branch
    builder.addCase(createATC.pending, (state, action) => {
      state.isSubmiting = true;
      state.error = null;
    })
    builder.addCase(createATC.fulfilled, (state, action) => {
      state.isSubmiting = false;
    })
    builder.addCase(createATC.rejected, (state, action) => {
      state.isSubmiting = false;
      state.error = action.error;
    })
    builder.addCase(updateATC.pending, (state, action) => {

    })
    builder.addCase(updateATC.fulfilled, (state, action) => {

    })
    builder.addCase(updateATC.rejected, (state, action) => {

    })
  },
})

export const { SEARCH_ATC, CREATE_ATC, DELETE_ATC, UPDATE_ATC } = ATCSlice.actions
export default ATCSlice.reducer;
