import React from 'react';
import parsePhoneNumber from 'libphonenumber-js'

const phoneNumber = (value: string | number) => {
  if (typeof value === 'number') {
    value = value.toString()
  }
  if (value[0] !== '+') {
    value = '+' + value
  }
  const phoneNumber = parsePhoneNumber(value)
  return phoneNumber ? phoneNumber.formatInternational() : ''
};

export default phoneNumber;