import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from 'src/apis'
import { ProductsItem, ProductsStore } from './types'

export const initialState: ProductsStore = {
  items: [],
  filters: {
    page: 1,
    limit: 13,
    search: '',
  },
  currentCount: 0,
  count: 0,
  loading: false,
  error: null,
  newCompanyForm: {},
  isSubmiting: false
}

export const getProducts = createAsyncThunk(
  'GET_PRODUCTS',
  async (payload: any = {}, thunkAPI: any) => {
    const {
      products
    } = thunkAPI.getState()
    if (payload.search !== products.filters.search && payload.search !== undefined) {
      payload.page = 1
    }

    const filters = { ...products.filters, ...payload }
    const { data } = await Apis.products.getProducts({ ...filters })
    return { data, filters };
  },
)

export const getOneProduct = createAsyncThunk(
  'GET_PRODUCT',
  async (payload: any = {}, thunkAPI: any) => {
    const {
      products: { id },
    } = thunkAPI.getState();
    const { data } = await Apis.products.getOneProduct(id)
    return { data }
  },
)

export const updateProduct = createAsyncThunk(
  'UPDATE_PRODUCT',
  async (payload: any = {}) => {
    // const {data} = await Apis.products.updateProduct(payload);
    // return {data}
  },
)

export const createProduct = createAsyncThunk(
  'CREATE_PRODUCT',
  async (payload: any = {}) => {
    const { data } = await Apis.products.createProduct(payload);
    return { data }
  },
)

const ProductSlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    SET_NEW_COMPANY_FORM: (state: ProductsStore, action) => {
      state.newCompanyForm = action.payload
    },
    SET_FILTERS: (state: ProductsStore, action) => {
      state.filters = { ...state.filters, ...action.payload }
    },
    CREATE_PRODUCT: (state: ProductsStore, { payload }: { payload: ProductsItem }) => {
      state.count += 1
      state.items.unshift(payload)
    },
    DELETE_PRODUCT: (state: ProductsStore, { payload }: { payload: string }) => {
      state.count -= 1
      state.items = state.items.filter(itm => itm.id !== payload)
    },
    UPDATE_PRODUCT: (state: ProductsStore, { payload }: { payload: ProductsItem }) => {
      for (let i = 0; i < state.items.length; i++) {
        if (state.items[i].id === payload.id) {
          state.items[i] = payload;
          break;
        }
      }
    }
  },
  extraReducers: (builder) => {
    //getBranches
    builder.addCase(getProducts.pending, (state, action) => {
      state.error = null
      state.loading = true
    })
    builder.addCase(getProducts.fulfilled, (state, action) => {
      const {
        filters,
        data: { items, count },
      } = action.payload
      // if (state.filters.search !== filters.search || filters.page === 1) {
      //   state.items = items
      // } else {
      //   state.items = [...state.items, ...items]
      // }
      state.items = [...items]
      state.filters = filters
      state.count = count
      state.loading = false
      state.currentCount = state.items.length
    })
    builder.addCase(getProducts.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
    })
    //get one branch
    builder.addCase(getOneProduct.pending, (state, action) => {

    })
    builder.addCase(getOneProduct.fulfilled, (state, action) => {

    })
    builder.addCase(getOneProduct.rejected, (state, action) => {

    })
    //create branch
    builder.addCase(createProduct.pending, (state, action) => {
      state.isSubmiting = true;
      state.error = null;
    })
    builder.addCase(createProduct.fulfilled, (state, action) => {
      state.isSubmiting = false;
    })
    builder.addCase(createProduct.rejected, (state, action) => {
      state.isSubmiting = false;
      state.error = action.error;
    })
    builder.addCase(updateProduct.pending, (state, action) => {

    })
    builder.addCase(updateProduct.fulfilled, (state, action) => {

    })
    builder.addCase(updateProduct.rejected, (state, action) => {

    })
  },
})

export default ProductSlice.reducer;
export const { CREATE_PRODUCT, DELETE_PRODUCT, UPDATE_PRODUCT } = ProductSlice.actions
