import React, { useEffect, useState } from 'react';
import SearchBox, { SearchBoxSuffix } from "src/components/SearchBox";
import Filter from "src/icons/fluency-regular/Filter";
import  { InnerColumnWrapper } from "src/layouts/TopbarLayout";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from "src/components/Box";
import { useRestriction } from 'src/hooks';
import { UserPermissionsEnum } from 'src/common/permissions';
import {layouts,Table,Button} from "@med-io/neural-ui";
import {useReleasesQuery} from "src/redux/API/releaseAPI";
const {TopBarLayout} = layouts;


function ReleaseForms() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch()
  const { isPermitted } = useRestriction()
  const limit = 30;
  const [search,setSearch] = useState('')
  const [page,setPage] = useState(1)
  const {data, isFetching, isError, refetch} = useReleasesQuery({limit,page,search})


  const columns = [
    {
      title: "Наименование",
      dataIndex: "name",
      render: (cellData: any) => cellData.ru
    }
  ]

  const onSearch = (search: string) => setSearch(search)

  return (
    <TopBarLayout
      title="Формы выпуска"
      isContentLoading={isFetching}
      isError={isError}
      onRetry={refetch}
      topBarActions={
        isPermitted(UserPermissionsEnum["release_form.create"]) ? <Button onClick={() => history.push('/catalog/releaseForms/new')} variant='primary' size='md'>Добавить форму выпуска</Button> : <></>
      }
    >
      <InnerColumnWrapper>
        <SearchBox delayedSearch onSearch={onSearch} defaultValue={search}
                   suffix={<><SearchBoxSuffix onClick={() => console.log("Filter click")}><Filter
                     size={20} /></SearchBoxSuffix></>} />
        <Box display='block'>
          <Table
            columns={columns}
            data={data?.items || []}
            onRow={(row) => ({
              onClick: () =>  history.push({
                pathname: `/catalog/releaseForms/${row.id}`,
                state: { releaseForm: row }
              })
            })}
            loading={isFetching}
            rowKey='id'
            size='md'
            scroll={{y: '70vh'}}
          />
        </Box>
      </InnerColumnWrapper>
    </TopBarLayout>
  )

}

export default ReleaseForms;
