import React, {useEffect, useMemo, useState} from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { CollectionItemType, CollectionsProductItem, Wrapper } from "../colored/coloredCollection";
import { Formik } from "formik";
import * as Yup from "yup"
import { HorizontalBox } from "src/components/HorizontalBox";
import Input from "src/components/Input";
import CollectionModal, { ProductItem } from "../collectionModal";
import Dismiss from "src/icons/fluency-regular/Dismiss";
import Empty from "src/icons/fluency/Empty";
import Confirm from "src/components/Confirm";
import { toast } from "react-toastify";
import Text from "src/components/Text";
import { useRestriction } from "src/hooks";
import { UserPermissionsEnum } from "src/common/permissions";
import {
    CollectionByIdResponse,Product,
    useCollectionByIdQuery,
    useCreateCollectionMutation,
    useDeleteCollectionMutation,
    useUpdateCollectionMutation
} from "src/redux/API/collectionAPI";
import {layouts, Box, FormikInput, Button, IconButton,icons} from "@med-io/neural-ui";
const {XClose} = icons
import {SectionWithHeader} from "src/components/SectionWithHeader";
const {TopBarLayout} = layouts;


const Schema = Yup.object().shape({
    name: Yup.object().shape({
        ru: Yup.string().required('Обязательное поле'),
        uz: Yup.string().required('Обязательное поле'),
        en: Yup.string()
    }),
})

interface Title {
    ru: string;
    uz: string;
    en: string;
}


const BasicCollection = () => {
    const { isPermitted } = useRestriction()
    const { params: { id } } = useRouteMatch<any>()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const history = useHistory()
    const isEditing = id !== "new"
    const [ids, setIds] = useState<string[]>([])
    const [confirm, setConfirm] = useState<boolean>(false)
    const [activeId, setActiveId] = useState<string>("")
    const [UPDATE_COLLECTION] = useUpdateCollectionMutation()
    const [CREATE_COLLECTION] = useCreateCollectionMutation()
    const [DELETE_COLLECTION] = useDeleteCollectionMutation()
    const {data,isFetching,isError,refetch} = useCollectionByIdQuery({id},{
        skip: !isEditing
    })


    const [collection, setCollection] = useState<CollectionByIdResponse>({
        color: "",
        id: "",
        image: "",
        products: [],
        title: {
            ru: "",
            uz: "",
            en: ""
        },
        type: "",
    })
    const [initialProducts, setInitialProducts] = useState<Product[]>([])


    const updateCollection = async ({ name }: { name: Title }) => {
        const rawIds: string[] = initialProducts.map(itm => itm.id)
        const added_products: string[] = []
        const deleted_products: string[] = []
        ids.forEach(id => {
            if (!rawIds.includes(id)) {
                added_products.push(id)
            }
        })

        rawIds.forEach(rawId => {
            if (!ids.includes(rawId)) {
                deleted_products.push(rawId)
            }
        })

        await UPDATE_COLLECTION({
            id,
            added_products,
            deleted_products,
            color: null,
            title: name,
            type: collection.type
        })
        toast.success("Подборка обновлена")
    }

    const deleteCollection = async () => {
        await DELETE_COLLECTION({id})
        toast.success("Подборка удалена")
    }

    const createCollection = async ({ name }: { name: Title }) => {
        await CREATE_COLLECTION({
            color: null,
            products: ids,
            title: name,
            type: "expanded"
        })
        toast.success("Подборка создана")
    }

    useMemo(() => {
        if (data?.products) {
            const productsId: string[] = data.products.map((itm: any) => itm.id);
            setIds(productsId)
            setCollection(data)
            setInitialProducts(data.products)
        } else {
            data && setCollection({ ...data, products: [] })
        }
    },[isFetching === true])


    const onRowClick = (row: ProductItem) => {
        const product: Product = {
            country: row.country,
            id: row.id,
            manufacturer: row.manufacturer,
            name: row.name.ru,
            on_prescription: row.on_prescription
        }

        setIds(prev => [...prev, row.id])
        setCollection(prev => {
            return {
                ...prev,
                products: [...prev.products, product]
            }
        })
    }

    const removeProduct = () => {
        // let id: string = ""
        // let collection: CollectionItemType = {
        //     color: "",
        //     id: "",
        //     image: "",
        //     title: {
        //         ru: "",
        //         uz: "",
        //         en: "",
        //     },
        //     products: [],
        //     type: ""
        // }
        // setActiveId(prev => {
        //     id = prev
        //     return prev
        // })
        //
        // setCollection(prev => {
        //     collection = prev
        //     return { ...prev }
        // })

        const products = collection.products.filter((itm) => itm.id !== activeId)
        setCollection(prev => {
            return {
                ...prev,
                products
            }
        })
        setIds(products.map((itm: any) => itm.id))
        setConfirm(false)

    }

    const handleClick = (id: string) => {
        setActiveId(id)
        setConfirm(prev => !prev)
    }


    return (
        <Formik
            validationSchema={Schema}
            initialValues={{ name: isEditing ? collection.title : { uz: "", ru: "", en: "" } }}
            onSubmit={(values) => isEditing ? updateCollection(values) : createCollection(values)}
            enableReinitialize
        >
            {({ setFieldValue, handleSubmit }) => {
                return (
                    <TopBarLayout
                        title={isEditing ? collection.title.ru : "Новая карточка"}
                        isContentLoading={isFetching}
                        isError={isError}
                        onRetry={refetch}
                        topBarActions={
                            <>
                                {
                                    isEditing && isPermitted(UserPermissionsEnum["collection.delete"]) ?
                                        <Confirm
                                            title="Удаление подборки"
                                            description="Вы действительно хотите удалить подборку?"
                                            onConfirm={deleteCollection}
                                            okButton={<Button ml={1} variant="primary" destructive size="md">Удалить</Button>}
                                        >
                                            <Button mr={1.3} variant="tertiaryGray" destructive size="md">Удалить</Button>
                                        </Confirm>
                                        :
                                        <Button  mr={1.3} variant="secondaryGray" size="md" onClick={() => history.goBack()}>Отмена</Button>
                                }
                                {
                                    isPermitted(UserPermissionsEnum["collection.update"]) && <Button type="submit" variant="primary" size="md" onClick={() => handleSubmit()}>Сохранить</Button>
                                }
                            </>
                        }
                        onBack={() => history.goBack()}
                    >
                        <Box display="flex" gap={1.5} h="85vh">
                            <Box w="30%">
                                <SectionWithHeader title="Данные подборки">
                                    <FormikInput name="name.en" label="Название(En)" size='sm' fullWidth/>
                                    <FormikInput name="name.ru" label="Название(Ru)" size="sm" fullWidth/>
                                    <FormikInput name="name.uz" label="Название(Uz)" size="sm" fullWidth/>
                                </SectionWithHeader>
                            </Box>
                            <Box w="67.5%">
                                <SectionWithHeader title="Продукты подборки" actions={
                                    collection.products.length ? <Button onClick={() => setIsOpen(prev => !prev)} variant="secondaryColor" size="md">Добавить продукт</Button> : <></>
                                }>
                                    <Box display="block" p="24px" h="100%">
                                        <CollectionModal onRowClick={(row) => onRowClick(row)} isOpen={isOpen} onClose={() => setIsOpen(prev => !prev)} ids={ids} />
                                        <Box display="flex" flexDirection="column" gap="12px"  h="100%">
                                            {
                                                collection.products.length ?
                                                    collection.products.map(itm =>
                                                        <CollectionsProductItem key={itm.id}>
                                                            <Text fontSize="16px" lineHeight="19px" color="#2c2c2c">{itm.name}</Text>
                                                            <Button variant="tertiaryGray" size="md" onClick={() => handleClick(itm.id)}>
                                                                <Dismiss size={24} />
                                                            </Button>
                                                        </CollectionsProductItem>
                                                    ) :
                                                    <Box display="flex" alignItems="center" justifyContent="center" h="100%">
                                                        <Box display="flex" flexDirection="column" alignItems="center">
                                                            <Empty/>
                                                            <Text margin="20px 0px" fontSize="16px" lineHeight="19px" color="#9c9c9c">
                                                                В эту подборку пока не добавлено продутов.
                                                            </Text>
                                                            <Button onClick={() => setIsOpen(prev => !prev)} variant="primary" size="md">Добавить продукты</Button>
                                                        </Box>
                                                    </Box>
                                            }
                                        </Box>
                                    </Box>
                                    <Confirm okButton={<Button ml={1} variant="primary" destructive size="md">Удалить</Button>} title="Продукт подборки" description="Вы действительно хотите удалить продукт из подборки?" onConfirm={() => removeProduct()} onCancel={() => setConfirm(false)} visible={confirm}><></></Confirm>
                                </SectionWithHeader>
                            </Box>
                        </Box>
                    </TopBarLayout>
                )
            }}

        </Formik>
    )
}

export default BasicCollection