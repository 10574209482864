import React, {useMemo} from 'react';
import {FormikSelect} from '@med-io/neural-ui';
import Fuse from 'fuse.js';
import {ATCItem} from 'src/redux/features/atc/types';
import {ATC, useATCsQuery} from "src/redux/API/atcClassAPI";

type Props = {
    name: string;
    excludedIds?: string[],
    [x: string]: any
}

export const ATCSelect = (props: Props) => {
    const {excludedIds} = props
    let {data, isFetching} = useATCsQuery({})
    const searchOptions: Fuse.IFuseOptions<ATCItem> = {
        keys: [
            {name: "full_code", weight: 1},
            {name: "code", weight: 0.8},
        ],
    };

    const items = useMemo(() => {
        if (data?.items) {
            if (excludedIds) {
                return data.items = data.items.filter((item) => !excludedIds.includes(item.id))
            } else {
                return data.items
            }
        } else {
            return []
        }
    }, [excludedIds?.length, data])


    return (
        <FormikSelect<ATC>
            options={items}
            searchOptions={searchOptions}
            delayedSearch={150}
            valueKey='id'
            labelKey='full_code'
            loading={isFetching}
            size="sm"
            fullWidth
            label='АТХ'
            placeholder='Выберите АТХ'
            cacheOptions
            virtualized
            searchable
            {...props}
        />
    );
};

export default ATCSelect;
