import { AxiosError } from "axios";
import { toast } from "react-toastify";

const errorHandler = (err: AxiosError) => {
  if (err.response) {
    // The client was given an error response (5xx, 4xx)
    if (err.response.data.error?.description?.length) {
      const errorType = err.response.data.error.type
      if (errorType === 'validation') {
        const errorFields = err.response.data.error.fields
        Object.keys(errorFields).forEach(key => {
          errorFields[key].forEach((error: string) => toast.error(error))
        })
      } else {
        toast.error(err.response.data.error.description);
      }
    } else {
      switch (err.response.status) {
        case 401:
          if (err.response.data?.error?.type === 'login') {
            toast.error("Неверная эл. почта или пароль");
          } else {
            toast.error('Вы не авторизованы')
          }
          break;
        case 403:
          toast.error('Нет доступа')
          break;
        case 500:
          toast.error('Непредвиденная ошибка')
          break;
        default:
          toast.error(err.response.data)
      }
    }
  } else if (err.request) {
    // The client never received a response, and the request was never left
    toast.error('Нет ответа от сервера')
  } else {
    // Anything else
    toast.error('Непредвиденная ошибка')
  }
}

export default errorHandler
