import React from 'react';
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.theme.colors.palette.background.paper};
  height: 50px;
  width: 100%;
  border-bottom: solid 1px;
  border-color: ${p => p.theme.colors.palette.divider};
`;

const MenuItemStyled: any = styled.div`
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background: ${p => p.theme.colors.palette.background.default};
  }

  &:after {
    content: "";
    height: 4px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: ease-in 0.3s;
    border-top-left-radius: ${p => p.theme.borderRadius.default}px;
    border-top-right-radius: ${p => p.theme.borderRadius.default}px;
  }

  &.active:after {
    background-color: ${p => p.theme.colors.palette.primary.main};
  }
`;

export const TopMenuItem = ({ title, to, exact }: { title: string, to: string, exact?: boolean }) => {
  return (
    <MenuItemStyled exact={exact} as={NavLink} to={to}>{title}</MenuItemStyled>
  )
}

function TopMenu({ children }: { children?: JSX.Element | JSX.Element[] }) {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
}

export default TopMenu;