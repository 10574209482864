import React from "react";
import { ProductVariantImportProductGroup } from "src/screens/catalog/import/types";
import { Box, icons, FeaturedIcon, Table } from "@med-io/neural-ui";
import ManufacturerSelect from "src/common/selects/ManufacturerSelect";
import { CountrySelect, DosageFormSelect, INNSelect, ReleaseFormSelect } from "src/common/selects";
import AtcSelect from "src/common/selects/AtcSelect";

const { Tick } = icons;

type Props = {
  data: ProductVariantImportProductGroup[];
  loading: boolean;
};

const productsColumns = [
  {
    title: "Название (RU)",
    dataIndex: "name",
    width: 300,
    ellipsis: true,
    render: (name: any) => name.ru
  },
  {
    title: "Производитель",
    dataIndex: "manufacturer_id",
    width: 300,
    render: (manufacturer_id: string) => <ManufacturerSelect label={false} name='manufacturer_id'  />
  },
  {
    title: "Страна",
    dataIndex: "country_id",
    width: 300,
    render: (country_id: string) => <CountrySelect  name='country_id'/>
  },
  {
    title: "Название (EN)",
    dataIndex: "name",
    width: 300,
    ellipsis: true,
    render: (name: any) => name.en
  },
  {
    title: "Название (UZ)",
    dataIndex: "name",
    width: 300,
    ellipsis: true,
    render: (name: any) => name.uz
  },
];

const variantsColumns = [
  {
    title: "Название (RU)",
    dataIndex: "name",
    width: 300,
    render: (name: any) => name.ru
  },
  {
    title: "Название (UZ)",
    dataIndex: "name",
    width: 300,
    render: (name: any) => name.uz
  },
  {
    title: "Название (EN)",
    dataIndex: "name",
    width: 300,
    render: (name: any) => name.en
  },
  {
    title: "МНН",
    dataIndex: "inn_id",
    width: 300,
    render: (inn_id: string) => <INNSelect label={false} name='inn_id' />
  },
  {
    title: "АТХ",
    dataIndex: "atc_id",
    width: 300,
    render: (atc_id: string) => <AtcSelect label={false} name='atc_id' />
  },
  {
    title: "Форма упаковки",
    dataIndex: "release_form_id",
    width: 300,
    render: (release_form_id: string) => <ReleaseFormSelect label={false} name='release_form_id' />
  },
  {
    title: "Лекарственная форма",
    dataIndex: "dosage_form_id",
    width: 300,
    render: (dosage_form_id: string) => <DosageFormSelect name='dosage_form_id' label={false} />
  },
  {
    title: "Делимо",
    dataIndex: "divisible",
    width: 100,
    render: (divisible: boolean) => divisible ? <FeaturedIcon size="sm" color="success" icon={Tick} /> : null
  },
  {
    title: "Дозировка",
    dataIndex: "unit_of_measure",
    width: 200,
    ellipsis: true,
    render: (unit_of_measure: { ru: string }) => unit_of_measure.ru
  },
  {
    title: "Упаковка",
    dataIndex: "package",
    width: 200,
  },
  {
    title: "Кол-во шт в упк",
    dataIndex: "pieces_per_package",
    width: 200,
    ellipsis: true
  },
  {
    title: "Баркоды",
    dataIndex: "barcode",
    ellipsis: true,
    render: (barcodes: string[]) => barcodes.join(", ")
  }
];

export const ImportProductsDataSheet = ({ data, loading }: Props) => {
  const [selectedIdx, setSelectedIdx] = React.useState<number>(-1);

  const handleSelect = (idx: number) => setSelectedIdx(idx);

  const selectedProductGroup = data[selectedIdx];

  return (
    <Box display='block' h='90vh'>
      <Box justifyContent="space-between" h='100%'>
        <Box display='block' h='100%' w='30%'>
          <Table
            key="products"
            data={data}
            columns={productsColumns}
            size="sm"
            rowKey="key"
            loading={loading}
            onRow={(record, idx) => ({
              onClick: () => handleSelect(idx as number)
            })}
          />
        </Box>
        <Box display='block' h='100%' w='70%' ml='1rem'>
          <Table
            key="variants"
            data={selectedProductGroup?.variants || []}
            columns={variantsColumns}
            size="sm"
            rowKey="key"
            loading={loading}
            emptyTitle="Выберите продукт"
          />
        </Box>
      </Box>
    </Box>
  );
};
