export const UserPermissions = {
  company: [
    { label: 'Просмотр', value: "company.read" },
    { label: 'Создание', value: "company.create" },
    { label: 'Обновление', value: "company.update" },
    { label: 'Удаление', value: "company.delete" },
  ],
  product: [
    { label: 'Просмотр', value: "product.read" },
    { label: 'Создание', value: "product.create" },
    { label: 'Обновление', value: "product.update" },
    { label: 'Удаление', value: "product.delete" },
  ],
  inn: [
    { label: 'Просмотр', value: "inn.read" },
    { label: 'Создание', value: "inn.create" },
    { label: 'Обновление', value: "inn.update" },
    { label: 'Удаление', value: "inn.delete" },
  ],
  category: [
    { label: 'Просмотр', value: "category.read" },
    { label: 'Создание', value: "category.create" },
    { label: 'Обновление', value: "category.update" },
    { label: 'Удаление', value: "category.delete" },
  ],
  atc: [
    { label: 'Просмотр', value: "atc.read" },
    { label: 'Создание', value: "atc.create" },
    { label: 'Обновление', value: "atc.update" },
    { label: 'Удаление', value: "atc.delete" },
  ],
  dosage_form: [
    { label: 'Просмотр', value: "dosage_form.read" },
    { label: 'Создание', value: "dosage_form.create" },
    { label: 'Обновление', value: "dosage_form.update" },
    { label: 'Удаление', value: "dosage_form.delete" },
  ],
  release_form: [
    { label: 'Просмотр', value: "release_form.read" },
    { label: 'Создание', value: "release_form.create" },
    { label: 'Обновление', value: "release_form.update" },
    { label: 'Удаление', value: "release_form.delete" },
  ],
  manufacturer: [
    { label: 'Просмотр', value: "manufacturer.read" },
    { label: 'Создание', value: "manufacturer.create" },
    { label: 'Обновление', value: "manufacturer.update" },
    { label: 'Удаление', value: "manufacturer.delete" },
  ],
  disease: [
    { label: 'Просмотр', value: "disease.read" },
    { label: 'Создание', value: "disease.create" },
    { label: 'Обновление', value: "disease.update" },
    { label: 'Удаление', value: "disease.delete" },
  ],
  symptom: [
    { label: 'Просмотр', value: "symptom.read" },
    { label: 'Создание', value: "symptom.create" },
    { label: 'Обновление', value: "symptom.update" },
    { label: 'Удаление', value: "symptom.delete" },
  ],
  collection: [
    { label: 'Просмотр', value: "collection.read" },
    { label: 'Создание', value: "collection.create" },
    { label: 'Обновление', value: "collection.update" },
    { label: 'Удаление', value: "collection.delete" },
  ],
  company_user_position: [
    { label: 'Просмотр', value: "company_user_position.read" },
    { label: 'Создание', value: "company_user_position.create" },
    { label: 'Обновление', value: "company_user_position.update" },
    { label: 'Удаление', value: "company_user_position.delete" },
  ],
  company_user_permission: [
    { label: 'Просмотр', value: "company_user_permission.read" },
    { label: 'Создание', value: "company_user_permission.create" },
    { label: 'Обновление', value: "company_user_permission.update" },
    { label: 'Удаление', value: "company_user_permission.delete" },
  ],
}

export enum UserPermissionsEnum {
  "company.read" = "company.read",
  "company.create" = "company.create",
  "company.update" = "company.update",
  "company.delete" = "company.delete",

  "product.read" = "product.read",
  "product.create" = "product.create",
  "product.update" = "product.update",
  "product.delete" = "product.delete",

  "product_variants.read" = "product_variants.read",
  "product_variants.create" = "product_variants.create",
  "product_variants.update" = "product_variants.update",
  "product_variants.delete" = "product_variants.delete",

  "inn.read" = "inn.read",
  "inn.create" = "inn.create",
  "inn.update" = "inn.update",
  "inn.delete" = "inn.delete",

  "category.read" = "category.read",
  "category.create" = "category.create",
  "category.update" = "category.update",
  "category.delete" = "category.delete",

  "atc.read" = "atc.read",
  "atc.create" = "atc.create",
  "atc.update" = "atc.update",
  "atc.delete" = "atc.delete",

  "dosage_form.read" = "dosage_form.read",
  "dosage_form.create" = "dosage_form.create",
  "dosage_form.update" = "dosage_form.update",
  "dosage_form.delete" = "dosage_form.delete",

  "release_form.read" = "release_form.read",
  "release_form.create" = "release_form.create",
  "release_form.update" = "release_form.update",
  "disease.create" = "disease.create",
  "disease.update" = "disease.update",
  "release_form.delete" = "release_form.delete",

  "manufacturer.read" = "manufacturer.read",
  "manufacturer.create" = "manufacturer.create",
  "manufacturer.update" = "manufacturer.update",
  "manufacturer.delete" = "manufacturer.delete",

  "disease.read" = "disease.read",
  "disease.delete" = "disease.delete",

  "symptom.read" = "symptom.read",
  "symptom.create" = "symptom.create",
  "symptom.update" = "symptom.update",
  "symptom.delete" = "symptom.delete",

  "collection.read" = "collection.read",
  "collection.create" = "collection.create",
  "collection.update" = "collection.update",
  "collection.delete" = "collection.delete"
}
