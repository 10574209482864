import React, {useCallback, useState} from 'react';
import {useHistory} from "react-router-dom";
import {UserPermissionsEnum} from "src/common/permissions";
import {useRestriction} from "src/hooks";
import SearchBox from 'src/components/SearchBox';
import {Box} from 'src/components/Box/Box';
import {ProductVariant, ProductVariantsRequestFilters, useProductVariantsQuery} from "src/redux/API/productVariantsAPI";
import {Table, TableColumns,layouts} from "@med-io/neural-ui";
import {SectionWithHeader} from "src/components/SectionWithHeader";
const {TopBarLayout} = layouts

const columns: TableColumns<ProductVariant> = [
    {
        title: "Наименование",
        dataIndex: "name",
        render: (cellData) => cellData.ru,
        width: 200
    },
    {
        title: "Лекарственная форма",
        dataIndex: "dosage_form",
        render: (cellData) => cellData ? cellData.name : '-',
        width: 300
    },
    {
        title: "Форма выпуска",
        dataIndex: "release_form",
        render: (cellData) => cellData ? cellData.name : '-',
        width: 150
    },
    {
        title: "Дозировка",
        dataIndex: "unit_of_measure",
        render: (cellData) => cellData ? cellData.ru : '-',
        width: 150
    },
    {
        title: "Упаковка",
        dataIndex: "package",
        width: 150
    },
    {
        title: "МНН",
        dataIndex: "inn",
        render: (cellData) => cellData ? cellData.name : '-',
        width: 150
    },
    {
        title: "АТХ",
        dataIndex: "atc",
        render: (cellData) => cellData ? cellData.name : '-',
        width: 150
    },
    {
        title: "Производитель",
        dataIndex: "manufacturer",
        render: (cellData) => cellData.name,
        width: 150
    },
    {
        title: "Страна",
        dataIndex: "country",
        render: (cellData) => cellData.name,
        width: 150
    }
]

type Props = {
    id: string;
    title: string;
    filter_by: keyof Omit<ProductVariantsRequestFilters, 'search'>
}

export const ProductVariantsList = ({ id, title, filter_by }: Props) => {
    const history = useHistory();
    const { isPermitted } = useRestriction()
    const [filters, setFilters] = useState<{
        limit: number;
        page: number;
        search: string;
    }>({
        limit: 10,
        page: 1,
        search: ""
    })

    const {data, isFetching, isError, refetch} = useProductVariantsQuery({
        ...filters,
        [filter_by]: id
    }, {
        skip: !isPermitted(UserPermissionsEnum["product.read"])
    })
    const onSearch = async (search: string) => {
        const value = search.trim()
        setFilters(prev => ({
            ...prev,
            search: value,
            page: 1
        }))
        }

    const onRowClick = useCallback((row: any ) => history.push(`/catalog/products/${row.product_id}/variants/${row.id}`), [])

    const onPageChange = useCallback((page: number) => {
        setFilters( prev => ({
            ...prev,
            page: page
        }))
    },[])


    if (!isPermitted(UserPermissionsEnum["product.read"])) return null;

    return (
        <SectionWithHeader title={title}>
            <Box display="block" height="100%" overflowY="hidden">
                           <Box>
                               <SearchBox onSearch={onSearch} delayedSearch />
                           </Box>
                               <Table<ProductVariant>
                               data={data?.items || []}
                               columns={columns}
                               loading={isFetching}
                               onRow={(row) => ({
                                   onClick: () => onRowClick(row)
                               })}
                               size='md'
                               rowKey='id'
                               scroll = {{y: '62vh'}}
                           />
                   </Box>
        </SectionWithHeader>
    )
};
