import httpService from 'src/apis/httpService'
import { createReleaseForm, getReleaseForms, updateReleaseForm } from './types'

export default {
    getReleaseForms(params: getReleaseForms) {
        return httpService.get('/v1/form/release', {params})
    },
    getOneReleaseForm(id: string) {
        return httpService.get(`/v1/form/release/${id}`)
    },
    createReleaseForm(body: createReleaseForm) {
        return httpService.post('/v1/form/release', body)
    },
    updateReleaseForm(body: updateReleaseForm, id: string) {
        return httpService.put(`/v1/form/release/${id}`, body)
    },
    deleteReleaseForm(id: string) {
        return httpService.delete(`/v1/form/release/${id}`)
    },
}