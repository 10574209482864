import { fetchBaseQuery } from "@reduxjs/toolkit/query";

export type Pagination = {
    limit: number;
    page: number;
}

export type MultilingualText = {
  ru: string;
  uz: string;
  en: string;
}

export const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const baseQuery = () =>
    fetchBaseQuery({
        baseUrl,
        credentials: "include",
    });
