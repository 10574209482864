import React, {useEffect, useState} from 'react';
import SearchBox, {SearchBoxSuffix} from "src/components/SearchBox";
import Filter from "src/icons/fluency-regular/Filter";
import {InnerColumnWrapper} from "src/layouts/TopbarLayout";
import {useHistory, useRouteMatch} from "react-router-dom";
import {layouts, Table, Button} from "@med-io/neural-ui";
import {useAdminsQuery} from "src/redux/API/adminAPI";

const {TopBarLayout} = layouts;

function Moderators() {
    const {path} = useRouteMatch();
    const history = useHistory();
    const [search, setSearch] = useState('')

    const {data, isFetching, isError, refetch} = useAdminsQuery({search})


    const onSearch = (search: string) => setSearch(search)

    const columns = [
        {
            title: "Ф.И.О",
            dataIndex: "name",
            render: (cellData: any, rowData: any) => `${rowData.surname} ${rowData.name} ${rowData.patronymic}`
        },
        {
            title: "Электронная почта",
            dataIndex: "email"
        },
    ]

    return (
        <TopBarLayout
            title="Модераторы"
            isContentLoading={isFetching}
            isError={isError}
            onRetry={refetch}
            topBarActions={<Button onClick={() => history.push('moderators/new')} variant='primary' size='md'>Добавить
                модератора</Button>}
        >
            <InnerColumnWrapper>
                <SearchBox delayedSearch onSearch={onSearch} defaultValue={search}
                           suffix={<><SearchBoxSuffix onClick={() => console.log("Filter click")}><Filter
                               size={20}/></SearchBoxSuffix></>}/>
                <Table
                    columns={columns}
                    data={data?.items || []}
                    onRow={(row: any) => ({
                        onClick: () => history.push({
                            pathname: `/moderators/${row.id}`
                        })
                    })}
                    loading={isFetching}
                    size='md'
                    rowKey='id'

                />
            </InnerColumnWrapper>
        </TopBarLayout>
    )

}


export default Moderators;