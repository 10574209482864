import React, { useMemo } from "react";
import type { StyledComponent } from "styled-components";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "src/styles/global";
import type { Theme } from "./theme";
import defaultTheme from "./theme";
import palettes from "./palettes";

type Props = {
  children: React.ReactNode,
  selectedPalette: "light" | "dark" | "dusk",
};

export type ThemedComponent<T> = StyledComponent<any, Theme & T, {}>;

const StyleProvider = ({ children, selectedPalette }: Props) => {
  const theme: Theme = useMemo(
    () => ({
      ...defaultTheme,
      colors: {
        ...defaultTheme.colors,
        // @ts-ignore
        palette: palettes[selectedPalette],
      },
    }),
    [selectedPalette],
  );


  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default StyleProvider;
