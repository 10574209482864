import React, { useLayoutEffect, useState } from 'react'
import { Provider } from 'react-redux'
import StyleProvider from 'src/styles/StyleProvider'
import Default from 'src/Default'
import { store } from 'src/redux/store'
import { Router } from 'react-router-dom'
import 'react-virtualized/styles.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'rc-tooltip/assets/bootstrap.css'
import 'src/styles/global.css'
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserHistory } from "history";
import { ThemeProvider } from "@med-io/neural-ui";

export const history = createBrowserHistory();

function App() {
  const selectedPalette = 'light'
  return (
    <Router history={history}>
      <Provider store={store}>
        <StyleProvider selectedPalette={selectedPalette}>
          <ThemeProvider>
            <Default />
          </ThemeProvider>
        </StyleProvider>
      </Provider>
    </Router>
  )
}

export default App
