import React from 'react';
import NestedMenuItem from "src/components/NestedMenu/NestedMenuItem";

type NestedMenuProps = {
  data: object[];
  labelGetter: (dataObj: object) => string | number | JSX.Element;
  childGetter: (dataObj: object) => [] | null | undefined
}

const NestedMenu = (props: NestedMenuProps) => {
  const { data, labelGetter, childGetter } = props;
  return (
    <div>
      {data.map((item: any) => {
        const children = childGetter(item) || [] as any
        return (
          <NestedMenuItem key={item.id} label={labelGetter(item)} hasChildren={!!children.length}>
            <NestedMenu data={children} labelGetter={labelGetter} childGetter={childGetter} />
          </NestedMenuItem>
        )
      })}
    </div>
  );
};

export default NestedMenu;