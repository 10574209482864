import { FileAndURL } from "src/screens/catalog/products/ProductVariant/ProductVariantImages";
import { MultilingualObject } from "src/typed";

const ProductVariantInitial: ProductVariant = {
  id: "",
  product_id: "",
  name:{
    en: "",
    ru: "",
    uz: ""
  },
  package: "",
  created_at: "",
  updated_at: "",
  divisible: true,
  pieces_per_package: undefined,
  barcode: [],
  sex: undefined,
  age_from: null,
  age_to: null,
  expiration_period: undefined,
  category: {
    id: "",
    name: "",
    icon: null,
  },
  atc: {
    id: "",
    code: "",
    full_code: "",
    name: ""
  },
  inn: {
    id: "",
    code: "",
    name: ""
  },
  dosage_form: {
    id: "",
    name: {
      uz: "",
      ru: "",
      en: ""
    }
  },
  release_form: {
    id: "",
    name: {
      uz: "",
      ru: "",
      en: ""
    }
  },
  manufacturer: {
    id: "",
    name: ""
  },
  unit_of_measure: {
    uz: "",
    ru: "",
    en: ""
  },
  composition: {
    uz: "",
    ru: "",
    en: ""
  },
  description: {
    uz: "",
    ru: "",
    en: ""
  },
  pharmacodynamics: {
    uz: "",
    ru: "",
    en: ""
  },
  pharmacokinetics: {
    uz: "",
    ru: "",
    en: ""
  },
  indications_for_use: {
    uz: "",
    ru: "",
    en: ""
  },
  contraindications: {
    uz: "",
    ru: "",
    en: ""
  },
  carefully: {
    uz: "",
    ru: "",
    en: ""
  },
  pregnancy_and_breastfeeding: {
    uz: "",
    ru: "",
    en: ""
  },
  methods_of_use_and_doses: {
    uz: "",
    ru: "",
    en: ""
  },
  side_effects: {
    uz: "",
    ru: "",
    en: ""
  },
  overdose: {
    uz: "",
    ru: "",
    en: ""
  },
  special_instructions: {
    uz: "",
    ru: "",
    en: ""
  },
  influence_on_driving: {
    uz: "",
    ru: "",
    en: ""
  },
  storage_conditions: {
    uz: "",
    ru: "",
    en: ""
  },
  interactions: {
    uz: "",
    ru: "",
    en: ""
  },
  images: [],
  on_prescription: false,
}

export type ProductVariant = {
  id: string,
  product_id: string,
  name: MultilingualObject,
  package: string,
  created_at: string,
  updated_at: string,
  divisible: boolean,
  pieces_per_package: undefined | number,
  barcode: string[],
  sex: number | undefined,
  age_from: number | null,
  age_to: number | null,
  expiration_period: number | undefined;
  category: {
    id: string;
    name: string;
    icon: string | null;
  }
  atc: {
    id: string,
    full_code: string,
    code: string,
    name: string
  },
  inn: {
    id: string,
    code: string,
    name: string
  },
  dosage_form: {
    id: string,
    name: MultilingualObject
  },
  release_form: {
    id: string,
    name: MultilingualObject
  },
  manufacturer: {
    id: string,
    name: string
  },
  unit_of_measure: MultilingualObject,
  composition: MultilingualObject,
  description: MultilingualObject,
  pharmacodynamics: MultilingualObject,
  pharmacokinetics: MultilingualObject,
  indications_for_use: MultilingualObject,
  contraindications: MultilingualObject,
  carefully: MultilingualObject,
  pregnancy_and_breastfeeding: MultilingualObject,
  methods_of_use_and_doses: MultilingualObject,
  side_effects: MultilingualObject,
  overdose: MultilingualObject,
  special_instructions: MultilingualObject,
  influence_on_driving: MultilingualObject,
  storage_conditions: MultilingualObject,
  interactions: MultilingualObject,
  images: FileAndURL[],
  on_prescription: boolean,
}

export default ProductVariantInitial