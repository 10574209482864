import React, { useRef, useState } from "react";
import { Box } from "src/components/Box/Box";
import Check from "src/icons/Check";
import styled from "styled-components";

type ColorCheckboxType = {
    value: string;
    onChange: (value: string) => void;
}

const colors = [
    "#FFEFB6",
    "#FFF2D0",
    "#FEFFBB",
    "#EDFFD6",
    "#DFFFB6",
    "#D0FFE9",
    "#B6FFDC",
    "#B6F2FF",
    "#E0E5FF",
    "#E7E4FF",
    "#E8D1FF",
    "#F9D3FF",
    "#FFCBFD",
    "#FFD8D2",
    "#FFCECE",
]

const ColorCheckboxWrapper = styled.span`
    display: block;
    width: 32px;
    height: 32px;
    position: relative;
    border-radius: 4px;

    cursor: pointer;
`

const  ColorCheckboxInput = styled.input`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 4px;
    pointer-events: none;

    & + .checkbox[data-checked="false"] {
        & svg {
            display: none;
        }
    }

    & + .checkbox[data-checked="true"] {
        & svg {
            display: block;
        }
    }
`

const ColorCheckboxReal = styled.span`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
`

const ColorPicker = ({onChange, value}: ColorCheckboxType) => {
    const boxRef: any = useRef()

    const handleClick = (e: any) => {
        if(e.target.id === "checkbox"){
            boxRef.current.childNodes.forEach((itm: any) => {
                if(itm.children[0].value === e.target.children[0].value) itm.children[1].setAttribute("data-checked", true)
                else itm.children[1].setAttribute("data-checked", false)
            })
            onChange(e.target.children[0].value)
        }
    }   
    
    return (
        <Box flexWrap="wrap" style={{ gap: "10px" }} ref={boxRef} onClick={(e: any) => handleClick(e)}>
            {
                colors.map((color) => {
                    return (
                        <ColorCheckboxWrapper id="checkbox" key={color} style={{background: color}}>
                            <ColorCheckboxInput  value={color} type={"radio"} />
                            <ColorCheckboxReal data-checked={color === value} className="checkbox">
                                <Check size={14} />
                            </ColorCheckboxReal>
                        </ColorCheckboxWrapper>
                    )
                })
            }
        </Box>
    )
}

export default ColorPicker