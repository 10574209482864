import React, {useMemo} from 'react';
import Fuse from 'fuse.js';
import {Category, useCategoriesQuery} from "src/redux/API/categoryAPI";
import {FormikSelect} from "@med-io/neural-ui";

type Props = {
    name: string;
    deepest?: boolean,
    excludedIds?: string[],
    [x: string]: any
}

export const CategorySelect = ({deepest, excludedIds, ...rest}: Props) => {
    const {data, isFetching} = useCategoriesQuery({})

    const grouped = useMemo(() => {
        if (data?.items) {
            return data?.items
        } else {
            return []
        }
    }, [data])
    const getDeepest = (items: any[]): any[] => {
        let newArr: any[] = []

        items.forEach((item: any) => {
            item = JSON.parse(JSON.stringify(item))
            if (item.children && item.children.length) {
                item.children.forEach((child: any) => {
                    child.label = `${item.name.ru} > ${child.name.ru}`
                })
                const result = getDeepest(item.children)
                newArr = [...newArr, ...result]
            } else {
                if (!item.label) {
                    item.label = item.name.ru
                }
                newArr.push(item)
            }
        })
        return [...newArr]
    }

    const labelWithParent = (items: any[]) => {
        let newArr: any[] = []
        items.forEach((item) => {
            item = JSON.parse(JSON.stringify(item))
            if (!item.label) item.label = item.name.ru
            const {itemChildren, ...itemRest} = item
            newArr.push(itemRest)
            if (item.children && item.children.length) {
                item.children.forEach((child: any) => {
                    child.label = `${item.name.ru} > ${child.name.ru}`
                })
                const result = labelWithParent(item.children)
                newArr = [...newArr, ...result]
            }
        })
        return newArr
    }

    let items = useMemo(() => {
        if (deepest) {
            return getDeepest([...grouped])
        } else {
            return labelWithParent(grouped)
        }
    }, [data?.count, deepest])

    if (excludedIds?.length) {
        items = items.filter((item) => !excludedIds.includes(item.id))
    }

    const searchOptions: Fuse.IFuseOptions<Category> = {
        keys: [
            {name: "name.ru", weight: 1},
        ],
    };


    return (
        <FormikSelect<Category>
            options={items}
            searchOptions={searchOptions}
            loading={isFetching}
            getOptionLabel={(option) => option.name.ru}
            valueKey='id'
            virtualized
            placeholder='Выберите категорию'
            label='Категория'
            size="sm"
            fullWidth
            defaultValue={rest.value}
            searchable
            delayedSearch={150}
            {...rest}
        />
    );
};

export default CategorySelect;
