import React from 'react';
import {Link, useHistory} from "react-router-dom";
import styled from "styled-components";
import Box from "src/components/Box";
import NestedMenu from "src/components/NestedMenu/NestedMenu";
import Text from "src/components/Text";
import UpdateCategoriesBtn from "src/screens/catalog/categories/UpdateCategoriesBtn";
import {useCategoriesGroupQuery} from "src/redux/API/categoryAPI";
import {layouts, Typography} from "@med-io/neural-ui";
const {TopBarLayout} = layouts

export const InnerColumnWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  & > *:last-child {
    flex: 1;
  }
`;

const LinkStyled = styled(Link)`
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.colors.palette.primary.main}
  }
`;

const CategoriesTree = () => {
    const {data, isFetching} = useCategoriesGroupQuery({})
    const history = useHistory()

    return (
        <TopBarLayout
            title="Древо категорий"
            topBarActions={<UpdateCategoriesBtn/>}
            onBack={() => history.goBack()}
        >
            <InnerColumnWrapper>
                <Box display='block'>
                    <NestedMenu
                        data={data?.items || []}
                        labelGetter={(data: any) => (
                            <LinkStyled to={`/catalog/categories/${data.id}`} target="_blank" rel="noopener noreferrer">
                                <Box alignItems='center'>
                                    <Typography>{data?.name?.ru}</Typography>
                                </Box>
                            </LinkStyled>
                        )}
                        childGetter={(data: any) => data?.children}
                    />
                </Box>
            </InnerColumnWrapper>
        </TopBarLayout>
    )
}

export default CategoriesTree;
