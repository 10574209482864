import React from "react";
import * as ReactTable from "react-virtualized"
import { TableStyled } from "src/components/Table/styled";
import { TableProps, TableColumn } from "src/components/Table/types";
import 'react-virtualized/styles.css';
import Skeleton from 'react-loading-skeleton'

export type OnSelectProps = {
  checked?: boolean
  selectedKey?: number | string
  selectedIndex?: number
  selectedKeys?: Array<number | string>
  selectedIndexes?: Array<number>
}

export type OnSortProps = {
  sortBy: string
  order: string
}

export type LoadMoreProps = { page: number; startIndex: number; stopIndex: number }


const Table = (props: TableProps) => {
  const {
    data,
    columns,
    onRowClick,
    onRowDoubleClick,
    loading,
    onLoadMore,
    virtualized = true,
    page,
    limit,
    totalCount
  } = props;

  const handleLoadMore = async ({ startIndex, stopIndex }: { startIndex: number; stopIndex: number }) => {
    onLoadMore && !loading && page && limit && (page * limit < totalCount) && onLoadMore({
      page: page + 1,
      startIndex,
      stopIndex
    })
  };

  return (
    <ReactTable.AutoSizer style={{ width: "100%", height: "100%" }}>
      {({ height, width }) => {
        return <ReactTable.InfiniteLoader
          isRowLoaded={({ index }) => !!data[index]}
          loadMoreRows={handleLoadMore}
          rowCount={totalCount}
        >
          {({ onRowsRendered, registerChild }) => (
            <TableStyled
              key={`${height}${width}`}
              onRowsRendered={onRowsRendered}
              ref={registerChild}
              width={width}
              height={height}
              headerHeight={43}
              autoWidth={true}
              rowHeight={50}
              rowCount={totalCount}
              rowGetter={({ index }: any) => data[index] ? data[index] : 'Loading ...'}
              onRowClick={({ event, index: rowIndex, rowData: row }: any) => onRowClick && data[rowIndex] && onRowClick({
                rowIndex,
                row,
                event
              })}
              onRowDoubleClick={({
                event,
                index: rowIndex,
                rowData: row
              }: any) => onRowDoubleClick && onRowDoubleClick({ rowIndex, row, event })}
              rowClassName="row"
              className="table"
              headerClassName="header"
            >
              {
                columns.map((column: TableColumn, idx: number) =>
                  <ReactTable.Column
                    cellRenderer={(props: any) => {
                      if (!data[props.rowIndex]) {
                        return (
                          <Skeleton
                            containerClassName='container-skel'
                            style={{ width: '100%', height: '10px', margin: '0' }}
                            width='100px'
                          />
                        )
                      } else if (column.render) {
                        return column.render(props)
                      } else {
                        return props.cellData
                      }
                    }}
                    flexGrow={column.flexGrow || 1}
                    dataKey={column.key}
                    label={column.title}
                    key={idx}
                    // minWidth={column.minWidth || 10}
                    width={column.width || 150}
                    className="cell"
                  />)
              }
            </TableStyled>
          )}
        </ReactTable.InfiniteLoader>
      }}
    </ReactTable.AutoSizer>
  )
}

export default Table
