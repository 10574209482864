import React from "react";

const Search = ({ size = 24, color = '#000' }: { size?: number, color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2003 2.74292C14.3188 2.74292 17.6575 6.0816 17.6575 10.2001C17.6575 11.9814 17.0328 13.6169 15.9907 14.8994L21.0315 19.9403C21.3328 20.2416 21.3328 20.73 21.0315 21.0312C20.7577 21.3051 20.3291 21.33 20.0271 21.1059L19.9406 21.0312L14.8997 15.9904C13.6171 17.0326 11.9817 17.6572 10.2003 17.6572C6.08185 17.6572 2.74316 14.3186 2.74316 10.2001C2.74316 6.0816 6.08185 2.74292 10.2003 2.74292ZM10.2003 4.28578C6.93394 4.28578 4.28602 6.93369 4.28602 10.2001C4.28602 13.4664 6.93394 16.1144 10.2003 16.1144C13.4667 16.1144 16.1146 13.4664 16.1146 10.2001C16.1146 6.93369 13.4667 4.28578 10.2003 4.28578Z" />
  </svg>

);

export default Search;
