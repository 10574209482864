import React from 'react';
import Button from "src/components/Button";
import {useHistory, useRouteMatch} from "react-router-dom";
import SearchBox, {SearchBoxSuffix} from "src/components/SearchBox";
import styled from "styled-components";
import {layouts} from "@med-io/neural-ui";
const {TopBarLayout} = layouts

const SearchButtonBox = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  & > *:first-child {
    width: 460px;
    height: 100%;
  }
  & > *:last-child {
    height: 100%;
  }
`


const Symptoms = () => {
    const {path} = useRouteMatch();
    const history = useHistory();

    const columns = [
        {
            title: "Наименование",
            key: "name",
            render: (cell: any) => cell.ru
        },
    ]

    const data = [
        {
            id: 1,
            fullName: "Высокая температура",
        },
        {
            id: 2,
            fullName: "Dori Darmon Company Limited",
        },
        {
            id: 3,
            fullName: "Dori Darmon Company Limited",
        },
        {
            id: 4,
            fullName: "Dori Darmon Company Limited",
        }
    ];

    return (
        <TopBarLayout title="Симптомы" >
            <SearchButtonBox>
                <SearchBox />
                <Button to={path + '/new_symptom'} primary>Добавить симптом</Button>
            </SearchButtonBox>
        </TopBarLayout>
    )
}

export default Symptoms;
