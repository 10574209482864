import {createApi} from '@reduxjs/toolkit/query/react';

import {baseQuery} from '../common';
import {
    CreateStateRequest,
    CreateStateResponse, StateByIdRequest, StateByIdResponse,
    StatesRequest,
    StatesResponse, UpdateStateByIdRequest, UpdateStateByIdResponse
} from "src/redux/API/stateAPI/stateAPI.types";

export const stateAPI = createApi({
    reducerPath: 'stateAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        states: builder.query<StatesResponse, StatesRequest>({
            query: ({
                        limit = 10,
                        page = 1,
                        search = '',
                        country_id,
                        order_by
                    }) => `/v1/area/state?limit=${limit}&page=${page}&search=${search}&country_id=${country_id}&order_by=${order_by}`
        }),
        createState: builder.mutation<CreateStateResponse, CreateStateRequest>({
            query: (state) => ({
                url: `/v1/area/state`,
                method: 'POST',
                body: state
            })
        }),
        stateById: builder.query<StateByIdResponse, StateByIdRequest>({
            query: ({id}) => `/v1/area/state/${id}`
        }),
        updateStateById: builder.mutation<UpdateStateByIdResponse, UpdateStateByIdRequest>({
            query: ({id, ...payload}) => ({
                url: `/v1/area/state/${id}`,
                method: 'PUT',
                body: payload
            })
        })

    }),
});

export const {useStatesQuery, useCreateStateMutation, useStateByIdQuery, useUpdateStateByIdMutation} = stateAPI