import React, {useState} from "react";
import {Box, layouts, Table, TableColumns} from "@med-io/neural-ui";
import SearchBox, {SearchBoxSuffix} from "src/components/SearchBox";
import {Pagination} from "src/stories/components/Pagination";
import CopyToClipboard from "src/components/CopyToClipboard/CopyToClipboard";
import {ProductVariant, useProductVariantsQuery} from "src/redux/API/productVariantsAPI";

const {TopBarLayout} = layouts;

export function ProductVariants() {
    const limit = 30;
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const {data, isFetching, isError, refetch} = useProductVariantsQuery({limit, page, search})

    const columns: TableColumns<ProductVariant> = [
        {
            title: "Название",
            dataIndex: "name",
            render: (value) => value.ru,
            width: 300,
            ellipsis: true
        },
        {
            title: "Производитель",
            dataIndex: "manufacturer",
            render: (value) => value?.name,
            width: 200,
            ellipsis: true
        },
        {
            title: "Страна",
            dataIndex: "country",
            render: (value) => value?.name,
            width: 150,
            ellipsis: true
        },
        {
            title: "Лек.форма",
            dataIndex: "dosage_form",
            render: (value) => value?.name,
            width: 150,
            ellipsis: true
        },
        {
            title: "Форма упаковки",
            dataIndex: "release_form",
            render: (value) => value?.name,
            width: 150,
            ellipsis: true
        },
        {
            title: "Измерение",
            dataIndex: "unit_of_measure",
            render: (value) => value?.ru,
            width: 150,
            ellipsis: true
        },
        {
            title: "Упаковка",
            dataIndex: "package",
            width: 110,
            ellipsis: true
        },
        {
            title: "MHH",
            dataIndex: "inn",
            render: (value) => value?.name,
            width: 150,
            ellipsis: true
        },
        {
            title: "ATX",
            dataIndex: "atc",
            render: (value) => value?.code,
            width: 150,
            ellipsis: true
        },
        {
            title: "ID",
            dataIndex: "id",
            render: (value) => <CopyToClipboard text={value}/>,
            width: 70
        }
    ];

    const onSearch = (search: string) => setSearch(search)


    return (
        <TopBarLayout
            title="Варианты продукта"
            isContentLoading={isFetching}
            isError={isError}
            onRetry={refetch}
            topBarActions={
                <Box display='flex' alignItems='center'>
                    <SearchBox delayedSearch onSearch={onSearch} defaultValue={search}
                               suffix={<SearchBoxSuffix/>}

                    />
                </Box>
            }
            bottomBar={
                <Pagination total={data?.count || 0} defaultPageSize={limit}
                            onChange={setPage} current={page}/>
            }
        >
            <Table
                data={data?.items || []}
                columns={columns}
                size="md"
                scroll={{y: "72vh"}}
                rowKey="id"
                divider="line"
                loading={isFetching}
            />
        </TopBarLayout>
    );
}

export default ProductVariants;
