import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from "src/apis";
import { getSessionsExpireTime } from "src/helpers/auth";
import { history } from "src/App";
import { UserStore } from './types';

const initialState: UserStore = {
  id: null,
  name: "",
  surname: "",
  patronymic: "",
  email: "",
  avatar: null,
  is_super: false,
  created_at: "",
  isAuthenticated: false,
  loading: true,
  error: null,
  permissions: [],
  expires_at: undefined,
}

export const login = createAsyncThunk(
  'LOGIN',
  async (payload: any = {}) => {
    const {data} = await Apis.auth.login(payload)
    return data
  },
)

export const logOut = createAsyncThunk(
  'LOGOUT',
  async () => {
    const {data} = await Apis.auth.logout()
    return data
  },
)

export const getIsAuthenticated = createAsyncThunk(
  'GET_IS_AUTHENTICATED',
  async () => {
    const {data} = await Apis.auth.isAuthenticated()
    return data
  }
)

const authSuccess = (data: any, state: any) => {
  if (data.is_super) {
    data.permissions = []
  }
  const expires_at = getSessionsExpireTime()
  state.id = data.id
  state.name = data.name
  state.surname = data.surname
  state.patronymic = data.patronymic
  state.email = data.email
  state.is_super = data.is_super
  state.created_at = data.created_at
  state.isAuthenticated = true
  state.loading = false
  state.error = data.error
  state.permissions = data.permissions
  state.expires_at = expires_at.toString()
  // return {...initialState, ...data, permissionCodes, isAuthenticated: true, loading: false, expires_at}
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    LOGOUT: () => {
      return {
        ...initialState,
        loading: false
      }
    },
    AUTH_SUCCESS: (state, { data }: any) => {
      return authSuccess(data, state)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.error = null
    })
    builder.addCase(login.fulfilled, (state, action) => {
      const data = action.payload
      return authSuccess(data, state)
    })
    builder.addCase(login.rejected, (state, action) => {
      return { ...initialState, loading: false }
    })
    builder.addCase(getIsAuthenticated.pending, (state, action) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getIsAuthenticated.fulfilled, (state, action) => {
      const data = action.payload
      return authSuccess(data, state)
    })
    builder.addCase(getIsAuthenticated.rejected, (state, action) => {
      return { ...initialState, loading: false }
    })
    builder.addCase(logOut.pending, (state, action) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(logOut.fulfilled, (state, action) => {
      return {
        ...initialState,
        loading: false
      }
    })
    builder.addCase(logOut.rejected, (state, action) => {
      state.error = action.error;
    })
  }
})

export const {LOGOUT, AUTH_SUCCESS} = userSlice.actions

export default userSlice.reducer
