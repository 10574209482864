import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ProductVariant from "src/screens/catalog/products/ProductVariant/ProductVariant"
import ProductVariantInstructions
  from "src/screens/catalog/products/ProductVariant/ProductVariantInstruction/ProductVariantInstruction";
import ProductVariantImages from "src/screens/catalog/products/ProductVariant/ProductVariantImages";

export const ProductVariantRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={ProductVariant} />
      <Route exact path={path + '/images'}
             component={ProductVariantImages}
      />
      <Route exact path={path + '/instructions'}
             component={ProductVariantInstructions}
      />
    </Switch>
  )
}