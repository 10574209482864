import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import styled from "styled-components";
import {ErrorMessage, Field, Form, Formik, FormikProps} from "formik";
import {HorizontalBox} from "src/components/HorizontalBox";
import Input, {ErrorStyled} from "src/components/Input";
import * as Yup from "yup";
import Apis from "src/apis";
import CategorySelect from "src/common/selects/CategorySelect";
import {toast} from "react-toastify";
import {UserPermissionsEnum} from "src/common/permissions";
import {useRestriction} from "src/hooks";
import Confirm from "src/components/Confirm";
import Text from "src/components/Text";
import {ProductVariant} from '../products/ProductVariant/ProductVariantInitial';
import {LoadMoreProps} from "src/components/Table/Table";
import {ProductVariantsList} from '../products/ProductVariant/ProductVariantsList';
import {
    useCategoryByIdQuery,
    useCreateCategoryMutation,
    useDeleteCategoryMutation,
    useUpdateCategoryMutation
} from "src/redux/API/categoryAPI";
import Spinner from "src/components/Spinner";
import {layouts, Button, Box, FormikInput, Typography} from "@med-io/neural-ui";
import {SectionWithHeader} from "src/components/SectionWithHeader";
const {TopBarLayout} = layouts

const FormBox = styled.div`
  height: 100%;
  border: 1px solid #E7E7E7;
  border-radius: ${p => p.theme.borderRadius.default}px;
`

const ProductFlex = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 24px;
  height: 100%;
`

const Schema = Yup.object({
    name: Yup.object().shape({
        ru: Yup.string().required('Обязательное поле'),
        uz: Yup.string().required('Обязательное поле'),
        en: Yup.string().nullable(true),
    }),
    // parent_id: Yup.string().nullable(true).transform((_, val) => val === Number(val) ? val : null),
    parent_id: Yup.string().nullable()
})

const Category = () => {
    const history = useHistory();
    const formRef = useRef<FormikProps<any>>(null)
    const {state: {category: editedData = {}, historyBack} = {}} = useLocation<any>() || null
    const [productVariants, setProductVariants] = useState<{
        items: ProductVariant[],
        count: number;
        loading: boolean;
        filters: {
            page: number;
            limit: number;
        }
    }>({
        items: [],
        count: 0,
        filters: {
            limit: 50,
            page: 1
        },
        loading: false
    })
    const {params: {id}} = useRouteMatch<any>()
    const isEditing = id !== 'new';
    const [updateCategory, {isLoading: isUpdating}] = useUpdateCategoryMutation()
    const [createCategory, {isLoading: isCreating}] = useCreateCategoryMutation()
    const [deleteCategory, {isLoading}] = useDeleteCategoryMutation()
    const {data: dataById, isFetching} = useCategoryByIdQuery({id}, {
        skip: !isEditing
    })

    const getProductVariants = async (page = productVariants.filters.page) => {
        // pending
        setProductVariants(prev => {
            return {
                ...prev,
                loading: true,
            }
        })

        const {data} = await Apis.products.getProducts({
            page,
            limit: productVariants.filters.limit,
            category_ids: [editedData.id]
        })

        setProductVariants(prev => {
            return {
                ...prev,
                items: [...prev.items, ...data.items],
                filters: {...prev.filters, page},
                count: data.count,
                loading: false
            }
        })
    }

    const onSubmit = async (values: any, actions: any) => {
        actions.setSubmitting(true)
        if (isEditing) {
            updateCategory({id, ...values})
            toast.success('Категория обновлена')
            history.replace({pathname: `/catalog/categories/${id}`, state: {category: values, historyBack: false}})
        } else {
            const {data}:any = await createCategory(values)
            toast.success('Категория создана')
            if (data) {
                history.replace({
                    pathname: `/catalog/categories/${data.id}`,
                    state: {category: values, historyBack: false}
                })
            }
        }
    }

    const handleDelete = async () => {
        if (formRef.current) {
            formRef.current.setSubmitting(true)
            deleteCategory({id})
            history.goBack()
            formRef.current.setSubmitting(false)
            toast.success('Удалено')
        }
    }

    const onLoadMore = async ({page}: LoadMoreProps) => {
        await getProductVariants(page)
    }

    const {isPermitted} = useRestriction()

    const isAllowed = isEditing && isPermitted(UserPermissionsEnum["category.update"]) || !isEditing && isPermitted(UserPermissionsEnum["category.create"])

    if (isFetching) {
        return <Spinner size={15}/>
    }

    const initialValues = {
        name: {
            uz: isEditing ? dataById?.name.uz : '',
            ru: isEditing ? dataById?.name.ru : '',
            en: isEditing ? dataById?.name.en : null
        },
        parent_id: null,
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={Schema}
            innerRef={formRef}
            enableReinitialize
        >
            {({handleSubmit, setFieldValue, values,isSubmitting}) => (
                <TopBarLayout
                    title={isEditing ? values.name.ru : 'Новая категория'}
                    disableBodyOverflow
                    onBack={() => historyBack ? history.goBack() : history.push('/catalog/categories')}
                    topBarActions={
                        <>
                            {isEditing && isPermitted(UserPermissionsEnum["category.delete"]) &&
                                <Confirm title='Удаление категории'
                                         okButton={<Button ml={1.5}
                                                           type='button'
                                                           destructive={true} size='md' variant='primary'>Удалить</Button>}
                                         description={
                                             <Box display='block' w='300px'>
                                                 <Typography>Вы
                                                     действительно
                                                     хотите
                                                     удалить
                                                     эту категорию?</Typography>
                                             </Box>
                                }
                                         onConfirm={handleDelete}>
                                    <Button mr={1.5} type='button' variant='tertiaryGray' size='md'>Удалить</Button>
                                </Confirm>}
                            {
                                isAllowed && <Button onClick={() => handleSubmit()} variant='primary' size='md' loading={isSubmitting}>Сохранить</Button>
                            }
                        </>
                    }
                >
                    <Box display='flex' gap={1.5} h='85vh'>
                        <Box w='30%'>
                            <SectionWithHeader title='Данные категории'>
                                <FormikInput name='name.ru' label='Название (Ru)' placeholder='Введите название' size='sm' fullWidth/>
                                <FormikInput name='name.uz' label='Название (Uz)' placeholder='Введите название' size='sm' fullWidth/>
                                <FormikInput name='name.en' label='Название (En)' placeholder='Введите название' size='sm' fullWidth/>
                                <CategorySelect name='parent_id' isClearable excludedIds={[id]} />
                                <ErrorMessage name='parent_id' component={ErrorStyled} />
                            </SectionWithHeader>
                        </Box>
                        <Box w='67.5%'>
                            {isEditing && <ProductVariantsList id={id} title='Варианты продукта' filter_by='category_ids'/>}
                        </Box>
                    </Box>
                </TopBarLayout>
            )}
        </Formik>
    )
}

export default Category;
