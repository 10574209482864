import React, {useEffect, useMemo, useState} from "react";
import { Col, Row } from "react-grid-system";
import { useHistory } from "react-router-dom";
import { Box } from "src/components/Box/Box";
import SearchBox from "src/components/SearchBox";
import { ImageCard, NewCard } from "../cards";
import { CollectionType } from "../colored";
import Fuse from "fuse.js";
import { useRestriction } from "src/hooks";
import { UserPermissionsEnum } from "src/common/permissions";
import {useCollectionsQuery} from "src/redux/API/collectionAPI";



const Image = () => {
    const [initialItems, setInitialItems] = useState<CollectionType[]>([])
    const [filteredItems, setFilteredItems] = useState<CollectionType[]>([])
    const history = useHistory()
    const { isPermitted } = useRestriction()
    const {data,isFetching} = useCollectionsQuery({ limit: 50, page: 1, type: "image" })


    useMemo(() => {
        if (data) {
            setInitialItems(data.items)
            setFilteredItems(data.items)
        }
    },[isFetching === true])


    const handleClick = (id: string) => {
        history.push(`/mobile/collections/image/${id}`)
    }

    const onSearch = (search: string) => {
        const value = search.trim()
        if (value.length !== 0) {
            const options = {
                includeScore: true,
                keys: ['title.ru', 'title.en', 'title.uz']
            }

            const fuse = new Fuse(initialItems, options)

            const result = fuse.search(value).map(itm => itm.item)

            setFilteredItems(result)

        } else setFilteredItems([...initialItems])
    }



    return (
        <Box display="block" width="100%" height="calc(100vh - 48px)" paddingTop="24px">
            <Box padding="0 24px">
                <SearchBox onSearch={onSearch} delayedSearch placeholder="Поиск подборок" />
            </Box>
            <Box display="block" overflowY="auto" height="calc(100% - 48px)" width="100%" padding="24px">
                <Row gutterWidth={16} style={{ marginBottom: "-24px" }}>
                    {
                        isPermitted(UserPermissionsEnum["collection.create"]) &&
                        <Col md={4} lg={3} xl={2} xxl={2}>
                            <NewCard onClick={() => history.push('/mobile/collections/image/new')} />
                        </Col>
                    }
                    {
                        filteredItems.map((itm: any) =>
                            <Col key={itm.id} md={4} lg={3} xl={2} xxl={2}>
                                <ImageCard onClick={() => handleClick(itm.id)} desc={itm.title.ru} img={String(itm.image)} />
                            </Col>
                        )
                    }
                </Row>
            </Box>
        </Box>
    )
}

export default Image