import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { useCallback } from "react";
import { UserPermissionsEnum } from "src/common/permissions";

type ReturnProps = {
  permissions: UserPermissionsEnum | string[]
  isCatalogAllowed: () => boolean
  isPermitted: (permission: UserPermissionsEnum) => boolean
  catalogMinPermissions: UserPermissionsEnum[]
  mobileMinPermissions: UserPermissionsEnum[]
}

export const useRestriction = (): ReturnProps => {
  const { permissions, is_super } = useSelector((state: RootState) => state.user);

  const catalogMinPermissions: UserPermissionsEnum[] = [UserPermissionsEnum["product.read"], UserPermissionsEnum["category.read"], UserPermissionsEnum["manufacturer.read"], UserPermissionsEnum["dosage_form.read"], UserPermissionsEnum["release_form.read"], UserPermissionsEnum["atc.read"], UserPermissionsEnum["inn.read"]];
  const mobileMinPermissions = [UserPermissionsEnum["collection.read"]];

  const isCatalogAllowed = useCallback(() => {
    return is_super || catalogMinPermissions.some(c => permissions.includes(c));
  }, [is_super]);

  const isPermitted = (permission: UserPermissionsEnum) => is_super || permissions.includes(permission);

  return { isCatalogAllowed, permissions, isPermitted, catalogMinPermissions, mobileMinPermissions };

};

export default useRestriction;