import React from 'react';
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ErrorStyled, TextArea } from "src/components/Input";
import Schema from "src/screens/catalog/products/ProductVariant/ProductVariantInstruction/Schema";
import { useProductVariants } from "src/hooks";
import Loading from "src/screens/Loading";
import { toast } from "react-toastify";
import { hasFormChanged } from "src/helpers/form";
import {
  useProductVariantByIdQuery,
  useUpdateProductVariantInstructionsMutation
} from "src/redux/API/productVariantsAPI";
import {layouts, Button,Box,FormikTextArea} from "@med-io/neural-ui";
const {TopBarLayout} = layouts;

const FormBox: any = styled.div`
  width: 100%;
  padding-bottom: 30px;
  margin-bottom: 24px;
`;

const Header = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 15px;
`

const ProductFlex = styled.div`
  width: 100%;
  margin-bottom: -80px;
  height: 100vh;
`

const TextAreaBox = styled.div`
  width: 33%;
  height: 100%;
`

const ProductVariantInstruction = () => {
  const history = useHistory()
  const { params: { product_variant_id, id: productId }, url } = useRouteMatch<any>()
  const [updateProductVariantInstruction, {isLoading}] = useUpdateProductVariantInstructionsMutation()
  const { item, loading, updateInstructions } = useProductVariants({ product_variant_id })
  const {data, isFetching} = useProductVariantByIdQuery({id: product_variant_id})
  const initialState = {
    composition: data?.composition,
    description: data?.description,
    pharmacodynamics: data?.pharmacodynamics,
    pharmacokinetics: data?.pharmacokinetics,
    indications_for_use: data?.indications_for_use,
    contraindications: data?.contraindications,
    carefully: data?.carefully,
    pregnancy_and_breastfeeding: data?.pregnancy_and_breastfeeding,
    methods_of_use_and_doses: data?.methods_of_use_and_doses,
    side_effects: data?.side_effects,
    overdose: data?.overdose,
    special_instructions: data?.special_instructions,
    influence_on_driving: data?.influence_on_driving,
    storage_conditions: data?.storage_conditions,
    interactions: data?.interactions,
  }

  const onSubmit = async (values: any) => {
    if (hasFormChanged(initialState, values)) {
      updateProductVariantInstruction({id:product_variant_id,...values})
      history.replace({ pathname: url, state: { productVariant: values} })
      toast.success('Инструкция варианта продукта обновлена')
    } else {
      toast.warn('Внесите изменения чтобы обновить')
    }
  }

  if (loading) return <Loading />

  return (
    <Formik
      initialValues={initialState}
      onSubmit={onSubmit}
      validationSchema={Schema}
      enableReinitialize={false}
    >
      {({ handleSubmit, errors, values, isSubmitting }) => {
        return (
          <TopBarLayout
            title="Инструкция"
            // onBack={() => history.push({
            //   pathname: `/catalog/products/${productId}/variants/${id}/images`,
            //   state: { productVariant: item }
            // })}
            onBack={() => history.goBack()}
            topBarActions={<Button loading={isSubmitting} type='submit' onClick={() => handleSubmit()} variant='primary' size='md'>Сохранить</Button>}
            disableBodyOverflow={false}
          >
            <Box w="100%" h="100vh" mb="-80px">
              <Form style={{ paddingBottom: '20px' }}>
                <FormBox>
                  <Header>Состав</Header>
                  <Box display='flex' justifyContent='space-between' h='140px' w='100%'>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="composition.uz" label='Uz' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="composition.ru" label='Ru' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="composition.en" label='En' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                  </Box>
                </FormBox>

                <FormBox>
                  <Header>Описание</Header>
                  <Box display='flex' justifyContent='space-between' h='140px' w='100%'>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="description.uz" label='Uz' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="description.ru" label='Ru' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="description.en" label='En' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                  </Box>
                </FormBox>

                <FormBox>
                  <Header>Фармакокинетика</Header>
                  <Box display='flex' justifyContent='space-between' h='140px' w='100%'>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="pharmacokinetics.uz" label='Uz' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="pharmacokinetics.ru" label='Ru' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="pharmacokinetics.en" label='En' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                  </Box>
                </FormBox>

                <FormBox>
                  <Header>Фармакодинамика</Header>
                  <Box display='flex' justifyContent='space-between' h='140px' w='100%'>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="pharmacodynamics.uz" label='Uz' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="pharmacodynamics.ru" label='Ru' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="pharmacodynamics.en" label='En' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                  </Box>
                </FormBox>

                <FormBox>
                  <Header>Показания к приминению</Header>
                  <Box display='flex' justifyContent='space-between' h='140px' w='100%'>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="indications_for_use.uz" label='Uz' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="indications_for_use.ru" label='Ru' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="indications_for_use.en" label='En' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                  </Box>
                </FormBox>

                <FormBox>
                  <Header>Противопоказания</Header>
                  <Box display='flex' justifyContent='space-between' h='140px' w='100%'>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="contraindications.uz" label='Uz' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="contraindications.ru" label='Ru' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="contraindications.en" label='En' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                  </Box>
                </FormBox>

                <FormBox>
                  <Header>С осторожностью</Header>
                  <Box display='flex' justifyContent='space-between' h='140px' w='100%'>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="carefully.uz" label='Uz' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="carefully.ru" label='Ru' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="carefully.en" label='En' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                  </Box>
                </FormBox>

                <FormBox>
                  <Header>Применение при беременности и в период грудного вскармливания</Header>
                  <Box display='flex' justifyContent='space-between' h='140px' w='100%'>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="pregnancy_and_breastfeeding.uz" label='Uz' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="pregnancy_and_breastfeeding.ru" label='Ru' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="pregnancy_and_breastfeeding.en" label='En' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                  </Box>
                </FormBox>

                <FormBox>
                  <Header>Способы применения и дозы</Header>
                  <Box display='flex' justifyContent='space-between' h='140px' w='100%'>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="methods_of_use_and_doses.uz" label='Uz' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="methods_of_use_and_doses.ru" label='Ru' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="methods_of_use_and_doses.en" label='En' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                  </Box>
                </FormBox>

                <FormBox>
                  <Header>Побочные действия</Header>
                  <Box display='flex' justifyContent='space-between' h='140px' w='100%'>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="side_effects.uz" label='Uz' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="side_effects.ru" label='Ru' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="side_effects.en" label='En' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                  </Box>
                </FormBox>

                <FormBox>
                  <Header>Передозировка</Header>
                  <Box display='flex' justifyContent='space-between' h='140px' w='100%'>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="overdose.uz" label='Uz' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="overdose.ru" label='Ru' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="overdose.en" label='En' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                  </Box>
                </FormBox>

                <FormBox>
                  <Header>Особые указания</Header>
                  <Box display='flex' justifyContent='space-between' h='140px' w='100%'>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="special_instructions.uz" label='Uz' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="special_instructions.ru" label='Ru' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="special_instructions.en" label='En' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                  </Box>
                </FormBox>

                <FormBox>
                  <Header>Взаимодействия</Header>
                  <Box display='flex' justifyContent='space-between' h='140px' w='100%'>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="interactions.uz" label='Uz' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="interactions.ru" label='Ru' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="interactions.en" label='En' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                  </Box>
                </FormBox>

                <FormBox>
                  <Header>Влияние на способность управлять транспортными средствами</Header>
                  <Box display='flex' justifyContent='space-between' h='140px' w='100%'>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="influence_on_driving.uz" label='Uz' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="influence_on_driving.ru" label='Ru' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="influence_on_driving.en" label='En' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                  </Box>
                </FormBox>

                <FormBox>
                  <Header>Условия хранения</Header>
                  <Box display='flex' justifyContent='space-between' h='140px' w='100%'>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="storage_conditions.uz" label='Uz' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="storage_conditions.ru" label='Ru' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                    <TextAreaBox>
                      <FormikTextArea size="xxl" name="storage_conditions.en" label='En' rows={4} style={{resize:'none'}} fullWidth/>
                    </TextAreaBox>
                  </Box>
                </FormBox>
              </Form>
            </Box>
          </TopBarLayout>
        )
      }}
    </Formik>
  )
}

export default ProductVariantInstruction;
