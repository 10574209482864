import React, { useCallback } from 'react'
import styled from 'styled-components'
import { rgba } from 'src/styles/helpers'
import Dismiss from 'src/icons/fluency-regular/Dismiss'
import { useDispatch, useSelector } from 'react-redux'
import { CLOSE_DRAWER } from 'src/redux/features/UI'
import {RootState} from "src/redux/store";
import { UIDrawerSelector } from 'src/redux/features/UI/selectors'

const Wrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  z-index: 100;
`

const Dimmer = styled.div`
  background-color: ${() => rgba('#000', 0.6)};
  width: calc(100% - 400px);
  height: 100%;
`

const DrawerWrapper = styled.div`
  background-color: ${(p) => p.theme.colors.palette.background.paper};
  height: 100vh;
  width: 400px;
  display: flex;
  flex-direction: column;

  & > * {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
  }
`

export const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  height: 50px;
  box-sizing: content-box;
  border-bottom: solid 1px;
  border-color: ${(p) => p.theme.colors.palette.divider};
`

const DrawerTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  padding: 0 20px;
`

const DrawerCloseBtnStyled = styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${(p) => p.theme.colors.palette.text.shade5};
  }
`

export const DrawerBody = styled.div`
  padding: 20px;
  overflow-y: auto;
`

export const DrawerFooter = styled.div`
  position: relative;
  bottom: 0;
  padding: 10px 20px;
  min-height: 50px;
  box-sizing: content-box;
  border-top: solid 1px;
  border-color: ${(p) => p.theme.colors.palette.divider};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
`

export const DrawerCloseBtn = () => {
  const dispatch = useDispatch()

  const handleClose = useCallback(() => {
    dispatch(CLOSE_DRAWER())
  }, [CLOSE_DRAWER])

  return (
    <DrawerCloseBtnStyled onClick={handleClose}>
      <Dismiss size={24} />
    </DrawerCloseBtnStyled>
  )
}

export const DefaultDrawerHeader = ({ title }: { title: string }) => (
  <DrawerHeader>
    <DrawerTitle>{title}</DrawerTitle>
    <DrawerCloseBtn />
  </DrawerHeader>
)

function Drawer() {
  const dispatch = useDispatch()

  const {
    key,
    title: defaultTitle,
    payload,
  } = useSelector(UIDrawerSelector)
  const isOpen = !!key

  const handleClose = useCallback(() => {
    dispatch(CLOSE_DRAWER())
  }, [CLOSE_DRAWER])

  if (!isOpen) {
    return null
  }

  const getContent = () => {
    switch (key) {
      default:
        return {
          content: <div></div>,
        }
    }
  }

  const { content }: any = getContent()

  return (
    <Wrapper>
      <Dimmer onClick={handleClose} />
      <DrawerWrapper>{content}</DrawerWrapper>
    </Wrapper>
  )
}

export default Drawer
