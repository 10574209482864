import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import { drawerActionPayload, modalActionPayload, UIStore } from './types'

const initialState: UIStore = {
    drawer: {
        key: '',
        payload: {},
        title: '',
        closeOnOutsideClick: true,
    },
    modal: {
        key: '',
        payload: {},
        title: '',
        closeOnOutsideClick: true,
    },
}

export const openNewArrivalDrawer = createAsyncThunk(
    'OPEN_STOCK_MODAL',
    async (payload: any, thunkAPI) => {
        thunkAPI.dispatch(
            branchesSlice.actions.OPEN_DRAWER({
                key: 'NEW_ARRIVAL_DRAWER',
                payload: payload,
                title: '',
            }),
        )
    },
)

export const openCashModal = createAsyncThunk(
    'OPEN_STOCK_MODAL',
    async (payload: any, thunkAPI) => {
        thunkAPI.dispatch(
            branchesSlice.actions.OPEN_MODAL({
                key: 'CASH_MODAL',
                payload: payload,
                title: '',
            }),
        )
    },
)

export const openInventoryModal = createAsyncThunk(
    'OPEN_INVENTORY_MODAL',
    async (payload: any, thunkAPI) => {
        thunkAPI.dispatch(
            branchesSlice.actions.OPEN_MODAL({
                key: 'OPEN_INVENTORY_MODAL',
                payload: payload,
                title: '',
            }),
        )
    },
)

const branchesSlice = createSlice({
    name: 'branches',
    initialState,
    reducers: {
        OPEN_DRAWER: (
            state: any,
            {
                payload: {key, payload, title, closeOnOutsideClick = true},
            }: { payload: drawerActionPayload },
        ) => {
            state.drawer = {key, title, payload, closeOnOutsideClick}
        },
        CLOSE_DRAWER: (state: any) => {
            state.drawer = initialState.drawer
        },
        OPEN_MODAL: (
            state: any,
            {
                payload: {key, payload, title, closeOnOutsideClick = true},
            }: { payload: modalActionPayload },
        ) => {
            state.modal = {key, title, payload, closeOnOutsideClick}
        },
        CLOSE_MODAL: (state: any) => {
            state.modal = initialState.modal
        },
    },
})

export const {OPEN_DRAWER, CLOSE_DRAWER, OPEN_MODAL, CLOSE_MODAL} = branchesSlice.actions

export default branchesSlice.reducer
