import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from 'src/apis'
import { CategoriesItem, CategoriesStore } from './types'
import Fuse from "fuse.js"
export const initialState: CategoriesStore = {
  items: [],
  grouped: [],
  filteredItems: [],
  filters: {
    lvl: 1,
    search: '',
  },
  currentCount: 0,
  count: 0,
  loading: false,
  error: null,
  newCompanyForm: {},
  isSubmiting: false
}

export const getCategories = createAsyncThunk(
  'GET_CATEGORIES',
  async (payload: any = {}, thunkAPI: any) => {
    const {
      categories
    } = thunkAPI.getState()
    const filters = { ...categories.filters, ...payload }

    if (payload.search && payload.search !== categories.filters.search) {
      payload.page = 1

      const { data: ungrouped } = await Apis.categories.getCategories({
        ...filters,
        group: false
      })
      return { ungrouped, filters, grouped: [...categories.grouped] };
    } else {
      const [{ data: ungrouped }, { data: grouped }] = await Promise.all([Apis.categories.getCategories({
        ...filters,
        group: false
      }), Apis.categories.getCategories({ search: '', lvl: filters.lvl, group: true })])
      return { ungrouped, grouped: grouped.items, filters };
    }
  },
)

export const getOneCategory = createAsyncThunk(
  'GET_CATEGORY',
  async (payload: any = {}, thunkAPI: any) => {
    const {
      categories: { id },
    } = thunkAPI.getState();
    const { data } = await Apis.categories.getOneCategory(id)
    return { data }
  },
)

export const updateCategory = createAsyncThunk(
  'UPDATE_CATEGORY',
  async (payload: any = {}) => {
    // const { data } = await Apis.categories.updateCategory(payload);
    // return { data }
  },
)

export const createCategory = createAsyncThunk(
  'CREATE_CATEGORY',
  async (payload: any = {}) => {
    const { data } = await Apis.categories.createCategory(payload);
    return { data }
  },
)

const CategorySlice = createSlice({
  name: 'ATC',
  initialState,
  reducers: {
    SET_NEW_CATEGORIES_FORM: (state: CategoriesStore, action) => {
      state.newCompanyForm = action.payload
    },
    SET_FILTERS: (state: CategoriesStore, action) => {
      state.filters = { ...state.filters, ...action.payload }
    },
    SEARCH_CATEGORY: (state: CategoriesStore, { payload }: { payload: string }) => {
      const searchValue = payload.trim()
      if (searchValue.length) {
        const options = {
          includeScore: true,
          keys: ['name.ru', 'name.uz', 'name.en']
        }
        const fuse = new Fuse(state.items, options)
        const result = fuse.search(searchValue).map(itm => itm.item)
        state.filteredItems = result
        state.count = result.length
      } else {
        state.filteredItems = state.items
        state.count = state.items.length
      }
    },
    DELETE_CATEGORY: (state: CategoriesStore, { payload }: { payload: string }) => {
      state.count -= 1
      state.items = state.items.filter(itm => itm.id !== payload)
      state.filteredItems = state.items
    },
    UPDATE_CATEGORY: (state: CategoriesStore, { payload }: { payload: CategoriesItem }) => {
      for (let i = 0; i < state.items.length; i++) {
        if (state.items[i].id === payload.id) {
          state.items[i] = payload;
          break;
        }
      }
      state.filteredItems = state.items
    },
    CREATE_CATEGORY: (state: CategoriesStore, { payload }: { payload: CategoriesItem }) => {
      state.count += 1
      state.items.unshift(payload)
      state.filteredItems = state.items
    },
  },
  extraReducers: (builder) => {
    //getBranches
    builder.addCase(getCategories.pending, (state) => {
      state.error = null
      state.loading = true
    })
    builder.addCase(getCategories.fulfilled, (state, action) => {
      const {
        filters,
        ungrouped: { items },
        grouped
      } = action.payload
      return { ...state, filters, items, filteredItems: items, count: items.length, grouped, loading: false }
    })
    builder.addCase(getCategories.rejected, (state, action) => {
      state.error = action.error
      state.loading = false
    })
    //get one branch
    builder.addCase(getOneCategory.pending, (state, action) => {

    })
    builder.addCase(getOneCategory.fulfilled, (state, action) => {

    })
    builder.addCase(getOneCategory.rejected, (state, action) => {

    })
    //create branch
    builder.addCase(createCategory.pending, (state, action) => {
      state.isSubmiting = true;
      state.error = null;
    })
    builder.addCase(createCategory.fulfilled, (state, action) => {
      state.isSubmiting = false;
    })
    builder.addCase(createCategory.rejected, (state, action) => {
      state.isSubmiting = false;
      state.error = action.error;
    })
    builder.addCase(updateCategory.pending, (state, action) => {

    })
    builder.addCase(updateCategory.fulfilled, (state, action) => {

    })
    builder.addCase(updateCategory.rejected, (state, action) => {

    })
  },
})

export const { SEARCH_CATEGORY, CREATE_CATEGORY, DELETE_CATEGORY, UPDATE_CATEGORY } = CategorySlice.actions
export default CategorySlice.reducer;
