import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from 'src/apis'
import { HomeStore } from './types'


export const initialState: HomeStore = {
  loading: true,
  error: null,
  company: null,
  atc: null,
  release_form: null,
  product_variants: null,
  inn: null,
  dosage_form: null,
  manufacturer: null,
  company_user: null,
  product: null,
  customer: null,
  category: null,
  company_branch: null
}

export const getHome = createAsyncThunk(
  'GET_HOME',
  async () => {
    const { data } = await Apis.home.getHome()
    return data
  },
)

const HomeSlice = createSlice({
  name: 'HOME',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //getBranches
    builder.addCase(getHome.pending, (state, action) => {
      state.error = null
      state.loading = true
    })
    builder.addCase(getHome.fulfilled, (state, action) => {
      const { payload } = action
      state.company = payload.company
      state.atc = payload.atc
      state.release_form = payload.release_form
      state.product_variants = payload.product_variants
      state.inn = payload.inn
      state.dosage_form = payload.dosage_form
      state.manufacturer = payload.manufacturer
      state.company_user = payload.company_user
      state.product = payload.product
      state.customer = payload.customer
      state.category = payload.category
      state.company_branch = payload.company_branch
      state.loading = false
    })
    builder.addCase(getHome.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
    })
  },
})

export default HomeSlice.reducer;
