import React, { useState } from 'react';
import { NestedMenuItemStyled } from './styled';
import Box from "src/components/Box";
import Button from "src/components/Button";
import Add from "src/icons/fluency-regular/Add";
import { NestedMenuItemProps } from "src/components/NestedMenu/props";
import Subtract from "src/icons/fluency-regular/Subtract";

const NestedMenuItem = ({ label, children, hasChildren }: NestedMenuItemProps) => {
  const [open, setOpen] = useState(false)
  return (
    <NestedMenuItemStyled>
      <Box alignItems='center'>
        <Box height='40px' width='40px' alignItems='center'>
          {hasChildren && <Button onClick={() => setOpen(!open)} icon>{open ? <Subtract /> : <Add />}</Button>}
        </Box>
        <Box paddingLeft={10}>
          {label}
        </Box>
      </Box>
      {open && hasChildren && <>
          <Box padding={10} paddingLeft={50} display='block'>{children}</Box>
      </>}
    </NestedMenuItemStyled>
  );
};

export default NestedMenuItem;
