import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../common";
import {
    IsAuthenticatedResponse,
    LoginRequest,
    LoginResponse, LogoutRequest, LogoutResponse, RecoverPasswordRequest,
    RecoverPasswordResponse, VerifyRecoverPasswordRequest, VerifyRecoverPasswordResponse
} from "src/redux/API/authAPI/authAPI.types";


export const authAPI = createApi({
    reducerPath: 'authAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        login: builder.mutation<LoginResponse, LoginRequest>({
            query: (body) => ({
                url: `/v1/auth`,
                method: 'POST',
                body: body
            })
        }),
        isAuthenticated: builder.query<IsAuthenticatedResponse, void>({
            query: () => `/v1/auth/is-authenticated`
        }),
        recoverPassword: builder.mutation<RecoverPasswordResponse, RecoverPasswordRequest>({
            query: (payload) => ({
                url: `/v1/auth/recover-password`,
                method: 'POST',
                body: payload
            })
        }),
        verifyRecoverPassword: builder.mutation<VerifyRecoverPasswordResponse, VerifyRecoverPasswordRequest>({
            query: (body) => ({
                url: `/v1/auth/recover-password`,
                method: 'PUT',
                body: body
            })
        }),
        logout: builder.mutation<LogoutResponse, LogoutRequest>({
            query: (body) => ({
                url: `/v1/auth/logout`,
                method: 'DELETE',
                body: body
            })
        })
    }),

})

export const {
    useLoginMutation,
    useIsAuthenticatedQuery,
    useRecoverPasswordMutation,
    useVerifyRecoverPasswordMutation,
    useLogoutMutation
} = authAPI