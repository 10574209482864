import React from "react";

const Subtract = ({ size = 20, color = '#000' }: { size?: number, color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 20 18" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.7793 8.50359C2.7793 8.25702 3.00119 8.05716 3.27491 8.05716H16.5574C16.8311 8.05716 17.053 8.25702 17.053 8.50359C17.053 8.75016 16.8311 8.95002 16.5574 8.95002H3.27491C3.00119 8.95002 2.7793 8.75016 2.7793 8.50359Z" />
  </svg>


);

export default Subtract;
