import {createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from 'src/apis'
import { DiseasesStore } from './types';


export const initialState: DiseasesStore = {
    items: [],
    filters: {
        page: 1,
        limit: 100,
        search: '',
    },
    currentCount: 0,
    count: 0,
    loading: false,
    error: null,
    newCompanyForm: {},
    isSubmiting: false
}

export const getDiseases = createAsyncThunk(
        'GET_DISEASES',
    async (payload: any = {}, thunkAPI: any) => {
        const {
            diseases
        } = thunkAPI.getState()
        const filters = {...diseases.filters, ...payload}
        const {data} = await Apis.diseases.getDiseases({...filters})
        return {data, filters};
    },
)

export const getOneDisease = createAsyncThunk(
    'GET_DISEASE',
    async (payload: any = {}, thunkAPI: any) => {
        const {
            diseases: {id},
        } = thunkAPI.getState();
        const {data} = await Apis.diseases.getOneDisease(id)
        return {data}
    },
)

export const updateDisease = createAsyncThunk(
    'UPDATE_DISEASE',
    async (payload: any = {}) => {
        const {data} = await Apis.diseases.updateDisease(payload);
        return {data}
    },
)

export const createDisease = createAsyncThunk(
    'CREATE_DISEASE',
    async (payload: any = {}) => {
        const {data} = await Apis.diseases.createDisease(payload);
        return {data}
    },
)

export const deleteDisease = createAsyncThunk(
    'DELETE_DISEASE',
    async (payload: any = {}, thunkAPI: any) => {
        const {
            diseases: {id},
        } = thunkAPI.getState();
        const {data} = await Apis.diseases.deleteDisease(id)
        return {data}
    },
)

const DiseaseSlice = createSlice({
    name: 'diseases',
    initialState,
    reducers: {
        SET_NEW_DISEASE_FORM: (state: DiseasesStore, action) => {
            state.newCompanyForm = action.payload
        },
        SET_FILTERS: (state: DiseasesStore, action) => {
            state.filters = {...state.filters, ...action.payload}
        },
    },
    extraReducers: (builder) => {
        //getBranches
        builder.addCase(getDiseases.pending, (state, action) => {
            state.error = null
            state.loading = true
        })
        builder.addCase(getDiseases.fulfilled, (state, action) => {
            const {
                filters,
                data: {items, count},
            } = action.payload
            state.filters = filters
            state.items = [...state.items, ...items]
            state.count = count
            state.loading = false
            state.currentCount += items.length
        })
        builder.addCase(getDiseases.rejected, (state, action) => {
            state.loading = false
            state.error = action.error
        })
        //get one branch
        builder.addCase(getOneDisease.pending, (state, action) => {

        })
        builder.addCase(getOneDisease.fulfilled, (state, action) => {

        })
        builder.addCase(getOneDisease.rejected, (state, action) => {

        })
        //create branch
        builder.addCase(createDisease.pending, (state, action) => {
            state.isSubmiting = true;
            state.error = null;
        })
        builder.addCase(createDisease.fulfilled, (state, action) => {
            state.isSubmiting = false;
        })
        builder.addCase(createDisease.rejected, (state, action) => {
            state.isSubmiting = false;
            state.error = action.error;
        })
        builder.addCase(updateDisease.pending, (state, action) => {

        })
        builder.addCase(updateDisease.fulfilled, (state, action) => {

        })
        builder.addCase(updateDisease.rejected, (state, action) => {

        })
        builder.addCase(deleteDisease.pending, (state, action) => {

        })
        builder.addCase(deleteDisease.fulfilled, (state, action) => {

        })
        builder.addCase(deleteDisease.rejected, (state, action) => {

        })
    },
})

export default DiseaseSlice.reducer;
