import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import ArrowLeft from "src/icons/fluency-regular/ArrowLeft";
import { withProps } from "src/typed";

const MenuWrapper = styled.div`
  position: relative;
  display: block;
  height: 60px;
  width: 100%;
  border-bottom: solid 1px;
  border-color: ${p => p.theme.colors.palette.divider};

  .inner-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const HeaderWrapper = styled.div`

`

const HeaderTitle = styled.div`
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  align-self: center;
  justify-self: center;
  flex: 1;
  text-align: center;
`

const SelfCentered = styled.div`
  align-self: center;
`

type PrefixSuffixProps = {
  noPd?: boolean
}

const HeaderPrefixSuffix: any = withProps<PrefixSuffixProps>()(styled.div)`
  padding: 10px 20px;
  height: 100%;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  &.left {
   justify-content: flex-start;
  }
  &.right {
    justify-content: flex-end;
  }
  &.noPd {
    padding: 0;
  }
`

const BackBtnWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;

  :hover {
    background-color: ${p => p.theme.colors.palette.text.shade5};
  }
`

type Props = { children?: JSX.Element | JSX.Element[], title?: string, onBack?: () => void, prefix?: JSX.Element, noBack?: boolean, noPrefixPd?: boolean, noPrefixSuffix?: boolean }

const TopBar = React.memo(({
                  children,
                  title = "",
                  onBack,
                  prefix,
                  noBack,
                  noPrefixPd,
  noPrefixSuffix
                }: Props) => {

  const history = useHistory()

  return (
    <MenuWrapper>
      <div className="inner-wrapper">
        {!noPrefixSuffix && <HeaderPrefixSuffix className="left noPd">
          {!noBack && <BackBtnWrapper
              onClick={() => onBack && !noBack ? onBack() : !noBack && history.goBack()}><ArrowLeft
              size={24} /></BackBtnWrapper>}
          {children && <HeaderTitle style={{ textAlign: "left" }}>{title}</HeaderTitle>}
        </HeaderPrefixSuffix>}
        {children ? <SelfCentered>{children}</SelfCentered> : <HeaderTitle>{title}</HeaderTitle>}
        {!noPrefixSuffix && <HeaderPrefixSuffix className="right">
          {prefix}
        </HeaderPrefixSuffix>}
      </div>
    </MenuWrapper>
  );
})

export default TopBar;
