import React, { useState } from 'react';
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import styled from "styled-components";
import { HorizontalBox } from "src/components/HorizontalBox";
import Input, { ErrorStyled } from "src/components/Input";

const Header = styled.div`
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #2C2C2C;
`

const FormBox: any = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #E7E7E7;
  border-radius: ${p => p.theme.borderRadius.default}px;
  padding: 24px 32px 32px;
`;

const FormStyled = styled(Form)`
  display: flex;
  justify-content: space-between;
  width: 35%;

  & > * {
    flex: 1 1 auto; /* Positive flex-shrink */
    width: 100%;
  }

  & > *:first-child {
    margin-right: 12px;
  }

  & > *:last-child {
    margin-left: 12px;
  }
`

const Schema = Yup.object({
  name_Ru: Yup.string().max(16, 'Too long!').required('Обязательное поле'),
  name_uz: Yup.string().max(16, 'Too Long!').required('Обязательное поле'),
  name_eng: Yup.string().max(16, 'Too Long!').required('Обязательное поле'),
})

const NewSymptomForm = () => {

  const [state, setState] = useState({
    name_Ru: '',
    name_uz: '',
    name_eng: '',
  })

  return (
    <Formik
      initialValues={state}
      validationSchema={Schema}
      onSubmit={(values) => console.log(values)}
    >
      {({ values, errors, touched }) => (
        <FormStyled>
          <FormBox>
            <Header>Название</Header>
            <HorizontalBox>
              <Field
                name='name_Ru'
                value={values.name_Ru}
                label='Ru'
                component={Input}
                placeholder='Введите название'
              />
            </HorizontalBox>
            <HorizontalBox>
              <Field
                name='name_uz'
                value={values.name_uz}
                label='Uzb'
                component={Input}
                placeholder='Введите название'
              />
            </HorizontalBox>
            <HorizontalBox>
              <Field
                name='name_eng'
                value={values.name_eng}
                label='End'
                component={Input}
                placeholder='Введите название'
              />
            </HorizontalBox>
          </FormBox>
        </FormStyled>
      )}
    </Formik>
  )
}

export default NewSymptomForm;