import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import SidebarLayout from "src/layouts/SidebarLayout";
import { SideMenuItem } from "src/components/Menus/SideMenu";
import TopBar from "src/components/Menus/TopBar";
import Companies from "src/screens/company/Companies";
import Company from "src/screens/company/Company";
import CatalogIcon from "src/icons/fluency-regular/Catalog";
import CompanyIcon from "src/icons/fluency-regular/Company";
import ModeratorIcon from "src/icons/fluency-regular/Moderator";
import Moderators from "src/screens/moderator/Moderators";
import Moderator from "src/screens/moderator/Moderator";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import HomeIcon from "src/icons/fluency-regular/Home";
import Home from "src/screens/home";
import Restricted from "src/common/Restricted";
import useRestriction from "src/hooks/useRestriction";
import RestrictedRoute from "src/routes/RestrictedRoute";
import SideMenuUserBlock from "src/common/SideMenuUserBlock";
import { UserPermissionsEnum } from "src/common/permissions";
import Phone from "src/icons/fluency-regular/Phone";
import CollectionsRoutes from "./CollectionsRoutes";
import { CatalogRoutes } from "./catalog";

const MainRoutes = React.memo(() => {
  const location = useLocation()
  const { catalogMinPermissions, isPermitted, mobileMinPermissions } = useRestriction()

  const catalogSections = [
    { title: 'Продукты', to: '/catalog/products', permission: UserPermissionsEnum["product.read"] },
      { title: 'Варианты продукта', to: '/catalog/productVariants', permission: UserPermissionsEnum["product_variants.read"] },
    { title: 'Категории', to: '/catalog/categories', permission: UserPermissionsEnum["category.read"] },
    { title: 'Производители', to: '/catalog/manufacturers', permission: UserPermissionsEnum["manufacturer.read"] },
    { title: 'Лекарственные формы', to: '/catalog/dosageForms', permission: UserPermissionsEnum["dosage_form.read"] },
    { title: 'Формы упаковки', to: '/catalog/releaseForms', permission: UserPermissionsEnum["release_form.read"] },
    { title: 'ATX', to: '/catalog/atcs', permission: UserPermissionsEnum["atc.read"] },
    { title: 'МНН', to: '/catalog/inns', permission: UserPermissionsEnum["inn.read"] },
    { title: 'Импорт', to: '/catalog/import', permission: UserPermissionsEnum["product.create"] },
  ]

  const mobileSections = [
    { title: 'Подборки', to: `/mobile/collections`, permission: UserPermissionsEnum["collection.read"] },
  ]
  const permittedCatalogSections = catalogSections.filter((section: any) => isPermitted(section.permission))
  const permittedMobileSections = mobileSections.filter((section: any) => isPermitted(section.permission))

  return (
    <SidebarLayout
      backLink='/'
      mainHeader
      header={<TopBar title='Приход' noBack />}
      footer={<SideMenuUserBlock />}
      menuItems={[
        <SideMenuItem key='Главная' title='Главная' to='/' exact={true} icon={HomeIcon} />,
        <Restricted key='Каталог' anyOf={catalogMinPermissions}>
          <SideMenuItem exact key='Каталог' title='Каталог' to='/catalog' icon={CatalogIcon}
            items={permittedCatalogSections} />
        </Restricted>,
        <Restricted permission='company.read' key='Компании'>
          <SideMenuItem key='Компании' title='Компании' to='/company' icon={CompanyIcon} />
        </Restricted>,
        <Restricted key='Модераторы'>
          <SideMenuItem title='Модераторы' to='/moderators' icon={ModeratorIcon} />
        </Restricted>,
        <Restricted key='Мобильное приложение' anyOf={mobileMinPermissions}>
          <SideMenuItem key='Мобильное приложение' title='Мобильное приложение' to='/mobile' icon={Phone}
            items={permittedMobileSections} />
        </Restricted>
      ]}
    >
      <TransitionGroup style={{ height: '100%' }}>
        {/*
            This is no different than other usage of
            <CSSTransition>, just make sure to pass
            `location` to `Switch` so it can match
            the old location as it animates out.
          */}
        <CSSTransition
          style={{ height: '100%' }}
          key={location.pathname}
          classNames="fade"
          timeout={300}
        >
          <Switch location={location}>
            <Route exact path='/' component={Home} />
            <RestrictedRoute permission={UserPermissionsEnum["company.read"]} exact path='/company' component={Companies} />
            <RestrictedRoute permission={UserPermissionsEnum["company.read"]} exact path='/company/:id' component={Company} />
            <Route path='/catalog' component={CatalogRoutes} />
            <RestrictedRoute is_super exact path='/moderators' component={Moderators} />
            <RestrictedRoute is_super exact path='/moderators/:id' component={Moderator} />
            <RestrictedRoute permission={UserPermissionsEnum["collection.read"]} path='/mobile/collections/' component={CollectionsRoutes} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </SidebarLayout>
  )
})

export default MainRoutes
