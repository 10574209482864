import React from 'react';
import styled, { keyframes } from "styled-components";
import Box from "src/components/Box";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import Text from "src/components/Text";
import { fadeIn, pulse } from "react-animations";
import { logOut } from "src/redux/features/user";
import { Col, Row } from 'react-grid-system';
import { getHome } from 'src/redux/features/home';
import { HomeSelector } from 'src/redux/features/home/selectors';
import AnimatedNumbers from "react-animated-numbers"
import {useAdminsPermissionsQuery, useAdminsQuery} from "src/redux/API/adminAPI";
import {useStatsCountQuery} from "src/redux/API/statsAPI";
import {Typography, Button} from "@med-io/neural-ui";

const GridItem = styled.div`
  padding: 16px 24px;
  border-radius: ${p => p.theme.borderRadius.default}px;
  color: #fff;
  display: grid;
  grid-row-gap: 16px;
  min-width: 200px;
  margin-bottom: 24px;
  min-height: 104px;
`

const GridItemTitle = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
`

const GridItemCount = styled.p`
  font-weight: 400;
  font-size: 32px;
  line-height: 39px;
  color: #FBFBFB;
  margin: 0;
`

const animation = keyframes`${fadeIn}`;

const AnimatedBlock: any = styled(Box)`
  animation: 1s ${animation};
`;

const Wrapper = styled(Box)`
  & .col:nth-child(1) .item {
    background: linear-gradient(135deg, #FF6FD8 0%, #3813C2 100%);
  }

  & .col:nth-child(2) .item {
    background: linear-gradient(135deg, #0396FF 0%, #ABDCFF 100%);;
  }

  & .col:nth-child(3) .item {
    background: linear-gradient(135deg, #487CF2 10.94%, #29EAD1 100%);;
  }

  & .col:nth-child(4) .item {
    background: linear-gradient(135deg, #CE9FFC 0%, #7367F0 100%);;
  }

  & .col:nth-child(5) .item {
    background: linear-gradient(135deg, #43CBFF 0%, #9708CC 100%);;
  }

  & .col:nth-child(6) .item {
    background: linear-gradient(135deg, #858BF0 0%, #484EC7 100%);;
  }

  & .col:nth-child(7) .item {
    background: linear-gradient(135deg, #3C8CE7 0%, #00EAFF 100%);;
  }

  & .col:nth-child(8) .item {
    background: linear-gradient(135deg, #3989BB 0%, #1CDBCB 100%);;
  }

  & .col:nth-child(9) .item {
    background: linear-gradient(135deg, #A97FB5 0%, #941ABE 100%);;
  }

  & .col:nth-child(10) .item {
    background: linear-gradient(135deg, #3C8CE7 0%, #00EAFF 100%);;
  }

  & .col:nth-child(11) .item {
    background: linear-gradient(135deg, #3C8CE7 0%, #00EAFF 100%);;
  }

  & .col:nth-child(12) .item {
    background: linear-gradient(135deg, #CE9FFC 0%, #7367F0 100%);;
  }

`

const Home = () => {
  const { name, is_super } = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()
  const onSignOut = () => {
    // @ts-ignore
    dispatch(logOut())
  }
  return (
    <>
        <Wrapper display='flex' flexDirection='column' justifyContent='center' alignItems='center'
          width='100%'>
          <Statistics />
          <AnimatedBlock marginTop={30} display='flex' flexDirection='column' justifyContent='center' alignItems='center' height='100%'
            width='100%'>
            <Text fontSize={40}>Добро пожаловать</Text>
            <br />
            <Text fontSize={30}>{name}</Text>
            <Button size='sm' variant='secondaryGray' onClick={onSignOut} mt={2}>Выйти</Button>
          </AnimatedBlock>
        </Wrapper>
    </>
  )
};

export const Statistics = () => {
  const dispatch = useDispatch()
  const {data,isFetching} = useStatsCountQuery()
  return (
    <Wrapper
      overflowY="auto"
      display='flex'
      flexDirection='column'
      justifyContent='top'
      alignItems='center'
      width='100%'
      padding="24px"
    >
      {
        !isFetching &&
        <Row gutterWidth={24} style={{marginBottom: "-24px"}}>
          <Col className='col' md={6} lg={4} xl={3} xxl={2}>
            <GridItem className='item'>
              <Typography size='sm' color="base">Компании</Typography>
              <Typography color="base" component="div">
                <AnimatedNumbers
                    animateToNumber={data?.company || 0}
                    fontStyle={{ fontSize: 34, lineHeight: "34px" }}
                    configs={[
                      { mass: 1, tension: 200, friction: 50 },
                      { mass: 1, tension: 180, friction: 50 },
                      { mass: 1, tension: 160, friction: 50 },
                      { mass: 1, tension: 140, friction: 50 },
                      { mass: 1, tension: 120, friction: 50 },
                      { mass: 1, tension: 100, friction: 50 },
                    ]}
                />
              </Typography>
            </GridItem>
          </Col>
          <Col className='col' md={6} lg={4} xl={3} xxl={2}>
            <GridItem className='item'>
              <Typography color="base">Аптеки</Typography>
              <GridItemCount>
                <AnimatedNumbers
                  animateToNumber={data?.company_branch || 0}
                  fontStyle={{ fontSize: 40 }}
                  configs={[
                    { mass: 1, tension: 200, friction: 50 },
                    { mass: 1, tension: 180, friction: 50 },
                    { mass: 1, tension: 160, friction: 50 },
                    { mass: 1, tension: 140, friction: 50 },
                    { mass: 1, tension: 120, friction: 50 },
                    { mass: 1, tension: 100, friction: 50 },
                  ]}
                />
              </GridItemCount>
            </GridItem>
          </Col>
          <Col className='col' md={6} lg={4} xl={3} xxl={2}>
            <GridItem className='item'>
              <Typography color="base">Сотрудники аптек</Typography>
              <GridItemCount>
                <AnimatedNumbers
                  animateToNumber={data?.company_user || 0}
                  fontStyle={{ fontSize: 40 }}
                  configs={[
                    { mass: 1, tension: 200, friction: 50 },
                    { mass: 1, tension: 180, friction: 50 },
                    { mass: 1, tension: 160, friction: 50 },
                    { mass: 1, tension: 140, friction: 50 },
                    { mass: 1, tension: 120, friction: 50 },
                    { mass: 1, tension: 100, friction: 50 },
                  ]}
                />
              </GridItemCount>
            </GridItem>
          </Col>
          <Col className='col' md={6} lg={4} xl={3} xxl={2}>
            <GridItem className='item'>
              <Typography color="base">Потребители</Typography>
              <GridItemCount>
                <AnimatedNumbers
                  animateToNumber={data?.customer || 0}
                  fontStyle={{ fontSize: 40 }}
                  configs={[
                    { mass: 1, tension: 200, friction: 50 },
                    { mass: 1, tension: 180, friction: 50 },
                    { mass: 1, tension: 160, friction: 50 },
                    { mass: 1, tension: 140, friction: 50 },
                    { mass: 1, tension: 120, friction: 50 },
                    { mass: 1, tension: 100, friction: 50 },
                  ]}
                />
              </GridItemCount>
            </GridItem>
          </Col>
          <Col className='col' md={6} lg={4} xl={3} xxl={2}>
            <GridItem className='item'>
              <Typography color="base">Продукты</Typography>
              <GridItemCount>
                <AnimatedNumbers
                  animateToNumber={data?.product || 0}
                  fontStyle={{ fontSize: 40 }}
                  configs={[
                    { mass: 1, tension: 200, friction: 50 },
                    { mass: 1, tension: 180, friction: 50 },
                    { mass: 1, tension: 160, friction: 50 },
                    { mass: 1, tension: 140, friction: 50 },
                    { mass: 1, tension: 120, friction: 50 },
                    { mass: 1, tension: 100, friction: 50 },
                  ]}
                />
              </GridItemCount>
            </GridItem>
          </Col>
          <Col className='col' md={6} lg={4} xl={3} xxl={2}>
            <GridItem className='item'>
              <Typography color="base">Варианты продуктов</Typography>
              <GridItemCount>
                <AnimatedNumbers
                  animateToNumber={data?.product_variants || 0}
                  fontStyle={{ fontSize: 40 }}
                  configs={[
                    { mass: 1, tension: 200, friction: 50 },
                    { mass: 1, tension: 180, friction: 50 },
                    { mass: 1, tension: 160, friction: 50 },
                    { mass: 1, tension: 140, friction: 50 },
                    { mass: 1, tension: 120, friction: 50 },
                    { mass: 1, tension: 100, friction: 50 },
                  ]}
                />
              </GridItemCount>
            </GridItem>
          </Col>
          <Col className='col' md={6} lg={4} xl={3} xxl={2}>
            <GridItem className='item'>
              <Typography color="base">Прозиводители</Typography>
              <GridItemCount>
                <AnimatedNumbers
                  animateToNumber={data?.manufacturer || 0}
                  fontStyle={{ fontSize: 40 }}
                  configs={[
                    { mass: 1, tension: 200, friction: 50 },
                    { mass: 1, tension: 180, friction: 50 },
                    { mass: 1, tension: 160, friction: 50 },
                    { mass: 1, tension: 140, friction: 50 },
                    { mass: 1, tension: 120, friction: 50 },
                    { mass: 1, tension: 100, friction: 50 },
                  ]}
                />
              </GridItemCount>
            </GridItem>
          </Col>
          <Col className='col' md={6} lg={4} xl={3} xxl={2}>
            <GridItem className='item'>
              <Typography color="base">АТХ</Typography>
              <GridItemCount>
                <AnimatedNumbers
                  animateToNumber={data?.atc || 0}
                  fontStyle={{ fontSize: 40 }}
                  configs={[
                    { mass: 1, tension: 200, friction: 50 },
                    { mass: 1, tension: 180, friction: 50 },
                    { mass: 1, tension: 160, friction: 50 },
                    { mass: 1, tension: 140, friction: 50 },
                    { mass: 1, tension: 120, friction: 50 },
                    { mass: 1, tension: 100, friction: 50 },
                  ]}
                />
              </GridItemCount>
            </GridItem>
          </Col>
          <Col className='col' md={6} lg={4} xl={3} xxl={2}>
            <GridItem className='item'>
              <Typography size='sm' color="base">Лекарственные формы</Typography>
              <GridItemCount>
                <AnimatedNumbers
                  animateToNumber={data?.dosage_form || 0}
                  fontStyle={{ fontSize: 34, lineHeight: "34px" }}
                  configs={[
                    { mass: 1, tension: 200, friction: 50 },
                    { mass: 1, tension: 180, friction: 50 },
                    { mass: 1, tension: 160, friction: 50 },
                    { mass: 1, tension: 140, friction: 50 },
                    { mass: 1, tension: 120, friction: 50 },
                    { mass: 1, tension: 100, friction: 50 },
                  ]}
                />
              </GridItemCount>
            </GridItem>
          </Col>
          <Col className='col' md={6} lg={4} xl={3} xxl={2}>
            <GridItem className='item'>
              <Typography color="base">Формы упаковки</Typography>
              <GridItemCount>
                <AnimatedNumbers
                  animateToNumber={data?.release_form || 0}
                  fontStyle={{ fontSize: 40 }}
                  configs={[
                    { mass: 1, tension: 200, friction: 50 },
                    { mass: 1, tension: 180, friction: 50 },
                    { mass: 1, tension: 160, friction: 50 },
                    { mass: 1, tension: 140, friction: 50 },
                    { mass: 1, tension: 120, friction: 50 },
                    { mass: 1, tension: 100, friction: 50 },
                  ]}
                />
              </GridItemCount>
            </GridItem>
          </Col>
          <Col className='col' md={6} lg={4} xl={3} xxl={2}>
            <GridItem className='item'>
              <Typography color="base">МНН</Typography>
              <GridItemCount>
                <AnimatedNumbers
                  animateToNumber={data?.inn || 0}
                  fontStyle={{ fontSize: 40 }}
                  configs={[
                    { mass: 1, tension: 200, friction: 50 },
                    { mass: 1, tension: 180, friction: 50 },
                    { mass: 1, tension: 160, friction: 50 },
                    { mass: 1, tension: 140, friction: 50 },
                    { mass: 1, tension: 120, friction: 50 },
                    { mass: 1, tension: 100, friction: 50 },
                  ]}
                />
              </GridItemCount>
            </GridItem>
          </Col>
          <Col className='col' md={6} lg={4} xl={3} xxl={2}>
            <GridItem className='item'>
              <Typography color="base">Категории</Typography>
              <GridItemCount>
                <AnimatedNumbers
                  animateToNumber={data?.category || 0}
                  fontStyle={{ fontSize: 40 }}
                  configs={[
                    { mass: 1, tension: 200, friction: 50 },
                    { mass: 1, tension: 180, friction: 50 },
                    { mass: 1, tension: 160, friction: 50 },
                    { mass: 1, tension: 140, friction: 50 },
                    { mass: 1, tension: 120, friction: 50 },
                    { mass: 1, tension: 100, friction: 50 },
                  ]}
                />
              </GridItemCount>
            </GridItem>
          </Col>
        </Row>
      }
    </Wrapper>
  )
}

export default Home;
