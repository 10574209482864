import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from 'src/apis'
import { ModeratorsItem, ModeratorsStore } from './types'
import Fuse from 'fuse.js'

export const initialState: ModeratorsStore = {
  items: [],
  filteredItems: [],
  filters: {
    page: 1,
    limit: 10000,
    search: ''
  },
  currentCount: 0,
  count: 0,
  loading: false,
  error: null,
  newCompanyForm: {},
  isSubmiting: false
}

export const getModerators = createAsyncThunk(
  'GET_MODERATORS',
  async (payload: any = {}, thunkAPI: any) => {
    const {
      moderators
    } = thunkAPI.getState()
    if (payload.search && payload.search !== moderators.filters.search) {
      payload.page = 1
    }
    const filters = { ...moderators.filters, ...payload }
    const { data } = await Apis.moderators.getModerators({ ...filters })
    return { data, filters, reload: !!payload.reload };
  },
)

export const getOneModerator = createAsyncThunk(
  'GET_MODERATOR',
  async (payload: any = {}, thunkAPI: any) => {
    const {
      manufacturers: { id },
    } = thunkAPI.getState();
    const { data } = await Apis.moderators.getOneModerator(id)
    return { data }
  },
)

export const updateModerator = createAsyncThunk(
  'UPDATE_MODERATOR',
  async (payload: any = {}) => {
    // const { data } = await Apis.moderators.updateModerator(payload);
    // return { data }
  },
)

export const createModerator = createAsyncThunk(
  'CREATE_MODERATOR',
  async (payload: any = {}) => {
    const { data } = await Apis.moderators.createModerator(payload);
    return { data }
  },
)

const ManufacturerSlice = createSlice({
  name: 'MODERATORS',
  initialState,
  reducers: {
    SET_NEW_MANUFACTURERS_FORM: (state: ModeratorsStore, action) => {
      state.newCompanyForm = action.payload
    },
    SET_FILTERS: (state: ModeratorsStore, action) => {
      state.filters = { ...state.filters, ...action.payload }
    },
    SEARCH_MODERATORS: (state: ModeratorsStore, { payload }) => {
      const options = {
        includeScore: true,
        keys: ['name', 'surname', 'patronymic']
      }
      const fuse = new Fuse(state.items, options)
      const result = fuse.search(payload).map(itm => itm.item)
      state.filteredItems = result
    },
    DELETE_MODERATOR: (state: ModeratorsStore, {payload}: {payload: string}) => {
      state.count -= 1
      state.items = state.items.filter(itm => itm.id !== payload)
      state.filteredItems = state.items
    },
    UPDATE_MODERATOR: (state: ModeratorsStore, {payload}: {payload: ModeratorsItem}) => {
      for(let i = 0; i < state.count; i++) {
        if(state.items[i].id === payload.id){
          state.items[i] = payload;
          break;
        }
      }
      state.filteredItems = state.items
    },
    CREATE_MODERATOR: (state: ModeratorsStore, {payload}: {payload: ModeratorsItem}) => {
      state.items.unshift(payload)
      state.count += 1
      state.filteredItems = state.items
    },
  },
  extraReducers: (builder) => {
    //getBranches
    builder.addCase(getModerators.pending, (state, action) => {
      state.error = null
      state.loading = true
    })
    builder.addCase(getModerators.fulfilled, (state, action) => {
      const {
        filters,
        data: { items, count },
        reload
      } = action.payload
      if (state.filters.search !== filters.search || reload) {
        state.items = items
      } else {
        state.items = [...state.items, ...items]
      }
      state.filters = filters
      state.count = count
      state.loading = false
      state.currentCount = state.items.length
    })
    builder.addCase(getModerators.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
    })
    //get one branch
    builder.addCase(getOneModerator.pending, (state, action) => {

    })
    builder.addCase(getOneModerator.fulfilled, (state, action) => {

    })
    builder.addCase(getOneModerator.rejected, (state, action) => {

    })
    //create branch
    builder.addCase(createModerator.pending, (state, action) => {
      state.isSubmiting = true;
      state.error = null;
    })
    builder.addCase(createModerator.fulfilled, (state, action) => {
      state.isSubmiting = false;
    })
    builder.addCase(createModerator.rejected, (state, action) => {
      state.isSubmiting = false;
      state.error = action.error;
    })
    builder.addCase(updateModerator.pending, (state, action) => {

    })
    builder.addCase(updateModerator.fulfilled, (state, action) => {

    })
    builder.addCase(updateModerator.rejected, (state, action) => {

    })
  },
})

export const { SEARCH_MODERATORS, DELETE_MODERATOR, UPDATE_MODERATOR, CREATE_MODERATOR } = ManufacturerSlice.actions
export default ManufacturerSlice.reducer;
