import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import {  Field, Form, Formik, FormikProps } from "formik";
import { HorizontalBox } from "src/components/HorizontalBox";
import Input, { ErrorStyled } from "src/components/Input";
import * as Yup from "yup";
import Apis from "src/apis";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Confirm from "src/components/Confirm";
import Text from "src/components/Text";
import { useRestriction } from "src/hooks";
import { UserPermissionsEnum } from "src/common/permissions";
import { ProductVariantsList } from '../products/ProductVariant/ProductVariantsList';
import {
  useCreateOneReleaseMutation,
  useDeleteReleaseByIdMutation, useReleaseByIdQuery,
  useUpdateReleaseByIdMutation
} from "src/redux/API/releaseAPI";
import Spinner from "src/components/Spinner";
import {layouts, Button, Box, FormikInput, Typography} from "@med-io/neural-ui";
import {SectionWithHeader} from "src/components/SectionWithHeader";
const {TopBarLayout} = layouts

const FormBox = styled.div`
  height: 100%;
  border: 1px solid #E7E7E7;
  border-radius: ${p => p.theme.borderRadius.default}px;
`;

const ProductFlex = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 24px;
  height: 100%;
`

const Schema = Yup.object({
  name: Yup.object().shape({
    ru: Yup.string().required('Обязательное поле'),
    uz: Yup.string().required('Обязательное поле'),
    en: Yup.string().nullable(true),
  })
})


const ReleaseForm = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  const formRef = useRef<FormikProps<any>>(null)
  const { state: { releaseForm: editedData = {}, historyBack } = {} } = useLocation<any>() || null
  const { params: { id } } = useRouteMatch<any>()
  const isEditing = id !== 'new';
  const [updateReleaseForm, {isLoading: isUpdating}] = useUpdateReleaseByIdMutation()
  const [createReleaseForm, {isLoading: isCreating}] = useCreateOneReleaseMutation()
  const {data: dataById, isFetching} = useReleaseByIdQuery({id}, {
    skip: !isEditing
  })
  const [deleteReleaseForm, {isLoading}] = useDeleteReleaseByIdMutation()
  const [state, setState] = useState({
    name: {
      uz: '',
      ru: '',
      en: ''
    }
  })

  const getReleaseForm = async (releaseFormId = id) => {
    const { data } = await Apis.releaseForms.getOneReleaseForm(releaseFormId)
    setState(data)
  }

  useEffect(() => {
    if (isEditing) {
      if (!!Object.keys(editedData).length) {
        setState({ name: editedData.name })
      } else {
        getReleaseForm()
      }
    }
  }, [])

  const onSubmit = async (values: any, actions: any) => {
    actions.setSubmitting(true)
    if (isEditing) {
      updateReleaseForm({id,...values})
      toast.success('Форма выпуска обновлена')
      history.replace({ pathname: `/catalog/releaseForms/${id}`, state: { releaseForm: values, historyBack: false } })
    } else {
      const {data}:any = createReleaseForm(values)
      toast.success('Форма выпуска создана')
      if (data) {
        history.replace({ pathname: `/catalog/releaseForms/${data.id}`, state: { releaseForm: values, historyBack: false } })
      }
    }
  }

  const handleDelete = async () => {
    if (formRef.current) {
      formRef.current.setSubmitting(true)
      deleteReleaseForm({id})
      formRef.current.setSubmitting(false)
      toast.success('Удалено')
      history.goBack()
    }
  }

  const { isPermitted } = useRestriction()

  const isAllowed = isEditing && isPermitted(UserPermissionsEnum["release_form.update"]) || !isEditing && isPermitted(UserPermissionsEnum["release_form.create"])

  if (isFetching) {
    return <Spinner size={15}/>
  }

  const initialValues = {
    name: {
      uz: isEditing ? dataById?.name.uz : '',
      ru: isEditing ? dataById?.name.ru : '',
      en: isEditing ? dataById?.name.en : null
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Schema}
      enableReinitialize
      innerRef={formRef}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <TopBarLayout
          title={isEditing ? values.name.ru : 'Новая форма выпуска'}
          disableBodyOverflow
          onBack={() => historyBack ? history.goBack() : history.push('/catalog/releaseForms')}
          topBarActions={<>
            {isEditing && isPermitted(UserPermissionsEnum["release_form.delete"]) && <Confirm title='Удаление модератора'
              okButton={<Button ml={1.5} type='button' destructive variant='primary' size='md'>Удалить</Button>}
              description={
                <Box display='block' w='300px'>
                  <Typography>Вы действительно
                    хотите
                    удалить
                    данного модератора?</Typography>
                </Box>
              }
              onConfirm={handleDelete}>
              <Button mr={1.5} type='button' variant='tertiaryGray' size='md'>Удалить</Button>
            </Confirm>}
            {
              isAllowed && <Button type='submit' onClick={() => handleSubmit()} variant='primary' size='md'>Сохранить</Button>
            }
          </>
          }
        >
          <Box display='flex' h='85vh' gap={1.5}>
            <Box w='30%'>
              <SectionWithHeader title='Данные формы упаковки'>
                <FormikInput name='name.ru' label='Название (Ru)' placeholder='Введите название' size='sm' fullWidth/>
                <FormikInput name='name.uz' label='Название (Uz)' placeholder='Введите название' size='sm' fullWidth/>
                <FormikInput name='name.en' label='Название (En)' placeholder='Введите название' size='sm' fullWidth/>
              </SectionWithHeader>
            </Box>
            <Box w='67.5%'>
              {isEditing && <ProductVariantsList title='Варианты продукта' filter_by='dosage_form_ids' id={id}/>}
            </Box>
          </Box>
        </TopBarLayout>
      )}
    </Formik>
  )
}

export default ReleaseForm;
