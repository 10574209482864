import React from "react";
import styled, { css, keyframes, useTheme } from "styled-components";

import Box from "src/components/Box";
import IconLoader from "src/icons/Loader";
import type { ThemedComponent } from "src/styles/StyleProvider";
import { Theme } from "src/styles/theme";

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

type StyledType = ThemedComponent<{ size: number, isRotating?: boolean }>

export const Rotating: StyledType = styled(Box)`
  width: ${(p: StyledType) => p.size}px;
  height: ${p => p.size}px;
  animation: ${p =>
          p.isRotating === false
                  ? "none"
                  : css`
                    ${rotate} 1s linear infinite
                  `};
  transition: 100ms linear transform;
`;

export default function Spinner({ size, ...props }: { size: number, isRotating?: boolean, color?: string }) {
  const theme: Theme = useTheme() as Theme
  return (
    <Rotating {...props} size={size}>
      <IconLoader size={size} color={props.color ? props.color : theme.colors.palette.primary.main} />
    </Rotating>
  );
}
