import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../common";
import {
    CreateDiseaseRequest,
    CreateDiseaseResponse,
    DiseasesRequest,
    DiseasesResponse, UpdateDiseaseRequest, UpdateDiseaseResponse,
    DeleteDiseaseRequest, DeleteDiseaseResponse,
    DiseaseByIdResponse, DiseaseByIdRequest
} from "src/redux/API/diseasesAPI/diseasesAPI.types";


export const diseasesAPI = createApi({
    reducerPath: 'diseasesAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        diseases: builder.query<DiseasesResponse, DiseasesRequest>({
            query: ({search, limit, page}) => `/v1/diseases?search=${search}&limit=${limit}&page=${page}`
        }),
        createDisease: builder.mutation<CreateDiseaseResponse, CreateDiseaseRequest>({
            query: (payload) => ({
                url: `/v1/diseases`,
                method: 'POST',
                body: payload
            })
        }),
        updateDisease: builder.mutation<UpdateDiseaseResponse, UpdateDiseaseRequest>({
            query: ({id, ...body}) => ({
                url: `/v1/diseases/${id}`,
                method: 'PUT',
                body: body
            })
        }),
        diseaseById: builder.query<DiseaseByIdResponse, DiseaseByIdRequest>({
            query: ({id}) => `/v1/diseases/${id}`
        }),
        deleteDisease: builder.mutation<DeleteDiseaseResponse, DeleteDiseaseRequest>({
            query: ({id}) => ({
                url: `/v1/category/${id}`,
                method: 'DELETE'
            })
        })
    }),
})

export const {
    useDiseasesQuery,
    useCreateDiseaseMutation,
    useUpdateDiseaseMutation,
    useDiseaseByIdQuery,
    useDeleteDiseaseMutation
} = diseasesAPI