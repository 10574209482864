import httpService from "src/apis/httpService";
import {
  createProduct,
  createProductCollection,
  createProductVariant,
  getProductCollections,
  getProducts,
  getProductVariants,
  updateProduct,
  updateProductCollection,
  updateProductCollectionImage,
  updateProductVariant,
  updateProductVariantImages,
  updateProductVariantInstructions
} from "./types";

export default {
  // TODO: Add request abort to all requests in the entire app.
  //  All requests will receive restConfigs param which is spread in config after params. restConfigs will include signal.
  //  E.g.:

//     const controller = new AbortController();
//
//     axios.get('/foo/bar', {
//         signal: controller.signal
//     }).then(function(response) {
//         //...
//     });
// // cancel the request
//     controller.abort()

//   getProducts(params: getProducts, restConfigs: any) {
//     return httpService.get('/v1/product', { params, ...restConfigs })
//   }

  // product
  getProducts(params: getProducts) {
    return httpService.get("/v1/product", { params });
  },
  getOneProduct(id: string) {
    return httpService.get(`/v1/product/${id}`);
  },
  createProduct(body: createProduct) {
    return httpService.post("/v1/product", body);
  },
  updateProduct(body: updateProduct, id: string) {
    return httpService.put(`/v1/product/${id}`, body);
  },
  deleteProduct(id: string) {
    return httpService.delete(`/v1/product/${id}`);
  },

  // product variant
  getProductVariants(params: getProductVariants) {
    return httpService.get("/v1/product/variants", { params });
  },
  getOneProductVariant(id: string) {
    return httpService.get(`/v1/product/variants/${id}`);
  },
  createProductVariant(body: createProductVariant) {
    return httpService.post("/v1/product/variants", body);
  },
  updateProductVariant(body: updateProductVariant, id: string) {
    return httpService.put(`/v1/product/variants/${id}`, body);
  },
  updateProductVariantInstructions(body: updateProductVariantInstructions, id: string) {
    return httpService.put(`/v1/product/variants/${id}/instructions`, body);
  },
  updateProductVariantImages(id: string, body: updateProductVariantImages) {
    return httpService.put(`/v1/product/variants/${id}/images`, body);
  },
  deleteProductVariant(id: string) {
    return httpService.delete(`/v1/product/variants/${id}`);
  },

  // product collection
  getProductCollections(params: getProductCollections) {
    return httpService.get("/v1/product/collection", { params });
  },
  getOneProductCollection(id: string) {
    return httpService.get(`/v1/product/collection/${id}`);
  },
  createProductCollection(body: createProductCollection) {
    return httpService.post("/v1/product/collection", body);
  },
  updateProductCollection(id: string, body: updateProductCollection) {
    return httpService.put(`/v1/product/collection/${id}`, body);
  },
  updateProductCollectionImage(id: string, body: updateProductCollectionImage) {
    return httpService.put(`/v1/product/collection/${id}/image`, body);
  },
  deleteProductCollection(id: string) {
    return httpService.delete(`/v1/product/collection/${id}`);
  },
  importProducts(items: any[]) {
    return httpService.post("/v1/product/import", { items });
  }
};
