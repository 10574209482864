import React, {useEffect} from 'react';
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import styled from "styled-components";
import Box from "src/components/Box";
import NestedMenu from "src/components/NestedMenu/NestedMenu";
import Text from "src/components/Text";
import UpdateATCsBtn from "src/screens/catalog/atc/UpdateATCsBtn";
import {useATCsGroupQuery} from "src/redux/API/atcClassAPI";
import {layouts, Typography} from "@med-io/neural-ui";
const {TopBarLayout} = layouts

export const InnerColumnWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  & > *:last-child {
    flex: 1;
  }
`;

const LinkStyled = styled(Link)`
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.colors.palette.primary.main}
  }
`;

const ATCsTree = () => {
    const {data, isFetching} = useATCsGroupQuery({})
    const history = useHistory()

    const {path} = useRouteMatch();

    return (
        <TopBarLayout
            title="АТХ древо"
            isContentLoading={isFetching}
            topBarActions={<UpdateATCsBtn/>}
            onBack={() => history.goBack()}
        >
            <InnerColumnWrapper>
                <Box display='block'>
                    <NestedMenu
                        data={data?.items || []}
                        labelGetter={(data: any) => (
                            <LinkStyled to={`/catalog/atcs/${data.id}`} target="_blank" rel="noopener noreferrer">
                                <Box alignItems='center'>
                                    <Box paddingRight={20}>
                                        <Typography>{data.full_code}</Typography>
                                    </Box>
                                    <Typography>{data?.name?.ru}</Typography>
                                </Box>
                            </LinkStyled>
                        )}
                        childGetter={(data: any) => data?.children}
                    />
                </Box>
            </InnerColumnWrapper>
        </TopBarLayout>
    )
}

export default ATCsTree;
