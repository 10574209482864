import React from 'react';
import {useDispatch} from 'react-redux';
import Button from "src/components/Button";
import ArrowClockwise from "src/icons/fluency-regular/ArrowClockwise";
import {categoryAPI} from "src/redux/API/categoryAPI";

const UpdateATCbtn = () => {
    const dispatch = useDispatch();
    const onClick = () => dispatch(categoryAPI.util.invalidateTags(['CATEGORY']))
    return <Button onClick={onClick} icon><ArrowClockwise/></Button>;
};

export default UpdateATCbtn;