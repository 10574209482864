import React from 'react';
import { Box, Divider, Typography } from "@med-io/neural-ui";
import styled from "styled-components";

export type SectionWithHeaderProps = {
    title: string | React.ReactNode
    actions?: React.ReactNode | React.ReactNode[]
    children: React.ReactNode | React.ReactNode[]
};

const DividerStyled = styled(Divider)`
    margin-top: 0 !important;
`;

export const SectionWithHeader = ({ title, actions, children }: SectionWithHeaderProps) => {
    return (
        <Box w='100%' h='100%'>

            <Box pl={0.5} h='50px' w='100%' display='flex'>
                <Box w='100%' h='100%'>
                    <Box h='100%' display='flex' alignItems='center' justifyContent='space-between'>
                        <Typography component='div' size='md' weight='semiBold'>{title}</Typography>
                        <Box>
                            {actions}
                        </Box>
                    </Box>
                    <Box>
                        <DividerStyled />
                    </Box>
                </Box>
            </Box>

            <Box w='100%' h='100%' pl={0.5} pt={1} pb={1} overflowY='auto' position='relative' >
                {children}
            </Box>

        </Box>
    );
};
