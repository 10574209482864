import React, { useRef, useState } from 'react';
import { HorizontalBox } from "src/components/HorizontalBox";
import { ErrorMessage, Field, Form, Formik, FormikProps, useFormikContext } from "formik";
import Switch from "src/components/Switch";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import * as Yup from 'yup';
import SubSectionHeader from "src/components/SubSectionHeader";
import Permissions from "src/screens/moderator/Permissions";
import Confirm from "src/components/Confirm";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useModerators } from "src/hooks";
import {layouts, Box, FormikInput, Button, Typography} from "@med-io/neural-ui";
import {SectionWithHeader} from "src/components/SectionWithHeader";
import {
  useAdminsByIdQuery,
  useCreateAdminMutation,
  useDeleteAdminByIdMutation,
  useUpdateAdminByIdMutation
} from "src/redux/API/adminAPI";
const {TopBarLayout} = layouts;

const Wrapper = styled.div`
  display: flex;
  min-width: 0;
  height: 100%;
`

const StyledBox = styled(Box)`
  display: block;
  overflow-y: auto;
  height: 100%;
  padding: 24px;
`

const FormStyled = styled(Form)`
  display: grid;
  grid-template-columns: 300px 1fr;
  width: 100%;
  gap: 24px;
`

const FormBox = styled.div`
  height: 100%;
  border: 1px solid #E7E7E7;
  border-radius: ${p => p.theme.borderRadius.default}px;
  overflow-x: hidden;
`;

const Moderator = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const formRef = useRef<FormikProps<any>>(null)
  const { params: { id } } = useRouteMatch<any>()
  const isEditing = id !== 'new';
  const [updateAdmin] = useUpdateAdminByIdMutation()
  const  [createAdmin, {isLoading,data}] = useCreateAdminMutation()
  const [deleteAdmin] = useDeleteAdminByIdMutation();
  const {data: dataById, isFetching, isError} = useAdminsByIdQuery({id}, {
    skip: !isEditing
  })

  const Schema = Yup.object().shape({
    name: Yup.string().max(20).required('Обязательное поле!'),
    surname: Yup.string().max(20).required('Обязательное поле!'),
    patronymic: Yup.string().max(20).required('Обязательное поле!'),
    email: Yup.string().email().required('Обязательное поле!'),
    password: isEditing ? Yup.string().min(8, 'Минимум 8 символов').max(16, 'Максимум 16 символов').nullable() : Yup.string().min(8, 'Минимум 8 символов').max(16, 'Максимум 16 символов').required('Обязательное поле!'),
    is_super: Yup.boolean().required('Обязательное поле!'),
    permissions: Yup.array()
      .of(
        Yup.string()
      ),
  })

  const {  deleteItem } = useModerators(isEditing ? { id } : {})

  const onSubmit = async (values: any) => {
    if (isEditing) {
      await updateAdmin({id,...values})
      toast.success('Модератор обновлен')
    } else {
      values.confirm_password = values.password
      const {data}: any = await createAdmin(values)
      toast.success('Модератор создан')
      if (data) {
        history.push({ pathname: `/moderators/${data.id}`, state: { data, historyBack: false } })
      }
    }
    if (values.is_super && formRef.current) {
      formRef.current.setFieldValue('permissions', [])
    }
  }
  const handleDelete = async () => {
    if (formRef.current) {
      formRef.current.setSubmitting(true)
      deleteAdmin({id})
      history.goBack()
      formRef.current.setSubmitting(false)
      toast.success('Удалено')
    }
  }

  const initialValues = {
    avatar: isEditing ? dataById?.avatar : null,
    created_at: isEditing ? dataById?.created_at : new Date(),
    email: isEditing ? dataById?.email : '',
    id: isEditing ? dataById?.id : '',
    is_super: isEditing ? dataById?.is_super : false,
    name: isEditing ? dataById?.name : '',
    patronymic: isEditing ? dataById?.patronymic : '',
    permissions: isEditing ? dataById?.permissions : [],
    surname: isEditing ? dataById?.surname : '',
    updated_at:  null
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={Schema}
      innerRef={formRef}
    >
      {({ handleSubmit, setFieldValue, values, isSubmitting, errors }) => {
        return (
          <TopBarLayout
            title={isEditing ? `${dataById?.is_super ? 'Супер админ' : 'Модератор'} - ${values.name} ${values.surname} ${values.patronymic}` : 'Новый модератор'}
            onBack={() => history.push('/moderators')}
            isContentLoading={isFetching}
            isError={isError}
            disableBodyOverflow
            topBarActions={<>
              {isEditing && <Confirm title='Удаление модератора'
                okButton={<Button ml={1.5} type='button' destructive variant='primary' size='md'>Удалить</Button>}
                description={
                <Box display='block' w='300px'>
                  <Typography>
                    Вы действительно
                    хотите
                    удалить
                    данного модератора?
                  </Typography>
                </Box>}
                onConfirm={handleDelete}>
                <Button mr={1.5} type='button' variant='tertiaryGray' size='md'>Удалить</Button>
              </Confirm>}
              <Button type='submit' loading={isSubmitting} onClick={() => handleSubmit()} variant='primary' size='md'>Сохранить</Button>
            </>}
          >
            <Box display='flex' h='85vh' gap={1.5}>
              <Box w='30%'>
                <SectionWithHeader title='Данные модератора'>
                  <SubSectionHeader>Личные данные</SubSectionHeader>
                  <FormikInput name='name' label='Имя' placeholder='Введите Имя' size='sm' fullWidth />
                  <FormikInput name='surname' label='Фамилия' placeholder='Введите Фамилию' size='sm' fullWidth/>
                  <FormikInput name='patronymic' label='Отчество' placeholder='Введите отчество' size='sm' fullWidth/>
                  <Box  display='block' w='100%'>
                    <SubSectionHeader>Данные для входа</SubSectionHeader>
                  </Box>
                  <FormikInput name='email' label='Электронная почта' placeholder='Введите e-mail' size='sm' fullWidth/>
                  <FormikInput name='password' label='Пароль' placeholder='Введите ваш пароль' size='sm' fullWidth required={!isEditing} type='password'/>
                </SectionWithHeader>
              </Box>
              <Box w='67.5%'>
                <Permissions onChange={(permissions) => setFieldValue('permissions', permissions)}
                             permissions={values.permissions} disabled={dataById?.is_super} />

              </Box>
            </Box>
          </TopBarLayout>
        )
      }}
    </Formik>
  )
}

export default Moderator;