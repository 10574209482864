import React, { useRef} from 'react';
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import styled from "styled-components";
import {Field, Form, Formik, FormikProps} from "formik";
import {HorizontalBox} from "src/components/HorizontalBox";
import Input from "src/components/Input";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {useRestriction} from "src/hooks";
import {UserPermissionsEnum} from "src/common/permissions";
import Confirm from "src/components/Confirm";
import Text from "src/components/Text";
import {ProductVariantsList} from '../products/ProductVariant/ProductVariantsList';
import {
    useCreateOneDosageMutation,
    useDeleteDosageMutation, useDosageByIdQuery,
    useUpdateDosageByIdMutation
} from "src/redux/API/dosageAPI";
import Spinner from "src/components/Spinner";
import {layouts, Button, Box, FormikInput, Typography} from "@med-io/neural-ui";
import {SectionWithHeader} from "src/components/SectionWithHeader";
const {TopBarLayout} = layouts

const FormBox: any = styled.div`
  height: 100%;
  border: 1px solid #E7E7E7;
  border-radius: ${p => p.theme.borderRadius.default}px;
`;

const ProductFlex = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 24px;
  height: 100%;
`

const Schema = Yup.object({
    name: Yup.object().shape({
        ru: Yup.string().required('Обязательное поле'),
        uz: Yup.string().required('Обязательное поле'),
        en: Yup.string().nullable(true),
    })
})

const DosageForm = () => {
    const history = useHistory();
    const formRef = useRef<FormikProps<any>>(null)
    const {state: {dosageForm: editedData = {}, historyBack} = {}} = useLocation<any>() || null
    const {params: {id}} = useRouteMatch<any>()
    const isEditing = id !== 'new';
    const dispatch = useDispatch()
    const [updateDosageForm, {isLoading: isUpdating}] = useUpdateDosageByIdMutation()
    const [createDosageForm, {isLoading: isCreating}] = useCreateOneDosageMutation()
    const [deleteDosageForm, {isLoading}] = useDeleteDosageMutation()
    const {data: dataById, isFetching, isError, refetch} = useDosageByIdQuery({id}, {
        skip: !isEditing
    })


    const onSubmit = async (values: any, actions: any) => {
        actions.setSubmitting(true)
        if (isEditing) {
            updateDosageForm({id, ...values})
            toast.success('Лекарственная форма обновлена')
            history.replace({pathname: `/catalog/dosageForms/${id}`, state: {dosageForm: values, historyBack: false}})
        } else {
            const {data}:any = await createDosageForm(values)
            if (data) {
                toast.success('Лекарственная форма создана')
                history.replace({
                    pathname: `/catalog/dosageForms/${data.id}`,
                    state: {dosageForm: values, historyBack: false}
                })
            }

        }
    }

    const handleDelete = async () => {
        if (formRef.current) {
            formRef.current.setSubmitting(true)
            deleteDosageForm({id})
            history.goBack()
            formRef.current.setSubmitting(false)
            toast.success('Удалено')
        }
    }

    const {isPermitted} = useRestriction()

    const isAllowed = isEditing && isPermitted(UserPermissionsEnum["dosage_form.update"]) || !isEditing && isPermitted(UserPermissionsEnum["dosage_form.create"])

    if (isFetching) {
        return <Spinner size={15}/>
    }

    const initialValue = {
        name: {
            uz: isEditing ? dataById?.name.uz : '',
            ru: isEditing ? dataById?.name.ru : '',
            en: isEditing ? dataById?.name.en : null
        }
    }

    return (
        <Formik
            initialValues={initialValue}
            onSubmit={onSubmit}
            validationSchema={Schema}
            innerRef={formRef}
            enableReinitialize
        >
            {({handleSubmit, values}) => (
                <TopBarLayout
                    title={isEditing ? values.name.ru : 'Новая лекарственная форма'}
                    isContentLoading={isFetching}
                    disableBodyOverflow
                    isError={isError}
                    onRetry={refetch}
                    onBack={() => historyBack ? history.goBack() : history.push('/catalog/dosageForms')}
                    topBarActions={
                        <>
                            {isEditing && isPermitted(UserPermissionsEnum["dosage_form.delete"]) &&
                                <Confirm title='Удаление лекарственной формы'
                                         okButton={<Button ml={1.5} type='button' variant='primary' destructive size='md'>Удалить</Button>}
                                         description={
                                    <Box display='block' w='300px'>
                                        <Typography>
                                            Вы
                                            действительно
                                            хотите
                                            удалить
                                            эту лекарственную форму?
                                        </Typography>
                                    </Box>
                                    }
                                         onConfirm={handleDelete}>
                                    <Button mr={1.5} type='button' variant='tertiaryGray' size='md'>Удалить</Button>
                                </Confirm>}
                            {
                                isAllowed && <Button onClick={() => handleSubmit()} variant='primary' size='md'>Сохранить</Button>
                            }
                        </>
                    }
                >
                    <Box display='flex' h='85vh' gap={1.5}>
                        <Box w='30%'>
                            <SectionWithHeader title='Данные лекарственной формы'>
                                <FormikInput name='name.ru' label='Название (Ru)' placeholder='Введите название' size='sm' fullWidth/>
                                <FormikInput name='name.uz' label='Название (Uz)' placeholder='Введите название' size='sm' fullWidth/>
                                <FormikInput name='name.en' label='Название (En)' placeholder='Введите название' size='sm' fullWidth/>
                            </SectionWithHeader>
                        </Box>
                        <Box w='67.5%'>
                            {isEditing && <ProductVariantsList title='Варианты продукта' filter_by='dosage_form_ids' id={id}/>}
                        </Box>
                    </Box>
                </TopBarLayout>
            )}
        </Formik>
    )
}

export default DosageForm;
