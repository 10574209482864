import * as yup from "yup";
import { toast } from "react-toastify";
import { ATCItem } from "src/redux/features/atc/types";
import { InnItem } from "src/redux/features/inn/types";
import { ReleaseFormsItem } from "src/redux/features/releaseForms/types";
import { DosageFormsItem } from "src/redux/features/dosageForms/types";
import { ManufacturerItem } from "src/redux/features/manufacturers/types";
import { CountriesItem } from "src/redux/features/countries/types";
import { transliterateRuToEn } from "src/common/string";

export type ProductVariantExcelRow = {
  product_name: string;
  product_variant_name: string;
  country_name: string;
  manufacturer_name: string;
  package: string;
  pieces_per_package: number;
  divisible: number | boolean;
  atc: string;
  unit_of_measure: string;
  inn: string;
  release_form: string;
  dosage_form: string;
  barcode: string[];
  atc_id: string | undefined;
  inn_id: string | undefined;
  release_form_id: string | undefined;
  dosage_form_id: string | undefined;
  manufacturer_id: string | undefined;
  country_id: string | undefined;
  key: string;
};

export const productVariantExcelKeyMapping = {
  "product_name": "Название продукта",
  "product_variant_name": "Название варианта",
  "country_name": "Страна",
  "manufacturer_name": "Производитель",
  "expiration_period": "Срок годности (мес)",
  "package": "Упаковка",
  "pieces_per_package": "Кол-во штук в упк",
  "divisible": "Делимо (1 или 0)",
  "atc": "АТХ",
  "inn": "МНН",
  "release_form": "Форма упаковки",
  "dosage_form": "Лекарственная форма",
  "barcode": "Бакркоды (через запятую)",
  "unit_of_measure": "Дозировка"
};

export type Assets = {
  atcs: ATCItem[];
  inns: InnItem[];
  releaseForms: ReleaseFormsItem[];
  dosageForms: DosageFormsItem[];
  countries: CountriesItem[];
  manufacturers: ManufacturerItem[];
}

export const transformProductVariantExcelKeys = (obj: any, index: number, assets: Assets) => {
  const { atcs, inns, releaseForms, dosageForms, countries, manufacturers } = assets;
  const newObj: any = {};
  // Some fields that are common for product variants that belong to the same product may be merged. That's if product variant doesn't contain some field, it will be taken from the latest row where the field is available.
  // Now we need store the latest values for these fields and use them if the further row doesn't contain the field.
  const latestValues: any = {};
  Object.keys(obj).forEach(key => {
    Object.keys(productVariantExcelKeyMapping).forEach((k) => {
      // Check if row contains the following fields: product_name, country_name, manufacturer_name, atc, inn, release_form, dosage_form.
      // If it does, then we need to store the latest values for these fields.

      // @ts-ignore
      if (productVariantExcelKeyMapping[k] === key) {
        newObj.sequence_number = index + 1;
        newObj.key = "product_variant_" + index;
        newObj.manufacturer_id = manufacturers.find((m: ManufacturerItem) => m.name.trim().toUpperCase() === obj[productVariantExcelKeyMapping.manufacturer_name]?.trim().toUpperCase())?.id;
        newObj.country_id = countries.find((c: CountriesItem) => c.name.ru.trim().toUpperCase() === obj[productVariantExcelKeyMapping.country_name]?.trim().toUpperCase())?.id;
        newObj.release_form_id = releaseForms.find((r: ReleaseFormsItem) => r.name.ru.trim().toUpperCase() === obj[productVariantExcelKeyMapping.release_form]?.trim().toUpperCase())?.id;
        newObj.dosage_form_id = dosageForms.find((d: DosageFormsItem) => d.name.ru.trim().toUpperCase() === obj[productVariantExcelKeyMapping.dosage_form]?.trim().toUpperCase())?.id;
        newObj.atc_id = atcs.find((a: ATCItem) => a.full_code.trim().toUpperCase() === obj[productVariantExcelKeyMapping.atc]?.trim().toUpperCase())?.id;
        newObj.inn_id = inns.find((i: InnItem) => i.name.ru.trim().toUpperCase() === obj[productVariantExcelKeyMapping.inn].trim().toUpperCase())?.id;
        newObj.unit_of_measure = {
          ru: obj[productVariantExcelKeyMapping.unit_of_measure].trim(),
          en: '',
          uz: ''
        }
        switch (k) {
          case "barcode":
            newObj[k] = obj[key].toString().split(",").map((barcode: string) => barcode.trim());
            break;
          case "divisible":
            newObj[k] = obj[key] === 1;
            break;
          default:
            newObj[k] = obj[key];
        }
      }
    });
  });
  return newObj;
};

export const ProductVariantExcelRowSchema = yup.object().shape({
  product_name: yup.string().required("Название продукта обязательна для заполнения"),
  product_variant_name: yup.string().required("Название варианта обязательна для заполнения"),
  country_name: yup.string().required("Страна обязательна для заполнения"),
  manufacturer_name: yup.string().required("Производитель обязателен для заполнения"),
  package: yup.string().required("Упаковка обязательна для заполнения"),
  pieces_per_package: yup.number().required("Кол-во штук в упаковке обязательна для заполнения").positive().integer(),
  divisible: yup.boolean().required("Делимо обязательно для заполнения"),
  atc: yup.string().required("АТХ обязательно для заполнения"),
  inn: yup.string().required("МНН обязательно для заполнения"),
  release_form: yup.string().required("Форма упаковки обязательна для заполнения"),
  dosage_form: yup.string().required("Лекарственная форма обязательна для заполнения"),
  unit_of_measure: yup.object().shape({
    ru: yup.string().required("Дозировка обязательна для заполнения"),
  }).required("Дозировка обязательна для заполнения"),
  barcodes: yup.array().of(yup.string()).optional()
});

export const validateProductVariantExcel = async (data: ProductVariantExcelRow[]) => {
  for (let i = 0; i < data.length; i++) {
    try {
      await ProductVariantExcelRowSchema.validate(data[i]);
    } catch (err: any) {
      toast.error(`Строка ${i + 1}. Ошибка: ${err.message}`);
      return false;
    }
  }
  return true;
};

export type ProductVariantImportRowVariantItem = Omit<ProductVariantExcelRow, "product_name" | "manufacturer_id" | "country_id">;

export interface ProductVariantImportProductGroup {
  name: { "ru": string, "en": string, "uz": string };
  manufacturer_id: string;
  country_id: string;
  variants: Array<Omit<ProductVariantImportRowVariantItem, "product_variant_name" | "manufacturer_name" | "country_name"> & { name: { ru: string, en: string, uz: string } }>;
}
