import React, {useState} from 'react';
import SearchBox, {SearchBoxSuffix} from "src/components/SearchBox";
import Filter from "src/icons/fluency-regular/Filter";
import {InnerColumnWrapper} from "src/layouts/TopbarLayout";
import {useHistory, useRouteMatch} from "react-router-dom";
import Box from "src/components/Box";
import {useRestriction} from 'src/hooks';
import {UserPermissionsEnum} from 'src/common/permissions';
import {layouts, Table,Button} from "@med-io/neural-ui";
import {useCategoriesQuery} from "src/redux/API/categoryAPI";

const {TopBarLayout} = layouts

function Categories() {
    const {isPermitted} = useRestriction()
    const {path} = useRouteMatch();
    const history = useHistory();
    const [search, setSearch] = useState('')
    const {data, isFetching, isError, refetch} = useCategoriesQuery({search})


    const columns = [
        {
            title: "Наименование",
            dataIndex: "name",
            render: (cellData: any) => cellData.ru
        }
    ]

    const onSearch = (search: string) => setSearch(search)
    return (
        <TopBarLayout
            title="Категории"
            isContentLoading={isFetching}
            isError={isError}
            onRetry={refetch}
            topBarActions={
                <>
                    <Button mr={1.5} onClick={() => history.push('/catalog/categories/tree')} variant='tertiaryGray' size='md'>Древо</Button>
                    {
                        isPermitted(UserPermissionsEnum["category.create"]) &&
                        <Button onClick={() => history.push('/catalog/categories/new')} variant='primary' size='md'>Добавить категорию</Button>
                    }
                </>
            }
        >
            <InnerColumnWrapper>
                <SearchBox onSearch={onSearch} defaultValue={search} delayedSearch
                           suffix={<><SearchBoxSuffix onClick={() => console.log("Filter click")}><Filter
                               size={20}/></SearchBoxSuffix></>}/>
                <Box display='block'>
                    <Table
                        columns={columns}
                        data={data?.items || []}
                        onRow={(row: any) => ({
                            onClick: () => history.push({
                                pathname: `/catalog/categories/${row.id}`,
                                state: {category: row}
                            })
                        })}
                        loading={isFetching}
                        size="md"
                        rowKey='id'
                        scroll={{y: '75vh'}}
                    />
                </Box>
            </InnerColumnWrapper>
        </TopBarLayout>
    )
}

export default Categories;
