import React from 'react';
import styled from "styled-components";
import LoginImg from '../../common/images/illlustration.jpg';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 720px;
`

const Intro = React.memo(() => {
  return (
    <Wrapper>
      <Image src={LoginImg} />
    </Wrapper>
  );
})

export default Intro;