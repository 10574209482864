import React, { useEffect } from 'react';
import { IconButton, icons } from "@med-io/neural-ui";
import { toast } from "react-toastify";

const { Clipboard, ClipboardCheck } = icons;

type CopyToClipboardProps = {
    text: string;
    successMessage?: string;
}

export const CopyToClipboard = ({ text, successMessage = 'Скопировано в буфер обмена'}: CopyToClipboardProps) => {
    const [isCopied, setIsCopied] = React.useState(false);
    const timeoutRef = React.useRef<NodeJS.Timeout | null>(null); // Use a ref to store the timeout id

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    const handleCopy = async () => {
        await navigator.clipboard.writeText(text);
        setIsCopied(true);
        toast(successMessage);
        timeoutRef.current = setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    };

    return <IconButton icon={isCopied ? ClipboardCheck : Clipboard} size='sm' variant={isCopied ? 'secondaryColor' : 'secondaryGray'} onClick={handleCopy} />
};

export default CopyToClipboard;