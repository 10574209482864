import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../common";
import {
    ATCByIdRequest,
    ATCByIdResponse,
    ATCsRequest,
    ATCsResponse,
    CreateATCRequest,
    CreateATCResponse, DeleteATCRequest, DeleteATCResponse, UpdateATCRequest, UpdateATCResponse
} from "src/redux/API/atcClassAPI/atcAPI.types";

export const atcAPI = createApi({
    reducerPath: 'atcAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    tagTypes: ['ATCs'],
    endpoints: builder => ({
        ATCs: builder.query<ATCsResponse, ATCsRequest>({
            query: ({lvl, group, search}) => `/v1/atc?lvl=${lvl}&group=false&search=${search}`,
            providesTags: ['ATCs']
        }),
        ATCsGroup: builder.query<ATCsResponse, ATCsRequest>({
            query: ({lvl, search}) => `/v1/atc?lvl=${lvl}&group=true&search=${search}`,
            providesTags: ['ATCs']
        }),
        createATC: builder.mutation<CreateATCResponse, CreateATCRequest>({
            query: (payload) => ({
                url: `/v1/atc`,
                method: 'POST',
                body: payload
            }),
            invalidatesTags: ['ATCs']
        }),
        ATCById: builder.query<ATCByIdResponse, ATCByIdRequest>({
            query: ({id}) => `/v1/atc/${id}`,
            providesTags: ['ATCs']
        }),
        updateATC: builder.mutation<UpdateATCResponse, UpdateATCRequest>({
            query: ({id, ...body}) => ({
                url: `/v1/atc/${id}`,
                method: 'PUT',
                body: body
            }),
            invalidatesTags: ['ATCs']
        }),
        deleteATC: builder.mutation<DeleteATCResponse, DeleteATCRequest>({
            query: ({id}) => ({
                url: `/v1/atc/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['ATCs']
        })
    }),

})

export const {
    useATCsQuery,
    useCreateATCMutation,
    useATCByIdQuery,
    useUpdateATCMutation,
    useDeleteATCMutation, useATCsGroupQuery
} = atcAPI