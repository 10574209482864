import React, {useEffect, useMemo, useState} from "react";
import Apis from "src/apis";
import { Col, Row } from 'react-grid-system';
import { useHistory } from "react-router-dom";
import { ColorCard, NewCard } from "../cards";
import { Box } from "src/components/Box/Box";
import SearchBox from "src/components/SearchBox";
import Fuse from "fuse.js";
import { useRestriction } from "src/hooks";
import { UserPermissionsEnum } from "src/common/permissions";
import {useCollectionsQuery} from "src/redux/API/collectionAPI";

export type CollectionType = {
    id: string;
    color: null | string;
    type: string;
    title: Title;
    image: null | string;
}

interface Title {
    en: string;
    ru: string;
    uz: string;
}


const Colored = () => {
    const [initialItems, setInitialItems] = useState<CollectionType[]>([])
    const [filteredItems, setFilteredItems] = useState<CollectionType[]>([])
    const history = useHistory()
    const { isPermitted } = useRestriction()
    const {data,isFetching} = useCollectionsQuery({ page: 1, limit: 50, type: "colored" })

    useMemo(() => {
        if (data) {
            setInitialItems(data.items)
            setFilteredItems(data.items)
        }
    },[isFetching === true])

    const handleClick = (id: string) => {
        history.push(`/mobile/collections/colored/${id}`)
    }

    const onSearch = (search: string) => {
        const value = search.trim()
        if (value.length !== 0) {
            const options = {
                includeScore: true,
                keys: ['title.ru', 'title.en', 'title.uz']
            }

            const fuse = new Fuse(initialItems, options)

            const result = fuse.search(value).map(itm => itm.item)

            setFilteredItems(result)

        } else setFilteredItems([...initialItems])
    }


    return (
        <Box display="block" width="100%" height="calc(100vh - 48px)" paddingTop="24px">
            <Box padding="0 24px">
                <SearchBox onSearch={onSearch} delayedSearch placeholder="Поиск подборок" />
            </Box>
            <Box display="block" overflowY="auto" height="calc(100% - 48px)" width="100%" padding="24px">
                <Row gutterWidth={16} style={{ marginBottom: "-24px" }}>
                    {
                        isPermitted(UserPermissionsEnum["collection.create"]) &&
                        <Col md={4} lg={3} xl={2} xxl={2}>
                            <NewCard onClick={() => history.push('/mobile/collections/colored/new')} />
                        </Col>
                    }
                    {
                        filteredItems.map((itm: any) =>
                            <Col key={itm.id} md={4} lg={3} xl={2} xxl={2}>
                                <ColorCard onClick={() => handleClick(itm.id)} desc={itm.title.ru} color={itm.color} img={String(itm.image)} />
                            </Col>
                        )
                    }
                </Row>
            </Box>
        </Box>
    )
}

export default Colored