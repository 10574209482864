import React, {useEffect} from 'react';
import styled from "styled-components";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useRestriction} from "src/hooks";
import {UserPermissionsEnum} from "src/common/permissions";
import {useProductVariantsQuery} from "src/redux/API/productVariantsAPI";
import {layouts, Table,Button} from "@med-io/neural-ui";
import {SectionWithHeader} from "src/components/SectionWithHeader";

const {TopBarLayout} = layouts
const Wrapper: any = styled.div`
  width: 100%;
  min-height: 100%;
  border: 1px solid #E7E7E7;
  border-radius: ${p => p.theme.borderRadius.default}px;
`

const columns = [
    {
        title: "Наименование",
        dataIndex: "name",
        render: (cellData: any) => cellData ? cellData.ru : '-',
        width: 200
    },
    {
        title: "Лекарственная форма",
        dataIndex: "dosage_form",
        render: (cellData: any) => cellData ? cellData.name : '-',
        width: 300
    },
    {
        title: "Форма выпуска",
        dataIndex: "release_form",
        render: (cellData: any) => cellData ? cellData.name : '-',
        width: 150
    },
    {
        title: "Дозировка",
        dataIndex: "unit_of_measure",
        render: (cellData: any) => cellData ? cellData.ru : '-',
        width: 150
    },
    {
        title: "Упаковка",
        dataIndex: "package",
        width: 150
    },
    {
        title: "МНН",
        dataIndex: "inn",
        render: (cellData: any) => cellData ? cellData.name : '-',
        width: 150
    },
    {
        title: "АТХ",
        dataIndex: "atc",
        render: (cellData: any) => cellData ? cellData.name : '-',
        width: 150
    },
]

const ProductVariants = () => {
    const history = useHistory();
    const match: any = useRouteMatch()
    const productId = match.params.id

    if (productId === 'new') return null

    const {data, isFetching, isError, refetch} = useProductVariantsQuery({limit: 30, page: 1})

    const {isPermitted} = useRestriction()


    return (
        <SectionWithHeader title='Варианты продукта' actions={
            isPermitted(UserPermissionsEnum["product.create"]) ?
                <Button onClick={() => history.push(`/catalog/products/${match.params.id}/variants/new`)} variant='primary' size='sm'>Добавить
                    вариант</Button> : <></>
        }>
            <Table
                       loading={isFetching}
                       columns={columns}
                       data={data?.items || []}
                       onRow={(row) => ({
                           onClick: () => history.push({
                             pathname: `/catalog/products/${match.params.id}/variants/${row.id}`,
                             state: { productVariant: row }
                           })
                         })
                         }
                         size='md'
                         rowKey='id'
                         scroll = {{y: '70vh'}}
                       />
        </SectionWithHeader>
    )
}

export default ProductVariants;
