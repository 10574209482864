import styled from "styled-components";
import { darken, lighten } from "src/styles/helpers";
import { Table } from "react-virtualized"

export const TableStyled: any = styled(Table)`
  width: 100%;
  height: 100%;


  .table {

  }

  & .cell {
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    max-width: 100%;
    width: 100%;
    border-left: solid 1px transparent;
    border-right: solid 1px transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 5px 10px;
  }

  & .ReactVirtualized__Table__row {
    border-bottom: ${p => `1px solid ${p.theme.colors.palette.text.shade10}`};
    display: flex;
    width: 100%;
    cursor: pointer;
    justify-content: space-between;
    color: ${p => p.theme.colors.palette.text.shade100};

    &:hover {
      background-color: ${(p) => lighten(p.theme.colors.palette.primary.main, 0.65)};
      border-bottom-color: ${(p) => lighten(p.theme.colors.palette.primary.main, 0.5)};
      color: ${(p) => p.theme.colors.palette.primary.main};
    }
  }

  .ReactVirtualized__Table__headerRow {
    height: 35px !important
  }

  .header {
    height: 100%;
    width: 100%;
    margin: 0;
      // background-color: ${() => darken('#fff', 0.03)};
    display: flex;
    align-items: center;
    justify-content: left;
    transition: 0.2s ease-in;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    padding: 5px 10px;
    color: ${p => p.theme.colors.palette.text.shade50};
    border-bottom: ${p => `1px solid ${p.theme.colors.palette.text.shade10}`};

    &:hover {
      background: ${p => p.theme.colors.palette.text.shade5};
    }
  }
`