import styled from "styled-components";
import { lighten } from "src/styles/helpers";

const SubSectionHeader = styled.div`
  background-color: ${(p) => lighten(p.theme.colors.palette.primary.main, 0.65)};
  color: ${(p) => p.theme.colors.palette.primary.main};
  border-radius: ${(p) => p.theme.borderRadius.default}px;
  display: flex;
  align-items: center;
  padding: 8px 20px;
  margin-bottom: 1rem;
`

export default SubSectionHeader