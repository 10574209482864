import React from 'react';
import styled from "styled-components";
import TopMenu from "src/components/Menus/TopMenu";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`
const ContentWrapper = styled.div`
  background-color: ${p => p.theme.colors.palette.background.paper};
  width: 100%;
  height: 100%;
  padding: 30px;
  overflow-y: auto;
  box-sizing: border-box;
  flex: 1;

  &.noPd {
    padding: 0;
  }
`

const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;  
  position: absolute;
  left: 24px;
  top: 18px;
`

const MenuWrapper = styled.div`
  position: relative;
  display: block;
  /* height: 60px; */
  width: 100%;

  .inner-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

function TopMenuLayout({
  children,
  menuItems,
  title,
  noChildrenPd
}: { children?: JSX.Element, menuItems?: JSX.Element | JSX.Element[], title?: string, noChildrenPd?: boolean }) {


  return (
    <Wrapper>
      <MenuWrapper>
        <Title>{title}</Title>
        <TopMenu>{menuItems}</TopMenu>
      </MenuWrapper>
      <ContentWrapper className={noChildrenPd ? 'noPd' : ''}>
        {children}
      </ContentWrapper>
    </Wrapper>
  )
}

export default TopMenuLayout;
