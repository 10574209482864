import React from "react";

const Add = ({ size = 20, color = "#000" }: { size?: number, color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.78435 2.57141C10.0854 2.57141 10.3344 2.77285 10.3738 3.03433L10.3792 3.10702L10.3802 8.2857H16.1318C16.4602 8.2857 16.7265 8.52555 16.7265 8.82141C16.7265 9.09262 16.5027 9.31677 16.2125 9.35227L16.1318 9.35712H10.3802L10.3818 14.5351C10.3819 14.8309 10.1157 15.0709 9.7872 15.0709C9.4861 15.0709 9.23719 14.8695 9.19778 14.608L9.19231 14.5353L9.19072 9.35712H3.44239C3.11392 9.35712 2.84766 9.11727 2.84766 8.82141C2.84766 8.5502 3.0714 8.32605 3.36169 8.29055L3.44239 8.2857H9.19072L9.18977 3.10723C9.18969 2.81137 9.45589 2.57141 9.78435 2.57141Z" />
  </svg>

);

export default Add;
