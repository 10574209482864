import ReactSwitch from 'react-switch'
import React from 'react'
import { useTheme } from "styled-components";
import type { Theme } from "src/styles/theme";

interface Props {
  checked?: boolean
  onToggleChange: (checked: any) => void
}

const Switch: React.FC<Props> = ({ checked = false, onToggleChange }) => {
  const theme: Theme = useTheme() as Theme

  const handleChange = (nextChecked: any) => {
    if (onToggleChange) onToggleChange(nextChecked)
  }

  return (
    <ReactSwitch
      checked={!!checked}
      onChange={handleChange}
      onColor={theme.colors.palette.primary.main}
      offColor='#E7E7E7'
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow='none'
      activeBoxShadow='none'
      className='react-switch'
    />
  )
}

export default Switch
