import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { getIsAuthenticated } from "src/redux/features/user";
import { useEffect, useMemo } from "react";

type Props = {
  initialize?: boolean
}

export const useUser = (props?: Props) => {
  const { initialize } = props || {}
  const dispatch = useDispatch()
  const { expires_at, loading, isAuthenticated: isAuthenticatedCached, ...rest } = useSelector((state: RootState) => state.user)

  useEffect(() => {
    if (!isAuthenticatedCached && initialize) {
      dispatch(getIsAuthenticated())
    }
  }, [])

  const isAuthenticated = useMemo(() => {
    return isAuthenticatedCached && expires_at !== undefined && new Date() < new Date(expires_at)
  }, [isAuthenticatedCached])

  return { loading, isAuthenticated, ...rest }
}