import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../common";
import {
    CompaniesRequest,
    CompaniesResponse,
    CompanyByIdRequest,
    CompanyByIdResponse, CreateCompanyRequest, CreateCompanyResponse, UpdateCompanyRequest, UpdateCompanyResponse
} from "src/redux/API/companyAPI/companyAPI.types";

export const companyAPI = createApi({
    reducerPath: 'companyAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        companies: builder.query<CompaniesResponse, CompaniesRequest>({
            query: ({
                        limit = 10,
                        page = 1,
                        search = '',
                        order_by,
                        is_distributor
                    }) => `/v1/company?limit=${limit}&page=${page}&search=${search}&order_by=${order_by}&is_distributor=${is_distributor}`
        }),
        companyById: builder.query<CompanyByIdResponse, CompanyByIdRequest>({
            query: ({id}) => `/v1/company/${id}`
        }),
        createCompany: builder.mutation<CreateCompanyResponse, CreateCompanyRequest>({
            query: (body) => ({
                url: `/v1/company`,
                method: 'POST',
                body: body
            })
        }),
        updateCompany: builder.mutation<UpdateCompanyResponse, UpdateCompanyRequest>({
            query: ({id, ...payload}) => ({
                url: `/v1/company/${id}`,
                method: 'PUT',
                body: payload
            })
        })
    }),
});


export const {useCompaniesQuery, useCompanyByIdQuery, useCreateCompanyMutation, useUpdateCompanyMutation} = companyAPI