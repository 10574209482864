import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../common";
import {
    BookingByIdRequest,
    BookingByIdResponse,
    BookingsRequest,
    BookingsResponse
} from "src/redux/API/bookingAPI/bookingAPI.types";


export const bookingAPI = createApi({
    reducerPath: 'bookingAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        bookings: builder.query<BookingsResponse, BookingsRequest>({
            query: ({
                        limit = 10,
                        offset,
                        customer_id
                    }) => `/v1/booking/?limit=${limit}&offset=${offset}&customer_id=${customer_id}`
        }),
        bookingById: builder.query<BookingByIdResponse, BookingByIdRequest>({
            query: ({id}) => `/v1/booking/${id}`
        })
    }),

})

export const {useBookingsQuery, useBookingByIdQuery} = bookingAPI