import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {baseQuery} from "src/redux/API/common";
import {
    CreateProductRequest, CreateProductResponse, DeleteProductRequest, DeleteProductResponse, Product,
    ProductByIdRequest,
    ProductByIdResponse,
    ProductsRequest,
    ProductsResponse, UpdateProductRequest, UpdateProductResponse
} from "src/redux/API/productAPI/productAPI.types";

export const productAPI = createApi({
    reducerPath: 'productAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        products: builder.query<ProductsResponse, ProductsRequest>({
            query: ({
                        limit,
                        page,
                        search
                    }) => `/v1/product?limit=${limit}&page=${page}&search=${search}`
        }),
        productById: builder.query<ProductByIdResponse, ProductByIdRequest>({
            query: ({id}) => `/v1/product/${id}`
        }),
        createProduct: builder.mutation<CreateProductResponse, CreateProductRequest>({
            query: (payload) => ({
                url: `/v1/product`,
                method: 'POST',
                body: payload
            })
        }),
        updateProduct: builder.mutation<UpdateProductResponse, UpdateProductRequest>({
            query: ({id, ...payload}) => ({
                url: `/v1/product/${id}`,
                method: 'PUT',
                body: payload
            })
        }),
        deleteProduct: builder.mutation<DeleteProductResponse, DeleteProductRequest>({
            query: ({id}) => ({
                url: `/v1/product/${id}`,
                method: 'DELETE'
            })
        }),
    }),
});

export const {
    useProductsQuery,
    useProductByIdQuery,
    useCreateProductMutation,
    useUpdateProductMutation,
    useDeleteProductMutation,
} = productAPI;
