import React, {useEffect, useMemo, useRef, useState} from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import Apis from "src/apis";
import styled from "styled-components";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup"
import Input, { ErrorStyled, Label } from "src/components/Input";
import { HorizontalBox } from "src/components/HorizontalBox";
import ColorPicker from "../colorPicker";
import Confirm from "src/components/Confirm";
import Dismiss from "src/icons/fluency-regular/Dismiss";
import CollectionModal, { ProductItem } from "../collectionModal";
import Empty from "src/icons/fluency/Empty";
import { toast } from "react-toastify";
import Text from "src/components/Text";
import { AvatarPicker } from "src/components/AvatarPicker";
import { lighten } from "src/styles/helpers";
import { useRestriction } from "src/hooks";
import {layouts, Box, Button, FormikInput} from "@med-io/neural-ui";
const {TopBarLayout} = layouts;
import { UserPermissionsEnum } from "src/common/permissions";
import {
    CollectionByIdResponse, CreateCollectionResponse,
    useCollectionByIdQuery,
    useCreateCollectionMutation,
    useDeleteCollectionMutation, useUpdateCollectionImageMutation,
    useUpdateCollectionMutation
} from "src/redux/API/collectionAPI";
import {SectionWithHeader} from "src/components/SectionWithHeader";



export type CollectionItemType = {
    id: string;
    color: string;
    type: string;
    title: Title;
    image: any;
    products: Product[];
}

export type Product = {
    id: string;
    name: string;
    on_prescription: boolean;
    country: Country;
    manufacturer: Manufacturer;
}

interface Country {
    id: string;
    name: string;
    code: string;
}

interface Manufacturer {
    id: string;
    name: string;
}

interface Title {
    ru: string;
    uz: string;
    en: string;
}


export const CollectionsProductItem = styled.div`
    padding: 15px 24px;
    border: 1px solid #e7e7e7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: ${p => p.theme.borderRadius.default}px;
    
    &:hover {
        background-color: ${(p) => lighten(p.theme.colors.palette.primary.main, 0.65)};
        border-bottom-color: ${(p) => lighten(p.theme.colors.palette.primary.main, 0.5)};
        color: ${(p) => p.theme.colors.palette.primary.main};
    }
`


export const Wrapper = styled.div`
    display: block;
    border: 1px solid #E7E7E7;
    border-radius: ${p => p.theme.borderRadius.default}px;
    background-color: #fff;
    overflow-y: auto;
    height: 100%;
`

const ColoredCollection = () => {
    const { isPermitted } = useRestriction()
    const { params: { id } } = useRouteMatch<any>()
    const formikRef: any = useRef()
    const history = useHistory()
    const isEditing = id !== "new"
    const [ids, setIds] = useState<string[]>([])
    const [confirm, setConfirm] = useState<boolean>(false)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [activeId, setActiveId] = useState<string>("")
    const [UPDATE_COLLECTION] = useUpdateCollectionMutation()
    const [CREATE_COLLECTION] = useCreateCollectionMutation()
    const [DELETE_COLLECTION] = useDeleteCollectionMutation()
    const [UPDATE_COLLECTION_IMAGE] = useUpdateCollectionImageMutation()
    const {data,isFetching,isError,refetch} = useCollectionByIdQuery({id},{
        skip: !isEditing
    })
    const [collection, setCollection] = useState<CollectionByIdResponse>({
        color: "",
        id: "",
        image: "",
        products: [],
        title: {
            ru: "",
            uz: "",
            en: ""
        },
        type: "",
    })
    const [initialProducts, setInitialProducts] = useState<Product[]>([])

    const Schema = Yup.object().shape({
        name: Yup.object().shape({
            ru: Yup.string().required('Обязательное поле'),
            uz: Yup.string().required('Обязательное поле'),
            en: Yup.string()
        }),
        color: Yup.string().required("Это обязательное поле"),
        icon: isEditing ? Yup.mixed() : Yup.mixed().required("Это обязательное поле")
    })

    const updateCollection = async ({ name, color, icon }: { name: Title; color: string; icon: string | null }) => {
        const rawIds: string[] = initialProducts.map(itm => itm.id)
        const added_products: string[] = []
        const deleted_products: string[] = []
        ids.forEach(id => {
            if (!rawIds.includes(id)) {
                added_products.push(id)
            }
        })

        rawIds.forEach(rawId => {
            if (!ids.includes(rawId)) {
                deleted_products.push(rawId)
            }
        })

        await UPDATE_COLLECTION({
            id,
            added_products,
            deleted_products,
            color: color,
            title: name,
            type: collection.type,
        })

        if (typeof formikRef.current.values.icon !== "string") {
            const formData = new FormData()
            formData.append("image", formikRef.current.values.icon)
            await UPDATE_COLLECTION_IMAGE({
                id,
                formData
            })
        }
        toast.success("Подборка обновлена")

    }

    const deleteCollection = async () => {
        await DELETE_COLLECTION({id})
        toast.success("Подборка удалена")

    }

    const createCollection = async ({ name, color, icon }: { name: Title; color: string; icon: string | null }) => {
        const {data}: any = await CREATE_COLLECTION({
            color: color,
            products: ids,
            title: name,
            type: "colored"
        });

        if (formikRef.current.values.icon) {
            const formData = new FormData()
            formData.append("image", formikRef.current.values.icon)
            await UPDATE_COLLECTION_IMAGE({
                id: data.id,
                formData
            })
        }


        toast.success("Подборка создана")
    }

    useMemo(() => {
        if (data?.products) {
            const productsId: string[] = data.products.map((itm: any) => itm.id);
            setIds(productsId)
            setCollection(data)
            setInitialProducts(data.products)
        } else {
            data && setCollection({ ...data, products: [] })
        }
    },[isFetching === true])

    const onRowClick = (row: ProductItem) => {
        const product: Product = {
            country: row.country,
            id: row.id,
            manufacturer: row.manufacturer,
            name: row.name.ru,
            on_prescription: row.on_prescription
        }

        setIds(prev => [...prev, row.id])
        setCollection(prev => {
            return {
                ...prev,
                products: [...prev.products, product]
            }
        })
    }

    const removeProduct = () => {
        // let id: string = ""
        // let collection: CollectionItemType = {
        //     color: "",
        //     id: "",
        //     image: "",
        //     title: {
        //         ru: "",
        //         uz: "",
        //         en: "",
        //     },
        //     products: [],
        //     type: ""
        // }
        // setActiveId(prev => {
        //     id = prev
        //     return prev
        // })
        //
        // setCollection(prev => {
        //     collection = prev
        //     return { ...prev }
        // })

        const products = collection.products.filter((itm) => itm.id !== activeId)
        setCollection(prev => {
            return {
                ...prev,
                products
            }
        })
        setIds(products.map((itm: any) => itm.id))
        setConfirm(false)
    }

    const handleClick = (id: string) => {
        setActiveId(id)
        setConfirm(prev => !prev)
    }

    return (
        <Formik
            validationSchema={Schema}
            initialValues={{ name: isEditing ? collection.title : { uz: "", ru: "", en: "" }, color: isEditing ? collection.color : "", icon: isEditing ? collection.image : null }}
            onSubmit={(values) => isEditing ? updateCollection(values) : createCollection(values)}
            enableReinitialize
            innerRef={formikRef}
        >
            {({ setFieldValue, handleSubmit }) => {
                return (
                    <TopBarLayout
                        title={isEditing ? collection.title.ru : "Новая карточка"}
                        disableBodyOverflow
                        topBarActions={
                            <>
                                {
                                    isEditing && isPermitted(UserPermissionsEnum["collection.delete"]) ?
                                        <Confirm
                                            title="Удаление подборки"
                                            description="Вы действительно хотите удалить подборку?"
                                            onConfirm={deleteCollection}
                                            okButton={<Button ml={1} variant="primary" destructive size="md">Удалить</Button>}
                                        >
                                            <Button mr={1.3} variant="tertiaryGray" destructive size="md">Удалить</Button>
                                        </Confirm>
                                        :
                                        <Button mr={1.3} variant="secondaryGray" size="md" onClick={() => history.goBack()}>Отмена</Button>
                                }
                                {
                                    isPermitted(UserPermissionsEnum["collection.update"]) && <Button type="submit"  variant="primary" size="md" onClick={() => handleSubmit()}>Сохранить</Button>
                                }
                            </>
                        }
                        isContentLoading={isFetching}
                        isError={isError}
                        onRetry={refetch}
                        onBack={() => history.goBack()}
                    >
                        <Box display="flex" gap={1.5} h="85vh">
                            <Box w="30%">
                                <SectionWithHeader title="Данные подборки">
                                    <FormikInput name="name.en" label="Название(En)" size='sm' fullWidth/>
                                    <FormikInput name="name.ru" label="Название(Ru)" size="sm" fullWidth/>
                                    <FormikInput name="name.uz" label="Название(Uz)" size="sm" fullWidth/>
                                    <Field name="icon">
                                        {(filed: any) => {
                                            return (
                                                <Box display="block" mb="24px">
                                                    <Label>Иконка</Label>
                                                    <Box alignItems="center" style={{ gap: "16px" }}>
                                                        <AvatarPicker fileTypes={[".png"]} round src={filed.meta.value} onFilePicked={(file) => setFieldValue("icon", file)} />
                                                        <Text fontSize="12px" lineHeight="17px" color="#9c9c9c">Чтобы изменить изображение нажмите на него</Text>
                                                    </Box>
                                                    <ErrorMessage component={ErrorStyled} name="icon" />
                                                </Box>
                                            )
                                        }}
                                    </Field>
                                    <Field name="color">
                                        {(field: any) => {
                                            return (
                                                <>
                                                    <Label>Цвет карточки</Label>
                                                    <ColorPicker value={field.meta.value} onChange={(value) => setFieldValue("color", value)} />
                                                </>
                                            )
                                        }}
                                    </Field>
                                    <ErrorMessage name="color" component={ErrorStyled} />
                                </SectionWithHeader>
                            </Box>
                            <Box w="67.5%">
                                <SectionWithHeader title="Продукты подборки" actions={
                                    collection.products.length ? <Button onClick={() => setIsOpen(prev => !prev)} variant="secondaryColor" size="md">Добавить продукт</Button> : <></>
                                }>
                                    <Box display="block" mb="40px" p="24px" h="100%">
                                        <CollectionModal onRowClick={(row) => onRowClick(row)} isOpen={isOpen} onClose={() => setIsOpen(prev => !prev)} ids={ids} />
                                        <Box display="flex" flexDirection="column" gap="12px" h="100%">
                                            {
                                                collection.products.length ?
                                                    collection.products.map(itm =>
                                                        <CollectionsProductItem key={itm.id}>
                                                            <Text fontSize="16px" lineHeight="19px" color="#2c2c2c">{itm.name}</Text>
                                                            <Button variant="tertiaryGray" size="md" onClick={() => handleClick(itm.id)}>
                                                                <Dismiss size={24} />
                                                            </Button>
                                                        </CollectionsProductItem>
                                                    ) :
                                                    <Box display="flex" alignItems="center" justifyContent="center" h="100%">
                                                        <Box display="flex" flexDirection="column" alignItems="center">
                                                            <Empty />
                                                            <Text margin="20px 0px" fontSize="16px" lineHeight="19px" color="#9c9c9c">
                                                                В эту подборку пока не добавлено продутов.
                                                            </Text>
                                                            <Button variant="primary" size="md" onClick={() => setIsOpen(prev => !prev)}>Добавить продукты</Button>
                                                        </Box>
                                                    </Box>
                                            }
                                        </Box>
                                    </Box>
                                    <Confirm okButton={<Button ml={1} variant="primary" destructive size="md">Удалить</Button>} title="Продукт подборки" description="Вы действительно хотите удалить продукт из подборки?" onConfirm={() => removeProduct()} onCancel={() => setConfirm(false)} visible={confirm}><></></Confirm>
                                </SectionWithHeader>
                            </Box>
                        </Box>
                    </TopBarLayout>
                )
            }}
        </Formik>
    )
}

export default ColoredCollection