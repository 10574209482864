import React, { SyntheticEvent, useState } from 'react'
import styled from 'styled-components'
import {ErrorMessage, useField} from 'formik'
import { darken } from "src/styles/helpers";
import EyeOff from "src/icons/fluency-regular/EyeOff";
import Eye from "src/icons/fluency-regular/Eye";

interface Props {
  type?: string
  label?: string
  error?: string
  prefix?: any
  prefixIcon?: any
  suffix?: any
  suffixIcon?: any
  placeholder?: string
  value?: string | number
  onChange?: (value: number | string, e: SyntheticEvent) => void
  textarea?: boolean
  required?: boolean
  field?: any
  resizable?: boolean
  fullWidth?: boolean
  toUpperCase?: boolean
  toLowerCase?: boolean

  [x: string]: any
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  border-radius: ${(p) => p.theme.borderRadius.default}px;
  background-color: #ffffff;
  border: 1px solid #e7e7e7;
  overflow: hidden;

  :focus-within {
    border-color: ${(p) => p.theme.colors.palette.primary.main};
  }
`

const PrefixStyled = styled.label`
  display: flex;
  width: 36px;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-right: 1px solid #e7e7e7;
  cursor: pointer;
`

const PrefixIconStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 36px;
`

const SuffixIconStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 36px;
  cursor: pointer;
`

const InputStyled: any = styled.input`
  display: flex;
  width: ${(p: any) =>
          p.prefix && p.prefixIcon
                  ? 'calc(100% - 72px)'
                  : p.suffix || p.suffixIcon
                          ? 'calc(100% - 36px)'
                          : '100%'};
  height: 100%;
  align-items: center;
  border: none;
  font-weight: 400;
  padding: 0 10px;
  border-radius: ${(p) => p.theme.borderRadius.default}px;
  cursor: text;

  ::placeholder {
    color: ${() => darken('#fff', 0.2)};
  }
`

export const TextAreaStyled = styled.textarea<Props>`
  display: flex;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 100%;
  align-items: center;
  border: none;
  font-weight: 400;
  padding: 10px;
  border-radius: ${(p) => p.theme.borderRadius.default}px;
  cursor: text;
  resize: none;

  ::placeholder {
    color: ${() => darken('#fff', 0.2)};
  }
`

const SuffixStyled = styled.div`
  display: flex;
  width: 36px;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-left: 1px solid #e7e7e7;
`

export const Label = styled.label`
  color: #9c9c9c;
  font-weight: 500;
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
`

export const ErrorStyled = styled.label`
  color: ${(p) => p.theme.colors.palette.negative.main};
  font-weight: 400;
  font-size: 12px;
  display: block;
  margin-top: 5px;
`

const Flex = styled.div<Props>`
  display: flex;
  height: 100%;
  width: ${(p) =>
          p.suffix && p.suffixIcon
                  ? 'calc(100% - 72px)'
                  : p.suffix || p.suffixIcon
                          ? 'calc(100% - 36px)'
                          : '100%'};
`

const FlexSuffix = styled.div`
  display: flex;
  height: 100%;
`

// const MyTag = styled.input.attrs((props: InputProps) => {
//   paddingLeft: props.left;
//   paddingRight: props.right;
// })<InputProps>`
//   border-radius: 4px 4px 0 0;
//   background-color: #FFFFFF;
//   border: none;
//   border-bottom: 1px solid #E7E7E7;
//   font-weight: 500;
//
//   background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/How_to_use_icon.svg/1200px-How_to_use_icon.svg.png");
//   background-repeat: no-repeat;
//   background-position: left;
//   background-size: 14px;
// `;

const Wrapper = styled.div`
  height: 100%;

  :focus-within {
    .label {
      color: #000;
    }
  }
`

const Input: React.FC<Props> = React.memo(
  (props) => {
    const {
      type,
      label,
      error,
      prefix,
      onPrefixClick,
      prefixIcon,
      suffix,
      onSuffixClick,
      suffixIcon,
      onChange,
      placeholder,
      value,
      textarea,
      required,
      resizable,
      field: FieldProp,
      fullWidth,
      toUpperCase,
      toLowerCase,
      ...rest
    } = props
    const [field, meta, helpers] = useField(FieldProp?.name)

    const handleChange = (event: any) => {
      if (toUpperCase) event.target.value = event.target.value.toUpperCase()
      if (toLowerCase) event.target.value = event.target.value.toLowerCase()
      helpers.setValue(event.target.value)
      if (onChange) onChange(event.target.value, event)
    }

    const errorMsg = error || meta?.error || ''
    
    return (
      <Wrapper style={{ height: textarea ? '100%' : '', width: fullWidth ? '100%' : 'initial' }}>
        {label && (
          <Label className='label' htmlFor='input-form'>
            {label} {required && '*'}
          </Label>
        )}
        <Container style={{ height: textarea ? '100%' : '' }}>
          <Flex suffix={suffix} suffixIcon={suffixIcon}>
            {prefix && <PrefixStyled htmlFor='input-form'>{prefix}</PrefixStyled>}
            {prefixIcon && <PrefixIconStyled onClick={onPrefixClick}>{prefixIcon}</PrefixIconStyled>}
            {textarea ? (
              <TextAreaStyled
                value={value}
                prefix={prefix}
                prefixIcon={prefixIcon}
                type={type}
                id='input-form'
                onChange={handleChange}
                placeholder={placeholder}
                {...rest}
              />
            ) : (
              <InputStyled
                prefix={prefix}
                prefixIcon={prefixIcon}
                type={type}
                value={value}
                {...FieldProp}
                required={required}
                placeholder={placeholder}
                {...rest}
                onChange={handleChange}
              />
            )}
          </Flex>
          <FlexSuffix>
            {suffixIcon && <SuffixIconStyled>{suffixIcon}</SuffixIconStyled>}
            {suffix && <SuffixStyled onClick={onSuffixClick}>{suffix}</SuffixStyled>}
          </FlexSuffix>
        </Container>
        {!!errorMsg?.length && <ErrorStyled htmlFor='input-form'>{errorMsg}</ErrorStyled>}
      </Wrapper>
    )
  },
)

export const PasswordInput: React.FC<Props> = (props: any) => {
  const [visible, setVisible] = useState(false)

  return (
    <Input
      {...props}
      type={visible ? 'text' : 'password'}
      suffix={
        visible ? <Eye size={20} /> : <EyeOff size={20} />
      }
      onSuffixClick={() => setVisible(!visible)}
    />
  )
}

export const TextArea: React.FC<Props> = React.memo(
  ({
     name,
     type,
     label,
     error,
     prefix,
     prefixIcon,
     suffix,
     suffixIcon,
     onChange,
     placeholder,
     value,
     textarea,
     required,
     resizable,
     field,
     ...rest
   }) => {
    return (
      <Wrapper>
        {label && (
          <Label className='label' htmlFor='input-form'>
            {label} {required && '*'}
          </Label>
        )}
        <Container style={{ height: '100%' }}>
          <Flex suffix={suffix} suffixIcon={suffixIcon}>
            {prefix && <PrefixStyled htmlFor='input-form'>{prefix}</PrefixStyled>}
            {prefixIcon && <PrefixIconStyled>{prefixIcon}</PrefixIconStyled>}
            <TextAreaStyled
              {...field}
              {...rest}
              
            />
            <ErrorMessage name='fieldName' component={ErrorStyled} />
          </Flex>
          <FlexSuffix>
            {suffixIcon && <SuffixIconStyled>{suffixIcon}</SuffixIconStyled>}
            {suffix && <SuffixStyled>{suffix}</SuffixStyled>}
          </FlexSuffix>
        </Container>
        {error && <ErrorStyled htmlFor='input-form'>{error}</ErrorStyled>}
      </Wrapper>
    )
  }
)

export default Input
