import React from "react";
import Text from "src/components/Text";
import styled from "styled-components";
import { ImageCardType } from "./types";


const ImageCardWrapper = styled.div`
    color: #fff;
    padding: 22px 11px;
    background: rgba(44, 44, 44, 0.3);
    border-radius: ${p => p.theme.borderRadius.default}px;
    aspect-ratio: 1;
    min-height: 145px;
    text-align: left;
    display: flex;
    align-items: flex-end;

    background-position: center;
    background-size: cover;
    position: relative;
    margin-bottom: 24px;

    cursor: pointer;
    overflow: hidden;
`

const ImageCardDimmer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(44, 44, 44, 0.3);
`



export const ImageCard = ({img, desc, onClick}: ImageCardType) => {
    return (
        <ImageCardWrapper onClick={onClick} style={{backgroundImage: `url(${img})`}}>
            <ImageCardDimmer />
            <Text style={{position: "absolute"}}>{desc}</Text>
        </ImageCardWrapper>
    )
}