import React, { useEffect } from "react";
import styled from "styled-components";
import { Form, Formik } from "formik";
import * as Yup from "yup";
// import Button from "src/components/Button";
import { useDispatch } from "react-redux";
import { login } from "src/redux/features/user";
import { useUser } from "src/hooks/useUser";
import { history } from "src/App";
import { Button, FormikEmailInput, FormikPasswordInput } from "@med-io/neural-ui";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 50px;
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
`;

const HorizontalBox: any = styled.div`
  position: relative;
  padding: 1rem 0;
  flex-shrink: 1;

  &.flex {
    display: flex;

    & > * {
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: auto;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  &.justify-between {
    justify-content: space-between;
  }

  &.align-bottom {
    align-items: flex-end;
  }

  &.barcode {
    svg {
      width: 100% !important;
    }
  }
`;

const Title = styled.div`
  font-family: 'Proxima Nova', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 49px;
`;

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 29px;
  color: #9C9C9C;
`;

const FormWrapper = styled.div`
  width: 100%;

  & > * {
    margin: 2rem 0;
  }

  Button {
    width: 100%;
  }
`;

const Schema = Yup.object().shape({
  email: Yup.string().email("Неверный формат эл. почты").matches(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    , "Неверный формат эл. почты").required("Обязательное поле!"),
  password: Yup.string()
    .min(8, "Пароль не должен содержать меньше 8 символов!")
    .max(16, "Пароль не должен содержать больше 16 символов!").required("Обязательное поле!")
});

const LoginForm = () => {
  const dispatch = useDispatch();
  // const history = useHistory()

  const { isAuthenticated, loading } = useUser();

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated]);

  const onSubmit = (values: any) => {
    // @ts-ignore
      dispatch(login(values));
  };

  return (
    <Wrapper>
      <Title>Добро пожаловать</Title>
      <Subtitle>Введите ваши данные для входа в дэшбоард.</Subtitle>
      <Formik
        initialValues={{
          email: "",
          password: ""
        }}
        validationSchema={Schema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting }: any) => {
          return (
            <FormWrapper>
              <Form>
                <FormikEmailInput size="md" name="email" label="Email" placeholder="Введите ваш email" fullWidth />
                <FormikPasswordInput size="md" name="password" label="Пароль" placeholder="Введите ваш пароль"
                                     fullWidth />
                <Button loading={loading} onClick={handleSubmit} mt={1} type="submit" size="md"
                        variant="primary">Войти</Button>
              </Form>
            </FormWrapper>
          );
        }}
      </Formik>
    </Wrapper>
  );
};

export default LoginForm;
