import React, { useMemo } from "react";
import Fuse from "fuse.js";
import { CountriesItem } from "src/redux/features/countries/types";
import { Country, useCountriesQuery } from "src/redux/API/countryAPI";
import { FormikSelect, FormikSelectProps } from "@med-io/neural-ui";

type CountrySelectProps = Omit<{
  name: string,
} & FormikSelectProps<Country>,
  "options" | "getOptionValue" | "getOptionLabel" | "searchOptions" | "delayedSearch" | "fullWidth" | "searchable" | "placeholder" | "label" | "size"
>

export const CountrySelect = (props: CountrySelectProps) => {

  const { data, isFetching } = useCountriesQuery({});

  const items = useMemo(() => {
    if (data?.items) {
      return data.items;
    } else {
      return [];
    }
  }, [data]);

  const searchOptions: Fuse.IFuseOptions<CountriesItem> = {
    keys: [
      { name: "name.ru", weight: 1 }
    ]
  };

  return (
    <FormikSelect<Country>
      size="sm"
      options={items}
      loading={isFetching}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name.ru}
      searchOptions={searchOptions}
      delayedSearch={150}
      fullWidth
      searchable
      placeholder="Выберите страну"
      label="Страна"
      {...props}
    />
  );
};

export default CountrySelect;
