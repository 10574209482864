import React from "react";

const ArrowClockwise = ({ size = 20, color = '#000' }: { size?: number, color?: string }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.875 10C16.875 13.7969 13.7969 16.875 10 16.875C6.20304 16.875 3.125 13.7969 3.125 10C3.125 6.20304 6.20304 3.125 10 3.125C11.8269 3.125 13.4874 3.83758 14.7186 4.99996L13.125 5.00009C12.7798 5.00009 12.5 5.27991 12.5 5.62509C12.5 5.97027 12.7798 6.25006 13.125 6.25006H16.25C16.5952 6.25006 16.875 5.97027 16.875 5.62509V2.8125C16.875 2.46732 16.5952 2.1875 16.25 2.1875C15.9048 2.1875 15.625 2.46732 15.625 2.8125V4.13696C14.1651 2.73601 12.1831 1.875 10 1.875C5.51269 1.875 1.875 5.51269 1.875 10C1.875 14.4873 5.51269 18.125 10 18.125C14.4873 18.125 18.125 14.4873 18.125 10C18.125 9.75544 18.1142 9.51337 18.0931 9.27431C18.0662 8.97156 17.8051 8.75 17.5012 8.75C17.1164 8.75 16.8273 9.09938 16.8559 9.48319C16.8686 9.65381 16.875 9.82613 16.875 10Z" />
  </svg>

);

export default ArrowClockwise;
