import React, { memo } from 'react';
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";

type Props = { children: JSX.Element | JSX.Element[], permission?: string, anyOf?: string[] }

const Restricted = memo(({
                           children,
                           permission,
                           anyOf,
                           ...rest
                         }: Props) => {
  const { permissions, is_super } = useSelector((state: RootState) => state.user)
  const allowed = () => {
    if (is_super) return true
    if (permission) {
      return permissions.includes(permission)
    }
    if (anyOf) {
      return anyOf.some(c => permissions.includes(c))
    }
  }

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...rest });
    }
    return child;
  });

  if (allowed()) {
    return (
      <>{childrenWithProps}</>
    )
  }

  return null;
})

export default Restricted;
