import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../common";
import {
    CompanyBranchByIdRequest,
    CompanyBranchByIdResponse,
    CompanyBranchesRequest,
    CompanyBranchesResponse
} from "src/redux/API/companyBranchAPI/companyBranchAPI.types";


export const companyBranchAPI = createApi({
    reducerPath: 'companyBranchAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        companyBranches: builder.query<CompanyBranchesResponse, CompanyBranchesRequest>({
            query: ({
                        limit = 10,
                        page = 1,
                        search = '',
                        order_by
                    }) => `/v1/company/branch?limit=${limit}&page=${page}&order_by=${order_by}&search=${search}`
        }),
        companyBranchById: builder.query<CompanyBranchByIdResponse, CompanyBranchByIdRequest>({
            query: ({id}) => `/v1/company/branch/${id}`
        })
    }),
});

export const {useCompanyBranchesQuery, useCompanyBranchByIdQuery} = companyBranchAPI