import httpService from 'src/apis/httpService'
import { createModerator, getModerators, updateModerator, updateModeratorAvatar } from './types'

export default {
  getModerators(params: getModerators) {
    return httpService.get('/v1/admin', { params })
  },
  getPermissions() {
    return httpService.get('/v1/admin/permissions')
  },
  getOneModerator(id: string) {
    return httpService.get(`/v1/admin/${id}`)
  },
  createModerator(body: createModerator) {
    return httpService.post('/v1/admin', body)
  },
  updateModerator(body: updateModerator, id: string) {
    return httpService.put(`/v1/admin/${id}`, body)
  },
  updateModeratorAvatar(body: updateModeratorAvatar, id: string) {
    return httpService.put(`/v1/admin/${id}/avatar`, body)
  },
  deleteModerator(id: string) {
    return httpService.delete(`/v1/admin/${id}`)
  },
}