import company from 'src/apis/company';
import products from 'src/apis/product';
import symptoms from 'src/apis/symptoms';
import diseases from 'src/apis/diseases';
import atc from 'src/apis/atc';
import categories from 'src/apis/categories'
import countries from "src/apis/countries";
import manufacturers from "src/apis/manufacturers";
import inn from "src/apis/inn";
import releaseForms from "src/apis/releaseForms";
import dosageForms from "src/apis/dosageForms";
import auth from "src/apis/auth";
import moderators from "src/apis/moderators";
import permissions from "src/apis/permissions";
import home from "./home"

export default {
    company,
    products,
    symptoms,
    diseases,
    atc,
    categories,
    countries,
    manufacturers,
    inn,
    releaseForms,
    dosageForms,
    auth,
    moderators,
    permissions,
    home
}