import httpService from 'src/apis/httpService'
import {createATC, getATCs, updateATC} from './types'

export default {
    getATCs(params?: getATCs) {
        return httpService.get('/v1/atc', {params})
    },
    getOneATC(id: string) {
        return httpService.get(`/v1/atc/${id}`)
    },
    createATC(body: createATC) {
        return httpService.post('/v1/atc', body)
    },
    updateATC(body: updateATC, id: string) {
        return httpService.put('/v1/atc/' + id, body)
    },
    deleteATC(id: string) {
        return httpService.delete('/v1/atc/' + id)
    },
}