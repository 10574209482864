import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {baseQuery} from "src/redux/API/common";
import {
    CollectionByIdRequest,
    CollectionByIdResponse,
    CollectionsRequest,
    CollectionsResponse,
    CreateCollectionRequest,
    CreateCollectionResponse,
    DeleteCollectionRequest,
    DeleteCollectionResponse,
    UpdateCollectionImageRequest,
    UpdateCollectionImageResponse,
    UpdateCollectionRequest,
    UpdateCollectionResponse
} from "src/redux/API/collectionAPI/collectionAPI.types";

export const collectionAPI = createApi({
    reducerPath: 'collectionAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        collections: builder.query<CollectionsResponse, CollectionsRequest>({
            query: ({
                        limit,
                        page,
                        search,
                        color,
                        type
                    }) => `/v1/product/collection?limit=${limit}&page=${page}&search=${search}&color=${color}&type=${type}`
        }),
        createCollection: builder.mutation<CreateCollectionResponse, CreateCollectionRequest>({
            query: (payload) => ({
                url: `/v1/product/collection`,
                method: 'POST',
                body: payload
            })
        }),
        collectionById: builder.query<CollectionByIdResponse, CollectionByIdRequest>({
            query: ({id}) => `/v1/product/collection/${id}`
        }),
        updateCollection: builder.mutation<UpdateCollectionResponse, UpdateCollectionRequest>({
            query: ({id, ...payload}) => ({
                url: `/v1/product/collection/${id}`,
                method: 'PUT',
                body: payload
            })
        }),
        deleteCollection: builder.mutation<DeleteCollectionResponse, DeleteCollectionRequest>({
            query: ({id}) => ({
                url: `/v1/product/collection/${id}`,
                method: 'DELETE'
            })
        }),
        updateCollectionImage: builder.mutation<UpdateCollectionImageResponse, any>({
            query: ({id, formData}) => {
                return {
                    url: `/v1/product/collection/${id}/image`,
                    method: 'PUT',
                    body: formData
                }
            }
        }),


    }),
});

export const {
    useCollectionsQuery,
    useCollectionByIdQuery,
    useCreateCollectionMutation,
    useDeleteCollectionMutation,
    useUpdateCollectionMutation,
    useUpdateCollectionImageMutation
} = collectionAPI
