import React from "react";
import { ProductVariantExcelRow } from "src/screens/catalog/import/types";
import { icons, FeaturedIcon, Table } from "@med-io/neural-ui";
import ManufacturerSelect from "src/common/selects/ManufacturerSelect";
import AtcSelect from "src/common/selects/AtcSelect";
import { CountrySelect, DosageFormSelect, INNSelect, ReleaseFormSelect } from "src/common/selects";

const { Tick } = icons;

type Props = {
  data: ProductVariantExcelRow[];
  onChange: (fieldName: keyof ProductVariantExcelRow, value: string | number, index: number) => void;
};

export const ProductVariantsDataSheet: React.FC<Props> = ({ data, onChange }) => {
  // const [pagination, setPagination] = React.useState({ current: 1, pageSize: 50 });
  // const dataLength = data.length;

  const onFieldChange = (fieldName: keyof ProductVariantExcelRow, value: string | number, index: number) => {
    onChange(fieldName, value, index);
  };

  const columns = [
    {
      title: "№",
      dataIndex: "sequence_number",
      width: 50,
      render: (cell: any) => cell
    },
    {
      title: "Назавние продукта",
      dataIndex: "product_name",
      width: 300,
      ellipsis: true
    },
    {
      title: "Название варианта",
      dataIndex: "product_variant_name",
      width: 300,
      ellipsis: true
    },
    {
      title: "МНН",
      dataIndex: "inn",
      width: 300,
      ellipsis: true
    },
    {
      title: "Выбранный МНН",
      dataIndex: "inn_id",
      width: 300,
      ellipsis: true,
      render: (inn_id: undefined | string, row: object, rowIndex: number) => (
        <INNSelect
          label={false}
          name='inn_id'
        />
      )
    },
    {
      title: "Страна",
      dataIndex: "country_name",
      width: 300,
      ellipsis: true
    },
    {
      title: "Выбранная страна",
      dataIndex: "country_id",
      width: 300,
      ellipsis: true,
      render: (country_id: undefined | string, row: object, rowIndex: number) => (
        <CountrySelect
            name='country_id'
        />
      )
    },
    {
      title: "Производитель",
      dataIndex: "manufacturer_name",
      width: 300,
      ellipsis: true
    },
    {
      title: "Выбранный производитель",
      dataIndex: "manufacturer_id",
      width: 300,
      ellipsis: true,
      render: (manufacturer_id: undefined | string, row: object, rowIndex: number) => (
        <ManufacturerSelect
          label={false}
          name='manufacturer_id'
        />
      )
    },
    {
      title: "Делимо",
      dataIndex: "divisible",
      width: 100,
      ellipsis: true,
      render: (divisible: boolean) => divisible ? <FeaturedIcon size="sm" color="success" icon={Tick} /> : null
    },
    {
      title: "АТХ",
      dataIndex: "atc",
      width: 150,
      ellipsis: true
    },
    {
      title: "Выбранный АТХ",
      dataIndex: "atc_id",
      width: 220,
      ellipsis: true,
      render: (atc_id: undefined | string, row: object, rowIndex: number) => (
        <AtcSelect
          label={false}
          name='atc_id'
        />
      )
    },
    {
      title: "Форма упаковки",
      dataIndex: "release_form",
      width: 300,
      ellipsis: true
    },
    {
      title: "Выбранная Форма упаковки",
      dataIndex: "release_form_id",
      width: 300,
      ellipsis: true,
      render: (release_form_id: undefined | string, row: object, rowIndex: number) => (
        <ReleaseFormSelect
          label={false}
          name='release_form_id'
        />
      )
    },
    {
      title: "Лекарственаня форма",
      dataIndex: "dosage_form",
      width: 300,
      ellipsis: true
    },
    {
      title: "Выбранная Лекарственаня форма",
      dataIndex: "dosage_form_id",
      width: 300,
      ellipsis: true,
      render: (dosage_form_id: undefined | string, row: object, rowIndex: number) => (
        <DosageFormSelect
          name='dosage_form_id'
          label={false}
        />
      )
    },
    {
      title: "Дозировка",
      dataIndex: "unit_of_measure",
      width: 200,
      ellipsis: true,
      render: (unit_of_measure: { ru: string }) => unit_of_measure.ru
    },
    {
      title: "Упаковка",
      dataIndex: "package",
      ellipsis: true
    },
    {
      title: "Кол-во шт в упк",
      dataIndex: "pieces_per_package",
      width: 200,
      ellipsis: true
    },
    {
      title: "Баркоды",
      dataIndex: "barcode",
      ellipsis: true,
      render: (barcodes: string[]) => barcodes.join(", ")
    }
  ];

  // const onPageChange = (page: number, pageSize?: number) => {
  //   setPagination({ current: page, pageSize: pageSize || 50 });
  // };
  //
  // const currentData = data.slice((pagination.current - 1) * pagination.pageSize, pagination.current * pagination.pageSize);

  return (
    <Table rowKey="key" size="sm" data={data} columns={columns} virtualized scroll={{ y: "80vh" }} />
  );
};
