import styled from "styled-components";

export const NestedMenuItemStyled = styled.div`
  width: 100%;
  &:last-child {
    border-bottom: ${p => `solid 1px ${p.theme.colors.palette.text.shade10}`};    
  }
  border-top: ${p => `solid 1px ${p.theme.colors.palette.text.shade10}`};
  border-left: ${p => `solid 1px ${p.theme.colors.palette.text.shade10}`};
  border-right: ${p => `solid 1px ${p.theme.colors.palette.text.shade10}`};
`

export const HorizontalDivider = styled.div`
  border-top: ${p => `solid 1px ${p.theme.colors.palette.text.shade10}`};
  width: 100%;
  height: 2px;
`
