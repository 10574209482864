import httpService from 'src/apis/httpService'
import {
    createCountry,
    getCountries,
    updateCountry
} from './types'

export default {
    getCountries(params: getCountries) {
        return httpService.get('/v1/area/country', {params})
    },
    getOneCountry(id: string) {
        return httpService.get(`/v1/area/country/${id}`)
    },
    createCountry(body: createCountry) {
        return httpService.post('/v1/area/country', body)
    },
    updateCountry(body: updateCountry) {
        return httpService.put('/v1/area/country', body)
    },
}