import React, { Component, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import Search from "src/icons/fluency-regular/Search";
import _, { debounce, extend } from "lodash";

const Container: any = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 49px;
  background: #FFFFFF;
  border: 1px solid #E7E7E7;
  box-sizing: border-box;
  border-radius: ${p => p.theme.borderRadius.default}px;
  //border-top-right-radius: ${p => p.theme.borderRadius.default}px;
  overflow: hidden;
`;

const PrefixStyled = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 47px;
`;

const InputStyled = styled.input`
  display: flex;
  height: 100%;
  width: calc(100% - 47px);
  align-items: center;
  border: none;
  font-weight: 400;
  cursor: text;
  //padding: 0 10px;
`;

export const SearchBoxSuffix = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 47px;
`;

const Flex = styled.div`
  display: flex;
  height: 100%;
  width: calc(100% - 47px);
`;

interface Props {
  placeholder?: string,
  active?: boolean,
  type?: string,
  onChange?: (value: number | string) => void,
  suffix?: any,
  id?: string,
  onSearch?: (value: string) => void,
  delayedSearch?: boolean,
  delayTime?: number,
  typeDetect?: boolean,
  value?: string,
  defaultValue?: string

  [x: string]: any
}

// FIXME: Apply Props
class SearchBox extends Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      searchValue: ""
    }
    this.searchDebounced = debounce(this.searchDebounced, props.delayTime || 1000)
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    if (prevProps.typeDetect !== this.props.typeDetect) {
      if (!this.props.typeDetect) {
        document.removeEventListener("keydown", this.keyDown);
      } else if (this.props.typeDetect) {
        document.addEventListener("keydown", this.keyDown);
      }
    }
  }

  searchDebounced = (value: string) => {
    if (this.props.onSearch) {
      this.props.onSearch(value)
    }
  }

  // const [inputValue, setInputValue] = useState("");
  // const [active, setActive] = useState(defaultActive);
  // const delayedQuery = useRef(_.debounce(q => onSearch && onSearch(q), 500)).current;
  //
  allowedKeyCodes = ['Equal', 'Backspace', 'Digit1', 'Digit2', 'Digit3', 'Digit4', 'Digit5', 'Digit6', 'Digit7', 'Digit8', 'Digit9', 'Digit0', 'Minus', 'KeyZ', 'KeyX', 'KeyC', 'KeyV', 'KeyB', 'KeyN', 'KeyM', 'KeyA', 'KeyS', 'KeyD', 'KeyF', 'KeyG', 'KeyH', 'KeyJ', 'KeyK', 'KeyL', 'KeyQ', 'KeyW', 'KeyE', 'KeyR', 'KeyT', 'KeyY', 'KeyU', 'KeyI', 'KeyO', 'KeyP', 'BracketLeft', 'BracketRight', 'Backslash', 'Semicolon', 'Quote', 'Comma', 'Period', 'Slash', 'Space']
  //
  // const onToggleActive = () => {
  //   setActive(!active);
  // };
  //
  keyDown = (e: KeyboardEvent) => {
    const reservedKeys = ["Meta", "Control", "Alt"]
    // @ts-ignore
    if (this.allowedKeyCodes.indexOf(e.code) > -1 && this.props.onChange && !e.ctrlKey && !e.altKey && !e.metaKey && document.activeElement.tagName.toString() !== 'INPUT') {
      let new_value = this.props.value
      if (e.code === "Backspace") {
        new_value = new_value.slice(0, -1)
      } else {
        if (!new_value.length && e.code !== "Space") {
          new_value = new_value.concat(e.key)
        } else if (new_value.length) {
          new_value = new_value.concat(e.key)
        }
      }

      if (this.props.value !== new_value) {
        this.props.onChange(new_value)
        this.searchDebounced(new_value)
      }
    }
  }

  componentDidMount() {
    if (this.props.typeDetect) {
      document.addEventListener("keydown", this.keyDown);
    }
    if (this.props.defaultValue) this.setState({ searchValue: this.props.defaultValue })
  }

  componentWillUnmount() {
    if (this.props.typeDetect) {
      document.removeEventListener("keydown", this.keyDown);
    }
  }

  handleChange = (e: any) => {
    if (!this.props.value) {
      this.setState({ searchValue: e.target.value })
    }
    if (this.props.onChange) this.props.onChange(e.target.value);
    if (this.props.onSearch) {
      this.props.delayedSearch ? this.searchDebounced(e.target.value) : this.props.onSearch(e.target.value)
    }
  }

  render() {
    const {
      placeholder,
      active: defaultActive,
      type,
      onChange,
      suffix,
      id = "",
      inputProps,
      inputType,
      onSearch,
      delayedSearch = false,
      delayTime = 1000,
      typeDetect = false,
      value = this.state.searchValue,
    } = this.props
    return (
      <Container symptom>
        <Flex>
          <PrefixStyled htmlFor="SearchBox"><Search size={20} color={"#000"} /></PrefixStyled>
          <InputStyled {...inputProps} type={inputType} value={value} id={id} onChange={this.handleChange}
                       placeholder={placeholder} />
        </Flex>
        {suffix}
      </Container>
    );
  }
}

export default SearchBox;
