import React, { useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import ProductVariants from "src/screens/catalog/products/ProductVariant/ProductVariants";
import { Field, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import CountrySelect from "src/common/selects/CountrySelect";
import ManufacturersSelect from "src/common/selects/ManufacturerSelect";
import { toast } from "react-toastify";
import { useRestriction } from "src/hooks";
import { UserPermissionsEnum } from "src/common/permissions";
import Confirm from "src/components/Confirm";
import Text from "src/components/Text";
import { useDispatch } from "react-redux";
import {
  useCreateProductMutation,
  useDeleteProductMutation,
  useProductByIdQuery,
  useUpdateProductMutation
} from "src/redux/API/productAPI";
import Spinner from "src/components/Spinner";
import {Box, Button, FormikInput, layouts, Typography} from "@med-io/neural-ui";
import { SectionWithHeader } from "src/components/SectionWithHeader";

const { TopBarLayout } = layouts;

const ProductFlex = styled.div`
  display: flex;
  height: 100%;

  & > *:last-child {
    flex: 1;
  }
`;

const FormBox: any = styled.div`
  width: 300px;
  height: 100%;
  border: 1px solid #E7E7E7;
  border-radius: ${p => p.theme.borderRadius.default}px;
`;

const Schema = Yup.object().shape({
  name: Yup.object().shape({
    ru: Yup.string().required("Обязательное поле"),
    uz: Yup.string().required("Обязательное поле"),
    en: Yup.string().nullable(true)
  }),
  country_id: Yup.string().required("Обязательное поле"),
  manufacturer_id: Yup.string().required("Обязательное поле")
});

const Product = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const formRef = useRef<FormikProps<any>>(null);
  const { params: { id } } = useRouteMatch<any>();
  const isEditing = id !== "new";
  const [updateProduct, { isLoading: isUpdating }] = useUpdateProductMutation();
  const [createProduct, { isLoading: isCreating }] = useCreateProductMutation();
  const [deleteProduct, { isLoading }] = useDeleteProductMutation();

  const { data: dataById, isFetching } = useProductByIdQuery({ id }, {
    skip: !isEditing
  });


  const onSubmit = async (values: any) => {
    if (isEditing) {
      updateProduct({ id, ...{ ...values, added_diseases: [], deleted_diseases: [] } });
      toast.success("Продукт обновлен");
      history.replace({
        pathname: `/catalog/products/${id}`,
        state: { product: values, historyBack: false }
      });
    } else {
      const {data}: any = await createProduct({ ...values, diseases: [] });
      if (data) {
        history.replace({
          pathname: `/catalog/products/${data.id}`,
          state: { product: data, historyBack: false }
        });
      }
    }
  };


  const handleDelete = async () => {
    if (formRef.current) {
      formRef.current.setSubmitting(true);
      deleteProduct({ id });
      history.goBack();
      formRef.current.setSubmitting(false);
      toast.success("Удалено");
    }
  };

  const { isPermitted } = useRestriction();

  const isAllowed = isEditing && isPermitted(UserPermissionsEnum["product.update"]) || !isEditing && isPermitted(UserPermissionsEnum["product.create"]);

  if (isFetching) {
    return <Spinner size={15} />;
  }

  const initialValues = {
    name: {
      en: isEditing ? dataById?.name.en : null,
      ru: isEditing ? dataById?.name.ru : "",
      uz: isEditing ? dataById?.name.uz : ""
    },
    country_id: isEditing ? dataById?.country.id : "",
    manufacturer_id: isEditing ? dataById?.manufacturer.id : ""
  };

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formRef}
      onSubmit={onSubmit}
      validationSchema={Schema}
      enableReinitialize
    >
      {({ values, setFieldValue, handleSubmit, isSubmitting }) => (
        <TopBarLayout
          title={isEditing ? values.name.ru : "Новый продукт"}
          disableBodyOverflow
          onBack={() => history.goBack()}
          topBarActions={
            <>
              {isEditing && isPermitted(UserPermissionsEnum["product.delete"]) &&
                <Confirm title="Удаление продукта"
                         okButton={<Button ml={1.5} type="button" destructive variant="primary"
                                           size="md">Удалить</Button>}
                         description={
                           <Box display='block' w='300px'>
                             <Typography>Вы
                               действительно
                               хотите
                               удалить
                               этот продукт?</Typography>
                           </Box>
                         }
                         onConfirm={handleDelete}>
                  <Button mr={1.5} type="button" variant="tertiaryGray" size="md">Удалить</Button>
                </Confirm>}
              {
                isAllowed &&
                <Button onClick={() => handleSubmit()} variant="primary" size="md"
                        loading={isSubmitting}>{isEditing ? "Сохранить" : "Далее"}</Button>
              }

            </>
          }
        >
          <Box display="flex" gap={1.5} h="85vh">
            <Box w="30%">
              <SectionWithHeader title="Данные продукта">
                <FormikInput name="name.ru" label="Название (Ru)" placeholder="Введите название" size="sm" fullWidth />
                <FormikInput name="name.uz" label="Название (Uz)" placeholder="Введите название" size="sm" fullWidth />
                <FormikInput name="name.en" label="Название (En)" placeholder="Введите название" size="sm" fullWidth />
                <CountrySelect
                  name="country_id"
                />
                <ManufacturersSelect name='manufacturer_id'/>
              </SectionWithHeader>
            </Box>
            <Box w="67.5%">
              <ProductVariants />
            </Box>
          </Box>
        </TopBarLayout>
      )}
    </Formik>
  );
};

export default Product;
