import React, { useContext, useState } from 'react';
import styled, { useTheme } from "styled-components";
import Button from "src/components/Button";
import  { InnerColumnWrapper } from "src/layouts/TopbarLayout";
import { useHistory, useRouteMatch } from "react-router-dom";
import ProductVariantInfoBlock from "src/screens/catalog/products/ProductVariant/ProductVariantInfoBlock";
import Loading from "src/screens/Loading";
import { Box } from "src/components/Box/Box";
import { Container, Row, Col } from 'react-grid-system';
import { AutoSizer } from "react-virtualized";
import Dismiss from "src/icons/fluency-regular/Dismiss";
import CloudFilled from "src/icons/fluency-filled/CloudFilled";
import { Theme } from "src/styles/theme";
import Text from "src/components/Text";
import Confirm from "src/components/Confirm";
import DragEnter from "src/screens/catalog/products/ProductVariant/ProductVariantImages/DragEnter";
import { useProductVariants } from "src/hooks";
import {layouts} from "@med-io/neural-ui";
const {TopBarLayout} = layouts

const Wrapper = styled(Box)`
  position: relative;
  height: 100%;
`

const BorderWrapper = styled(Box)`
  border: solid 1px;
  border-color: ${(p) => p.theme.colors.palette.divider};
  border-radius: ${p => p.theme.borderRadius.default}px;
  padding: 24px;
  position: relative;
  overflow-y: auto;
`

const ImageWrapper = styled.div`
  position: relative;
  border: solid 1px;
  border-color: ${(p) => p.theme.colors.palette.divider};
  border-top-left-radius: ${p => p.theme.borderRadius.default}px;
  border-top-right-radius: ${p => p.theme.borderRadius.default}px;
  margin-bottom: 30px;
  overflow: hidden;
  background: #fff;

  img {
    width: 100%;
    height: 300px;
    margin: 0 auto;
    object-fit: contain;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  &.grabbable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  /* (Optional) Apply a "closed-hand" cursor during drag operation. */

  &.grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
`

const CloseBtn = styled(Button)`
  border-radius: 0;
`

type CouldImg = {
  id?: string,
  thumbnail_url?: string,
  url?: string,
  order_index?: number
}

export type FileAndURL = File & { url: string } & CouldImg

const Image = ({
  file,
  draggable,
  onDrag,
  onDelete
}: { file: FileAndURL, draggable?: boolean, onDrag?: any, onDelete: () => void }) => {
  const theme: Theme = useTheme() as Theme
  return (
    <ImageWrapper className={draggable ? 'grabbable' : ''} draggable={draggable} onDrag={onDrag}>
      <Box alignItems='center' justifyContent='space-between'>
        <Box padding='10px' title={file.id ? 'Изображение находиться в облаке' : 'Изображение еще не загружено'}>
          <CloudFilled color={file.id ? theme.colors.palette.primary.main : theme.colors.grey} />
        </Box>
        <Text ellipsis singleLine display='block' width='100%' flex={1} fontSize={12}
          fontWeight={400}>{file.name}</Text>
        <Confirm title='Внимание'
          okButton={<Button ml={24} type='submit' danger>Удалить</Button>}
          description={<Text display='block' width='300px' fontWeight={400}>
            Вы действительно хотите удалить выбранное изображение?
          </Text>}
          onConfirm={onDelete}>
          <CloseBtn icon={true}><Dismiss /></CloseBtn>
        </Confirm>
      </Box>
      <img src={file.url} />
    </ImageWrapper>
  )
}

const ProductVariantImages = () => {
  const [display, setDisplay] = useState("none")
  const history = useHistory()
  const { params: { product_variant_id, id: productId } } = useRouteMatch<any>()

  const {
    item,
    loading,
    updateImages,
    appendImages,
    deleteImage
  } = useProductVariants({ product_variant_id })

  const onDragOver = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setDisplay("block")
  }

  const onDragEnter = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  }

  const onDragLeave = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setDisplay("none")
  }

  const onDrop = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    const url = (window.URL || window.webkitURL).createObjectURL(e.dataTransfer.files[0]);
    e.dataTransfer.files[0].url = url
    appendImages(e.dataTransfer.files[0])
    setDisplay("none")
  }

  const onSubmit = async () => {
    await updateImages(product_variant_id)
    history.push({
      pathname: `/catalog/products/${productId}/variants/${product_variant_id}/instructions`,
      state: { productVariant: item }
    })
  }

  // TODO: Implement images reordering.

  const onDelete = async (index: number) => {
    await deleteImage(index)
  }

  if (loading) return <Loading />

  return (
    <TopBarLayout
      title="Изображения варианта продукта"
      onBack={() => history.goBack()}
      topBarActions={<Button onClick={onSubmit} primary>Далее</Button>}
      disableBodyOverflow={false}
    >
      <Box display="flex" flexDirection="column" height="86vh">
        {loading ? <Loading /> :
            <Wrapper>
              <BorderWrapper display='block' width='300px' marginRight='24px'>
                <ProductVariantInfoBlock data={item} />
              </BorderWrapper>
              <BorderWrapper onDragOver={onDragOver} onDragEnter={onDragEnter} onDrop={onDrop} flex={1}
                             onDragLeave={onDragLeave}>
                <DragEnter display={display} />
                <AutoSizer>
                  {({ height, width }) => (
                      <Container style={{ height, width }} fluid>
                        <Row>
                          {
                            item.images.map((img, i) =>
                                <Col sm={4} key={i}>
                                  <Image
                                      file={img}
                                      onDelete={() => onDelete(i)}
                                  />
                                </Col>
                            )
                          }
                        </Row>
                      </Container>
                  )}
                </AutoSizer>
              </BorderWrapper>
            </Wrapper>
        }
      </Box>
    </TopBarLayout>
  )
}

export default ProductVariantImages;