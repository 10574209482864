import React from 'react';
import styled from "styled-components";
import SideMenu from "src/components/Menus/SideMenu";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const ContentWrapper = styled.div`
  background-color: ${p => p.theme.colors.palette.background.paper};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const ContentBox = styled.div`
  box-sizing: content-box;
  flex: 1;
`

function SidebarLayout({
                         title,
                         children,
                         menuItems,
                         backLink,
                         header,
                         mainHeader = false,
                         footer
                       }: { title?: string, children?: JSX.Element, menuItems: JSX.Element | JSX.Element[], backLink: string, header: JSX.Element, mainHeader?: boolean, footer?: JSX.Element }) {
  return (
    <Wrapper>
      <SideMenu
        title={title}
        backLink={backLink}
        mainHeader={mainHeader}
        footer={footer}
      >{menuItems}</SideMenu>
      <ContentWrapper>
        <ContentBox>
          {children}
        </ContentBox>
      </ContentWrapper>
    </Wrapper>
  );
}

export default SidebarLayout;
