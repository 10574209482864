import React, {useMemo} from 'react';
import Fuse from 'fuse.js';
import {ManufacturerItem} from 'src/redux/features/manufacturers/types';
import { Manufacturer, useManufacturersQuery } from "src/redux/API/manufacturerAPI";
import { FormikSelect } from "@med-io/neural-ui";

type ManufacturersSelectProps = {
    name: string;
    [x: string]: any
}

export const ManufacturersSelect = (props: ManufacturersSelectProps) => {
    const {data, isFetching} = useManufacturersQuery({})

    const items = useMemo(() => {
        if (data?.items) {
            return data?.items
        } else {
            return []
        }
    }, [data])

    const searchOptions: Fuse.IFuseOptions<ManufacturerItem> = {
        keys: [
            {name: "name", weight: 1},
        ],
    };
    return (
        <FormikSelect<Manufacturer>
            options={items}
            loading={isFetching}
            labelKey='name'
            valueKey='id'
            virtualized
            searchOptions={searchOptions}
            delayedSearch={150}
            size="sm"
            fullWidth
            searchable
            placeholder='Выберите производителя'
            label='Производитель'
            {...props}
        />
    );
};

export default ManufacturersSelect;
