import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../common";
import {
    CategoriesRequest,
    CategoriesResponse,
    CategoriesGroupResponse,
    CategoriesGroupRequest,
    CreateCategoryResponse,
    CreateCategoryRequest,
    CategoryByIdResponse,
    CategoryByIdRequest,
    UpdateCategoryResponse,
    UpdateCategoryRequest,
    DeleteCategoryResponse,
    DeleteCategoryRequest,
    UpdateCategoryImageResponse
} from "src/redux/API/categoryAPI/categoryAPI.types";

export const categoryAPI = createApi({
    reducerPath: 'categoryAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    tagTypes: ['CATEGORY'],
    endpoints: builder => ({
        categories: builder.query<CategoriesResponse, CategoriesRequest>({
            query: ({lvl, search, group}) => `/v1/category?lvl=${lvl}&search=${search}&group=false`,
            providesTags: ['CATEGORY']
        }),
        categoriesGroup: builder.query<CategoriesGroupResponse, CategoriesGroupRequest>({
            query: ({lvl, group}) => `/v1/category?lvl=${lvl}&group=true`,
            providesTags: ['CATEGORY']
        }),
        createCategory: builder.mutation<CreateCategoryResponse, CreateCategoryRequest>({
            query: (payload) => ({
                url: `/v1/category`,
                method: 'POST',
                body: payload
            }),
            invalidatesTags:  ['CATEGORY']
        }),
        categoryById: builder.query<CategoryByIdResponse, CategoryByIdRequest>({
            query: ({id}) => `/v1/category/${id}`,
            providesTags: ['CATEGORY']
        }),
        updateCategory: builder.mutation<UpdateCategoryResponse, UpdateCategoryRequest>({
            query: ({id, ...body}) => ({
                url: `/v1/category/${id}`,
                method: 'PUT',
                body: body
            }),
            invalidatesTags: ['CATEGORY']
        }),
        updateCategoryImage: builder.mutation<UpdateCategoryImageResponse, any>({
            query: ({id, ...imageFile}) => {
                let bodyFormData = new FormData();
                bodyFormData.append('file', imageFile);
                return {
                    url: `./v1/category/${id}/image`,
                    method: 'PUT',
                    body: {bodyFormData}
                }
            },
            invalidatesTags: ['CATEGORY']
        }),
        deleteCategory: builder.mutation<DeleteCategoryResponse, DeleteCategoryRequest>({
            query: ({id}) => ({
                url: `/v1/category/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['CATEGORY']
        })
    }),

})

export const {
    useCategoriesQuery,
    useCategoriesGroupQuery,
    useCreateCategoryMutation,
    useCategoryByIdQuery,
    useUpdateCategoryMutation,
    useUpdateCategoryImageMutation,
    useDeleteCategoryMutation
} = categoryAPI
