import React from "react";
import Text from "src/components/Text";
import styled from "styled-components";
import { ColorCardType } from "./types";
import { invertColor } from "src/styles/helpers";


const ColorCardWrapper = styled.div`
  aspect-ratio: 1;
  padding: 17px 16px 25px 16px;
  border-radius: ${p => p.theme.borderRadius.default}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 145px;
  margin-bottom: 24px;

  cursor: pointer;
`

const ColorCardPic = styled.div`
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: ${p => p.theme.borderRadius.default}px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ColorCardImg = styled.img`
  width: 35px;
  height: 35px;
  object-fit: cover;
`

export const ColorCard = ({ img, desc, color, style, onClick }: ColorCardType) => {
  const textColor = invertColor(color) as string
  return (
    <ColorCardWrapper onClick={onClick} style={{ ...style, background: color }}>
      <ColorCardPic>
        <ColorCardImg src={img} />
      </ColorCardPic>
      <Text fontWeight="600" fontSize="14ipx" lineHeight="17px" color={textColor} width="80%">{desc}</Text>
    </ColorCardWrapper>
  )
}