import React from 'react'
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { Component } from "react";
import { UserPermissionsEnum } from "src/common/permissions";

type Props = {
  component: React.ElementType
  permission?: UserPermissionsEnum
  path: string
  exact?: boolean
  is_super: boolean
  anyOf?: UserPermissionsEnum[]
} | {
  component: React.ElementType
  permission?: UserPermissionsEnum
  path: string
  exact?: boolean
  is_super?: boolean
  anyOf?: UserPermissionsEnum[]
}

const PrivateRoute = ({ component: Component, permission, is_super, anyOf, ...rest }: Props) => {
  const { permissions, is_super: isSuperUser, isAuthenticated } = useSelector((state: RootState) => state.user)
  const allowed = isSuperUser || permission && (permissions.includes(permission) || anyOf?.some(p => permissions.includes(p)))

  return (
    <Route {...rest} render={props => (
      isAuthenticated && allowed ? (
        <Component {...props} />
      ) : (
        <Redirect to={{
          pathname: '/',
          state: { from: props.location }
        }} />
      )
    )} />
  )
}

export default PrivateRoute
