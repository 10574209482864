import React from 'react';
import Box from "src/components/Box";
import styled, { useTheme } from "styled-components";
import { useUser } from "src/hooks/useUser";
import Text from "src/components/Text";
import { Theme } from "src/styles/theme";
import { darken } from "src/styles/helpers";
import { Avatar, Typography } from "@med-io/neural-ui";

const Wrapper = styled(Box)`
  &:hover {
    background-color: ${(p) => p.theme.colors.palette.text.shade5};
  }
`

const SideMenuUserBlock = ({ expanded }: { expanded?: boolean }) => {
  const theme: Theme = useTheme() as Theme
  const { name, surname, avatar, patronymic, email } = useUser()
  return (
    <Wrapper title={`${name} ${surname} ${patronymic}`} width='100%' padding='12px 16px' alignItems='center'>
      <Avatar name={name} surname={surname} src={avatar} size={2} />
      {expanded && <Box display='block' padding='0 16px'>
        <Typography component='div' customFontSize={0.7} color='gray600'>{name} {surname}</Typography>
        <Typography component='div' customFontSize={0.6} color='gray400'>{email}</Typography>
      </Box>}
    </Wrapper>
  );
};

export default SideMenuUserBlock;
