import {createApi} from '@reduxjs/toolkit/query/react';

import {baseQuery} from '../common';
import {
    CreateDistrictRequest,
    CreateDistrictResponse, DistrictByIdRequest, DistrictByIdResponse,
    DistrictsRequest,
    DistrictsResponse, UpdateDistrictByIdRequest, UpdateDistrictByIdResponse
} from "src/redux/API/districtAPI/districtAPI.types";


export const districtAPI = createApi({
    reducerPath: 'districtAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        districts: builder.query<DistrictsResponse, DistrictsRequest>({
            query: ({
                        limit = 10,
                        page = 1,
                        search = '',
                        city_id,
                        order_by
                    }) => `/v1/area/district?limit=${limit}10&page=${page}&search=${search}&city_id=${city_id}&order_by=${order_by}`
        }),
        createDistrict: builder.mutation<CreateDistrictResponse, CreateDistrictRequest>({
            query: (district) => ({
                url: `/v1/area/district`,
                method: 'POST',
                body: district
            })
        }),
        districtById: builder.query<DistrictByIdResponse, DistrictByIdRequest>({
            query: ({id}) => `/v1/area/district/${id}`
        }),
        updateDistrictById: builder.mutation<UpdateDistrictByIdResponse, UpdateDistrictByIdRequest>({
            query: ({id, ...payload}) => ({
                url: `/v1/area/district/${id}`,
                method: 'PUT',
                body: payload
            })
        })

    }),
});

export const {
    useDistrictsQuery,
    useCreateDistrictMutation,
    useDistrictByIdQuery,
    useUpdateDistrictByIdMutation
} = districtAPI