import React from 'react';
import styled, { useTheme } from "styled-components";
import { lighten, rgba } from "src/styles/helpers";
import Box from "src/components/Box";
import Text from "src/components/Text";
import { Theme } from "src/styles/theme";

interface IDragEnter {
  display: string
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${() => rgba('#fff', 0.9)};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
`

const DragEnter = ({ display }: IDragEnter) => {
  const theme: Theme = useTheme() as Theme
  return (
    <Wrapper style={{display}}>
      <Box width='100%' height='100%' justifyContent='center' alignItems='center'>
        <Text style={{ zIndex: 21 }} color={theme.colors.palette.primary.main} fontSize={24}>Перетащите файлы сюда</Text>
      </Box>
    </Wrapper>
  );
};

export default DragEnter;