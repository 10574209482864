import httpService from 'src/apis/httpService'
import {createCompany, getCompanies, updateCompany} from './types'

export default {
    getCompanies(params: getCompanies) {
        return httpService.get('/v1/company', {params})
    },
    getOneCompany(id: string) {
        return httpService.get(`/v1/company/${id}`)
    },
    createCompany(body: createCompany) {
        return httpService.post('/v1/company', body)
    },
    updateCompany(body: updateCompany, id: string) {
        return httpService.put(`/v1/company/${id}`, body)
    },
}