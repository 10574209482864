import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from 'src/apis'
import { RootState } from "src/redux/store";
import { DosageFormsItem, DosageFormsStore } from './types';
import Fuse from "fuse.js"

export const initialState: DosageFormsStore = {
  items: [],
  filteredItems: [],
  filters: {
    page: 1,
    limit: 10000,
    search: '',
  },
  currentCount: 0,
  count: 0,
  loading: false,
  error: null,
  newCompanyForm: {},
  isSubmiting: false
}

export const getDosageForms = createAsyncThunk(
  'GET_DOSAGE_FORMS',
  async (payload: any = {}, thunkAPI: any) => {
    const {
      dosageForms
    }: RootState = thunkAPI.getState()
    if (payload.search && payload.search !== dosageForms.filters.search) {
      payload.page = 1
    }
    const filters = { ...dosageForms.filters, ...payload }
    const { data } = await Apis.dosageForms.getDosageForms({ ...filters })
    return { data, filters };
  },
)

export const getOneDosageForm = createAsyncThunk(
  'GET_ONE_DOSAGE_FORM',
  async (payload: any = {}, thunkAPI: any) => {
    const {
      dosageForms: { id },
    } = thunkAPI.getState();
    const { data } = await Apis.dosageForms.getDosageForm(id)
    return { data }
  },
)


export const createDosageForm = createAsyncThunk(
  'CREATE_DOSAGE_FORM',
  async (payload: any = {}) => {
    const { data } = await Apis.inn.createINN(payload);
    return { data }
  },
)

export const deleteDosageForm = createAsyncThunk(
  'DELETE_INN',
  async (payload: any = {}, thunkAPI: any) => {
    const {
      inn: { id },
    } = thunkAPI.getState();
    const { data } = await Apis.diseases.deleteDisease(id)
    return { data }
  },
)

const DosageFormSlice = createSlice({
  name: 'inn',
  initialState,
  reducers: {
    SET_NEW_DISEASE_FORM: (state: DosageFormsStore, action) => {
      state.newCompanyForm = action.payload
    },
    SET_FILTERS: (state: DosageFormsStore, action) => {
      state.filters = { ...state.filters, ...action.payload }
    },
    SEARCH_DOSAGEFORMS: (state: DosageFormsStore, { payload }: { payload: string }) => {
      const searchValue = payload.trim()
      if (searchValue.length) {
        const options = {
          includeScore: true,
          keys: ['name.ru', 'name.en', 'name.uz']
        }
        const fuse = new Fuse(state.items, options)
        const result = fuse.search(searchValue).map(itm => itm.item)
        state.filteredItems = result
        state.count = result.length
      } else {
        state.filteredItems = state.items
        state.count = state.items.length
      }
    },
    CREATE_DOSAGE_FORM: (state: DosageFormsStore, { payload }: { payload: DosageFormsItem }) => {
      state.count += 1
      state.items.unshift(payload)
      state.filteredItems = state.items
    },
    DELETE_DOSAGE_FORM: (state: DosageFormsStore, { payload }: { payload: string }) => {
      state.count -= 1
      state.items = state.items.filter(itm => itm.id !== payload)
      state.filteredItems = state.items
    },
    UPDATE_DOSAGE_FORM: (state: DosageFormsStore, { payload }: { payload: DosageFormsItem }) => {
      for (let i = 0; i < state.items.length; i++) {
        if (state.items[i].id === payload.id) {
          state.items[i] = payload;
          break;
        }
      }
      state.filteredItems = state.items
    }
  },
  extraReducers: (builder) => {
    //getBranches
    builder.addCase(getDosageForms.pending, (state, action) => {
      state.error = null
      state.loading = true
    })
    builder.addCase(getDosageForms.fulfilled, (state, action) => {
      const {
        filters,
        data: { items, count },
      } = action.payload
      state.items = items
      state.filteredItems = items
      state.filters = filters
      state.count = count
      state.loading = false
      state.currentCount = state.items.length
    })
    builder.addCase(getDosageForms.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
    })
    //get one branch
    builder.addCase(getOneDosageForm.pending, (state, action) => {

    })
    builder.addCase(getOneDosageForm.fulfilled, (state, action) => {

    })
    builder.addCase(getOneDosageForm.rejected, (state, action) => {

    })
    //create branch
    builder.addCase(createDosageForm.pending, (state, action) => {
      state.isSubmiting = true;
      state.error = null;
    })
    builder.addCase(createDosageForm.fulfilled, (state, action) => {
      state.isSubmiting = false;
    })
    builder.addCase(createDosageForm.rejected, (state, action) => {
      state.isSubmiting = false;
      state.error = action.error;
    })
    builder.addCase(deleteDosageForm.pending, (state, action) => {

    })
    builder.addCase(deleteDosageForm.fulfilled, (state, action) => {

    })
    builder.addCase(deleteDosageForm.rejected, (state, action) => {

    })
  },
})

export const { SEARCH_DOSAGEFORMS, CREATE_DOSAGE_FORM, DELETE_DOSAGE_FORM, UPDATE_DOSAGE_FORM } = DosageFormSlice.actions
export default DosageFormSlice.reducer;
