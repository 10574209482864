import { useEffect, useState } from "react";
import Apis from "src/apis";
import ProductVariantInitial, {
  ProductVariant
} from "src/screens/catalog/products/ProductVariant/ProductVariantInitial";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { FileAndURL } from "src/screens/catalog/products/ProductVariant/ProductVariantImages";
import { toast } from "react-toastify";
import { createProductVariant, updateProductVariantInstructions } from "src/apis/types";
import {useUpdateProductVariantImagesMutation} from "src/redux/API/productVariantsAPI";

type Props = {
  product_ids?: string[]
  product_variant_id?: string
}


export const useProductVariants = (props: Props = {}) => {
  const history = useHistory()
  const { product_ids = [], product_variant_id } = props
  const { params: { id: productId }, url } = useRouteMatch<any>()
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState<ProductVariant[]>([])
  const [count, setCount] = useState(0)
  const [error, setError] = useState<null | Error>(null)
  const [item, setItem] = useState<ProductVariant>(ProductVariantInitial)
  const { state: { productVariant: editedData = {} } = {} } = useLocation<any>() || null

  const getItems = async (ids: string[] = product_ids) => {
    if (product_ids.length) {
      try {
        setLoading(true)
        const { data } = await Apis.products.getProductVariants({ product_ids: ids })
        setItems(data.items)
        setCount(data.count)
      } catch (e: any) {
        setError(e)
      } finally {
        setLoading(false)
      }
    }
  }

  const helper = (value: string | number) => {
    if (typeof value === "number") return value
    return value?.length ? Number(value) : null
  }

  const generateBody = (values: any) => {
    let expiration_period;
    if (typeof values.expiration_period === 'string') {
      expiration_period = values.expiration_period?.length ? Number(values.expiration_period) : null
    } else expiration_period = values.expiration_period
    const body: createProductVariant = {
      product_id: productId || "",
      category_id: values.category_id,
      atc_id: values.atc_id ? values.atc_id : null,
      inn_id: values.inn_id ? values.inn_id : null,
      dosage_form_id: values.dosage_form_id ? values.dosage_form_id : null,
      release_form_id: values.release_form_id ? values.release_form_id : null,
      expiration_period: helper(values.expiration_period),
      package: values.package,
      divisible: values.divisible,
      on_prescription: values.on_prescription,
      sex: values.sex,
      name: values.name,
      age_from: helper(values.age_from),
      age_to: helper(values.age_to),
      barcode: values.barcode,
      pieces_per_package: Number(values.pieces_per_package),
      unit_of_measure: values.unit_of_measure,
    }

    return body
  }

  const deleteImage = async (index: number) => {
    const id = item.images[index].id
    if (id && product_variant_id) {
      setLoading(true)
      const formData = new FormData();
      formData.append('deleted[]', id)
      const { data } = await Apis.products.updateProductVariantImages(product_variant_id, formData)
      setItem(prevState => ({ ...prevState, ...data }))
      setLoading(false)
    } else {
      const newItem = { ...item }
      newItem.images.splice(index, 1)
      setItem(newItem)
    }
  }

  const appendImages = (file: FileAndURL) => {
    const allowedTypes = ['image/jpeg', 'image/png']
    if (allowedTypes.includes(file.type)) {
      const newItem = { ...item }
      newItem.images.push(file)
      setItem(newItem)
    } else {
      toast.warning('Допускаються только изображения с типом jpg и png')
    }
  }

  const updateImages = async (productVariantId: string) => {
    const formData = new FormData();
    const toUpload = item.images.filter((image) => !image.id)
    if (toUpload.length) {
      setLoading(true)
      toUpload.forEach((image) => formData.append('images', image))
      const { data } = await Apis.products.updateProductVariantImages(productVariantId, formData)
      setItem(data)
    }
    setLoading(true)
  }

  useEffect(() => {
    if (product_variant_id && !loading) {
      getOne(product_variant_id)
    }
  }, [])

  const createOne = async (values: any) => {
    const { data } = await Apis.products.createProductVariant(generateBody(values))
    setItem(data)
    history.replace({
      pathname: `/catalog/products/${productId}/variants/${data.id}`,
      state: { productVariant: data }
    })
    history.push({ pathname: `/catalog/products/${data.product_id}/variants/${data.id}/images` })
    toast.success('Вариант продукта создан')
  }

  const updateOne = async (values: any) => {
    const { data } = await Apis.products.updateProductVariant(generateBody(values), product_variant_id || "")
    setItem(data)
    toast.success('Данные варианта продукта обновлены')
    history.replace({ pathname: url, state: { productVariant: data } })
    history.push({ pathname: url + `/images`, state: { productVariant: data } })
  }

  const updateInstructions = async (body: updateProductVariantInstructions) => {
  }

  const getOne = async (id = product_variant_id) => {
    if (id && id !== 'new') {
      setLoading(true)
      const { data } = await Apis.products.getOneProductVariant(id)
      setItem(data)
      setLoading(false)
      // return datas
    }
  }

  return {
    loading,
    items,
    reloadItems: getItems,
    reloadItem: getOne,
    error,
    count,
    getOne,
    item,
    updateImages,
    deleteImage,
    appendImages,
    updateInstructions,
    updateOne,
    createOne,
    getItems,
  }
}

export type useProductVariantType = {
  loading: boolean;
  items: ProductVariant[];
  reloadItems: (ids?: string[]) => Promise<void>;
  reloadItem: (id?: string | undefined) => Promise<void>;
  error: Error | null;
  count: number;
  getOne: (id?: string | undefined) => Promise<void>;
  item: ProductVariant;
  updateImages: (productVariantId: string) => Promise<void>;
  deleteImage: (index: number) => Promise<void>;
  appendImages: (file: FileAndURL) => void;
  updateInstructions: (body: updateProductVariantInstructions) => Promise<void>;
  updateOne: (values: any) => Promise<void>;
  createOne: (values: any) => Promise<void>;
  getItems: (ids?: string[]) => Promise<void>;
}
