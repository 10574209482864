import React, { useEffect, useState } from "react";
import { Box } from "src/components/Box/Box";
import Modal, { DefaultModalHeader, ModalBody } from "src/components/Modal";
import SearchBox from "src/components/SearchBox";
import Table from "src/components/Table";
import Apis from "src/apis";
import { TableCellProps } from "react-virtualized";

type CollectionModalType = {
    isOpen: boolean;
    onClose: () => void;
    ids: string[];
    onRowClick: (row: ProductItem) => void;
}

export type ProductItem = {
    id:               string;
    name:             Name;
    on_prescription:  boolean;
    created_at:       Date;
    updated_at:       Date | null;
    manufacturer:     Manufacturer;
    category:         Category;
    country:          Country;
    diseases:         any[];
    disease_symptoms: any[];
}

interface Category {
    id:   string;
    name: string;
    icon: null;
}

interface Country {
    id:   string;
    code: string;
    name: string;
}

interface Manufacturer {
    id:   string;
    name: string;
}

interface Name {
    en: string;
    ru: string;
    uz: string;
}


const columns = [
    {
        title: "Название",
        key: "name",
        render: (cell: TableCellProps) => cell.cellData.ru
    }
]

const CollectionModal = ({isOpen, onClose, ids, onRowClick}: CollectionModalType) => {
    const [products, setProducts] = useState<ProductItem[]>([])
    const filterProducts = (products: any[]) => {
        const filteredProducts: any[] = [];
        for(let i = 0; i < products.length; i++) {
            if(!ids.includes(products[i].id)) {
                filteredProducts.push(products[i])
            }
        }
        return filteredProducts
    }

    const getProducts = async (search: string) => {
        if(search.trim().length) {
            const { data } = await Apis.products.getProducts({limit: 50, page: 1, search})
            setProducts(filterProducts(data.items))
        } else {
            const { data } = await Apis.products.getProducts({limit: 50, page: 1})
            setProducts(filterProducts(data.items))
        }

    }

    const handleClick = (row: any) => {
        onRowClick(row)
        onClose()
    }

    useEffect(() => {
        getProducts("")
    }, [isOpen])

    return (
        <Modal isOpen={isOpen}>
            <>
                <DefaultModalHeader title="Добавление продуктов в подборку" onClose={onClose} />
                <ModalBody>
                    <SearchBox delayedSearch onSearch={(search: string) => getProducts(search)} />
                    <Box style={{ height: "60vh", overflowY: "scroll", width: "60vw" }}>
                        <Table
                            data={products}
                            totalCount={products.length}
                            columns={columns}
                            onRowClick={({row}: {row: ProductItem}) => handleClick(row)}
                        />
                    </Box>
                </ModalBody>
            </>
        </Modal>
    )
}

export default CollectionModal;