import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Apis from 'src/apis'
import axios from "axios";
import { CompanyItem, CompanyStore } from './types';

export const initialState: CompanyStore = {
  items: [],
  filters: {
    page: 1,
    limit: 200,
    search: '',
  },
  currentCount: 0,
  count: 0,
  loading: false,
  error: null,
  newCompanyForm: {},
  isSubmitting: false
}

export const getCompanies = createAsyncThunk(
  'GET_COMPANIES',
  async (payload: any = {}, thunkAPI: any) => {
    const {
      companies
    } = thunkAPI.getState()
    if ((payload.search && payload.search !== companies.filters.search) || payload.reload) {
      payload.page = 1
    }
    const filters = { ...companies.filters, ...payload }
    const { data } = await Apis.company.getCompanies({ ...filters })
    return { data, filters, reload: payload.reload };
  },
)

export const getOneCompany = createAsyncThunk(
  'GET_COMPANY',
  async (payload: any = {}, thunkAPI: any) => {
    const {
      companies: { id },
    } = thunkAPI.getState();
    const { data } = await Apis.company.getOneCompany(id)
    return { data }
  },
)

export const createCompany = createAsyncThunk(
  'CREATE_COMPANY',
  async (payload: any = {}) => {
    const { data } = await Apis.company.createCompany(payload);
    return { data }
  },
)

const CompanySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    SET_NEW_COMPANY_FORM: (state: CompanyStore, action) => {
      state.newCompanyForm = action.payload
    },
    SET_FILTERS: (state: CompanyStore, action) => {
      state.filters = { ...state.filters, ...action.payload }
    },
    CREATE_COMPANY: (state: CompanyStore, {payload}: {payload: CompanyItem}) => {
      state.count += 1
      state.items.unshift(payload)
    },
    DELETE_COMPANY: (state: CompanyStore, {payload}: {payload: string}) => {
      state.count -= 1
      state.items = state.items.filter(itm => itm.id !== payload)
    },
    UPDATE_COMPANY: (state: CompanyStore, {payload}: {payload: CompanyItem}) => {
      for(let i = 0; i < state.items.length; i++) {
        if(state.items[i].id === payload.id){
          state.items[i] = payload;
          break;
        }
      }
    }
  },
  extraReducers: (builder) => {
    //getBranches
    builder.addCase(getCompanies.pending, (state, action) => {
      state.error = null
      state.loading = true
    })
    builder.addCase(getCompanies.fulfilled, (state, action) => {
      const {
        filters,
        data: { items, count },
        reload
      } = action.payload
      if ((state.filters.search !== filters.search) || reload) {
        state.items = items
      } else {
        state.items = [...state.items, ...items]
      }
      state.filters = filters
      state.count = count
      state.loading = false
      state.currentCount = state.items.length
    })
    builder.addCase(getCompanies.rejected, (state, action) => {
      state.loading = false
      state.error = action.error
    })
    //get one branch
    builder.addCase(getOneCompany.pending, (state, action) => {

    })
    builder.addCase(getOneCompany.fulfilled, (state, action) => {

    })
    builder.addCase(getOneCompany.rejected, (state, action) => {

    })
    //create branch
    builder.addCase(createCompany.pending, (state, action) => {
      state.isSubmitting = true;
      state.error = null;
    })
    builder.addCase(createCompany.fulfilled, (state, action) => {
      state.isSubmitting = false;
    })
    builder.addCase(createCompany.rejected, (state, action) => {
      state.isSubmitting = false;
      state.error = action.error;
    })
  },
})

export default CompanySlice.reducer;
export const { CREATE_COMPANY, DELETE_COMPANY, UPDATE_COMPANY } = CompanySlice.actions
