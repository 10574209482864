import styled from 'styled-components';
import PropTypes from 'prop-types';

import { toCss, Flex, ind, defaultPx } from './helpers';
import { ThemedComponent } from "src/styles/StyleProvider";


export const propsMap = {
  display: { name: 'display', apply: ind },
  margin: { name: 'margin', apply: defaultPx },
  marginBottom: { name: 'margin-bottom', apply: defaultPx },
  marginTop: { name: 'margin-top', apply: defaultPx },
  marginLeft: { name: 'margin-left', apply: defaultPx },
  marginRight: { name: 'margin-right', apply: defaultPx },
  padding: { name: 'padding', apply: defaultPx },
  paddingBottom: { name: 'padding-bottom', apply: defaultPx },
  paddingTop: { name: 'padding-top', apply: defaultPx },
  paddingLeft: { name: 'padding-left', apply: defaultPx },
  paddingRight: { name: 'padding-right', apply: defaultPx },
  width: { name: 'width', apply: defaultPx },
  maxWidth: { name: 'max-width', apply: defaultPx },
  minWidth: { name: 'min-width', apply: defaultPx },
  height: { name: 'height', apply: defaultPx },
  maxHeight: { name: 'max-height', apply: defaultPx },
  minHeight: { name: 'min-height', apply: defaultPx },
  flexDirection: { name: 'flex-direction', apply: ind },
  flexWrap: { name: 'flex-wrap', apply: ind },
  flexFlow: { name: 'flex-flow', apply: ind },
  justifyContent: { name: 'justify-content', apply: ind },
  alignItems: { name: 'align-items', apply: ind },
  alignContent: { name: 'align-content', apply: ind },
  order: { name: 'order', apply: ind },
  flexGrow: { name: 'flex-grow', apply: ind },
  flexShrink: { name: 'flex-shrink', apply: ind },
  flexBasis: { name: 'flex-basis', apply: defaultPx },
  flex: { name: 'flex', apply: ind },
  alignSelf: { name: 'align-self', apply: ind },
  overflow: { name: 'overflow', apply: ind },
  overflowX: { name: 'overflow-x', apply: ind },
  overflowY: { name: 'overflow-y', apply: ind },
  position: { name: 'position', apply: ind },
};

export type flexDirectionValues = 'row' | 'row-reverse' | 'column' | 'column-reverse'
export const flexDirectionValues = ['row', 'row-reverse', 'column', 'column-reverse'];
export type flexWrapValues = 'nowrap' | 'wrap' | 'wrap-reverse';
export const flexWrapValues = ['nowrap', 'wrap', 'wrap-reverse'];
export type flexFlowValues = 'row' | 'row-reverse' | 'column' | 'column-reverse' | 'initial' | 'inherit'
export const flexFlowValues = flexDirectionValues
  .map((direction) => flexWrapValues.map((wrap) => `${direction} ${wrap}`))
  .reduce((acc, val) => acc.concat(val), []);

export const propTypes = {
  display: PropTypes.oneOf(['block', 'inline-block', 'inline', 'flex', 'inline-flex']),
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginLeft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginRight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  paddingBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  paddingTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  paddingLeft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  paddingRight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  flexDirection: Flex(PropTypes.oneOf(flexDirectionValues)),
  flexWrap: Flex(PropTypes.oneOf(flexWrapValues)),
  flexFlow: Flex(PropTypes.oneOf(flexFlowValues)),
  justifyContent: Flex(PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly'])),
  alignItems: Flex(PropTypes.oneOf(['flex-start', 'flex-end',  'center', 'baseline', 'stretch'])),
  alignContent: Flex(PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'stretch'])),
  order: Flex(PropTypes.number),
  flexGrow: Flex(PropTypes.number),
  flexShrink: Flex(PropTypes.number),
  flexBasis: Flex(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  flex: Flex(PropTypes.string),
  alignSelf: Flex(PropTypes.oneOf(['auto', 'flex-start', 'flex-end',  'center', 'baseline', 'stretch'])),
  overflow: PropTypes.oneOf(['visible', 'hidden', 'scroll', 'auto', 'initial', 'inherit']),
  overflowX: PropTypes.oneOf(['visible', 'hidden', 'scroll', 'auto', 'initial', 'inherit']),
  overflowY: PropTypes.oneOf(['visible', 'hidden', 'scroll', 'auto', 'initial', 'inherit']),
  position: PropTypes.oneOf(['relative', 'absolute', 'fixed', 'sticky', 'static']),
};

type Box = {
  display?: 'block' | 'inline-block' | 'inline' | 'flex' | 'inline-flex',
  margin?: number | string,
  marginTop?: number | string,
  marginBottom?: number | string,
  marginRight?: number | string,
  marginLeft?: number | string,
  padding?: number | string,
  paddingTop?: number | string,
  paddingBottom?: number | string,
  paddingRight?: number | string,
  paddingLeft?: number | string,
  width?: number | string,
  maxWidth?: number | string,
  minWidth?: number | string,
  height?: number | string,
  maxHeight?: number | string,
  minHeight?: number | string,
  flexDirection?: flexDirectionValues,
  flexWrap?: flexWrapValues,
  flexFlow?: flexFlowValues,
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly',
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch',
  alignContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'stretch',
  order?: number,
  flexGrow?: number,
  flexShrink?: number,
  flexBasis?: number | string,
  flex?: string,
  alignSelf?: 'auto' | 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch',
  overflow?: 'visible' | 'hidden' | 'scroll' | 'auto' | 'initial' | 'inherit',
  overflowX?: 'visible' | 'hidden' | 'scroll' | 'auto' | 'initial' | 'inherit',
  overflowY?: 'visible' | 'hidden' | 'scroll' | 'auto' | 'initial' | 'inherit',
  position?: 'relative' | 'absolute' | 'fixed' | 'sticky' | 'static'
}

export const Box: ThemedComponent<Box> = styled.div<Box>`
  ${(props) => toCss(props, propTypes, propsMap)}
`;
Box.displayName = 'Box';
// @ts-ignore
Box.propTypes = propTypes;
Box.defaultProps = {
  display: 'flex',
};

export default Box;