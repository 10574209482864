import React, { useMemo } from "react";
import {FormikSelect, Select} from "@med-io/neural-ui";
import Fuse from "fuse.js";
import {Dosage, useDosagesQuery} from "src/redux/API/dosageAPI";
type DosageFormSelectProps = {
  name: string,
  [x: string]: any
}
export const DosageFormSelect = (props: DosageFormSelectProps) => {

  const { data, isFetching } = useDosagesQuery({});

  const items = useMemo(() => {
    if (data?.items) {
      return data.items;
    } else {
      return [];
    }
  }, [data]);
  const searchOptions: Fuse.IFuseOptions<Dosage> = {
    keys: [
      { name: "name.ru", weight: 1 }
    ]
  };

  return (
    <FormikSelect<Dosage>
      searchOptions={searchOptions}
      options={items}
      loading={isFetching}
      getOptionLabel={(option) => option.name.ru}
      valueKey="id"
      virtualized
      placeholder="Выберите лекарственную форму"
      label="Лекарственная форма"
      size="sm"
      delayedSearch={150}
      fullWidth
      searchable
      {...props}
    />
  );
};

export default DosageFormSelect;
