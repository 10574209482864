import React, { useEffect, useState } from 'react';
import SearchBox, { SearchBoxSuffix } from "src/components/SearchBox";
import Filter from "src/icons/fluency-regular/Filter";
import  { InnerColumnWrapper } from "src/layouts/TopbarLayout";
import { useHistory, useRouteMatch } from "react-router-dom";
import Box from 'src/components/Box';
import { useRestriction } from 'src/hooks';
import { UserPermissionsEnum } from 'src/common/permissions';
import {useInnQuery} from "src/redux/API/innAPI";
import {layouts, Pagination, Table,Button} from "@med-io/neural-ui";
const {TopBarLayout} = layouts;


function INNs() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { isPermitted } = useRestriction()
  const [search,setSearch] = useState('')
  const [page,setPage] = useState(1)
  const limit = 30;
  const {data, isFetching, isError, refetch} = useInnQuery({search,page,limit})


  const columns = [
    {
      title: "Наименование",
      dataIndex: "name",
      render: (cellData:any) => cellData.ru
    },
    {
      title: "Код",
      dataIndex: "code",
      width: 200
    }
  ]


  const onSearch = (search: string) => setSearch(search)

  return (
    <TopBarLayout
      title="Международные непатентованные наименовании"
      isContentLoading={isFetching}
      isError={isError}
      onRetry={refetch}
      topBarActions={
        isPermitted(UserPermissionsEnum["inn.create"]) ? <Button onClick={() => history.push('/catalog/inns/new')} variant='primary' size='md'>Добавить МНН</Button> : <></>
      }
      bottomBar={
        <Pagination total={data?.count || 0} defaultPageSize={limit}
                    onChange={setPage} current={page}/>
      }
    >
      <InnerColumnWrapper>
        <SearchBox delayedSearch onSearch={onSearch} defaultValue={search}
                   suffix={<><SearchBoxSuffix onClick={() => console.log("Filter click")}><Filter
                     size={20} /></SearchBoxSuffix></>} />
        <Box display='block'>
          <Table
            columns={columns}
            data={data?.items || []}
            onRow={(row) => ({
              onClick: () =>  history.push({
                pathname: `/catalog/inns/${row.id}`,
                state: { inn: row }
              })
            })}
            loading={isFetching}
            size='md'
            rowKey='id'
            scroll={{y: '65vh'}}
          />
        </Box>
      </InnerColumnWrapper>
    </TopBarLayout>
  )

}

export default INNs;
