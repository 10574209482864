import httpService from 'src/apis/httpService'
import { getManufacturers, updateManufacturer, createManufacturer } from "src/apis/types";

export default {
  getManufacturers(params: getManufacturers) {
    return httpService.get('/v1/manufacturer/', { params })
  },
  getOneManufacturer(id: string) {
    return httpService.get(`/v1/manufacturer/${id}`)
  },
  createManufacturer(body: createManufacturer) {
    return httpService.post('/v1/manufacturer', body)
  },
  updateManufacturer(body: updateManufacturer, id: string) {
    return httpService.put(`/v1/manufacturer/${id}`, body)
  },
  deleteManufacturer(id: string) {
    return httpService.delete(`/v1/manufacturer/${id}`)
  },
}