import React from 'react'
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { Component } from "react";
import { useUser } from "src/hooks/useUser";

const PrivateRoute = ({component: Component, ...rest}: any) => {
  // const {isAuthenticated} = useSelector((state: RootState) => state.user)
  const {isAuthenticated} = useUser()

  return (
    <Route {...rest} render={props => (
      isAuthenticated ? (
        <Component {...props}/>
      ) : (
        <Redirect to={{
          pathname: '/login',
          state: {from: props.location}
        }}/>
      )
    )}/>
  )
}

export default PrivateRoute
