import React from 'react';
import {FormikSelect} from '@med-io/neural-ui';
import Fuse from 'fuse.js';

type SexSelectProps = {
  name: string;
  [x: string]: any
}

export const SexSelect = (props: SexSelectProps) => {
  const options = [
    { value: 0, label: 'Унисекс' },
    { value: 1, label: 'Мужчинам' },
    { value: 2, label: 'Женщинам' },
  ]

  const searchOptions: Fuse.IFuseOptions<any> = {
    keys: [
      { name: "label", weight: 1 },
    ],
  };

  return (
    <FormikSelect<typeof options[0]>
      options={options}
      getOptionLabel={(option) => option.label}
      valueKey='value'
      searchOptions={searchOptions}
      placeholder='Выберите пол'
      label='Пол'
      delayedSearch={150}
      size="sm"
      fullWidth
      {...props}
    />
  );
};

export default SexSelect;
