import React, { useMemo } from "react";
import {FormikSelect} from "@med-io/neural-ui";
import Fuse from "fuse.js";
import {Release, useReleasesQuery} from "src/redux/API/releaseAPI";

type ReleaseFormSelectProps = {
  name: string;
  [x: string]: any
}

export const ReleaseFormSelect = (props: ReleaseFormSelectProps) => {
  const { data, isFetching } = useReleasesQuery({});

  const items = useMemo(() => {
    if (data?.items) {
      return data?.items;
    } else {
      return [];
    }
  }, [data]);

  const searchOptions: Fuse.IFuseOptions<Release> = {
    keys: [
      { name: "name.ru", weight: 1 }
    ]
  };

  return (
    <FormikSelect<Release>
      searchOptions={searchOptions}
      options={items}
      loading={isFetching}
      getOptionLabel={(option) => option.name.ru}
      valueKey="id"
      virtualized
      placeholder="Выберите форму упаковки"
      label="Форма упаковки"
      delayedSearch={150}
      size="sm"
      fullWidth
      searchable
      {...props}
    />
  );
};

export default ReleaseFormSelect;
