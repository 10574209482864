import React, {useEffect, useState} from 'react';
import SearchBox, {SearchBoxSuffix} from "src/components/SearchBox";
import Filter from "src/icons/fluency-regular/Filter";
import {InnerColumnWrapper} from "src/layouts/TopbarLayout";
import {useHistory, useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import phoneNumber from "src/components/phoneNumber";
import Box from "src/components/Box";
import {useRestriction} from 'src/hooks';
import {UserPermissionsEnum} from 'src/common/permissions';
import {FeaturedIcon, layouts, Table, icons, Pagination,Button} from "@med-io/neural-ui";
import {useCompaniesQuery} from "src/redux/API/companyAPI";

const {TopBarLayout} = layouts;
const {Check} = icons;

function Companies() {
    const {path} = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch()
    const {isPermitted} = useRestriction()
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const limit = 30
    const {data, isFetching,isError,refetch} = useCompaniesQuery({page, limit, search})

    const columns = [
        {
            title: "Наименование",
            dataIndex: "name"
        },
        {
            title: "Номер телефона",
            dataIndex: "phone_number",
            render: (cellData: any) => phoneNumber(cellData)
        },
        {
            title: "Электронная почта",
            dataIndex: "email",
            width: 350
        },
        {
            title: "Адрес",
            dataIndex: "address"
        },
        {
            title: "Дистрибьютор",
            dataIndex: "is_distributor",
            render: (cellData: any) => cellData ? <FeaturedIcon icon={Check} size="sm" color="primary"/> : null
        }
    ]

    const onSearch = (search: string) => setSearch(search)

    return (
        <TopBarLayout
            title="Компании"
            isContentLoading={isFetching}
            isError={isError}
            onRetry={refetch}
            topBarActions={
                isPermitted(UserPermissionsEnum["company.create"]) ?
                    <Button onClick={() => history.push('/company/new')} variant='primary' size='md'>Добавить компанию</Button> : <></>
            }
            bottomBar={
                <Pagination total={data?.count || 0} defaultPageSize={limit}
                            onChange={setPage} current={page}/>
            }
        >
            <InnerColumnWrapper>
                <SearchBox delayedSearch onSearch={onSearch} defaultValue={search}
                           suffix={<><SearchBoxSuffix onClick={() => console.log("Filter click")}><Filter
                               size={20}/></SearchBoxSuffix></>}/>
                <Box display='block'>
                    <Table
                        columns={columns}
                        data={data?.items || []}
                        onRow={(row) => ({
                            onClick: () => history.push({
                                pathname: `/company/${row.id}`,
                                state: {company: row}
                            })
                        })}
                        loading={isFetching}
                        size="md"
                        rowKey="id"
                        scroll = {{y: '65vh'}}
                    />
                </Box>
            </InnerColumnWrapper>
        </TopBarLayout>
    )

}


export default Companies;