import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../common";
import {
    CreateOneDosageRequest,
    CreateOneDosageResponse, DeleteDosageByIdRequest, DeleteDosageByIdResponse, DosageByIdRequest, DosageByIdResponse,
    DosagesRequest,
    DosagesResponse, UpdateDosageByIdRequest, UpdateDosageByIdResponse
} from "src/redux/API/dosageAPI/dosageAPI.types";


export const dosageAPI = createApi({
    reducerPath: 'dosageAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        dosages: builder.query<DosagesResponse, DosagesRequest>({
            query: ({
                        limit = 30,
                        page= 1,
                        search = '',
                        order_by
                    }) => `/v1/form/dosage?limit=${limit}&page=${page}&search=${search}&order_by=${order_by}`
        }),
        createOneDosage: builder.mutation<CreateOneDosageResponse, CreateOneDosageRequest>({
            query: (payload) => ({
                url: `/v1/form/dosage`,
                method: 'POST',
                body: payload
            })
        }),
        dosageById: builder.query<DosageByIdResponse, DosageByIdRequest>({
            query: ({id}) => `/v1/form/dosage/${id}`
        }),
        updateDosageById: builder.mutation<UpdateDosageByIdResponse, UpdateDosageByIdRequest>({
            query: ({id, ...payload}) => ({
                url: `/v1/form/dosage/${id}`,
                method: 'PUT',
                body: payload
            })
        }),
        deleteDosage: builder.mutation<DeleteDosageByIdResponse, DeleteDosageByIdRequest>({
            query: ({id}) => ({
                url: `/v1/form/dosage/${id}`,
                method: 'DELETE'
            })
        })
    }),
})


export const {
    useDosagesQuery,
    useDosageByIdQuery,
    useCreateOneDosageMutation,
    useUpdateDosageByIdMutation,
    useDeleteDosageMutation
} = dosageAPI