import React from 'react';
import styled from "styled-components";
import NewSymptomForm from "src/screens/catalog/symptoms/NewSymptomForm";
import {layouts, Button} from "@med-io/neural-ui";
const {TopBarLayout} =layouts

const SymptomFlex = styled.div`
  display: flex;
  min-width: 0;
  height: 100%;
`

const NewSymptom = () => {
    return (
        <TopBarLayout
            title="Новый симптом"
            topBarActions={<Button variant='primary' size='md'>Сохранить</Button>}
        >
            <SymptomFlex>
                <NewSymptomForm/>
            </SymptomFlex>
        </TopBarLayout>
    )
}

export default NewSymptom;