import {createApi} from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../common";
import {
    CreateOneReleaseRequest,
    CreateOneReleaseResponse,
    DeleteReleaseByIdRequest,
    DeleteReleaseByIdResponse,
    ReleaseByIdRequest,
    ReleaseByIdResponse,
    ReleasesRequest,
    ReleasesResponse,
    UpdateReleaseByIdRequest,
    UpdateReleaseByIdResponse,
} from "src/redux/API/releaseAPI/releaseAPI.types";

export const releaseAPI = createApi({
    reducerPath: 'releaseAPI',
    baseQuery: baseQuery(),
    keepUnusedDataFor: 0,
    endpoints: builder => ({
        releases: builder.query<ReleasesResponse, ReleasesRequest>({
            query: ({
                        limit,
                        page,
                        search,
                        order_by
                    }) => `/v1/form/release?limit=${limit}&page=${page}&search=${search}&order_by=${order_by}`
        }),
        createOneRelease: builder.mutation<CreateOneReleaseResponse, CreateOneReleaseRequest>({
            query: (payload) => ({
                url: `/v1/form/release`,
                method: 'POST',
                body: payload
            })
        }),
        releaseById: builder.query<ReleaseByIdResponse, ReleaseByIdRequest>({
            query: ({id}) => `/v1/form/release/${id}`
        }),
        updateReleaseById: builder.mutation<UpdateReleaseByIdResponse, UpdateReleaseByIdRequest>({
            query: ({id, ...payload}) => ({
                url: `/v1/form/release/${id}`,
                method: 'PUT',
                body: payload
            })
        }),
        deleteReleaseById: builder.mutation<DeleteReleaseByIdResponse, DeleteReleaseByIdRequest>({
            query: ({id}) => ({
                url: `/v1/form/release/${id}`,
                method: 'DELETE'
            })
        })
    }),
})

export const {
    useReleaseByIdQuery,
    useReleasesQuery,
    useCreateOneReleaseMutation,
    useUpdateReleaseByIdMutation,
    useDeleteReleaseByIdMutation
} = releaseAPI