import httpService from 'src/apis/httpService'
import {getDiseases, createDisease, updateDisease} from './types'

export default {
    getDiseases(params: getDiseases) {
        return httpService.get('/v1/diseases', {params})
    },
    getOneDisease(id: string) {
        return httpService.get(`/v1/diseases/${id}`)
    },
    createDisease(body: createDisease) {
        return httpService.post('/v1/diseases', {body})
    },
    updateDisease(body: updateDisease) {
        return httpService.put('/v1/diseases', {body})
    },
    deleteDisease(id: string) {
        return httpService.delete(`/v1/diseases/${id}`,)
    },
}